import { useEffect } from "react";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import Sidebar from "./components/sidebar/Sidebar";
import Lottie from "lottie-react";
import loadingJson from "./icons/loading.json";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Login from "./components/login/Login";
import Connections from "./components/connections/Connections";
import ChatInterface from "./components/chat/ChatInterface";
import Embeds from "./components/embed/Embeds";
import useQueryParameter from "./components/utils/util";
import { useCreateUser, useUser } from "./components/api/user";
import { queryClient } from ".";
import { Breakpoint } from "react-socks";
import namedLogo from "./icons/name_logo.svg";
import Panel from "./components/admin/panel/Panel";
import AdminEditor from "./components/admin/editor/AdminEditor";
import AdminConnections from "./components/admin/connections/Connection";
import DashboardEmbed from "./components/embed/DashboardEmbed";
import Customers from "./components/admin/customers/Customers";
import ChatCanva from "./components/chat/ChatCanva";
import EmbeddedAdmin from "./components/admin/editor/EmbededAdmin";
import EmbededWrapper from "./components/admin/editor/EmbededWrapper";
import { v4 } from "uuid";

function App() {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  const { data: dbUser, refetch } = useUser(user?.email || "");
  const { mutate: createUser } = useCreateUser();
  const location = useLocation();
  const navigate = useNavigate();
  const embed = useQueryParameter("embed");

  useEffect(() => {
    if (user?.email) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("email", user.email);
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  }, [user?.email, location.pathname, isAuthenticated]);

  useEffect(() => {
    if (location.pathname === "/" && isAuthenticated) {
      navigate(`/chat/${v4()}`, { replace: true });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (user?.email) {
      refetch().then(() => {
        if (dbUser?.data?.email === "") {
          createUser(
            {
              email: user.email,
              firstName: user.given_name,
              lastName: user.family_name,
            } as any,
            {
              onSuccess: () => {
                refetch();
                queryClient.invalidateQueries(["connections", user.email]);
                queryClient.invalidateQueries(["dashboards", user.email]);
              },
            }
          );
        }
      });
    }
  }, [user, dbUser]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          margin: "auto",
          width: 200,
          height: "100vh",
        }}
      >
        <Lottie animationData={loadingJson} loop={true} />
      </div>
    );
  }

  if (user?.email && !user?.email_verified) {
    return (
      <div className="mobileBanner">
        <div>
          <img src={namedLogo} alt="SayData" height={36} />
        </div>
        <div className="banner">
          <div>We have send you an email to verify your account</div>
          <div>Please check your email</div>
          <div>Refresh this page once verified</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Breakpoint medium up>
        <div>
          {isAuthenticated ? (
            <div>
              {!embed && <Sidebar />}
              <Routes>
                {/* <Route path="/chat" element={<ChatInterface />} /> */}
                <Route path="/canva/:canvaId" element={<ChatCanva />} />
                <Route path="/chat/:chatId" element={<ChatInterface />} />
                <Route path="/history/:chatId" element={<ChatInterface />} />
                {/* <Route path="/dashboard/:dashboardId" element={<Dashboard />} /> */}
                <Route path="/connections" element={<Connections />} />
                <Route path="/embed/:chatId/:blockId" element={<Embeds />} />
                <Route
                  path="/embed/dashboard/:id/:activeConnection"
                  element={<EmbeddedAdmin />}
                />
                <Route
                  path="/embed/generate/:id/:activeConnection"
                  element={<EmbededWrapper />}
                />
                <Route path="/dashboard" element={<Panel />} />
                <Route
                  path="/admin/connections"
                  element={<AdminConnections />}
                />
                <Route path="/dashboard/filters" element={<Customers />} />
                <Route path="/dashboard/editor/:id" element={<AdminEditor />} />
              </Routes>
            </div>
          ) : (
            <Routes>
              <Route path="/embed/:chatId/:blockId" element={<Embeds />} />
              {/* <Route
                path="/embed/dashboard/:dashboardId/:activeConnection"
                element={<DashboardEmbed />}
              /> */}
              <Route
                path="/embed/dashboard/:id/:activeConnection"
                element={<EmbeddedAdmin />}
              />
              <Route
                path="/embed/generate/:id/:activeConnection"
                element={<EmbededWrapper />}
              />
              <Route path="*" element={<Login />} />
            </Routes>
          )}
        </div>
      </Breakpoint>
      <Breakpoint small down>
        {embed ? (
          <Routes>
            <Route path="/embed/:chatId/:blockId" element={<Embeds />} />
            <Route
              path="/embed/dashboard/:dashboardId/:activeConnection"
              element={<DashboardEmbed />}
            />
          </Routes>
        ) : (
          <div className="mobileBanner">
            <div>
              <img src={namedLogo} alt="SayData" height={36} />
            </div>
            <div className="banner">
              <div>Please use Desktop version</div>
              <div>Mobile version lauching soon</div>
              <div>Stay tuned @ SayData</div>
            </div>
          </div>
        )}
      </Breakpoint>
    </>
  );
}

export default App;
