import { Drawer, IconButton } from "@mui/material";
import classes from "./AddComponent.module.scss";
import table from "../../../icons/table.svg";
import text from "../../../icons/text.svg";
import chart from "../../../icons/chart.svg";
import container from "../../../icons/container.svg";
import select from "../../../icons/select.svg";
import date from "../../../icons/date.svg";
import { KeyboardDoubleArrowLeftRounded } from "@mui/icons-material";
import { WorkspaceItem } from "../../models/Dashboard";
import { v4 } from "uuid";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  workspaceItems: WorkspaceItem[];
  setWorkspaceItems: (items: WorkspaceItem[]) => void;
  setSelectedItem: React.Dispatch<React.SetStateAction<WorkspaceItem>>;
};

const tiles = [
  [
    {
      title: "Table",
      icon: table,
      type: "table",
    },
    {
      title: "Tile",
      icon: text,
      type: "tile",
    },
  ],
  [
    {
      title: "Chart",
      icon: chart,
      type: "graph",
    },
    {
      title: "Container",
      icon: container,
      type: "container",
    },
  ],
  [
    {
      title: "Select",
      icon: select,
      type: "select",
    },
    {
      title: "Date",
      icon: date,
      type: "date",
    },
  ],
];

export default function AddComponent({
  open,
  setOpen,
  workspaceItems,
  setWorkspaceItems,
  setSelectedItem,
}: Props) {
  const onIconClick = (type: string) => {
    const newItems = [...workspaceItems];
    const lastYItem = newItems.sort((a, b) => b?.y - a?.y)[0];
    const newY = isNaN(lastYItem?.y) ? 0 : lastYItem?.y + lastYItem?.height;
    const newItem = {
      id: v4(),
      x: 0,
      y: newY,
      title: "",
      width: type === "tile" ? 3 : 6,
      height: type === "tile" ? 3 : 6,
      type,
      dataQueryId: "",
      metadata: {},
    };
    if (type === "table") {
      newItem.metadata = {
        fontSize: 12,
        headerColor: "#F6F2F9",
        columns: [],
      };
    }
    if (type === "select" || type === "date") {
      newItem.width = 6;
      newItem.height = 2;
    }
    newItems.push(newItem);
    setWorkspaceItems(newItems);
    setSelectedItem(newItem);
  };

  return (
    <Drawer
      open={open}
      variant="persistent"
      className={classes.addDrawer}
      classes={{ paper: classes.paper }}
    >
      <div className={classes.parent}>
        <div className={classes.top}>
          <div className={classes.text}>Components</div>
          <IconButton
            onClick={() => setOpen(false)}
            className={classes.iconButton}
          >
            <KeyboardDoubleArrowLeftRounded />
          </IconButton>
        </div>
        <div className={classes.tiles}>
          {tiles.map((tile, index) => (
            <div className={classes.horizontal} key={index}>
              <div className={classes.tile}>
                <IconButton
                  className={classes.icon}
                  onClick={() => onIconClick(tile[0].type)}
                >
                  <img src={tile[0].icon} alt={tile[0].title} />
                </IconButton>
                <div className={classes.title}>{tile[0].title}</div>
              </div>
              {tile[1] &&
                <div className={classes.tile}>
                  <IconButton
                    className={classes.icon}
                    onClick={() => onIconClick(tile[1].type)}
                  >
                    <img src={tile[1].icon} alt={tile[1].title} />
                  </IconButton>
                  <div className={classes.title}>{tile[1].title}</div>
                </div>
              }
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
}
