import { Divider, Drawer, IconButton, Toolbar, Tooltip } from "@mui/material";
import classes from "./AdminEditor.module.scss";
import {
  AddCircleOutlineRounded,
  AddRounded,
  CodeRounded,
  ColorLensOutlined,
  ColorLensRounded,
  SettingsRounded,
} from "@mui/icons-material";
import { DarkTooltip } from "../../utils/util";
import clsx from "clsx";
import AddComponent from "./AddComponent";
import { useEffect, useState } from "react";
import QueriesDrawer from "./QueriesDrawer";
import { Query } from "../../models/Query";
import { WorkspaceItem } from "../../models/Dashboard";
import BrandPallete from "./BrandPallete";

type Props = {
  queries: Query[];
  workspaceItems: WorkspaceItem[];
  brandPallete: any;
  setBrandPallete: React.Dispatch<React.SetStateAction<any>>;
  setWorkspaceItems: (items: WorkspaceItem[]) => void;
  setLhsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setQuery: React.Dispatch<React.SetStateAction<Query>>;
  setQueryEditor: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedItem: React.Dispatch<React.SetStateAction<WorkspaceItem>>;
  addNewQuery: (queryType?: string) => void;
  deleteQuery: (queryId: string) => void;
};
export default function MiniDrawer({
  queries,
  workspaceItems,
  brandPallete,
  setBrandPallete,
  setWorkspaceItems,
  setQuery,
  setQueryEditor,
  addNewQuery,
  deleteQuery,
  setSelectedItem,
  setLhsOpened,
}: Props) {
  const [addComponent, setAddComponent] = useState(false);
  const [queryDrawer, setQueryDrawer] = useState(false);
  const [brandDrawer, setBrandDrawer] = useState(false);
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    setLhsOpened(addComponent || queryDrawer || brandDrawer);
    if (addComponent) setActiveTab("add");
    else if (queryDrawer) setActiveTab("query");
    else if (brandDrawer) setActiveTab("color");
    else setActiveTab("");
  }, [addComponent, queryDrawer, brandDrawer]);

  return (
    <>
      <Drawer
        variant="permanent"
        className={classes.miniDrawer}
        classes={{ paper: classes.paper }}
      >
        <div className={classes.content}>
          <DarkTooltip placement="right" title="Add">
            <IconButton
              className={
                activeTab === "add"
                  ? clsx(classes.iconButton, classes.active)
                  : classes.iconButton
              }
              onClick={() => {
                setQueryDrawer(false);
                setBrandDrawer(false);
                setAddComponent(!!!addComponent);
              }}
            >
              <AddCircleOutlineRounded />
            </IconButton>
          </DarkTooltip>
          <DarkTooltip placement="right" title="Code">
            <IconButton
              className={
                activeTab === "query"
                  ? clsx(classes.iconButton, classes.active)
                  : classes.iconButton
              }
              onClick={() => {
                setAddComponent(false);
                setBrandDrawer(false);
                setQueryDrawer(!!!queryDrawer);
              }}
            >
              <CodeRounded />
            </IconButton>
          </DarkTooltip>
          <DarkTooltip placement="right" title="Customize">
            <IconButton
              className={
                activeTab === "color"
                  ? clsx(classes.iconButton, classes.active)
                  : classes.iconButton
              }
              onClick={() => {
                setAddComponent(false);
                setQueryDrawer(false);
                setBrandDrawer(!!!brandDrawer);
              }}
            >
              <ColorLensOutlined />
            </IconButton>
          </DarkTooltip>
        </div>
        <AddComponent
          open={addComponent}
          setOpen={setAddComponent}
          workspaceItems={workspaceItems}
          setWorkspaceItems={setWorkspaceItems}
          setSelectedItem={setSelectedItem}
        />
        <QueriesDrawer
          open={queryDrawer}
          addNewQuery={addNewQuery}
          deleteQuery={deleteQuery}
          setOpen={setQueryDrawer}
          queries={queries}
          setQuery={setQuery}
          setQueryEditor={setQueryEditor}
        />
        <BrandPallete
          open={brandDrawer}
          setOpen={setBrandDrawer}
          brandPallete={brandPallete}
          setBrandPallete={setBrandPallete}
        />
      </Drawer>
    </>
  );
}
