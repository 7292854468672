import {
  AddCircleOutlineRounded,
  AutoAwesomeRounded,
  BoltRounded,
  InfoOutlined,
  PlayArrowRounded,
  SaveAltRounded,
  SendRounded,
  ThumbDownAltOutlined,
  ThumbDownAltRounded,
  ThumbUpAltOutlined,
  ThumbUpAltRounded,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Tooltip as MuiTooltip,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  CircularProgress,
  Skeleton,
  Backdrop,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import classes from "./ChatInner.module.scss";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { useEffect, useRef, useState } from "react";
import { Chat, ChatItem, Graph } from "../models/Chat";
import logo from "../../icons/logo_s.svg";
import Lottie from "lottie-react";
import loadingJson from "../../icons/loading.json";
import { useAuth0 } from "@auth0/auth0-react";
import useQueryParameter, {
  StyledSwitch,
  onSqlChange,
  stringAvatar,
} from "../utils/util";
import { useConnection, useConnectionById } from "../api/connections";
import { useAddChat, useChatDetails, usePatchChat } from "../api/chat";
import { v4 } from "uuid";
import { Editor } from "@monaco-editor/react";
import embedIcon from "../../icons/embed.svg";
import RowsView from "../utils/RowsView";
import Chart from "../utils/Chart";
import { useLocation, useParams } from "react-router-dom";
import { queryClient } from "../..";
import { useDashboards, usePatchDashboardEmail } from "../api/dashboard";
import Graphs from "../charts/Graphs";
import EditChart from "../charts/EditChart";
import Instructions from "../utils/Instructions";
import MetadataView from "../connections/MetadataView";
import { LOADING_TEXTS } from "../utils/constant";
import { useLocalStorage } from "usehooks-ts";
import Banner from "../utils/Banner";
import embedFrame from "../../icons/embedframe.svg";
import { useUser } from "../api/user";
import TileView from "../utils/TileView";
import Stripe from "../utils/Stripe";
import Embed from "../utils/Embed";
import EmbedDialog from "../utils/Embed";
import { DbConnection } from "../models/DbConnection";
import { ClarifyingQuestion } from "../models/Canva";
import clsx from "clsx";
import { WorkspaceItem } from "../models/Dashboard";
import ChartEditor from "../admin/editor/ChartEditor";

type ChatProps = {
  email: string;
  chatId: string;
  serverUser: any;
  connections: any;
  chatDetails: any;
  loadingDetails: boolean;
  refetch: any;
  addChat: any;
  addingChat: boolean;
  patchChat: any;
  addChatGraph: any;
  addChatSummary: any;
  addFollowUp: any;
  patchingChat: boolean;
  dashboards: any;
  updateDashboard: any;
  updatingDashboard: boolean;
  connection: any;
  activeConnection: any;
  refetchConnection: any;
  embed?: boolean;
  dashboardId?: string;
  filters?: any;
  loadingGraphs?: boolean;
  loadingSummary?: boolean;
  loadingFollowUp?: boolean;
  brandPalette?: any;
};

export default function ChatInner({
  email,
  chatId,
  serverUser,
  connections,
  chatDetails,
  loadingDetails,
  refetch,
  addChat,
  addingChat,
  patchChat,
  addChatGraph,
  addChatSummary,
  addFollowUp,
  patchingChat,
  dashboards,
  updateDashboard,
  updatingDashboard,
  connection,
  activeConnection,
  refetchConnection,
  embed = false,
  dashboardId = "",
  filters,
  loadingGraphs,
  loadingSummary,
  loadingFollowUp,
  brandPalette,
}: ChatProps) {
  const [loadingText, setLoadingText] = useState<string>(LOADING_TEXTS[0]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [upgradeBanner, setUpgradeBanner] = useState(false);
  const [selectedChat, setSelectedChat] = useState<ChatItem>({
    role: "system",
    blockId: "",
    markdownString: "",
    clarifyingQuestions: [],
  });
  const [openEmbedBanner, setEmbedBanner] = useState(false);
  const [openEmbed, setEmbed] = useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState("");
  const [selectedBlockType, setSelectedBlockType] = useState("");

  const [chartEditorDialog, setChartEditor] = useState(false);
  const [instructionDialog, setInstructionDialog] = useState(false);
  const [currentChartData, setCurrentChartData] = useState<ChatItem>({
    role: "system",
    blockId: "",
    markdownString: "",
    clarifyingQuestions: [],
  });

  useEffect(() => {
    if (activeConnection) {
      refetchConnection();
      if (chatHistory?.chatItems?.length === 0) {
        const newChatItems = [...chatHistory?.chatItems];
        newChatItems.push({
          role: "welcome",
          blockId: v4(),
          clarifyingQuestions: [],
        });
        chatHistory.chatItems = newChatItems;
        setChatHistory(chatHistory);
      }
    }
  }, [activeConnection]);

  useEffect(() => {
    if (brandPalette?.dark) {
      document.body.style.setProperty("--primary-dark", brandPalette?.dark);
      document.body.style.setProperty("--primary-light", brandPalette?.light);
    } else {
      document.body.style.setProperty("--primary-dark", "#4b0082");
      document.body.style.setProperty("--primary-light", "#faf8ff");
    }
  }, [brandPalette])

  const chatRef = useRef<HTMLDivElement>(null);

  const [chatHistory, setChatHistory] = useState<Chat>({
    id: chatId,
    email: email,
    connectionId: activeConnection,
    chatItems: [],
  });
  const [currentText, setCurrentText] = useState("");
  const [customFollowupText, setCustomFollowupText] = useState("");
  const [currentGraphText, setCurrentGraphText] = useState("");
  const [metadataDialog, setMetadataDialog] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showChartEditor, setShowChartEditor] = useState(false);
  const [selectedItem, setSelectedItem] = useState<WorkspaceItem>({
    id: "",
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    type: "",
  });

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    clickedChat: any,
    type: string
  ) => {
    if (serverUser?.data?.plan === "pro") {
      setAnchorEl(event.currentTarget);
      setSelectedChat(clickedChat);
      setSelectedBlockType(type);
    } else {
      setUpgradeBanner(true);
    }
  };

  const openEmbedDialog = (chat: ChatItem, type: string) => {
    setSelectedBlockType(type);
    setSelectedChat(chat);
    setEmbed(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);

  const [anchorElGraph, setAnchorElGraph] = useState<HTMLButtonElement | null>(
    null
  );

  const handleGraphClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    clickedChat: any
  ) => {
    setAnchorElGraph(event.currentTarget);
    setSelectedChat(clickedChat);
  };

  const handleGraphClose = () => {
    setAnchorElGraph(null);
  };
  const openGraphPopover = Boolean(anchorElGraph);

  const [currentFeedbackText, setCurrentFeedbackText] = useState<string>("");
  const [anchorElFeedback, setFeedbackAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const handleFeedbackClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    clickedChat: any
  ) => {
    setSelectedChat(clickedChat);
    setFeedbackAnchorEl(event.currentTarget);
  };

  const handleFeedbackClose = () => {
    setFeedbackAnchorEl(null);
  };
  const feedbackPopover = Boolean(anchorElFeedback);

  useEffect(() => {
    setChatHistory({
      id: chatId,
      email: email,
      connectionId: activeConnection,
      chatItems: [],
    });
    refetch();
  }, [chatId]);

  //   useEffect(() => {
  //     if (!activeConnection && connections?.data?.length > 0) {
  //       setActiveConnection(connections.data[0]?.id);
  //       refetchConnection();
  //     }
  //   }, [connections]);

  useEffect(() => {
    if (chatDetails?.data) {
      if (chatDetails.data?.chatItems?.length > 0) {
        setChatHistory(chatDetails.data);
      } else {
        const newChatItems = [...chatDetails.data?.chatItems];
        newChatItems.push({
          role: "welcome",
          blockId: v4(),
        });
        chatDetails.data.chatItems = newChatItems;
        setChatHistory(chatDetails.data);
      }
    }
  }, [chatDetails]);

  const changeLoadingText = () => {
    const index = Math.floor(Math.random() * LOADING_TEXTS.length);
    setLoadingText(LOADING_TEXTS[index]);
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (chatRef.current) {
        const scrollOptions: ScrollToOptions = {
          top: chatRef.current.scrollHeight,
          behavior: "smooth",
        };
        chatRef.current.scrollTo(scrollOptions);
      }
    }, 100);
  };

  const openChartEditor = (e: any, chat: ChatItem) => {
    e.stopPropagation();
    if (chat?.graph) {
      setCurrentChartData(chat);
      setChartEditor(true);
    }
  };

  const onChoiceSelect = (
    blockId: string,
    primaryIndex: number,
    secondaryIndex: number
  ) => {
    const newChatHistory = { ...chatHistory };
    const chatBlockIndex = newChatHistory?.chatItems?.findIndex(
      (chat) => chat.blockId === blockId && chat?.role === "system"
    );
    if (chatBlockIndex === -1) {
      return;
    }
    if (
      newChatHistory.chatItems[chatBlockIndex]?.clarifyingQuestions?.length > 0
    ) {
      newChatHistory.chatItems[chatBlockIndex].clarifyingQuestions[
        primaryIndex
      ].selectedIndex = secondaryIndex;
    }
    setChatHistory(newChatHistory);
  };

  const postProcessing = () => {
    const newChatHistory = { ...chatHistory };
    const chatItem = newChatHistory.chatItems?.at(-1);
    if (!chatItem) {
      return;
    }
    const followupRequest = {
      question: chatItem.question,
      threadId: newChatHistory.threadId,
      connectionId: activeConnection,
    };
    addChatGraph(chatItem)
      .then((response: any) => {
        chatItem.graph = response?.data?.graph;
        chatItem.title = response?.data?.title;
        chatItem.value = response?.data?.value;
        setChatHistory(newChatHistory);
        const request = {
          key: "new_graph",
          chatItem: chatItem,
          filters: filters ? filters : [],
        };
        patchChat(request as any);
      })
      .catch((error: any) => {
        console.log(error);
      });
    addChatSummary(chatItem)
      .then((response: any) => {
        chatItem.summary = response?.data?.summary;
        setChatHistory(newChatHistory);
        const request = {
          key: "summary",
          chatItem: chatItem,
          filters: filters ? filters : [],
        };
        patchChat(request as any);
      })
      .catch((error: any) => {
        console.log(error);
      });
    // addFollowUp(followupRequest).then((response: any) => {
    //   chatItem.followUpQuestions = response?.data;
    //   setChatHistory(newChatHistory);
    //   const request = {
    //     key: "followUpQuestions",
    //     chatItem: chatItem,
    //     filters: filters ? filters : [],
    //   };
    //   patchChat(request as any);
    // }).catch((error: any) => {
    //   console.log(error);
    // });
  };

  const handleGraphEditClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    clickedChat: ChatItem
  ) => {
    const newItem: WorkspaceItem = {
      id: clickedChat.blockId || "",
      dataQueryId: clickedChat.messageId,
      metadata: { options: clickedChat.graph },
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      type: "graph",
    };
    setSelectedItem(newItem);
    setSelectedChat(clickedChat);
    setShowChartEditor(true);
  };

  const updateItem = (id: string, key: string, value: any) => {
    key = key.replace("metadata.options.", "graph.");
    const keys = key.split(".");
    let newItem = { ...selectedChat };
    let currentItem = newItem;

    for (let i = 0; i < keys.length - 1; i++) {
      const currentKey = keys[i];
      if (!(currentKey in currentItem)) {
        (currentItem as any)[currentKey as keyof any] = {};
      }
      currentItem = (currentItem as any)[currentKey as keyof any];
    }

    const finalKey = keys[keys.length - 1];
    (currentItem as any)[finalKey as keyof any] = value;

    setSelectedChat(newItem);
  };

  const getDataFromId = (id: string | undefined) => {
    return chatHistory?.chatItems?.find(
      (chat: ChatItem) => chat.messageId === id
    )?.rows;
  };

  const onSaveChartChanges = () => {
    const request = {
      key: "new_graph",
      chatItem: selectedChat,
      filters: filters ? filters : [],
    };
    patchChat(request as any, {
      onSuccess: (response: any) => {
        const newChatHistory = { ...chatHistory };
        const chatBlockIndex = newChatHistory?.chatItems?.findIndex(
          (chat) =>
            chat.blockId === selectedChat?.blockId && chat?.role === "system"
        );
        const chatBlock = newChatHistory?.chatItems?.[chatBlockIndex];
        chatBlock.graph = response?.data?.graph;
        newChatHistory.chatItems[chatBlockIndex] = chatBlock;
        setChatHistory(newChatHistory);
        setShowChartEditor(false);
      },
    });
  };

  const onSubmit = (text: string) => {
    const newChatHistory = { ...chatHistory };

    const request = {
      chatId: chatId,
      question: text,
      blockId: v4(),
      embeddedDashboardId: dashboardId,
      connectionId: chatHistory.connectionId || activeConnection,
      filters: filters ? filters : [],
    };

    const lastChat = newChatHistory?.chatItems?.at(-1);
    if (
      lastChat?.type === "text" &&
      lastChat?.clarifyingQuestions?.length > 0
    ) {
      lastChat.clarifyingQuestions.forEach(
        (clarifyingQuestion: ClarifyingQuestion) => {
          const selectedChoice = clarifyingQuestion?.choices?.find(
            (choice: string, index: number) =>
              clarifyingQuestion.selectedIndex === index
          );
          if (selectedChoice) {
            request.question += ` ${selectedChoice}`;
          }
        }
      );
    }

    newChatHistory.chatItems?.push({
      role: "user",
      markdownString: request.question,
      summary: request.question,
      blockId: v4(),
      clarifyingQuestions: [],
    });
    setChatHistory(newChatHistory);
    setCurrentText("");
    setCustomFollowupText("");

    newChatHistory.chatItems?.push({
      role: "loading",
      blockId: v4(),
      clarifyingQuestions: [],
    });
    setChatHistory(newChatHistory);

    const loadInterval = setInterval(() => {
      changeLoadingText();
    }, 4000);

    addChat(request, {
      onSuccess: (response: any) => {
        newChatHistory.chatItems?.splice(-1);
        clearInterval(loadInterval);
        newChatHistory.chatItems?.push({
          role: "system",
          markdownString: response?.data?.markdownString,
          summary: response?.data?.summary,
          type: response?.data?.type,
          clarifyingQuestions: response?.data?.clarifyingQuestions,
          followUpQuestions: response?.data?.followUpQuestions,
          sql: response?.data?.sql,
          blockId: response?.data?.blockId,
          showSql: false,
          graph: response?.data?.graph,
          rows: response?.data?.rows,
          title: response?.data?.title,
          value: response?.data?.value,
        });
        setChatHistory(newChatHistory);
        if (
          response?.data?.type !== "text" &&
          response?.data?.rows?.length > 0
        ) {
          postProcessing();
        }
        scrollToBottom();
        if (dashboardId !== "") {
          queryClient.invalidateQueries(["chatHistoryDashboard", dashboardId]);
        }
      },
      onError: (error: any) => {
        newChatHistory.chatItems?.splice(-1);
        clearInterval(loadInterval);
        newChatHistory.chatItems?.push({
          role: "error",
          summary:
            error?.message === "Guardrails check failed"
              ? "Invalid question, please ask valid questions about the selected dataset only."
              : `Seems like we were not able to find any relevant data to answer your question or we are experincing high load.
              Can you be please elaborate a bit more on what you want to know or analyze or can point us to a relevant part of dataset to use for above question?
              Or maybe retry again once...`,
          blockId: v4(),
          clarifyingQuestions: [],
        });
        setChatHistory(newChatHistory);
        scrollToBottom();
      },
      onSettled: () => {
        setTimeout(() => {
          inputRef?.current?.focus();
        }, 100);
      },
    });
  };

  const onBlockUpdate = (blockId: string, key: string, newVal: any) => {
    const newChatItems = [...chatHistory.chatItems];
    const chatBlockIndex = newChatItems?.findIndex(
      (chat) => chat.blockId === blockId && chat?.role === "system"
    );
    if (chatBlockIndex && chatBlockIndex !== -1) {
      const chatBlock = newChatItems?.[chatBlockIndex];
      chatBlock[key as keyof ChatItem] = newVal;
      newChatItems[chatBlockIndex] = chatBlock;
      setChatHistory({ ...chatHistory, chatItems: newChatItems });
    }
  };

  const onBlockRerun = (blockId: string) => {
    const newChatHistory = [...chatHistory.chatItems];
    const chatBlockIndex = newChatHistory?.findIndex(
      (chat) => chat.blockId === blockId && chat?.role === "system"
    );
    const chatBlock = newChatHistory?.[chatBlockIndex];
    const patchRequest = {
      chatId: chatId,
      sql: chatBlock?.sql,
      blockId: chatBlock?.blockId,
      connectionId: chatHistory.connectionId || activeConnection,
    };
    const request = {
      key: "sql",
      chatItem: patchRequest,
      filters: filters ? filters : [],
    };
    patchChat(request, {
      onSuccess: (response: any) => {
        chatBlock.graph = response?.data?.graph;
        chatBlock.rows = response?.data?.rows;
        newChatHistory[chatBlockIndex] = chatBlock;
        setChatHistory({ ...chatHistory, chatItems: newChatHistory });
      },
    });
  };

  const onGraphEditSave = (blockId: string) => {
    const newChatHistory = [...chatHistory.chatItems];
    const chatBlockIndex = newChatHistory?.findIndex(
      (chat) => chat.blockId === blockId && chat?.role === "system"
    );
    const chatBlock = newChatHistory?.[chatBlockIndex];
    const patchRequest = {
      chatId: chatId,
      sql: chatBlock?.sql,
      graph: chatBlock?.graph,
      blockId: chatBlock?.blockId,
      connectionId: chatHistory.connectionId || activeConnection,
    };
    const request = {
      key: "graph",
      chatItem: patchRequest,
    };
    patchChat(request, {
      onSuccess: (response: any) => {
        chatBlock.graph = response?.data?.graph;
        newChatHistory[chatBlockIndex] = chatBlock;
        setChatHistory({ ...chatHistory, chatItems: newChatHistory });
      },
    });
  };

  const onFeedbackSave = (message?: string, blockId?: string) => {
    const selectBlock = blockId ? blockId : selectedChat?.blockId;
    const newChatHistory = [...chatHistory.chatItems];
    const chatBlockIndex = newChatHistory?.findIndex(
      (chat) => chat.blockId === selectBlock && chat?.role === "system"
    );
    const chatBlock = newChatHistory?.[chatBlockIndex];
    const patchRequest = {
      chatId: chatId,
      feedback: message ? message : currentFeedbackText,
      blockId: chatBlock?.blockId,
      type: "sql",
      connectionId: chatHistory.connectionId || activeConnection,
    };
    const request = {
      key: "feedback",
      chatItem: patchRequest,
    };
    patchChat(request, {
      onSuccess: (response: any) => {
        chatBlock.feedback = response?.data?.feedback;
        newChatHistory[chatBlockIndex] = chatBlock;
        setChatHistory({ ...chatHistory, chatItems: newChatHistory });
      },
      onSettled: () => {
        setCurrentFeedbackText("");
        handleFeedbackClose();
      },
    });
  };

  const onGraphNlpEditSave = () => {
    const newChatHistory = [...chatHistory.chatItems];
    const chatBlockIndex = newChatHistory?.findIndex(
      (chat) => chat.blockId === selectedChat.blockId && chat?.role === "system"
    );
    const chatBlock = newChatHistory?.[chatBlockIndex];
    const patchRequest = {
      chatId: chatId,
      sql: chatBlock?.sql,
      graph: chatBlock?.graph,
      blockId: chatBlock?.blockId,
      question: currentGraphText,
      rows: chatBlock?.rows,
      connectionId: chatHistory.connectionId || activeConnection,
    };
    const request = {
      key: "graph",
      chatItem: patchRequest,
    };
    patchChat(request, {
      onSuccess: (response: any) => {
        chatBlock.graph = response?.data?.graph;
        newChatHistory[chatBlockIndex] = chatBlock;
        setChatHistory({ ...chatHistory, chatItems: newChatHistory });
        setCurrentGraphText("");
        handleGraphClose();
      },
    });
  };

  const addChartToDashboard = (dashboardSelected: string) => {
    setSelectedDashboard(dashboardSelected);
    const connId = chatHistory.connectionId || activeConnection;
    const request = {
      id: dashboardSelected,
      obj: {
        chatItem: {
          blockId: selectedChat.blockId,
          rows: selectedChat.rows,
          graph: selectedChat.graph,
          sql: selectedChat.sql,
          chatId: chatId,
          title: selectedChat.title,
          value: selectedChat.value,
        },
        type: selectedBlockType,
        chatId: chatId,
        connectionId: chatHistory.connectionId || activeConnection,
        connectionType: connections?.data?.find(
          (conn: DbConnection) => conn.id === connId
        )?.type,
        x: 0,
        y: 0,
        width: selectedBlockType === "tile" ? 3 : 6,
        height: selectedBlockType === "tile" ? 3 : 6,
      },
    };
    updateDashboard(request as any, {
      onSuccess: () => {
        queryClient.invalidateQueries(["dashboard", dashboardSelected]);
        // queryClient.invalidateQueries(["dashboardQueries", dashboardSelected]);
        queryClient.invalidateQueries({
          queryKey: ["dashboardQueries", dashboardSelected],
        });
        handleClose();
      },
    });
  };

  const addChartToDashboardEmbed = (
    dashboardSelected: string,
    chatSelected: ChatItem,
    type: string
  ) => {
    setSelectedDashboard(dashboardSelected);
    const connId = chatHistory.connectionId || activeConnection;
    const request = {
      id: dashboardSelected,
      obj: {
        chatItem: {
          blockId: chatSelected.blockId,
          rows: chatSelected.rows,
          graph: chatSelected.graph,
          sql: chatSelected.sql,
          chatId: chatId,
          title: chatSelected.title,
          value: chatSelected.value,
        },
        type: type,
        chatId: chatId,
        connectionId: chatHistory.connectionId || activeConnection,
        connectionType: connections?.data?.find(
          (conn: DbConnection) => conn.id === connId
        )?.type,
        x: 0,
        y: 0,
        width: type === "tile" ? 3 : 6,
        height: type === "tile" ? 3 : 6,
      },
    };
    updateDashboard(request as any, {
      onSuccess: () => {
        queryClient.invalidateQueries(["dashboard", dashboardSelected]);
        // queryClient.invalidateQueries(["dashboardQueries", dashboardSelected]);
        queryClient.invalidateQueries({
          queryKey: ["dashboardQueries", dashboardSelected],
        });
        handleClose();
      },
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory?.chatItems?.length]);

  const handleEnterEvent = (e: any) => {
    if (e.keyCode === 13) {
      onSubmit(currentText);
    }
  };

  const handleFollowupEnterEvent = (e: any) => {
    if (e.keyCode === 13) {
      onSubmit(customFollowupText);
    }
  };

  const handleFeedbackEnterEvent = (e: any) => {
    if (e.keyCode === 13) {
      onFeedbackSave();
    }
  };

  const handleGraphEnterEvent = (e: any) => {
    if (e.keyCode === 13) {
      onGraphNlpEditSave();
    }
  };

  const onPredefinedClick = (text: string) => {
    setCurrentText(text);
    onSubmit(text);
  };

  return (
    <div
      className={classes.root}
      style={{
        marginLeft: !!!embed ? "0rem" : "14rem",
        marginTop: !!!embed ? "0rem" : "1rem",
      }}
    >
      <div className={classes.top} ref={chatRef}>
        {chatHistory?.chatItems?.map((chat, index) => (
          <div className={classes.content} key={chat?.blockId + chat?.role}>
            <div className={classes.innerDiv}>
              {chat.role !== "user" ? (
                <img
                  className={classes.logoTile}
                  src={logo}
                  alt="Logo"
                  height={30}
                ></img>
              ) : (
                <Avatar className={classes.logoTile} {...stringAvatar(email)} />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "95%",
                  gap: "12px",
                }}
              >
                {chat?.role === "loading" && (
                  <div className={classes.markdown} style={{ marginTop: 14 }}>
                    {loadingText}
                  </div>
                )}
                {chat?.role === "welcome" && (
                  <div className={classes.welcome}>
                    <div>Create visualisations for your dashboards here.</div>
                    <div>
                      Just type your questions to get started. Generate
                      embeddable links once done.
                    </div>
                    {connection?.data?.sampleQuestions && (
                      <>
                        <div>
                          Below are some common questions asked on this dataset
                          :
                        </div>
                        <div className={classes.sampleQuestions}>
                          {connection?.data?.sampleQuestions?.map(
                            (question: string, index: number) => (
                              <div
                                className={classes.preDefined}
                                key={index}
                                style={{
                                  background: brandPalette?.light || "#efe9ff",
                                }}
                              >
                                <Button
                                  className={classes.textq}
                                  onClick={() => onPredefinedClick(question)}
                                >
                                  {question}
                                </Button>
                              </div>
                            )
                          )}
                        </div>
                      </>
                    )}
                    {!!!embed && (
                      <div className={classes.sampleQuestions}>
                        Or let's start by exploring the dataset :
                        <div className={classes.preDefined}>
                          <Button
                            className={classes.textq}
                            onClick={() => setMetadataDialog(true)}
                          >
                            Explore Dataset
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {chat?.role === "system" && chat?.sql && (
                  <>
                    <div>
                      {!!!embed && (
                        <div className={classes.sqlBar}>
                          <FormControlLabel
                            control={
                              <StyledSwitch
                                shade="#4B0082"
                                checked={chat?.showSql}
                                onChange={(e) =>
                                  onBlockUpdate(
                                    chat?.blockId,
                                    "showSql",
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            label="Show query"
                            labelPlacement="start"
                            style={{ padding: "8px 0px 8px 0px" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              marginLeft: "auto",
                            }}
                          >
                            <IconButton
                              className={classes.iconButton}
                              onClick={() =>
                                onFeedbackSave("Approved", chat?.blockId)
                              }
                            >
                              {chat?.feedback === "Approved" ? (
                                <ThumbUpAltRounded
                                  style={{ fontSize: 18, color: "green" }}
                                />
                              ) : (
                                <ThumbUpAltOutlined style={{ fontSize: 18 }} />
                              )}
                            </IconButton>
                            <IconButton
                              className={classes.iconButton}
                              onClick={(e) => handleFeedbackClick(e, chat)}
                            >
                              {chat?.feedback &&
                              chat?.feedback !== "Approved" ? (
                                <ThumbDownAltRounded
                                  style={{
                                    fontSize: 18,
                                    color: "rgb(218,55,55)",
                                  }}
                                />
                              ) : (
                                <ThumbDownAltOutlined
                                  style={{ fontSize: 18 }}
                                />
                              )}
                            </IconButton>
                            {/* {chat?.showSql && (
                              <Button
                                className={classes.textButton}
                                style={{ marginLeft: "4px" }}
                                size="small"
                                onClick={(e) => onBlockRerun(chat.blockId)}
                                startIcon={
                                  <PlayArrowRounded style={{ fontSize: 18 }} />
                                }
                              >
                                Rerun
                              </Button>
                            )} */}
                          </div>
                        </div>
                      )}
                    </div>
                    {chat?.showSql && (
                      <div className={classes.sqlEditor}>
                        <Editor
                          value={onSqlChange(chat?.sql)}
                          onChange={(value, _) =>
                            value && onBlockUpdate(chat?.blockId, "sql", value)
                          }
                          language="sql"
                          theme="vs-dark"
                          height="30vh"
                          options={{
                            fontSize: 12,
                            scrollbar: {
                              horizontal: "hidden",
                              verticalScrollbarSize: 8,
                            },
                            minimap: { enabled: false },
                          }}
                        />
                      </div>
                    )}
                    {!!!embed && (
                      <div className={classes.nudge}>
                        <BoltRounded sx={{ fontSize: 20 }} /> Want higher
                        accuracy?
                        <a
                          href="https://calendar.app.google/Ha83FJLswBiXTKCe6"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Talk to us
                        </a>
                        for a custom AI solution on your data.
                      </div>
                    )}
                  </>
                )}
                {chat?.role === "system" && chat?.type === "text" && (
                  <div className={classes.clarifyingQuestions}>
                    {chat?.clarifyingQuestions?.map(
                      (q: ClarifyingQuestion, i: number) => (
                        <div className={classes.clarify} key={i}>
                          <div className={classes.question}>{q.question}</div>
                          <div className={classes.choices}>
                            {q?.choices?.map((a: string, j: number) => (
                              <div
                                key={j}
                                className={
                                  q.selectedIndex === j
                                    ? clsx(classes.choice, classes.selected)
                                    : classes.choice
                                }
                                onClick={() =>
                                  onChoiceSelect(chat?.blockId, i, j)
                                }
                              >
                                {a}
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    )}
                    <div className={classes.proceed}>
                      <TextField
                        value={customFollowupText}
                        onChange={(e) => setCustomFollowupText(e.target.value)}
                        onKeyDown={handleFollowupEnterEvent}
                        sx={{
                          flex: 1,
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: brandPalette?.dark || "#4B0082",
                              borderWidth: 1,
                            },
                          },
                        }}
                        placeholder="anyother thing you want to add"
                        ref={inputRef}
                        InputProps={{
                          disabled: addingChat,
                          autoFocus: true,
                          style: {
                            borderRadius: 8,
                            height: 36,
                          },
                        }}
                      ></TextField>
                      <Button
                        className={classes.choiceSubmit}
                        style={{ background: brandPalette?.dark }}
                        variant="contained"
                        size="small"
                        onClick={() => onSubmit(customFollowupText)}
                      >
                        Proceed
                      </Button>
                    </div>
                  </div>
                )}
                {chat?.role === "system" &&
                  chat?.type !== "text" &&
                  (chat?.rows?.length > 0 ? (
                    <RowsView
                      rows={chat?.rows || []}
                      serverUser={serverUser}
                      chat={chat}
                      addChartToDashboard={addChartToDashboard}
                      addChartToDashboardEmbed={addChartToDashboardEmbed}
                      embed={embed}
                      dashboards={dashboards}
                      updatingDashboard={updatingDashboard}
                      selectedDashboard={
                        !!!embed ? selectedDashboard : dashboardId
                      }
                      setSelectedChat={setSelectedChat}
                      setSelectedBlockType={setSelectedBlockType}
                      setUpgradeBanner={setUpgradeBanner}
                      openEmbedDialog={openEmbedDialog}
                      blockId={chat?.blockId}
                      brandPalette={!!!embed ? {} : brandPalette}
                      // loading={patchingChat}
                      showEmbed={!!!embed}
                    />
                  ) : (
                    <ReactMarkdown
                      className={classes.markdown}
                      children={`Looks like no data found for your request, would you like to tweak your search query and try again?`}
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                    />
                  ))}
                {loadingGraphs &&
                  index === chatHistory?.chatItems?.length - 1 && (
                    <div className={classes.loading}>
                      Looking for relevant graph...
                      <div
                        style={{
                          width: 50,
                          height: 50,
                        }}
                      >
                        <Lottie animationData={loadingJson} loop={true} />
                      </div>
                    </div>
                  )}
                {chat?.role === "system" &&
                  chat?.graph?.chartType !== "" &&
                  chat.rows?.length >= 2 && (
                    <div className={classes.graph}>
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          marginLeft: "auto",
                          marginTop: "8px",
                        }}
                      >
                        <Button
                          style={{ marginLeft: "auto" }}
                          className={!!!dashboardId ? classes.textButton : classes.embed}
                          size="small"
                          // onClick={(e) => handleGraphClick(e, chat)}
                          onClick={(e) => handleGraphEditClick(e, chat)}
                          startIcon={<AutoAwesomeRounded />}
                        >
                          Edit
                        </Button>
                        <Button
                          style={{ marginLeft: "auto" }}
                          className={!!!dashboardId ? classes.textButton : classes.embed}
                          onClick={(e) =>
                            !!!embed
                              ? handleClick(e, chat, "graph")
                              : addChartToDashboardEmbed(
                                  dashboardId,
                                  chat,
                                  "graph"
                                )
                          }
                          size="small"
                          startIcon={<AddCircleOutlineRounded />}
                        >
                          Add to Dashboard
                        </Button>
                        {!!!embed && (
                          <Button
                            style={{ marginLeft: "auto" }}
                            className={classes.textButton}
                            onClick={() =>
                              serverUser?.data?.plan === "pro"
                                ? openEmbedDialog(chat, "graph")
                                : setUpgradeBanner(true)
                            }
                            size="small"
                            startIcon={
                              <img
                                src={embedIcon}
                                alt="Embed"
                                height={14}
                                style={{ paddingLeft: "5px" }}
                              />
                            }
                          >
                            Embed
                          </Button>
                        )}
                      </div>
                      <div className={classes.container}>
                        <Graphs rows={chat?.rows} options={chat?.graph} />
                      </div>
                    </div>
                  )}
                {chat?.rows?.length === 1 && chat?.value && (
                  <div className={classes.tile}>
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        marginLeft: "auto",
                        marginTop: "8px",
                        marginBottom: "8px",
                      }}
                    >
                      {/* <Button
                              style={{ marginLeft: "auto" }}
                              className={classes.textButton}
                              size="small"
                              onClick={(e) => handleGraphClick(e, chat)}
                              startIcon={<AutoAwesomeRounded />}
                            >
                              Edit
                            </Button> */}
                      {serverUser?.data?.plan === "pro" && (
                        <Button
                          style={{ marginLeft: "auto" }}
                          className={!!!dashboardId ? classes.textButton : classes.embed}
                          onClick={(e) =>
                            !!!embed
                              ? handleClick(e, chat, "tile")
                              : addChartToDashboardEmbed(
                                  dashboardId,
                                  chat,
                                  "tile"
                                )
                          }
                          size="small"
                          startIcon={<AddCircleOutlineRounded />}
                        >
                          Add to Dashboard
                        </Button>
                      )}
                      {!!!embed && (
                        <Button
                          style={{ marginLeft: "auto" }}
                          className={classes.textButton}
                          onClick={() =>
                            serverUser?.data?.plan === "pro"
                              ? openEmbedDialog(chat, "tile")
                              : setUpgradeBanner(true)
                          }
                          size="small"
                          startIcon={
                            <img
                              src={embedIcon}
                              alt="Embed"
                              height={14}
                              style={{ paddingLeft: "5px" }}
                            />
                          }
                        >
                          Embed
                        </Button>
                      )}
                    </div>
                    <TileView
                      title={chat?.title}
                      value={chat?.rows?.[0][chat?.value]}
                    />
                  </div>
                )}
                {loadingSummary &&
                  index === chatHistory?.chatItems?.length - 1 && (
                    <div className={classes.loading}>
                      Generating summary and insights...
                      <div
                        style={{
                          width: 50,
                          height: 50,
                        }}
                      >
                        <Lottie animationData={loadingJson} loop={true} />
                      </div>
                    </div>
                  )}
                {chat?.summary &&
                  (chat?.role === "system" ? (
                    <>
                      {/* <div className={classes.explanation}>
                          <FormControlLabel
                            control={
                              <StyledSwitch
                                shade="#4B0082"
                                checked={chat?.showExplanation}
                                onChange={(e) =>
                                  onBlockUpdate(
                                    chat?.blockId,
                                    "showExplanation",
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            label="Show explanation"
                            labelPlacement="start"
                            style={{ padding: "8px 0px 8px 0px" }}
                          />
                        </div> */}
                      {
                        <div
                          style={{
                            border: "1px solid rgba(224, 224, 224, 1)",
                            borderRadius: "12px",
                            padding: "12px",
                          }}
                        >
                          <ReactMarkdown
                            className={classes.markdown}
                            children={chat?.summary}
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw]}
                          />
                        </div>
                      }
                    </>
                  ) : (
                    <ReactMarkdown
                      className={classes.markdown}
                      children={chat?.summary}
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                    />
                  ))}
                {loadingFollowUp &&
                  index === chatHistory?.chatItems?.length - 1 && (
                    <div className={classes.loading}>
                      Generating follow-up question...
                      <div
                        style={{
                          width: 50,
                          height: 50,
                        }}
                      >
                        <Lottie animationData={loadingJson} loop={true} />
                      </div>
                    </div>
                  )}
                {chat?.followUpQuestions && (
                  <div className={classes.suggestions}>
                    {chat?.followUpQuestions?.map((q: string, i: number) => (
                      <div
                        className={classes.block}
                        key={i}
                        onClick={(e) => {
                          setCurrentText(q);
                          onSubmit(q);
                        }}
                      >
                        <AutoAwesomeRounded />
                        <div className={classes.title}>{q}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <TextField
        value={currentText}
        onChange={(e) => setCurrentText(e.target.value)}
        onKeyDown={handleEnterEvent}
        sx={{
          width: "calc(100% - 256px)",
          marginTop: "auto",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "0px 24px 24px 24px",
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: brandPalette?.dark || "#4B0082",
              borderWidth: 1,
            },
          },
        }}
        placeholder="Ask a question"
        ref={inputRef}
        InputProps={{
          disabled: addingChat,
          autoFocus: true,
          style: {
            borderRadius: 8,
            boxShadow:
              "0px 2px 15px 0px " + (brandPalette?.dark || "#4B0082") + "60",
            height: 44,
          },
          startAdornment: !!!embed && (
            <InputAdornment position="start">
              <MuiTooltip
                arrow
                placement="top"
                title="Tips to get better results"
              >
                <IconButton
                  onClick={() => setInstructionDialog(true)}
                  className={classes.iconButton}
                >
                  <InfoOutlined />
                </IconButton>
              </MuiTooltip>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {addingChat ? (
                <CircularProgress size={20} sx={{ color: "#4B0082" }} />
              ) : (
                <IconButton
                  onClick={() => onSubmit(currentText)}
                  className={classes.iconButton}
                >
                  <SendRounded />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      ></TextField>
      <Dialog
        open={showChartEditor}
        className={classes.editChartDialog}
        classes={{ paper: classes.paper }}
        onClose={() => setShowChartEditor(false)}
        fullScreen
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={patchingChat}
        >
          <CircularProgress sx={{ color: brandPalette?.dark || "#4B0082" }} />
        </Backdrop>
        <DialogTitle className={classes.titleContainer}>
          <div className={classes.title}>Edit Chart</div>
          <div className={classes.right}>

            <Button
              className={classes.saveButton}
              variant="contained"
              onClick={() => onSaveChartChanges()}
            >
              Save
            </Button>
            <Button
              className={classes.closeButton}
              variant="contained"
              onClick={() => setShowChartEditor(false)}
            >
              Close
            </Button>
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.graph}>
            <Graphs
              key={JSON.stringify(selectedChat?.graph)}
              rows={selectedChat?.rows}
              options={selectedChat?.graph}
            />
          </div>
          <div className={classes.editor}>
            <ChartEditor
              selectedItem={selectedItem}
              updateItem={updateItem}
              getDataFromQuery={getDataFromId}
            />
          </div>
        </DialogContent>
      </Dialog>
      <Popover
        id="more-options-connections"
        open={openPopover}
        anchorEl={anchorEl}
        className={classes.addDashboardPopover}
        classes={{ paper: classes.paper }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={classes.list}>
          {dashboards?.data?.map((dashboard: any) => (
            <Button
              key={dashboard?.id}
              size="small"
              variant="text"
              onClick={() => addChartToDashboard(dashboard?.id)}
              endIcon={
                updatingDashboard && selectedDashboard === dashboard?.id ? (
                  <CircularProgress sx={{ color: "#4B0082" }} size={12} />
                ) : (
                  <></>
                )
              }
            >
              {dashboard?.title}
            </Button>
          ))}
        </div>
      </Popover>
      {currentChartData?.graph && (
        <EditChart
          openChartEditor={chartEditorDialog}
          setChartEditor={setChartEditor}
          chartData={currentChartData}
          onGraphEditSave={onGraphEditSave}
          onBlockUpdate={onBlockUpdate}
        />
      )}
      <Popover
        id="graph-edit"
        open={openGraphPopover}
        anchorEl={anchorElGraph}
        className={classes.graphPopper}
        classes={{ paper: classes.paper }}
        onClose={handleGraphClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <TextField
          value={currentGraphText}
          onChange={(e) => setCurrentGraphText(e.target.value)}
          onKeyDown={handleGraphEnterEvent}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                // borderColor: "#4B0082",
                // borderWidth: 1,
                border: "transparent",
              },
              fieldset: {
                border: "transparent",
              },
            },
          }}
          placeholder="convert it to line, area, bar..."
          InputProps={{
            style: {
              // borderRadius: 6,
              // boxShadow: "0px 2px 15px 0px rgba(115, 40, 171, 0.60)",
              height: 36,
              width: 400,
            },
            endAdornment: (
              <InputAdornment position="end">
                {patchingChat ? (
                  <CircularProgress size={16} sx={{ color: "#4B0082" }} />
                ) : (
                  <IconButton
                    onClick={onGraphNlpEditSave}
                    className={classes.iconButton}
                  >
                    <SendRounded />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        ></TextField>
        {!!!embed && (
          <div className={classes.footerNudge}>
            <BoltRounded sx={{ fontSize: 16, color: "#4B0082" }} /> Get custom
            visualisation, tailored to your business.{" "}
            <a
              href="https://calendar.app.google/Ha83FJLswBiXTKCe6"
              target="_blank"
              rel="noreferrer"
            >
              Book demo now
            </a>
          </div>
        )}
      </Popover>
      <Instructions
        openInstructions={instructionDialog}
        page={1}
        setInstructions={setInstructionDialog}
        setExplore={setMetadataDialog}
      />
      <MetadataView
        metadataDialog={metadataDialog}
        id={activeConnection}
        setMetadataDialog={setMetadataDialog}
      />
      <Banner
        openBanner={openEmbedBanner}
        setBanner={setEmbedBanner}
        title="Embed Into Your App"
        media={embedFrame}
        description="Get embeddable link to direclty use them into supporting applications, such as Notion or get iframe for a webpage or any other supporting application."
        ctaLink="https://calendar.app.google/Ha83FJLswBiXTKCe6"
      />
      <Popover
        id="feedback-share"
        open={feedbackPopover}
        anchorEl={anchorElFeedback}
        className={classes.feedbackPopper}
        classes={{ paper: classes.paper }}
        onClose={handleFeedbackClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <TextField
          value={currentFeedbackText}
          onChange={(e) => setCurrentFeedbackText(e.target.value)}
          // onKeyDown={handleFeedbackEnterEvent}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                border: "transparent",
              },
              fieldset: {
                border: "transparent",
              },
            },
          }}
          multiline
          rows={4}
          maxRows={4}
          placeholder="improve accuracy by explaining what went wrong..."
          InputProps={{
            style: {
              width: 500,
            },
          }}
        ></TextField>
        <div className={classes.footer}>
          <Button
            variant="contained"
            size="small"
            onClick={() => onFeedbackSave()}
          >
            Submit
          </Button>
        </div>
      </Popover>
      <Stripe
        openStripe={upgradeBanner}
        setStripe={setUpgradeBanner}
        email={email}
      />
      <EmbedDialog
        embedLink={`https://app.saydata.tech/embed/${chatId}/${selectedChat.blockId}?type=${selectedBlockType}&embed=true`}
        iframeText={`<iframe 
        width="800"
        height="600" 
        frameborder="0"
        src="https://app.saydata.tech/embed/${chatId}/${selectedChat.blockId}?type=${selectedBlockType}&embed=true">
       </iframe>`}
        embedDialog={openEmbed}
        setEmbedDialog={setEmbed}
      />
    </div>
  );
}
