import { useMutation, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { BE_SERVER } from "../../utils/constant";
import { queryClient } from "../../..";

export const useDashboards = (apiKey: string) => {
  return useQuery(["dashboards", apiKey], async () => {
    const res = await fetch(`${BE_SERVER}/api/dashboards`, {
      headers: {
        ApiKey: `${apiKey}`,
      },
    });

    if (!res.ok) {
      throw new Error("Error while fetching dashboards");
    }

    return res.json();
  });
};

export const useDashboardDetails = (id: string, apiKey: string) => {
  return useQuery(["dashboard", id], async () => {
    const res = await fetch(
      `${BE_SERVER}/api/dashboards/${id}`,
      {
        headers: {
          ApiKey: `${apiKey}`,
        },
      }
    );

    if (!res.ok) {
      throw new Error("Error while fetching dashboard details");
    }

    return res.json();
  });
};

export const useAddDashboard = (apiKey: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request: any) => {
      const res = await fetch(
        `${BE_SERVER}/api/dashboards/${request.id}`,
        {
          method: "POST",
          headers: {
            ApiKey: `${apiKey}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(request),
        }
      );

      if (!res.ok) {
        throw new Error("Error while adding dashboard details");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dashboards", apiKey]);
        enqueueSnackbar("Dashboard created", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Error while creating dashboard", { variant: "error" });
      },
    }
  );
};

export const usePatchDashboard = (dashboardId: string, apiKey: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request: any) => {
      const res = await fetch(
        `${BE_SERVER}/api/dashboards/${dashboardId}`,
        {
          method: "PATCH",
          headers: {
            ApiKey: `${apiKey}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(request),
        }
      );

      if (!res.ok) {
        throw new Error("Error while updating dashboard");
      }

      return res.text();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dashboard", dashboardId]);
        enqueueSnackbar("Dashboard updated", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Error while saving changes", { variant: "error" });
      },
    }
  );
};

export const usePatchDashboardApiKey = (apiKey: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request: any) => {
      const res = await fetch(
        `${BE_SERVER}/api/dashboards/${request.id}/item`,
        {
          method: "POST",
          headers: {
            ApiKey: `${apiKey}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(request.obj),
        }
      );

      if (!res.ok) {
        throw new Error("Error while updating dashboard");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dashboards", apiKey]);
        enqueueSnackbar("Block added to dashboard", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Error while adding block to dashboard", { variant: "error" });
      },
    }
  );
};

export const usePatchItem = (dashboardId: string, apiKey: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request: any) => {
      const headers = {
        ApiKey: `${apiKey}`,
        "Content-Type": "application/json",
      };
      const res = await fetch(
        `${BE_SERVER}/api/dashboards/${dashboardId}/item`,
        {
          method: "PATCH",
          headers: headers,
          body: JSON.stringify(request),
        }
      );

      if (!res.ok) {
        throw new Error("Error while updating item");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        enqueueSnackbar("Updated successfully", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Error while updating", { variant: "error" });
      }
    }
  );
};

export const useGenerateEmbedId = (dashboardId: string, apiKey: string) => {
  return useMutation(async (request: any) => {
    const headers = {
      ApiKey: `${apiKey}`,
      "Content-Type": "application/json",
    };
    const res = await fetch(
      `${BE_SERVER}/api/dashboards/${dashboardId}/embed`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(request),
      }
    );

    if (!res.ok) {
      const message = await res.json();
      throw new Error(message?.error);
    }

    return res.json();
  });
};

export const useDashboardChatHistory = (id: string, apiKey: string) => {
  return useQuery(["chatHistoryDashboard", id], async () => {
    const headers = {
      ApiKey: `${apiKey}`,
    };
    const res = await fetch(`${BE_SERVER}/api/chat/dashboard/${id}/history`, {
      headers: headers,
    });

    if (!res.ok) {
      throw new Error("Error while fetching dashboard chat history");
    }

    return res.json();
  });
};
