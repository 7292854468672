import { useAuth0 } from "@auth0/auth0-react";
import classes from "./Login.module.scss";
import { Button } from "@mui/material";
import { useEffect } from "react";

export default function Login() {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({
      appState: { targetUrl: window.location.pathname },
    });
  }, []);

  return <></>;

  // return (
  //   <div className={classes.root}>
  //     Loading...
  //     {/* <div className={classes.leftPane}>
  //       <div className={classes.title}>Saydata</div>
  //       <Button
  //         onClick={() =>
  //           loginWithRedirect({
  //             appState: { targetUrl: window.location.pathname },
  //           })
  //         }
  //       >
  //         Login
  //       </Button>
  //     </div>
  //     <div className={classes.righPane}>Right</div> */}
  //   </div>
  // );
}
