import { useQuery, useQueryClient } from 'react-query';

export const useQueryVariable = () => {
  const queryClient = useQueryClient();

  const dynamicStateQuery = useQuery('vs', () => {});
  const { data: vs } = dynamicStateQuery;

  const updateVs = (key:string, newValue:any) => {
    if (key !== '') {
      const newState = { ...(vs ?? {}), [key]: { value: newValue } };
      queryClient.setQueryData('vs', newState);
    }
  };

  return { vs, updateVs };
};