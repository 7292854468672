import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Divider,
  IconButton,
  Popover,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { WorkspaceItem } from "../../models/Dashboard";
import classes from "./ItemEditor.module.scss";
import bar from "../../../icons/bar.svg";
import pie from "../../../icons/pie.svg";
import area from "../../../icons/area.svg";
import line from "../../../icons/line.svg";
import {
  AddCircleOutlineRounded,
  AlignHorizontalCenterRounded,
  AlignHorizontalLeftRounded,
  AlignHorizontalRightRounded,
  KeyboardArrowDownRounded,
  RemoveCircleOutline,
} from "@mui/icons-material";
import { useState } from "react";
import { SketchPicker } from "react-color";
import { DarkTooltip } from "../../utils/util";
import ColorPicker from "../utils/ColorPIcker";

type Props = {
  selectedItem: WorkspaceItem;
  updateItem: (id: string, key: string, value: any) => void;
  getDataFromQuery: (queryId: string | undefined) => any;
};

export default function ChartEditor({
  selectedItem,
  updateItem,
  getDataFromQuery,
}: Props) {
  const rows = getDataFromQuery(selectedItem.dataQueryId);
  const cols = rows?.length > 0 ? Object.keys(rows?.[0]) : [];

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [anchorColorPicker, setAnchorColorPicker] =
    useState<HTMLDivElement | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);

  const handleColorPickerClick = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    setAnchorColorPicker(event.currentTarget);
  };

  const handlePickerClose = () => {
    setAnchorColorPicker(null);
  };
  const openColorPicker = Boolean(anchorColorPicker);

  const addYCol = () => {
    const yCols = selectedItem?.metadata?.options?.yCols || [];
    yCols.push({
      yCol: "",
      color: "#4B0082",
    });
    updateItem(selectedItem.id, "metadata.options.yCols", yCols);
  };

  const removeYCol = (index: number) => {
    const yCols = selectedItem?.metadata?.options?.yCols || [];
    yCols.splice(index, 1);
    updateItem(selectedItem.id, "metadata.options.yCols", yCols);
  };
  return (
    <div className={classes.panel}>
      <div className={classes.chart}>
        <div className={classes.text}>Chart type</div>
        <ToggleButtonGroup
          value={selectedItem?.metadata?.options?.chartType || "bar"}
          sx={{
            margin: "auto",
          }}
          exclusive
          onChange={(e, value) =>
            updateItem(selectedItem.id, "metadata.options.chartType", value)
          }
        >
          <ToggleButton value="bar">
            <img src={bar} alt="bar" height={36} />
          </ToggleButton>
          <ToggleButton value="area">
            <img src={area} alt="area" height={36} />
          </ToggleButton>
          <ToggleButton value="line">
            <img src={line} alt="line" height={36} />
          </ToggleButton>
          <ToggleButton value="pie">
            <img src={pie} alt="pie" height={36} />
          </ToggleButton>
        </ToggleButtonGroup>
        <Accordion className={classes.accordionAxis} defaultExpanded>
          <AccordionSummary
            expandIcon={<KeyboardArrowDownRounded />}
            className={classes.summary}
          >
            X axis
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div className={classes.option}>
              <div>Column</div>
              <Autocomplete
                options={cols}
                className={classes.autocomplete}
                value={selectedItem?.metadata?.options?.xCol || null}
                getOptionLabel={(option: string) => option}
                onChange={(e, value) =>
                  value &&
                  updateItem(selectedItem.id, "metadata.options.xCol", value)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <KeyboardArrowDownRounded sx={{ fontSize: 18 }} />
                      ),
                      style: {
                        height: 24,
                        fontSize: 12,
                        color: "#000",
                        padding: "0px 8px 0px 4px",
                      },
                    }}
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li
                    {...props}
                    style={{
                      fontWeight: selected ? 600 : 400,
                      fontSize: 12,
                      padding: "4px 16px",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    {option}
                  </li>
                )}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordionAxis} defaultExpanded>
          <AccordionSummary
            expandIcon={<KeyboardArrowDownRounded />}
            className={classes.summary}
          >
            Y axis
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            {selectedItem?.metadata?.options?.yCols?.length > 0 &&
              selectedItem?.metadata?.options?.yCols?.map(
                (yColObj: any, index: number) => (
                  <div className={classes.axis} key={index}>
                    <Divider>
                      Axis {index + 1}{" "}
                      <DarkTooltip title="Remove" placement="top">
                        <IconButton
                          onClick={() => removeYCol(index)}
                          className={classes.removeButton}
                        >
                          <RemoveCircleOutline />
                        </IconButton>
                      </DarkTooltip>
                    </Divider>
                    <div className={classes.option}>
                      <div>Column</div>
                      <Autocomplete
                        options={cols}
                        className={classes.autocomplete}
                        value={yColObj?.yCol || null}
                        getOptionLabel={(option: string) => option}
                        onChange={(e, value) => {
                          if (value) {
                            updateItem(
                              selectedItem.id,
                              `metadata.options.yCols.${index}.yCol`,
                              value
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <KeyboardArrowDownRounded
                                  sx={{ fontSize: 18 }}
                                />
                              ),
                              style: {
                                height: 24,
                                fontSize: 12,
                                color: "#000",
                                padding: "0px 8px 0px 4px",
                              },
                            }}
                          />
                        )}
                        renderOption={(props, option, { selected }) => (
                          <li
                            {...props}
                            style={{
                              fontWeight: selected ? 600 : 400,
                              fontSize: 12,
                              padding: "4px 16px",
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            {option}
                          </li>
                        )}
                      />
                    </div>
                    <div className={classes.option}>
                      <div className={classes.text}>Color</div>
                      <TextField
                        size="small"
                        sx={{ width: "80%", marginLeft: "auto" }}
                        value={yColObj?.color || "#F6F2F9"}
                        InputProps={{
                          style: {
                            height: 24,
                            fontSize: 12,
                            color: "#000",
                          },
                          startAdornment: (
                            <div
                              style={{
                                width: 16,
                                height: 12,
                                margin: "4px 8px 4px 0px",
                                borderRadius: 4,
                                cursor: "pointer",
                                background: yColObj?.color || "#F6F2F9",
                              }}
                              onClick={(e) => handleClick(e, index)}
                            ></div>
                          ),
                        }}
                      />
                    </div>
                    {selectedItem?.metadata?.options?.chartType === "pie" && (
                      <div className={classes.option}>
                        <div className={classes.text}>Sec Color</div>
                        <TextField
                          size="small"
                          sx={{ width: "80%", marginLeft: "auto" }}
                          value={yColObj?.lightColor || "#DBABFF"}
                          InputProps={{
                            style: {
                              height: 24,
                              fontSize: 12,
                              color: "#000",
                            },
                            startAdornment: (
                              <div
                                style={{
                                  width: 16,
                                  height: 12,
                                  margin: "4px 8px 4px 0px",
                                  borderRadius: 4,
                                  cursor: "pointer",
                                  background: yColObj?.lightColor || "#DBABFF",
                                }}
                                onClick={(e) => handleColorPickerClick(e, index)}
                              ></div>
                            ),
                          }}
                        />
                      </div>
                    )}
                  </div>
                )
              )}
          </AccordionDetails>
          <Button
            className={classes.addButton}
            onClick={addYCol}
            endIcon={<AddCircleOutlineRounded />}
          >
            Add Y Column
          </Button>
        </Accordion>
      </div>
      <Popover
        id="color-picker"
        open={openPopover}
        anchorEl={anchorEl}
        className={classes.popover}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <SketchPicker
          color={
            selectedItem?.metadata?.options?.yCols?.[selectedIndex]?.color ||
            "#F6F2F9"
          }
          onChangeComplete={(color) =>
            updateItem(
              selectedItem.id,
              `metadata.options.yCols.${selectedIndex}.color`,
              color.hex
            )
          }
        />
      </Popover>
      <ColorPicker
        openPopover={openColorPicker}
        anchorEl={anchorColorPicker}
        selectedColor={
          selectedItem?.metadata?.options?.yCols?.[selectedIndex]?.lightColor ||
          "#DBABFF"
        }
        updateColor={(color) =>
          updateItem(
            selectedItem.id,
            `metadata.options.yCols.${selectedIndex}.lightColor`,
            color
          )
        }
        handleClose={handlePickerClose}
      />
    </div>
  );
}
