import {
  DataGrid,
  GridColDef,
  GridCsvExportOptions,
  useGridApiContext,
} from "@mui/x-data-grid";
import classes from "./RowsView.module.scss";
import {
  Button,
  CircularProgress,
  LinearProgress,
  Popover,
} from "@mui/material";
import embedIcon from "../../icons/embed.svg";
import embedFrame from "../../icons/embedframe.svg";
import noData from "../../icons/nodata.svg";
import { AddCircleOutlineRounded, DownloadRounded } from "@mui/icons-material";
import Banner from "./Banner";
import { useState } from "react";
import { ChatItem } from "../models/Chat";
import { formatWithThousandSeparator } from "./util";
import clsx from "clsx";
import { format } from 'date-fns';

type RowsViewProps = {
  title?: string;
  rows: any;
  cols?: any;
  blockId: string;
  loading?: boolean;
  serverUser?: any;
  chat?: ChatItem;
  showFooter?: boolean;
  showExport?: boolean;
  showEmbed?: boolean;
  dashboards?: any;
  updatingDashboard?: boolean;
  selectedDashboard?: string;
  gridHeight?: string;
  fontSize?: number;
  showBorder?: boolean;
  embed?: boolean;
  headerColor?: string;
  brandPalette?: any;
  setSelectedChat?: (chat: ChatItem) => void;
  setSelectedBlockType?: (type: string) => void;
  addChartToDashboard?: (id: string) => void;
  addChartToDashboardEmbed?: (id: string, chat: ChatItem, type: string) => void;
  setUpgradeBanner?: (value: boolean) => void;
  openEmbedDialog?: (chat: ChatItem, type: string) => void;
};

export default function RowsView({
  title,
  rows,
  cols = [],
  blockId,
  loading = false,
  chat,
  showFooter = true,
  showExport = true,
  showEmbed = true,
  dashboards = [],
  serverUser = {},
  updatingDashboard = false,
  embed = false,
  selectedDashboard = "",
  gridHeight = "40vh",
  headerColor,
  showBorder = true,
  brandPalette,
  setSelectedChat = () => {},
  setSelectedBlockType = () => {},
  addChartToDashboard = () => {},
  addChartToDashboardEmbed = () => {},
  setUpgradeBanner = () => {},
  openEmbedDialog = () => {},
  fontSize = 14,
}: RowsViewProps) {
  const [openEmbedBanner, setEmbedBanner] = useState(false);

  function CustomToolbar() {
    const apiRef = useGridApiContext();
    const [anchorElTable, setAnchorElTable] =
      useState<HTMLButtonElement | null>(null);
    const openTablePopover = Boolean(anchorElTable);
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>, chat: any) => {
      if (serverUser?.data?.plan === "pro") {
        setAnchorElTable(e.currentTarget);
        setSelectedBlockType("table");
        setSelectedChat(chat);
      } else {
        setUpgradeBanner(true);
      }
    };

    const handleClose = () => {
      setAnchorElTable(null);
    };

    const handleExport = (options: GridCsvExportOptions) =>
      apiRef.current.exportDataAsCsv(options);
    return (
      <div className={classes.customToolbar}>
        <Button
          className={!!!embed ? classes.textButton : classes.embed}
          size="small"
          onClick={() =>
            handleExport({
              delimiter: ";",
              utf8WithBom: true,
              fileName: title ? title : "export",
            })
          }
          startIcon={
            <DownloadRounded sx={{ fontSize: 18, paddingLeft: "5px" }} />
          }
        >
          Export
        </Button>
        {chat && (
          <Button
            style={{ marginLeft: "auto" }}
            className={!!!embed ? classes.textButton : classes.embed}
            onClick={(e) =>
              !!!embed
                ? handleClick(e, chat)
                : addChartToDashboardEmbed(selectedDashboard, chat, "table")
            }
            size="small"
            startIcon={<AddCircleOutlineRounded />}
          >
            Add to Dashboard
          </Button>
        )}
        {showEmbed && chat && (
          <Button
            className={classes.textButton}
            onClick={() =>
              serverUser?.data?.plan === "pro"
                ? openEmbedDialog(chat, "table")
                : setUpgradeBanner(true)
            }
            size="small"
            startIcon={
              <img
                src={embedIcon}
                alt="Embed"
                height={14}
                style={{ paddingLeft: "5px" }}
              />
            }
          >
            Embed
          </Button>
        )}
        <Popover
          id="rows-view-add-popper"
          open={openTablePopover}
          anchorEl={anchorElTable}
          className={classes.addDashboardPopover}
          classes={{ paper: classes.paper }}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className={classes.list}>
            {dashboards?.data?.map((dashboard: any) => (
              <Button
                key={dashboard?.id}
                size="small"
                variant="text"
                onClick={() => addChartToDashboard(dashboard?.id)}
                endIcon={
                  updatingDashboard && selectedDashboard === dashboard?.id ? (
                    <CircularProgress sx={{ color: "#4B0082" }} size={12} />
                  ) : (
                    <></>
                  )
                }
              >
                {dashboard?.title}
              </Button>
            ))}
          </div>
        </Popover>
      </div>
    );
  }

  function CustomLinearProgress() {
    return (
      <LinearProgress
        classes={{
          colorPrimary: classes.linearPrimary,
          barColorPrimary: classes.linearBarPrimary,
        }}
        sx={{ borderRadius: 4 }}
      />
    );
  }

  const formatDate = (inputDate:string, userFormat:string) => {
    const date = new Date(inputDate);
    const formattedDate = format(date, userFormat);
    return formattedDate;
  }

  const formatColumnValue = (col: any, text: any) => {
    let val = text;
    if (col?.format === "date" && col?.dateFormat) {
      val = formatDate(text, col?.dateFormat);
    }
    if (col?.thousandSeparator) {
      val = formatWithThousandSeparator(text);
    }
    if (col?.prefix) {
      val = col.prefix + val;
    }
    if (col?.suffix) {
      val = val + col.suffix;
    }
    return val;
  };

  const columns: GridColDef[] =
    cols?.length > 0
      ? cols
          ?.filter((col: any) => !col?.hidden)
          .map((col: any) => {
            return {
              field: col?.key,
              headerName: col?.label,
              flex: 1,
              headerClassName: "table-header",
              headerAlign: col?.align || "center",
              cellClassName: "cell-class",
              minWidth: 150,
              disableColumnMenu: true,
              renderCell: (params: any) => (
                <div
                  style={{
                    backgroundColor: col?.backgroundColor || "transparent",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: col?.align || "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {formatColumnValue(col, params?.value)}
                </div>
              ),
            };
          })
      : rows?.length > 0
      ? Array.from(Object.keys(rows?.[0])).map((col_name) => {
          return {
            field: col_name,
            headerName: col_name,
            flex: 1,
            headerClassName: "table-header",
            align: "center",
            headerAlign: "center",
            cellClassName: "cell-class",
            minWidth: 150,
            disableColumnMenu: true,
          };
        })
      : [];

  const modifiedRows = rows?.map((row: any, index: number) => {
    return {
      ...row,
      id: blockId + "-" + index,
    };
  });
  return (
    <div
      className={classes.root}
      style={{ border: showBorder ? "1px solid rgba(224, 224, 224, 1)" : "" }}
    >
      <DataGrid
        style={
          rows?.length === 0
            ? { maxHeight: "40vh", minHeight: "20vh" }
            : { maxHeight: gridHeight }
        }
        rows={modifiedRows}
        columns={columns}
        sx={{
          fontSize: fontSize,
          border: "none",
          padding: "4px",
          "& .table-header": {
            background: brandPalette?.light || "#faf8ff",
          },
          "& .MuiDataGrid-cell": {
            padding: "0px",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: 600,
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "none",
          },
          "& .cell-class": {
            color: "rgba(0, 0, 0, 0.56)",
            marginLeft: "5px"
          },
          "& .MuiDataGrid-footerContainer": {
            minHeight: "unset",
            height: "30px",
          },
          "& .MuiTablePagination-displayedRows": {
            fontSize: fontSize,
          },
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 50 } },
        }}
        columnHeaderHeight={30}
        hideFooter={!showFooter || rows?.length === 0}
        rowHeight={30}
        rowSelection={false}
        pageSizeOptions={[50]}
        slots={
          showExport && rows?.length > 0
            ? {
                toolbar: CustomToolbar,
                loadingOverlay: CustomLinearProgress,
              }
            : {
                loadingOverlay: CustomLinearProgress,
                noRowsOverlay: () => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img src={noData} alt="No Data" />
                  </div>
                ),
              }
        }
        loading={loading}
      />
      <Banner
        openBanner={openEmbedBanner}
        setBanner={setEmbedBanner}
        title="Embed Into Your App"
        description="Get embeddable link to direclty use them into supporting applications, such as Notion or get iframe for a webpage or any other supporting application."
        media={embedFrame}
        ctaLink="https://calendar.app.google/Ha83FJLswBiXTKCe6"
      />
    </div>
  );
}
