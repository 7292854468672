import { Autocomplete, Button, IconButton, TextField } from "@mui/material";
import { WorkspaceItem } from "../../models/Dashboard";
import classes from "./ItemEditor.module.scss";
import { DATABASES, DATE_FORMATS } from "../../utils/constant";
import { Query } from "../../models/Query";
import { ClearRounded, KeyboardArrowDownRounded } from "@mui/icons-material";
import { useState } from "react";

type Props = {
  queries: Query[];
  selectedItem: WorkspaceItem;
  updateItem: (id: string, key: string, value: any) => void;
};

export default function DateEditor({
  queries,
  selectedItem,
  updateItem,
}: Props) {
  const [dateFormatError, setDateFormatError] = useState('');
  const [dateFormat, setDateFormat] = useState('');
  const addTriggerQuery = (query: Query) => {
    const existingQueries = selectedItem?.metadata?.triggerQueries || [];
    const index = existingQueries.findIndex((q: Query) => q.id === query.id);
    if (index === -1) {
      existingQueries.push(query);
      updateItem(selectedItem.id, "metadata.triggerQueries", existingQueries);
    }
  };

  const removeTriggerQuery = (query: Query) => {
    const existingQueries = selectedItem?.metadata?.triggerQueries || [];
    const index = existingQueries.findIndex((q: Query) => q.id === query.id);
    if (index !== -1) {
      existingQueries.splice(index, 1);
      updateItem(selectedItem.id, "metadata.triggerQueries", existingQueries);
    }
  };

  const isValidFormat = (format:string) => {
    return DATE_FORMATS.includes(format);
  }

  const handleDateFormatChange = (e: any) => {
    const value = e.target.value;
    setDateFormat(value);
    const isValid = isValidFormat(value);
    if (!isValid) {
      console.log("Invalid date format")
      setDateFormatError('Invalid date format')
      return;
    }
    setDateFormatError('');
    updateItem(
      selectedItem.id, "metadata.dateFormat", value
    );
  }

  return (
    <div className={classes.panel}>
      <div className={classes.title}>
        <div className={classes.text}>Label</div>
        <TextField
          size="small"
          type="string"
          sx={{ width: "80%", marginLeft: "auto" }}
          value={selectedItem?.metadata?.label || ""}
          onChange={(e) =>
            updateItem(selectedItem.id, "metadata.label", e.target.value)
          }
          InputProps={{
            style: {
              height: 24,
              fontSize: 12,
              color: "#000",
            },
          }}
        />
      </div>
      <div className={classes.title}>
        <div className={classes.text}>DateFormat</div>
        <TextField
          error={dateFormatError !== '' ? true : false}
          size="small"
          sx={{ width: "80%", marginLeft: "auto"}}
          value={selectedItem?.metadata?.dateFormat ?? dateFormat}
          placeholder="MM/dd/yyyy"
          onChange={(e) =>
            handleDateFormatChange(e)
          }
          InputProps={{
            style: {
              height: 24,
              fontSize: 12,
              color: "#000",
            },
          }}
        />
      </div>
      <div className={classes.title}>
        <div className={classes.text}>Default value</div>
        <TextField
          size="small"
          type="text"
          sx={{ width: "80%", marginLeft: "auto" }}
          value={selectedItem?.metadata?.defaultValue || ""}
          onChange={(e) =>
            updateItem(selectedItem.id, "metadata.defaultValue", e.target.value)
          }
          InputProps={{
            style: {
              height: 24,
              fontSize: 12,
              color: "#000",
            },
          }}
        />
      </div>
      <div
        className={classes.title}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div className={classes.title} style={{
          width: "100%"
        }}>
          <div className={classes.text}>Trigger queries on change</div>
          <Autocomplete
            sx={{
              width: "60%",
              marginLeft: "auto",
            }}
            options={queries}
            className={classes.autocomplete}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option: Query) => option.displayName}
            onChange={(e, value) => value && addTriggerQuery(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <KeyboardArrowDownRounded sx={{ fontSize: 18 }} />
                  ),
                  style: {
                    height: 24,
                    fontSize: 12,
                    color: "#000",
                    padding: "0px 8px 0px 4px",
                  },
                }}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li
                {...props}
                style={{
                  fontWeight: selected ? 600 : 400,
                  fontSize: 12,
                  padding: "4px 16px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <img
                  src={DATABASES[option.type].icon}
                  alt={option.type}
                  width={16}
                  height={16}
                />
                {option.displayName}
              </li>
            )}
          />
        </div>

        <div className={classes.colContainer}>
          {selectedItem?.metadata?.triggerQueries?.map((query: Query, index: number) => (
            <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
              <Button
                size="small"
                key={query?.id}
                className={classes.colBtn}
                endIcon={
                  <IconButton
                    className={classes.iconBtn}
                    onClick={(e) => {
                      removeTriggerQuery(query);
                    }}
                  >
                    <ClearRounded/>
                  </IconButton>
                }
              >
                {query?.displayName}
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
