import { useMutation, useQuery } from "react-query";
import { queryClient } from "../..";
import { BE_SERVER } from "../utils/constant";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "notistack";

export const useChatHistory = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["chatHistory", email], async () => {
    const token = await getAccessTokenSilently();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const res = await fetch(`${BE_SERVER}/api/chat/history?email=${email}`, {
      headers: headers,
    });

    if (!res.ok) {
      throw new Error("Error while fetching chat history");
    }

    return res.json();
  });
};

export const useChatDetails = (chatId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["chat", chatId], async () => {
    const token = await getAccessTokenSilently();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const res = await fetch(`${BE_SERVER}/api/chat/${chatId}?email=${email}`, {
      headers: headers,
    });

    if (!res.ok) {
      throw new Error("Error while fetching chat details");
    }

    return res.json();
  });
};

export const useChatDetailsById = (chatId: string) => {
  return useQuery(["chat", chatId], async () => {
    const res = await fetch(`${BE_SERVER}/api/embed/chat/${chatId}`);

    if (!res.ok) {
      throw new Error("Error while fetching chat details");
    }

    return res.json();
  });
};

export const useAddChat = (chatId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const res = await fetch(
        `${BE_SERVER}/api/chat/${chatId}?email=${email}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(request),
        }
      );

      if (!res.ok) {
        const message = await res.json();
        throw new Error(message?.error);
      }

      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["chatHistory", email]);
      },
    }
  );
};

export const usePatchChat = (chatId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const res = await fetch(
        `${BE_SERVER}/api/chat/${chatId}?email=${email}`,
        {
          method: "PATCH",
          headers: headers,
          body: JSON.stringify(request),
        }
      );

      if (!res.ok) {
        throw new Error("Error while updating chat");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        // enqueueSnackbar("Updated successfully", { variant: "success" });
      },
      onError: () => {
        // enqueueSnackbar("Error while updating", { variant: "error" });
      },
    }
  );
};

export const useAddChatGraph = (chatId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const res = await fetch(
        `${BE_SERVER}/api/chat/${chatId}/graph?email=${email}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(request),
        }
      );

      if (!res.ok) {
        throw new Error("Error while adding chat graph");
      }

      return res.json();
    }
  );
};

export const useAddChatSummary = (chatId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const res = await fetch(
        `${BE_SERVER}/api/chat/${chatId}/summary?email=${email}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(request),
        }
      );

      if (!res.ok) {
        throw new Error("Error while adding chat summary");
      }

      return res.json();
    }
  );
};

export const useAddFollowUpQuestions = (chatId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const res = await fetch(
        `${BE_SERVER}/api/chat/${chatId}/followup?email=${email}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(request),
        }
      );

      if (!res.ok) {
        throw new Error("Error while creating followup questions");
      }

      return res.json();
    }
  );
};
