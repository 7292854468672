import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQuery } from "react-query";
import { BE_SERVER } from "../utils/constant";
import { queryClient } from "../..";
import { useSnackbar } from "notistack";
import { Filter } from "../models/Dashboard";

// export const useQueryDetails = (id: string, email: string) => {
//   const { getAccessTokenSilently } = useAuth0();
//   return useQuery(["queryDetail", id], async () => {
//     const token = await getAccessTokenSilently();
//     const headers = {
//       Authorization: `Bearer ${token}`,
//     };
//     const res = await fetch(
//       `${BE_SERVER}/api/data/query/${id}?email=${email}`,
//       {
//         headers: headers,
//       }
//     );

//     if (!res.ok) {
//       throw new Error("Error while fetching query details");
//     }

//     return res.json();
//   });
// };

export const useDashboardQueries = (
  dashboardId: string,
  email: string,
  filters: Filter[]
) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["dashboardQueries", dashboardId, filters], async () => {
    const token = await getAccessTokenSilently();
    const headers = {
      Authorization: `Bearer ${token}`,
      Filters: JSON.stringify(filters),
    };
    const res = await fetch(
      `${BE_SERVER}/api/data/query/dashboard/${dashboardId}?email=${email}`,
      {
        headers: headers,
      }
    );

    if (!res.ok) {
      throw new Error("Error while fetching queries for dashboard");
    }

    return res.json();
  });
};

export const useAddQuery = (dashboardId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const res = await fetch(`${BE_SERVER}/api/data/query?email=${email}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(request),
      });

      if (!res.ok) {
        const message = await res.json();
        throw new Error(message?.error);
      }

      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["dashboardQueries", dashboardId],
        });
      },
    }
  );
};

export const usePatchQuery = (dashboardId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const res = await fetch(
        `${BE_SERVER}/api/data/query/${request?.id}?email=${email}`,
        {
          method: "PATCH",
          headers: headers,
          body: JSON.stringify(request),
        }
      );

      if (!res.ok) {
        throw new Error("Error while updating query");
      }

      return res.text();
    },
    {
      onSuccess: () => {
        enqueueSnackbar("Updated successfully", { variant: "success" });
        // queryClient.invalidateQueries(["dashboardQueries", dashboardId]);
        queryClient.invalidateQueries({
          queryKey: ["dashboardQueries", dashboardId],
        });
      },
      onError: () => {
        enqueueSnackbar("Error while updating", { variant: "error" });
      },
    }
  );
};

export const useDeleteQuery = (dashboardId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const res = await fetch(
        `${BE_SERVER}/api/data/query/${request.id}?email=${email}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Params: JSON.stringify(request.filters),
          },
        }
      );

      if (!res.ok) {
        throw new Error("Error while deleting query");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(["dashboardQueries", dashboardId]);
        queryClient.invalidateQueries({
          queryKey: ["dashboardQueries", dashboardId],
        });
        enqueueSnackbar("Deleted successfully", {
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar("Error while deleting query", {
          variant: "error",
        });
      },
    }
  );
};

export const useRunDataQuery = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation(async (request: any) => {
    const token = await getAccessTokenSilently();
    const res = await fetch(
      `${BE_SERVER}/api/data/query/${request.id}/run?email=${email}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(request),
      }
    );

    if (res.ok) {
      return res.json();
    } else {
      const errorText = await res.text();
      const errorObj = JSON.parse(errorText || "{}");
      const errorMessage = errorObj.error || "Internal Server Error";
      throw new Error(errorMessage);
    }
  });
};
