import {
  Switch,
  SwitchProps,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import chroma from "chroma-js";
import CryptoJs from "crypto-js";
import { Query } from "../models/Query";
import { format } from "sql-formatter";

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(email: string) {
  return {
    sx: {
      bgcolor: stringToColor(email),
      width: 28,
      height: 28,
    },
    children: `${email[0].toUpperCase()}`,
  };
}

const useQueryParameter = (parameterName: string) => {
  const [searchParams] = useSearchParams();
  const parameterValue = searchParams.get(parameterName);

  return parameterValue || "";
};

export default useQueryParameter;

export const StyledSwitch = styled(
  (props: SwitchProps & { shade: string; size?: string }) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  )
)(({ theme, shade, size = "normal" }) => ({
  width: size === "small" ? 24 : 32,
  height: size === "small" ? 12 : 16,
  padding: 0,
  marginLeft: 16,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: size === "small" ? "translateX(12px)" : "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : shade,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: size === "small" ? 8 : 12,
    height: size === "small" ? 8 : 12,
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export function generateShades(
  baseColor: string,
  lightColor: string,
  numberOfShades: number
) {
  if (!lightColor) {
    lightColor = "#DBABFF";
  }
  const colorScale = chroma
    .scale([baseColor, lightColor])
    .mode("lch")
    .colors(numberOfShades);
  return colorScale;
}

export function encrypt(value: string, key: string) {
  try {
    return CryptoJs.AES.encrypt(JSON.stringify({ value }), key).toString();
  } catch (e) {
    console.error("Error while encrypting: ", e);
    return "";
  }
}

export function decrypt(value: any, key: string) {
  try {
    const decryptJson = CryptoJs.AES.decrypt(
      decodeURIComponent(value),
      key
    ).toString(CryptoJs.enc.Utf8);
    const plainText = JSON.parse(decryptJson)?.value;
    return plainText;
  } catch (e) {
    console.error("Error while decrypting: ", e);
    return "";
  }
}

export function dataFormater(value: number) {
  value = +value;
  if (value >= 1000000000) {
    return (
      removeTrailingZeroes((value / 1000000000)?.toFixed(2)).toString() + "B"
    );
  } else if (value >= 1000000) {
    return removeTrailingZeroes((value / 1000000)?.toFixed(2)).toString() + "M";
  } else if (value >= 1000) {
    return removeTrailingZeroes((value / 1000)?.toFixed(2)).toString() + "K";
  } else {
    return removeTrailingZeroes(value?.toFixed(2)).toString();
  }
}

function removeTrailingZeroes(value: string) {
  return value.includes(".") ? value.replace(/\.?0*$/, "") : value;
}

export const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#000",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontWeight: 700,
  },
}));

export function replaceGlobalVariables(code: string, vs: any) {
  if (vs) {
    Object.keys(vs)?.forEach((key) => {
      code = code.replaceAll(`{{${key}.value}}`, "'" + vs[key]?.value + "'");
    });
  }
  return code;
}

export function convertJsToValue(
  jsString: string,
  allQueries: Query[],
  vs?: any
) {
  const queryList = allQueries.filter((query) =>
    jsString.includes(query.displayName)
  );
  let addOn = "";
  if (vs) {
    Object.keys(vs)?.forEach((key) => {
      addOn += `const ${key} = ${JSON.stringify(vs[key])};\n`;
    });
  }
  queryList.forEach((query) => {
    addOn += `const ${query.displayName} = ${JSON.stringify({
      results: query.results,
    })};\n`;
  });
  try {
    const code = jsString.replaceAll("{{", "").replaceAll("}}", "");
    const modifiedJsString = `
    (function() {
      ${addOn}
      ${code}
    })()
  `;
    const value = eval(modifiedJsString);
    return value;
  } catch (error: any) {
    console.log(error);
  }
}

export function removeMarkdownCodeFromString(messageString: string) {
  const regex = /```[\s\S]*?```/g;
  return messageString.replace(regex, "");
}

export function formatWithThousandSeparator(numStr: string) {
  // Ensure the input is a string
  numStr = String(numStr);

  // Use a regular expression to add commas as thousand separators
  return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function onSqlChange(sql: string) {
  try {
    const formattedSql = format(sql, {
      language: "sql",
    });
    return formattedSql;
  } catch (e) {
    return sql;
  }
}

export function hashJsonObject(obj: any) {
  return CryptoJs.SHA256(JSON.stringify(obj)).toString();
}
