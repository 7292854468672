import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import classes from "./Banner.module.scss";
import { CloseRounded } from "@mui/icons-material";

type BannerProps = {
  openBanner: boolean;
  title: string;
  description: string;
  ctaLink: string;
  media?: any;
  setBanner: React.Dispatch<React.SetStateAction<boolean>>;
  setPricing?: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function Banner({
  openBanner,
  title,
  description,
  ctaLink,
  media,
  setBanner,
  setPricing,
}: BannerProps) {
  return (
    <Dialog
      open={openBanner}
      onClose={() => setBanner(false)}
      className={classes.root}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>
        <div className={classes.title}>{title}</div>
        <div className={classes.subTitle}>{description}</div>
      </DialogTitle>
      <DialogContent>
        {media && (
          <div className={classes.media}>
            <img src={media} alt="Saydata" width={500} height={300}></img>
          </div>
        )}
      </DialogContent>
      <div className={classes.footer}>
        {ctaLink === "showPricing" ? (
          <Button
            onClick={() => setPricing && setPricing(true)}
            className={classes.cta}
          >
            Upgrade Now
          </Button>
        ) : (
          <Button
            href={ctaLink}
            target="_blank"
            rel="noreferrer"
            className={classes.cta}
          >
            Get Now
          </Button>
        )}
      </div>
    </Dialog>
  );
}
