import {
  AddRounded,
  DeleteRounded,
  KeyboardDoubleArrowLeftRounded,
} from "@mui/icons-material";
import { Button, Drawer, IconButton } from "@mui/material";
import classes from "./CanvaHistDrawer.module.scss";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import useQueryParameter from "../utils/util";
import { format, parseISO } from "date-fns";

type Props = {
  canvaHistory: any[];
  openCanvaHistDrawer: boolean;
  toggleCanvaHistDrawer: () => void;
};

export default function CanvaHistDrawer({
  canvaHistory,
  openCanvaHistDrawer,
  toggleCanvaHistDrawer,
}: Props) {
  const navigate = useNavigate();
  const email = useQueryParameter("email");
  const newCanva = () => {
    toggleCanvaHistDrawer();
    navigate(`/canva/${v4()}?email=${email}`);
  };
  const historicalCanva = (selectedCanva: any) => {
    toggleCanvaHistDrawer();
    navigate(`/canva/${selectedCanva?.id}?email=${email}`);
  };
  const drawerWidth = 300;
  return (
    <Drawer
      sx={{ width: openCanvaHistDrawer ? drawerWidth : 0 }}
      variant="persistent"
      open={openCanvaHistDrawer}
      className={classes.canvaDrawer}
      classes={{ paper: classes.paper }}
    >
      <div className={classes.pane}>
        <Button
          startIcon={<AddRounded />}
          className={classes.button}
          variant="contained"
          onClick={() => newCanva()}
        >
          New Canva
        </Button>
        <IconButton
          className={classes.closeDrawer}
          onClick={() => toggleCanvaHistDrawer()}
        >
          <KeyboardDoubleArrowLeftRounded />
        </IconButton>
      </div>
      <div className={classes.hist}>
        {canvaHistory?.map((canvaEle) => (
          <div
            key={canvaEle?.id}
            className={classes.tile}
            onClick={() => historicalCanva(canvaEle)}
          >
            <div className={classes.title}>{canvaEle?.title.slice(1, -1)}</div>
            <div className={classes.date}>{format(parseISO(canvaEle?.createdAt), "d MMM yyyy")}</div>
            {/* todo :: implement delete later
            <IconButton className={classes.icon}>
              <DeleteRounded color="error" />
            </IconButton> */}
          </div>
        ))}
      </div>
    </Drawer>
  );
}