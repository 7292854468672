import {
  Autocomplete,
  Button,
  Checkbox,
  IconButton,
  Popover,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { WorkspaceItem } from "../../models/Dashboard";
import classes from "./ItemEditor.module.scss";
import { SketchPicker } from "react-color";
import { useEffect, useState } from "react";
import {
  AlignHorizontalCenterRounded,
  AlignHorizontalLeftRounded,
  AlignHorizontalRightRounded,
  ArrowBackRounded,
  KeyboardArrowDownRounded,
  MoreHorizRounded,
  PanoramaFishEyeRounded,
  RemoveRedEyeRounded,
  VisibilityOffRounded,
} from "@mui/icons-material";
import clsx from "clsx";
import ColorPicker from "../utils/ColorPIcker";
import { COL_FORMATS, DATE_FORMATS } from "../../utils/constant";
import { Draggable } from "react-drag-reorder";

type Props = {
  selectedItem: WorkspaceItem;
  updateItem: (id: string, key: string, value: any) => void;
  getDataFromQuery: (queryId: string | undefined) => any;
};

export default function TableEditor({
  selectedItem,
  updateItem,
  getDataFromQuery,
}: Props) {
  const rows = getDataFromQuery(selectedItem.dataQueryId);
  const cols = rows?.length > 0 ? Object.keys(rows?.[0]) : [];
  const [selectedColIndex, setSelectedColIndex] = useState(0);
  const [colDetails, setColDetail] = useState(false);
  const [dateFormatError, setDateFormatError] = useState('');
  const [dateFormat, setDateFormat] = useState('');

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = useState<HTMLDivElement | null>(null);

  const handleClick2 = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  useEffect(() => {
    if (selectedItem.dataQueryId) {
      if (!selectedItem?.metadata?.columns) {
        const colMetadata: any[] = [];
        cols.forEach((col) => {
          colMetadata.push({
            key: col,
            label: col,
            hidden: false,
            backgroundColor: "#fff",
            align: "center",
            format: "string",
          });
        });
        updateItem(selectedItem.id, "metadata.columns", colMetadata);
      } else {
        const colMetadata = [...selectedItem?.metadata?.columns];
        const missingColumns: any[] = [];
        cols.forEach((col) => {
          const colIndex = selectedItem?.metadata?.columns?.findIndex(
            (c: any) => c.key === col
          );
          if (colIndex === -1) {
            missingColumns.push({
              key: col,
              label: col,
              hidden: false,
              backgroundColor: "#fff",
              align: "center",
              format: "string",
            });
          }
        });
        const newColMetadata = colMetadata.concat(missingColumns);
        updateItem(selectedItem.id, "metadata.columns", newColMetadata);
      }
    }
  }, [selectedItem.dataQueryId]);

  const showColDetail = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    event.stopPropagation();
    setSelectedColIndex(index);
    setColDetail(true);
  };

  const openPopover = Boolean(anchorEl);
  const openPopover2 = Boolean(anchorEl2);

  const isValidFormat = (format:string) => {
    return DATE_FORMATS.includes(format);
  }

  const handleDateFormatChange = (e: any) => {
    const value = e.target.value;
    setDateFormat(value);
    const isValid = isValidFormat(value);
    if (!isValid) {
      console.log("Invalid date format")
      setDateFormatError('Invalid date format')
      return;
    }
    setDateFormatError('');
    updateItem(
      selectedItem.id,
      "metadata.columns." + selectedColIndex + ".dateFormat",
      value
    );
  }

  const getChangedPos = (currentPos: any, newPos: any) => {
    let tempArr = [...selectedItem?.metadata?.columns];
    if (currentPos !== newPos) {
      let currentElement = { ...tempArr[currentPos] };
      tempArr = tempArr.filter((val, idx) => idx !== currentPos);
      tempArr.splice(newPos, 0, currentElement);
    }
    updateItem(selectedItem.id, "metadata.columns", tempArr);
  };
  return (
    <div className={classes.panel}>
      <div
        className={classes.title}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div className={classes.text}>Columns</div>
        <div className={classes.colContainer}>
          {colDetails ? (
            <div className={classes.colDetail}>
              <Button
                startIcon={<ArrowBackRounded />}
                className={classes.colBtn}
                onClick={() => setColDetail(false)}
              >
                {selectedItem?.metadata?.columns?.[selectedColIndex]?.label}
              </Button>
              <div className={classes.content}>
                <div className={classes.text}>Source</div>
                <Autocomplete
                  options={cols}
                  className={classes.child}
                  sx={{ width: "80%", marginLeft: "auto" }}
                  value={
                    cols.find(
                      (op) =>
                        op ===
                        selectedItem?.metadata?.columns?.[selectedColIndex]?.key
                    ) || null
                  }
                  onChange={(e, value) => {
                    if (value) {
                      updateItem(
                        selectedItem.id,
                        "metadata.columns." + selectedColIndex + ".key",
                        value
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <KeyboardArrowDownRounded sx={{ fontSize: 18 }} />
                        ),
                        style: {
                          height: 28,
                          fontSize: 12,
                          fontWeight: 600,
                          padding: "2px 8px 4px 8px",
                        },
                      }}
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        fontWeight: selected ? 600 : 400,
                        fontSize: 12,
                        padding: "4px 16px",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      {option}
                    </li>
                  )}
                />
              </div>
              <div className={classes.content}>
                <div className={classes.text}>Label</div>
                <TextField
                  size="small"
                  sx={{ width: "80%", marginLeft: "auto" }}
                  value={
                    selectedItem?.metadata?.columns?.[selectedColIndex]?.label
                  }
                  onChange={(e) =>
                    updateItem(
                      selectedItem.id,
                      "metadata.columns." + selectedColIndex + ".label",
                      e.target.value
                    )
                  }
                  InputProps={{
                    style: {
                      height: 24,
                      fontSize: 12,
                      color: "#000",
                    },
                  }}
                />
              </div>
              <div className={classes.content}>
                <div className={classes.text}>Prefix</div>
                <TextField
                  size="small"
                  sx={{ width: "80%", marginLeft: "auto" }}
                  value={selectedItem?.metadata?.columns?.[selectedColIndex]?.prefix || ""}
                  placeholder="$,€,¥,₹,..."
                  onChange={(e) =>
                    updateItem(
                      selectedItem.id,
                      "metadata.columns." + selectedColIndex + ".prefix",
                      e.target.value)
                  }
                  InputProps={{
                    style: {
                      height: 24,
                      fontSize: 12,
                      color: "#000",
                    },
                  }}
                />
              </div>
              <div className={classes.content}>
                <div className={classes.text}>Suffix</div>
                <TextField
                  size="small"
                  sx={{ width: "80%", marginLeft: "auto" }}
                  value={selectedItem?.metadata?.columns?.[selectedColIndex]?.suffix || ""}
                  placeholder="%,v/v,w/w,..."
                  onChange={(e) =>
                    updateItem(
                      selectedItem.id,
                      "metadata.columns." + selectedColIndex + ".suffix",
                      e.target.value)
                  }
                  InputProps={{
                    style: {
                      height: 24,
                      fontSize: 12,
                      color: "#000",
                    },
                  }}
                />
              </div>
              <div className={classes.content}>
                <div className={classes.text}>Format</div>
                <Autocomplete
                  options={COL_FORMATS}
                  className={classes.child}
                  sx={{ width: "80%", marginLeft: "auto" }}
                  value={
                    COL_FORMATS.find(
                      (op) =>
                        op.value ===
                        selectedItem?.metadata?.columns?.[selectedColIndex]
                          ?.format
                    ) || null
                  }
                  onChange={(e, value) => {
                    if (value) {
                      updateItem(
                        selectedItem.id,
                        "metadata.columns." + selectedColIndex + ".format",
                        value.value
                      );
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <KeyboardArrowDownRounded sx={{ fontSize: 18 }} />
                        ),
                        style: {
                          height: 28,
                          fontSize: 12,
                          fontWeight: 600,
                          padding: "2px 8px 4px 8px",
                        },
                      }}
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        fontWeight: selected ? 600 : 400,
                        fontSize: 12,
                        padding: "4px 16px",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      {option.label}
                    </li>
                  )}
                />
              </div>
              {selectedItem?.metadata?.columns?.[selectedColIndex]?.format === "date" && (
                <div className={classes.content}>
                <div className={classes.text}>DateFormat</div>
                  <TextField
                  error={dateFormatError !== '' ? true : false}
                  size="small"
                  sx={{ width: "80%", marginLeft: "auto"}}
                  value={selectedItem?.metadata?.columns?.[selectedColIndex]?.dateFormat ?? dateFormat}
                  placeholder="MM/dd/yyyy"
                  onChange={(e) =>
                    handleDateFormatChange(e)
                  }
                  InputProps={{
                    style: {
                      height: 24,
                      fontSize: 12,
                      color: "#000",
                    },
                  }}
                />
              </div>
              )}
              <div className={classes.content}>
                <div className={classes.text}>Align</div>
                <ToggleButtonGroup
                  value={
                    selectedItem?.metadata?.columns?.[selectedColIndex]?.align
                  }
                  sx={{
                    width: "80%",
                    marginLeft: "auto",
                    height: 24,
                  }}
                  exclusive
                  onChange={(e, value) =>
                    updateItem(
                      selectedItem.id,
                      "metadata.columns." + selectedColIndex + ".align",
                      value
                    )
                  }
                >
                  <ToggleButton value="left">
                    <AlignHorizontalLeftRounded style={{ fontSize: 14 }} />
                  </ToggleButton>
                  <ToggleButton value="center">
                    <AlignHorizontalCenterRounded style={{ fontSize: 14 }} />
                  </ToggleButton>
                  <ToggleButton value="right">
                    <AlignHorizontalRightRounded style={{ fontSize: 14 }} />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div className={classes.content}>
                <div className={classes.text}>BgColor</div>
                <TextField
                  size="small"
                  sx={{ width: "80%", marginLeft: "auto" }}
                  value={
                    selectedItem?.metadata?.columns?.[selectedColIndex]
                      ?.backgroundColor || "#fff"
                  }
                  InputProps={{
                    style: {
                      height: 24,
                      fontSize: 12,
                      color: "#000",
                    },
                    startAdornment: (
                      <div
                        style={{
                          width: 16,
                          height: 12,
                          margin: "4px 8px 4px 0px",
                          borderRadius: 4,
                          cursor: "pointer",
                          border: "1px solid #888",
                          background:
                            selectedItem?.metadata?.columns?.[selectedColIndex]
                              ?.backgroundColor || "#fff",
                        }}
                        onClick={(e) => handleClick(e)}
                      ></div>
                    ),
                  }}
                />
              </div>
              <div className={classes.content}>
                <div className={classes.text}>Show thousands separator</div>
                <Checkbox
                  checked={selectedItem?.metadata?.columns?.[selectedColIndex]?.thousandSeparator}
                  size="small"
                  sx={{
                    marginLeft: "auto",
                  }}
                  onChange={(e) =>
                    updateItem(
                      selectedItem.id,
                      "metadata.columns." + selectedColIndex + ".thousandSeparator",
                      e.target.checked
                    )
                  }
                />
              </div>
            </div>
          ) : (
            <Draggable onPosChange={getChangedPos}>
              { selectedItem?.metadata?.columns?.map((col: any, index: number) => (
                <Button
                  size="small"
                  key={col?.key}
                  className={
                    col?.hidden
                      ? clsx(classes.colBtn, classes.hidden)
                      : classes.colBtn
                  }
                  onClick={(e) => {
                    showColDetail(e, index);
                  }}
                  endIcon={
                    <IconButton
                      className={classes.iconBtn}
                      onClick={(e) => {
                        e.stopPropagation();
                        col.hidden = !col.hidden;
                        updateItem(
                          selectedItem.id,
                          "metadata.columns",
                          selectedItem?.metadata?.columns
                        );
                      }}
                    >
                      {col?.hidden ? (
                        <VisibilityOffRounded />
                      ) : (
                        <RemoveRedEyeRounded />
                      )}
                    </IconButton>
                  }
                >
                {col?.label}
              </Button>
            ))}
            </Draggable>
          )}
        </div>
      </div>

      <div className={classes.title}>
        <div className={classes.text}>Font size</div>
        <TextField
          size="small"
          type="number"
          sx={{ width: "80%", marginLeft: "auto" }}
          value={selectedItem?.metadata?.fontSize || 12}
          onChange={(e) =>
            updateItem(selectedItem.id, "metadata.fontSize", e.target.value)
          }
          InputProps={{
            style: {
              height: 24,
              fontSize: 12,
              color: "#000",
            },
          }}
        />
      </div>

      <div className={classes.title}>
        <div className={classes.text}>Header color</div>
        <TextField
          size="small"
          sx={{ width: "80%", marginLeft: "auto" }}
          value={selectedItem?.metadata?.headerColor || "#F6F2F9"}
          InputProps={{
            style: {
              height: 24,
              fontSize: 12,
              color: "#000",
            },
            startAdornment: (
              <div
                style={{
                  width: 16,
                  height: 12,
                  margin: "4px 8px 4px 0px",
                  borderRadius: 4,
                  border: "1px solid #888",
                  cursor: "pointer",
                  background: selectedItem?.metadata?.headerColor || "#F6F2F9",
                }}
                onClick={(e) => handleClick2(e)}
              ></div>
            ),
          }}
        />
      </div>
      <ColorPicker
        selectedColor={
          selectedItem?.metadata?.columns?.[selectedColIndex]
            ?.backgroundColor || "#fff"
        }
        openPopover={openPopover}
        anchorEl={anchorEl}
        handleClose={handleClose}
        updateColor={(value: string) => {
          updateItem(
            selectedItem.id,
            "metadata.columns." + selectedColIndex + ".backgroundColor",
            value
          );
        }}
      />
      <ColorPicker
        selectedColor={selectedItem?.metadata?.headerColor || "#F6F2F9"}
        openPopover={openPopover2}
        anchorEl={anchorEl2}
        handleClose={handleClose2}
        updateColor={(value: string) => {
          updateItem(selectedItem.id, "metadata.headerColor", value);
        }}
      />
    </div>
  );
}
