import {
  CloseRounded,
  HistoryRounded,
  MenuRounded,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import ReactFlow, { Controls, Background } from "reactflow";
import classes from "./ChatCanva.module.scss";
import { useNavigate } from "react-router-dom";
import useQueryParameter from "../utils/util";

type Props = {
  initialNodes: any;
  initialEdges: any;
  onNodesChange: any;
  onEdgesChange: any;
  onConnect: any;
  nodeTypes: any;
  proOptions: any;
  onClick: () => void;
  openCanvaHistDrawer: boolean;
  toggleCanvaHistDrawer: () => void;
};

export default function InnerCanva({
  initialNodes,
  initialEdges,
  onNodesChange,
  onEdgesChange,
  onConnect,
  nodeTypes,
  proOptions,
  onClick,
  openCanvaHistDrawer,
  toggleCanvaHistDrawer,
}: Props) {
  const navigate = useNavigate();
  const email = useQueryParameter("email");

  const onClose = () => {
    navigate(`/dashboard?email=${email}`);
  };

  return (
    <ReactFlow
      nodes={initialNodes}
      edges={initialEdges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      nodeTypes={nodeTypes}
      nodesDraggable={false}
      proOptions={proOptions}
      fitView
      className={classes.pane}
      zoomOnScroll={false}
      panOnScroll={true}
    >
      <div className={classes.top}>
        {!openCanvaHistDrawer && (
          <IconButton
            className={classes.button}
            onClick={() => toggleCanvaHistDrawer()}
          >
            <HistoryRounded />
          </IconButton>
        )}
        <IconButton className={classes.closeButton} onClick={() => onClose()}>
          <CloseRounded />
        </IconButton>
      </div>
      <Controls showInteractive={false} />
      <Background color="#aaa" gap={16} />
    </ReactFlow>
  );
}