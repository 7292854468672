import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Tooltip as MuiTooltip,
} from "@mui/material";
import classes from "./EditChart.module.scss";
import { Chat, ChatItem, Graph, YCol } from "../models/Chat";
import {
  AddCircleOutlineRounded,
  AddRounded,
  CloseRounded,
  ColorizeRounded,
  RemoveCircleOutlineRounded,
  SaveRounded,
} from "@mui/icons-material";
import Graphs from "./Graphs";
import { useState } from "react";
import { SketchPicker } from "react-color";

type EditChartsProps = {
  openChartEditor: boolean;
  setChartEditor: React.Dispatch<React.SetStateAction<boolean>>;
  chartData: ChatItem;
  onGraphEditSave: (blockId: string) => void;
  onBlockUpdate: (blockId: string, key: string, value: any) => void;
};

const EditChart = ({
  openChartEditor,
  setChartEditor,
  chartData,
  onGraphEditSave,
  onBlockUpdate,
}: EditChartsProps) => {
  const columns = Array.from(Object.keys(chartData?.rows?.[0]));
  const [chartOptions, setChartOptions] = useState(
    chartData?.graph || { xCol: "", yCols: [], chartType: "bar" }
  );
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onSelectChange = (val: string, key: string) => {
    setChartOptions({
      ...chartOptions,
      [key]: val,
    });
    // const newGraph = { ...chartData?.graph };
    // newGraph.options[key] = val;
    // onBlockUpdate(chartData?.block_id, "graph", newGraph);
  };

  const onYColChange = (index: number, key: string, val: string) => {
    // const newGraph = { ...chartData?.graph };
    const newYCols = [...chartOptions?.yCols];
    const yCol = newYCols?.[index];
    yCol[key as keyof YCol] = val;
    setChartOptions({ ...chartOptions, yCols: newYCols });
    // onBlockUpdate(chartData?.block_id, "graph", newGraph);
  };

  const addNewYCol = () => {
    const newYCols = [...chartOptions?.yCols];
    newYCols.push({ yCol: "", color: "#82ca9d" });
    setChartOptions({ ...chartOptions, yCols: newYCols });
  };

  const removeYCol = (e: any, index: number) => {
    const newYCols = [...chartOptions?.yCols];
    newYCols.splice(index, 1);
    setChartOptions({ ...chartOptions, yCols: newYCols });
  };

  const onSave = () => {
    onBlockUpdate(chartData?.blockId, "graph", chartOptions);
    onGraphEditSave(chartData?.blockId);
    setChartEditor(false);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);

  return (
    <Dialog
      open={openChartEditor}
      onClose={() => setChartEditor(false)}
      fullScreen
      className={classes.dialog}
    >
      <DialogTitle className={classes.titleContainer}>
        <div>
          <div className={classes.title}>Chart settings</div>
          <div className={classes.subTitle}>
            Customize and save your chart modify layout, colors, etc
          </div>
        </div>
        <div className={classes.buttonDiv}>
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={() => setChartEditor(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => onSave()}
          >
            Save
          </Button>
        </div>
      </DialogTitle>
      <br />
      <Divider />
      <br />
      <DialogContent className={classes.content}>
        <div className={classes.chartDiv}>
          <Graphs rows={chartData?.rows} options={chartOptions} />
        </div>
        <div className={classes.optionsDiv}>
          <div className={classes.optionsTitle}>Chart type</div>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              id="chart-type-select"
              value={chartOptions?.chartType}
              onChange={(e) => onSelectChange(e?.target?.value, "chartType")}
            >
              <MenuItem key="line" value={"line"}>
                Line
              </MenuItem>
              <MenuItem key="bar" value={"bar"}>
                Bar
              </MenuItem>
              <MenuItem key="area" value={"area"}>
                Area
              </MenuItem>
              <MenuItem key="pie" value={"pie"}>
                Pie
              </MenuItem>
            </Select>
          </FormControl>
          <div className={classes.optionsTitle}>X Axis</div>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              id="chart-type-select"
              value={chartOptions?.xCol}
              onChange={(e) => onSelectChange(e?.target?.value, "xCol")}
            >
              {columns?.map((col) => (
                <MenuItem key={col} value={col}>
                  {col}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {chartOptions?.yCols.map((yColObj: any, index: number) => (
            <div key={index}>
              <div className={classes.optionsTitle}>Y Axis</div>
              <div style={{ display: "flex" }}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <Select
                    id="chart-type-select"
                    value={yColObj?.yCol}
                    onChange={(e) =>
                      onYColChange(index, "yCol", e?.target?.value)
                    }
                  >
                    {columns?.map((col) => (
                      <MenuItem key={index + col} value={col}>
                        {col}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <MuiTooltip placement="top" title="Choose color" arrow>
                  <IconButton
                    className={classes.iconButton}
                    onClick={(e) => handleClick(e, index)}
                  >
                    <ColorizeRounded />
                  </IconButton>
                </MuiTooltip>
                {index !== 0 && (
                  <MuiTooltip placement="top" title="Remove" arrow>
                    <IconButton
                      className={classes.iconButton}
                      onClick={(e) => removeYCol(e, index)}
                      color="error"
                    >
                      <RemoveCircleOutlineRounded />
                    </IconButton>
                  </MuiTooltip>
                )}
              </div>
            </div>
          ))}
          <div className={classes.add}>
            <Button
              variant="text"
              startIcon={<AddCircleOutlineRounded />}
              onClick={() => addNewYCol()}
            >
              Add another series
            </Button>
          </div>
        </div>
      </DialogContent>
      <Popover
        id="more-options-connections"
        open={openPopover}
        anchorEl={anchorEl}
        className={classes.popover}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <SketchPicker
          color={chartOptions?.yCols?.[selectedIndex]?.color}
          onChangeComplete={(color) =>
            onYColChange(selectedIndex, "color", color.hex)
          }
        />
      </Popover>
    </Dialog>
  );
};

export default EditChart;
