import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Auth0ProviderWithHistory from "./components/auth/auth";
import { ThemeProvider, createTheme } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";
import "@fontsource/montserrat";
import { SnackbarProvider } from "notistack";
import { BreakpointProvider } from "react-socks";
import { ReactFlowProvider } from "reactflow";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: 1,
    },
  },
});

const theme = createTheme({
  typography: {
    allVariants: {
      fontSize: 14,
      fontFamily: "inherit",
    },
  },
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <BreakpointProvider>
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <ReactFlowProvider>
                <SnackbarProvider
                  maxSnack={3}
                  autoHideDuration={3000}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <App />
                </SnackbarProvider>
              </ReactFlowProvider>
            </QueryClientProvider>
          </ThemeProvider>
        </BreakpointProvider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
