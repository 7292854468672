import {
  Autocomplete,
  Divider,
  Drawer,
  IconButton,
  TextField,
} from "@mui/material";
import classes from "./ItemEditor.module.scss";
import {
  KeyboardArrowDownRounded,
  KeyboardDoubleArrowRightRounded,
} from "@mui/icons-material";
import { WorkspaceItem } from "../../models/Dashboard";
import Tile from "./Tile";
import { Query } from "../../models/Query";
import { DATABASES } from "../../utils/constant";
import TableEditor from "./TableEditor";
import ChartEditor from "./ChartEditor";
import clsx from "clsx";
import SelectEditor from "./SelectEditor";
import DateEditor from "./DateEditor";

type Props = {
  queries: Query[];
  selectedItem: WorkspaceItem;
  updateItem: (id: string, key: string, value: any) => void;
  itemEditorDrawer: boolean;
  setItemEditorDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  getDataFromQuery: (queryId: string | undefined) => any;
  viewOnly?: boolean;
};

export default function ItemEditor({
  queries,
  selectedItem,
  updateItem,
  itemEditorDrawer,
  setItemEditorDrawer,
  getDataFromQuery,
  viewOnly = false,
}: Props) {
  const selectedQuery =
    queries.find((q) => q.id === selectedItem.dataQueryId) || null;
  return (
    <Drawer
      open={itemEditorDrawer}
      variant="persistent"
      id="itemEditor"
      anchor="right"
      className={classes.drawer}
      classes={{
        paper: viewOnly
          ? clsx(classes.paper, classes.noTopMargin)
          : classes.paper,
      }}
    >
      <div className={classes.parent}>
        <div className={classes.top}>
          <div className={classes.text}>Component</div>
          <IconButton
            onClick={() => setItemEditorDrawer(false)}
            className={classes.iconButton}
          >
            <KeyboardDoubleArrowRightRounded />
          </IconButton>
        </div>
        <div className={classes.content}>
          {!!!viewOnly && selectedItem?.type !== "date" && (
            <div className={classes.querySelect}>
              <div className={classes.text}>Data source</div>
              <Autocomplete
                options={queries}
                className={classes.autocomplete}
                value={selectedQuery || null}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option: Query) => option.displayName}
                onChange={(e, value) =>
                  value && updateItem(selectedItem.id, "dataQueryId", value.id)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <KeyboardArrowDownRounded sx={{ fontSize: 18 }} />
                      ),
                      style: {
                        height: 24,
                        fontSize: 12,
                        color: "#000",
                        padding: "0px 8px 0px 4px",
                      },
                    }}
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li
                    {...props}
                    style={{
                      fontWeight: selected ? 600 : 400,
                      fontSize: 12,
                      padding: "4px 16px",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <img
                      src={DATABASES[option.type].icon}
                      alt={option.type}
                      width={16}
                      height={16}
                    />
                    {option.displayName}
                  </li>
                )}
              />
            </div>
          )}
          <div className={classes.title}>
            <div className={classes.text}>Title</div>
            <TextField
              size="small"
              sx={{ width: "80%", marginLeft: "auto" }}
              value={selectedItem?.title || ""}
              onChange={(e) =>
                updateItem(selectedItem.id, "title", e.target.value)
              }
              InputProps={{
                style: {
                  height: 24,
                  fontSize: 12,
                  color: "#000",
                },
              }}
            />
          </div>
          <div className={classes.title}>
            <div className={classes.text}>Title size</div>
            <TextField
              size="small"
              type="number"
              sx={{ width: "80%", marginLeft: "auto" }}
              value={selectedItem?.metadata?.title?.fontSize || ""}
              onChange={(e) =>
                updateItem(selectedItem.id, "metadata.title.fontSize", e.target.value)
              }
              InputProps={{
                style: {
                  height: 24,
                  fontSize: 12,
                  color: "#000",
                },
              }}
            />
          </div>
          {selectedItem?.type === "tile" ? (
            <Tile
              selectedItem={selectedItem}
              updateItem={updateItem}
              readonly={viewOnly}
            />
          ) : selectedItem?.type === "table" ? (
            <TableEditor
              selectedItem={selectedItem}
              updateItem={updateItem}
              getDataFromQuery={getDataFromQuery}
            />
          ) : selectedItem?.type === "chart" ||
            selectedItem?.type === "graph" ? (
            <ChartEditor
              selectedItem={selectedItem}
              updateItem={updateItem}
              getDataFromQuery={getDataFromQuery}
            />
          ) : selectedItem?.type === "select" ? (
            <SelectEditor
              queries={queries}
              selectedItem={selectedItem}
              updateItem={updateItem}
              getDataFromQuery={getDataFromQuery}
            />
          ) : selectedItem?.type === "date" ? (
            <DateEditor
              queries={queries}
              selectedItem={selectedItem}
              updateItem={updateItem} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </Drawer>
  );
}
