import { useLocation, useNavigate, useParams } from "react-router-dom";
import classes from "./AdminEditor.module.scss";
import MiniDrawer from "./MiniDrawer";
import TopBar from "./TopBar";
import { useEffect, useState } from "react";
import { Query } from "../../models/Query";
import { v4 } from "uuid";
import QueryEditor from "./QueryEditor";
import { useConnection, useConnectionById } from "../../api/connections";
import useQueryParameter, {
  DarkTooltip,
  convertJsToValue,
} from "../../utils/util";
import { DbConnection } from "../../models/DbConnection";
import {
  useAddQuery,
  useDashboardQueries,
  useDeleteQuery,
  usePatchQuery,
  useRunDataQuery,
} from "../../api/query";
import Workspace from "./Workspace";
import { DashboardBlock, Filter, WorkspaceItem } from "../../models/Dashboard";
import ItemEditor from "./ItemEditor";
import Lottie from "lottie-react";
import loadingJson from "../../../icons/loading.json";
import {
  useDashboardDetails,
  useDashboards,
  useGenerateEmbedId,
  usePatchDashboard,
  usePatchDashboardEmail,
  usePatchItem,
} from "../../api/dashboard";
import { useUpdateUser, useUser, useUserCustomers } from "../../api/user";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { Circle, CloseRounded, SendRounded } from "@mui/icons-material";
import ChatInner from "../../chat/ChatInner";
import {
  useAddChat,
  useAddChatGraph,
  useAddChatSummary,
  useAddFollowUpQuestions,
  useChatDetails,
  usePatchChat,
} from "../../api/chat";
import { useLocalStorage } from "usehooks-ts";
import { queryClient } from "../../..";
import { useQueryVariable } from "../../utils/useQueryVariable";

type Props = {
  readonly?: boolean;
};

export default function AdminEditor({ readonly = false }: Props) {
  const { id = "" } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [brandPalette, setBrandPalette] = useState<any>({
    light: "#faf8ff",
    dark: "#4b0082",
  });
  const email = useQueryParameter("email");
  const filterId: Number = +(useQueryParameter("filter") || 0);
  const parameters: any = JSON.parse(useQueryParameter("parameters") || "[]");
  const [chatId, setChatId] = useState<string>(v4());
  readonly = useQueryParameter("preview") === "true";
  const { data: existingConnections, isLoading } = useConnection(email);
  const { data: serverUser } = useUser(email);
  const { mutate: updateUser, isLoading: updatingUser } = useUpdateUser(email);
  const {
    data: chatDetails,
    isLoading: loadingDetails,
    refetch: refetchChat,
  } = useChatDetails(chatId, email);
  const { mutate: addChat, isLoading: addingChat } = useAddChat(chatId, email);
  const { mutate: patchChat, isLoading: patchingChat } = usePatchChat(
    chatId,
    email
  );
  const { data: dashboards, isLoading: fetchingDashboards } =
    useDashboards(email);
  const { mutate: updateDashboard, isLoading: updatingDashboard } =
    usePatchDashboardEmail(email);
  const { data: dashboardDetails, isLoading: loadingDashboard } =
    useDashboardDetails(id, email);
  const { mutate: patchDashboard, isLoading: patchingDashboard } =
    usePatchDashboard(id, email);
  const [activeConnection, setActiveConnection] = useLocalStorage(
    "saydata-active-connection",
    ""
  );
  const { mutate: patchQuery, isLoading: savingQuery } = usePatchQuery(
    id,
    email
  );
  const { mutate: mutateQuery, isLoading: runningQuery } = useRunDataQuery(email);
  const { mutateAsync: addChatGraph, isLoading: addingGraph } = useAddChatGraph(
    chatId,
    email
  );
  const { mutate: generateEmbedId, isLoading: generatingEmbedId } =
    useGenerateEmbedId(id, email);
  const { mutateAsync: addChatSummary, isLoading: addingSummary } =
    useAddChatSummary(chatId, email);
  const { mutateAsync: addFollowUp, isLoading: addingFollowUp } =
    useAddFollowUpQuestions(chatId, email);
  const { data: connection, refetch: refetchConnection } = useConnectionById(
    email,
    activeConnection
  );
  const [openChatDialog, setChatDialog] = useState(false);
  const [connections, setConnections] = useState<DbConnection[]>([]);
  const [dashboard, setDashboard] = useState<DashboardBlock>({
    editorItems: [],
  });
  const [filters, setFilters] = useState<Filter[]>([]);
  const {
    data: existingQueries,
    refetch,
    isFetching: isLoadingQueries,
  } = useDashboardQueries(id, email, filters);
  const { mutate: addQuery, isLoading: addingQuery } = useAddQuery(id, email);
  const { mutate: removeQuery, isLoading: deletingQuery } = useDeleteQuery(
    id,
    email
  );
  const [queries, setQueries] = useState<Query[]>([]);
  const [lhsOpened, setLhsOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState<WorkspaceItem>({
    id: "",
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    type: "",
  });
  const { data: customers, isLoading: loadingCustomers } =
    useUserCustomers(email);
  const [currentCustomer, setCurrentCustomer] = useState<any>({});

  const [queryEditor, setQueryEditor] = useState(false);
  const [itemEditorDrawer, setItemEditorDrawer] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState<Query>({
    id: "",
    displayName: "",
    dashboardId: "",
    type: "",
    code: "",
    connectionId: "",
    refreshRate: 24,
    parameters: {},
    results: [],
  });

  useEffect(() => {
    if (existingQueries?.data?.length > 0) {
      const allQueries = existingQueries.data || [];
      allQueries?.forEach((query: Query, index: number) => {
        if (query.type === "js") {
          allQueries[index].results = convertJsToValue(query.code, allQueries);
        }
      });
      setQueries(allQueries);
    }
  }, [existingQueries]);

  useEffect(() => {
    if (dashboardDetails?.data) {
      setDashboard(dashboardDetails.data);
    }
  }, [dashboardDetails]);

  useEffect(() => {
    if (customers?.data?.length > 0) {
      const cusSelected = customers.data.find(
        (customer: any) => customer.id === filterId
      );
      setCurrentCustomer(cusSelected);
    }
  }, [filterId, customers]);

  const updateFiltersParams = (value: any) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("filter", value?.id);
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    if (parameters && parameters?.length > 0) {
      const newFilters = currentCustomer?.filters || [];
      if (newFilters.length > 0) {
        parameters?.forEach((param: any) => {
          const innerFilter = newFilters?.[param?.index];
          innerFilter.values = param?.values;
        });
      }
      setFilters(newFilters);
    } else {
      setFilters(currentCustomer?.filters || []);
    }
  }, [currentCustomer]);

  useEffect(() => {
    if (filters && filters.length > 0) {
      refetch();
    }
  }, [filters]);

  const onSave = () => {
    const request = {
      id: id,
      editorItems: dashboard.editorItems,
    };
    patchDashboard(request as any);
    const userRequest = {
      brandPalette: brandPalette,
    };
    updateUser(userRequest as any);
  };

  const setWorkspaceItems = (items: WorkspaceItem[]) => {
    setDashboard({
      ...dashboard,
      editorItems: items,
    });
  };

  const deleteQuery = (id: string) => {
    removeQuery({
      id: id,
      filters: filters,
    });
  };

  useEffect(() => {
    if (serverUser) {
      setBrandPalette(
        serverUser?.data?.brandPalette || {
          light: "#faf8ff",
          dark: "#4b0082",
        }
      );
    }
  }, [serverUser]);

  const getConnectionName = (id: any) => {
    return existingConnections?.data?.find(
      (connection: any) => connection.id === id
    )?.displayName;
  };

  const addNewQuery = (queryType?: string) => {
    const length = queries.length;
    const newQuery = {
      id: v4(),
      displayName: `query${length + 1}`,
      type: queryType ? queryType : connections[0].type,
      email: email,
      code: "",
      dashboardId: id,
      connectionId: connections[0].id,
      refreshRate: 24,
      parameters: {},
      results: [],
    };
    addQuery(newQuery as any, {
      onSuccess: () => {
        setQueries([...queries, newQuery]);
      },
    });
  };

  const saveQueryRun = (queryId: string, newParams: any) => {
    const queryClone = queries?.find((query) => query.id === queryId);
    if (!queryClone) {
      return;
    }

    if (queryClone.type === "js") {
      const value = convertJsToValue(queryClone.code, queries, newParams);
      queryClone.results = value;
      setQueries([...queries]);
    } else {
      let queryRequest;
      if (queryClone.type === "mongodb") {
        queryRequest = {
          queryOptions: queryClone.queryOptions,
          type: "mongodb",
          connectionId: queryClone.connectionId,
          parameters: newParams,
        };
      } else {
        queryRequest = {
          code: queryClone.code,
          connectionId: queryClone.connectionId,
          parameters: newParams,
        };
      }
      const request = {
        query: queryRequest,
        filters: filters,
      };
      mutateQuery(request as any, {
        onSuccess: (response) => {
          queryClone.results = response?.data;
          setQueries([...queries]);
        }
      });
    }
  };

  const getDataFromQuery = (queryId: string | undefined) => {
    return queries.find((query) => query.id === queryId)?.results || [];
  };

  useEffect(() => {
    if (existingConnections?.data?.length > 0) {
      const adminConnections = existingConnections.data;
      setConnections(adminConnections);
    }
  }, [existingConnections]);

  const updateItem = (id: string, key: string, value: any) => {
    const newWorkspaceItems = [...(dashboard.editorItems || [])];
    const index = newWorkspaceItems.findIndex((item) => item.id === id);

    if (index >= 0) {
      const keys = key.split(".");
      let currentItem = newWorkspaceItems[index];

      for (let i = 0; i < keys.length - 1; i++) {
        const currentKey = keys[i];
        if (!(currentKey in currentItem)) {
          (currentItem as any)[currentKey as keyof any] = {};
        }
        currentItem = (currentItem as any)[currentKey as keyof any];
      }

      const finalKey = keys[keys.length - 1];
      (currentItem as any)[finalKey as keyof any] = value;

      setWorkspaceItems(newWorkspaceItems);
    }
  };

  if (loadingCustomers) {
    return (
      <div
        style={{
          display: "flex",
          margin: "auto",
          width: 200,
          height: "100vh",
        }}
      >
        <Lottie animationData={loadingJson} loop={true} />
      </div>
    );
  }

  return (
    <div
      className={classes.root}
      style={{ backgroundColor: brandPalette.light }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingQueries}
      >
        <CircularProgress sx={{ color: "#4B0082" }} />
      </Backdrop>
      {!!!readonly && (
        <TopBar
          onSave={onSave}
          serverUser={serverUser}
          id={id}
          activeConnection={activeConnection}
          dashboard={dashboard}
          customers={customers?.data}
          currentCustomer={currentCustomer}
          parameters={parameters}
          filters={filters}
          generateEmbedId={generateEmbedId}
          generatingEmbedId={generatingEmbedId}
          updateFiltersParams={updateFiltersParams}
        />
      )}
      {!!!readonly && (
        <MiniDrawer
          queries={queries}
          workspaceItems={dashboard.editorItems || []}
          brandPallete={brandPalette}
          setBrandPallete={setBrandPalette}
          setWorkspaceItems={setWorkspaceItems}
          setLhsOpened={setLhsOpened}
          setQuery={setSelectedQuery}
          setQueryEditor={setQueryEditor}
          addNewQuery={addNewQuery}
          deleteQuery={deleteQuery}
          setSelectedItem={setSelectedItem}
        />
      )}
      {!!!readonly && (
        <TextField
          onClick={() => {
            if (serverUser?.data?.featureFlags?.canva) {
              navigate(`/canva/${v4()}?email=${email}`);
            } else {
              setChatId(v4());
              setChatDialog(true);
            }
          }}
          sx={{
            width: lhsOpened ? "calc(100% - 260px)" : "calc(100% - 84px)",
            marginLeft: lhsOpened ? "240px" : "64px",
            marginTop: readonly ? "24px" : "64px",
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: brandPalette.dark,
                borderWidth: 1,
              },
            },
          }}
          placeholder="Add new metric, ask in plain english to get started..."
          InputProps={{
            autoFocus: true,
            style: {
              height: 44,
              borderRadius: "12px",
              border: "1px solid " + brandPalette.dark,
              background: "#FFF",
              boxShadow: "0px 2px 2px 0px " + brandPalette.dark,
            },
            endAdornment: (
              <InputAdornment position="end">
                {false ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: brandPalette.dark }}
                  />
                ) : (
                  <IconButton
                    className={classes.iconButton}
                    style={{
                      rotate: "-40deg",
                      marginTop: "-5px",
                      color: brandPalette.dark,
                    }}
                  >
                    <SendRounded />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        ></TextField>
      )}
      <Workspace
        queries={queries}
        lhsOpened={lhsOpened}
        brandPalette={brandPalette}
        workspaceItems={dashboard.editorItems || []}
        setWorkspaceItems={setWorkspaceItems}
        setSelectedItem={setSelectedItem}
        setItemEditorDrawer={setItemEditorDrawer}
        getDataFromQuery={getDataFromQuery}
        saveQueryRun={saveQueryRun}
        viewOnly={readonly}
      />
      {!!!readonly && (
        <QueryEditor
          open={queryEditor}
          dashboardId={id}
          email={email}
          setOpen={setQueryEditor}
          query={selectedQuery}
          allQueries={queries}
          connections={connections}
          lhsOpened={lhsOpened}
          filters={filters}
        />
      )}
      {!!!readonly && (
        <ItemEditor
          selectedItem={selectedItem}
          updateItem={updateItem}
          itemEditorDrawer={itemEditorDrawer}
          queries={queries}
          setItemEditorDrawer={setItemEditorDrawer}
          getDataFromQuery={getDataFromQuery}
        />
      )}
      <Dialog
        open={openChatDialog}
        onClose={() => setChatDialog(false)}
        className={classes.chatDialog}
        classes={{ paper: classes.paperNonEmbed }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingDetails}
        >
          <CircularProgress sx={{ color: "#4B0082" }} />
        </Backdrop>
        <div className={classes.toolbar}>
          <DarkTooltip
            title="Active connection"
            placement="top"
            enterDelay={500}
          >
            <div className={classes.activeConnection}>
              {getConnectionName(activeConnection)}
              <Circle sx={{ fontSize: 10, color: "#74A854" }} />
            </div>
          </DarkTooltip>
          <IconButton
            style={{ position: "absolute", top: 12, right: 12 }}
            onClick={() => setChatDialog(false)}
          >
            <CloseRounded style={{ fontSize: "16px" }} />
          </IconButton>
        </div>
        <ChatInner
          email={email}
          chatId={chatId}
          serverUser={serverUser}
          connections={{ data: connections }}
          chatDetails={chatDetails}
          loadingDetails={loadingDetails}
          refetch={refetchChat}
          addChat={addChat}
          addingChat={addingChat}
          patchChat={patchChat}
          addChatGraph={addChatGraph}
          addChatSummary={addChatSummary}
          addFollowUp={addFollowUp}
          filters={filters}
          patchingChat={patchingChat}
          dashboards={dashboards}
          updateDashboard={updateDashboard}
          updatingDashboard={updatingDashboard}
          connection={connection}
          activeConnection={activeConnection}
          refetchConnection={refetchConnection}
        />
      </Dialog>
    </div>
  );
}
