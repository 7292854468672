import { Drawer, IconButton, TextField } from "@mui/material";
import classes from "./BrandPallete.module.scss";
import { KeyboardDoubleArrowLeftRounded } from "@mui/icons-material";
import ColorPicker from "../utils/ColorPIcker";
import { useState } from "react";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  brandPallete: any;
  setBrandPallete: React.Dispatch<React.SetStateAction<any>>;
};
export default function BrandPallete({
  open,
  setOpen,
  brandPallete,
  setBrandPallete,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);
  const [anchorEl2, setAnchorEl2] = useState<HTMLDivElement | null>(null);
  const handleClick2 = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const openPopover2 = Boolean(anchorEl2);
  return (
    <Drawer
      open={open}
      variant="persistent"
      className={classes.brandDrawer}
      classes={{ paper: classes.paper }}
    >
      <div className={classes.content}>
        <div className={classes.top}>
          <div className={classes.text}>Brand Color</div>
          <IconButton
            onClick={() => setOpen(false)}
            className={classes.iconButton}
          >
            <KeyboardDoubleArrowLeftRounded />
          </IconButton>
        </div>
        <div className={classes.title}>
          <div className={classes.text}>Primary</div>
          <TextField
            size="small"
            // sx={{ width: "80%", marginLeft: "auto" }}
            value={brandPallete?.dark}
            InputProps={{
              style: {
                height: 24,
                fontSize: 12,
                color: "#000",
              },
              startAdornment: (
                <div
                  style={{
                    width: 16,
                    height: 12,
                    margin: "4px 8px 4px 0px",
                    borderRadius: 4,
                    border: "1px solid #888",
                    cursor: "pointer",
                    background: brandPallete?.dark,
                  }}
                    onClick={(e) => handleClick(e)}
                ></div>
              ),
            }}
          />
        </div>
        <div className={classes.title}>
          <div className={classes.text}>Secondary</div>
          <TextField
            size="small"
            // sx={{ width: "80%", marginLeft: "auto" }}
            value={brandPallete?.light}
            InputProps={{
              style: {
                height: 24,
                fontSize: 12,
                color: "#000",
              },
              startAdornment: (
                <div
                  style={{
                    width: 16,
                    height: 12,
                    margin: "4px 8px 4px 0px",
                    borderRadius: 4,
                    border: "1px solid #888",
                    cursor: "pointer",
                    background: brandPallete?.light,
                  }}
                    onClick={(e) => handleClick2(e)}
                ></div>
              ),
            }}
          />
        </div>
      </div>
      <ColorPicker
        selectedColor={brandPallete?.dark}
        openPopover={openPopover}
        anchorEl={anchorEl}
        handleClose={handleClose}
        updateColor={(value: string) => {
          setBrandPallete({ ...brandPallete, dark: value });
        }}
      />
      <ColorPicker
        selectedColor={brandPallete?.light}
        openPopover={openPopover2}
        anchorEl={anchorEl2}
        handleClose={handleClose2}
        updateColor={(value: string) => {
          setBrandPallete({ ...brandPallete, light: value });
        }}
      />
    </Drawer>
  );
}
