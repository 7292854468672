import { DeleteRounded, MoreHorizRounded } from "@mui/icons-material";
import { Backdrop, CircularProgress, Button, IconButton } from "@mui/material";
import DbConnectionDetails from "../../connections/DbConnectionDetails";
import DbTypes from "../../connections/DbTypes";
import GSheet from "../../connections/GSheet";
import { DATABASES } from "../../utils/constant";
import classes from "./Connection.module.scss";
import {
  useConnection,
  useAddConnection,
  useDeleteConnection,
} from "../../api/connections";
import useQueryParameter from "../../utils/util";
import { useEffect, useState } from "react";
import { DbConnection } from "../../models/DbConnection";
import Upload from "../../connections/Upload";

export default function AdminConnections() {
  const email = useQueryParameter("email");
  const { data: existingConnections, isLoading } = useConnection(email);
  const { mutate: addConnection, isLoading: addingConnection } =
    useAddConnection(email);
  const { mutate: deleteConneciton, isLoading: deletingConnection } =
    useDeleteConnection(email);
  const [connections, setConnections] = useState<DbConnection[]>([]);
  const [dbChoicesDialog, setDbChoicesDialog] = useState(false);
  const [dbDetailDialog, setDbDetailDialog] = useState(false);
  const [selectedDb, setSelectedDb] = useState("postgres");
  const [uploadDialog, setUploadDialog] = useState(false);
  const [gsheetDialog, setGsheetDialog] = useState(false);

  const onDbSelect = (type: string) => {
    setSelectedDb(type);
    setDbChoicesDialog(false);
    if (type === "csv") {
      setUploadDialog(true);
    } else if (type === "gsheet") {
      setGsheetDialog(true);
    } else {
      setDbDetailDialog(true);
    }
  };

  const onSave = (newConn: DbConnection) => {
    addConnection(newConn as any);
    setDbDetailDialog(false);
    return [];
  };

  useEffect(() => {
    if (existingConnections?.data?.length > 0) {
      const adminConnections = existingConnections.data?.filter(
        (c: any) => c.tab === "admin"
      );
      setConnections(adminConnections);
    }
  }, [existingConnections]);

  const onDeleteConnection = (connectionId: string) => {
    deleteConneciton(connectionId as any);
  };
  return (
    <div className={classes.root}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading || deletingConnection || addingConnection}
      >
        <CircularProgress sx={{ color: "#4B0082" }} />
      </Backdrop>
      <div className={classes.header}>
        <div>Connections</div>
        <Button
          variant="contained"
          className={classes.addButton}
          onClick={() => setDbChoicesDialog(true)}
        >
          Add connection
        </Button>
      </div>

      {connections?.map((connection, index) => (
        <div className={classes.wrapper}>
          <div key={index} className={classes.tile}>
            <img
              src={DATABASES[connection?.displayType]?.icon}
              alt="icon"
              width={28}
              height={28}
            ></img>
            <div className={classes.connectionName}>
              {connection.displayName}
            </div>
            <div className={classes.iconGroup}>
              <IconButton onClick={(e) => onDeleteConnection(connection.id)}>
                <DeleteRounded color="error" />
              </IconButton>
            </div>
          </div>
        </div>
      ))}

      <DbTypes
        dbChoicesDialog={dbChoicesDialog}
        setDbChoicesDialog={setDbChoicesDialog}
        onDbSelect={onDbSelect}
        tab="admin"
      />
      <Upload openUpload={uploadDialog} setUpload={setUploadDialog} />
      <GSheet
        openGSheetDialog={gsheetDialog}
        setGSheetDialog={setGsheetDialog}
      />
      <DbConnectionDetails
        dbDetailDialog={dbDetailDialog}
        selectedDb={selectedDb}
        onSave={onSave}
        setDbDetailDialog={setDbDetailDialog}
        tab="admin"
      />
    </div>
  );
}
