import { useMutation, useQuery } from "react-query";
import { BE_SERVER } from "../../utils/constant";
import { useSnackbar } from "notistack";
import { queryClient } from "../../..";

export const useChatHistory = (apiKey: string) => {
  return useQuery(["chatHistory", apiKey], async () => {
    const headers = {
      ApiKey: `${apiKey}`,
    };
    const res = await fetch(`${BE_SERVER}/api/chat/history`, {
      headers: headers,
    });

    if (!res.ok) {
      throw new Error("Error while fetching chat history");
    }

    return res.json();
  });
};

export const useChatDetails = (chatId: string, apiKey: string) => {
  return useQuery(["chat", chatId], async () => {
    const headers = {
      ApiKey: `${apiKey}`,
    };
    const res = await fetch(`${BE_SERVER}/api/chat/${chatId}`, {
      headers: headers,
    });

    if (!res.ok) {
      throw new Error("Error while fetching chat details");
    }

    return res.json();
  });
};

export const useAddChat = (chatId: string, apiKey: string) => {
  return useMutation(
    async (request: any) => {
      const headers = {
        ApiKey: `${apiKey}`,
        "Content-Type": "application/json",
      };
      const res = await fetch(`${BE_SERVER}/api/chat/${chatId}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(request),
      });

      if (!res.ok) {
        const message = await res.json();
        throw new Error(message?.error);
      }

      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["chatHistory", apiKey]);
      },
    }
  );
};

export const usePatchChat = (chatId: string, apiKey: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request: any) => {
      const headers = {
        ApiKey: `${apiKey}`,
        "Content-Type": "application/json",
      };
      const res = await fetch(`${BE_SERVER}/api/chat/${chatId}`, {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(request),
      });

      if (!res.ok) {
        throw new Error("Error while updating chat");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        // enqueueSnackbar("Updated successfully", { variant: "success" });
      },
      onError: () => {
        // enqueueSnackbar("Error while updating", { variant: "error" });
      },
    }
  );
};

export const useAddChatGraph = (chatId: string, apiKey: string) => {
  return useMutation(async (request: any) => {
    const headers = {
      ApiKey: `${apiKey}`,
      "Content-Type": "application/json",
    };
    const res = await fetch(`${BE_SERVER}/api/chat/${chatId}/graph`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(request),
    });

    if (!res.ok) {
      throw new Error("Error while adding chat graph");
    }

    return res.json();
  });
};

export const useAddChatSummary = (chatId: string, apiKey: string) => {
  return useMutation(async (request: any) => {
    const headers = {
      ApiKey: `${apiKey}`,
      "Content-Type": "application/json",
    };
    const res = await fetch(`${BE_SERVER}/api/chat/${chatId}/summary`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(request),
    });

    if (!res.ok) {
      throw new Error("Error while adding chat summary");
    }

    return res.json();
  });
};

export const useAddFollowUpQuestions = (chatId: string, apiKey: string) => {
  return useMutation(async (request: any) => {
    const headers = {
      ApiKey: `${apiKey}`,
      "Content-Type": "application/json",
    };
    const res = await fetch(`${BE_SERVER}/api/chat/${chatId}/followup`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(request),
    });

    if (!res.ok) {
      throw new Error("Error while creating followup questions");
    }

    return res.json();
  });
};
