import { memo } from "react";
import classes from "./ChatCanva.module.scss";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Handle, Position } from "reactflow";
import { AutoAwesomeOutlined, SendRounded } from "@mui/icons-material";
import logoGif from "../../icons/logo.gif";
import logo from "../../icons/new_logo.png";
import clsx from "clsx";

type Props = {
  id: string;
  data: any;
};

function WelcomeNode({ id, data }: Props) {
  return (
    <div className={classes.welcomeNode}>
      <div className={classes.container}>
        <div className={classes.message}>
          <img src={logoGif} alt="logo" width={28} height={28} />
          <div className={classes.title}>Welcome to Magic Canvas</div>
          <div className={classes.subtitle}>
            Create visualisations for your dashboards here. Just type your
            questions to get started.
          </div>
        </div>
        <div className={classes.suggestions}>
          <div className={classes.top}>
            {data?.value?.sampleQuestions
              ?.slice(0, 2)
              ?.map((q: string, i: number) => (
                <div
                  className={classes.block}
                  key={i}
                  onClick={(e) => {
                    data.updateNodeText(id, q);
                    data.onSubmit(id, q);
                  }}
                >
                  <AutoAwesomeOutlined />
                  <div className={classes.title}>{q}</div>
                </div>
              ))}
          </div>
          <div className={classes.top}>
            {data?.value?.sampleQuestions
              ?.slice(-2)
              ?.map((q: string, i: number) => (
                <div
                  className={classes.block}
                  key={i}
                  onClick={(e) => {
                    data.updateNodeText(id, q);
                    data.onSubmit(id, q);
                  }}
                >
                  <AutoAwesomeOutlined />
                  <div className={classes.title}>{q}</div>
                </div>
              ))}
          </div>
        </div>
        <TextField
          value={data?.currentText}
          onChange={(e) => data.updateNodeText(id, e.target.value)}
          onKeyDown={(e) => data.handleEnterEvent(e, id)}
          sx={{
            width: "100%",
            //   padding: "0px 24px 24px 24px",
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#4B0082",
                borderWidth: 1,
              },
            },
          }}
          placeholder="Ask a question"
          // ref={inputRef}
          InputProps={{
            //   disabled: addingChat,
            autoFocus: true,
            style: {
              borderRadius: 8,
              fontSize: 8,
              fontWeight: 500,
              background: "#fff",
              cursor: "auto",
              paddingRight: 6,
              height: 24,
            },
            startAdornment: (
              <InputAdornment position="start">
                <img src={logo} alt="logo" width={12} height={12} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={(e) => data.onSubmit(id)}
                  className={classes.iconButton}
                >
                  <SendRounded />
                </IconButton>
              </InputAdornment>
            ),
          }}
        ></TextField>
      </div>
      {/* <Handle type="source" id="a" position={Position.Bottom} /> */}
    </div>
  );
}

export default memo(WelcomeNode);
