import * as React from "react";
import { styled, Theme, CSSObject, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useAuth0 } from "@auth0/auth0-react";
import namedLogo from "../../icons/logo_no_back.svg";
import logo from "../../icons/logo_sl.svg";
import {
  Avatar,
  Button,
  Collapse,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import ChatInterface from "../chat/ChatInterface";
import { stringAvatar } from "../utils/util";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AddRounded,
  AutoAwesomeRounded,
  Circle,
  DashboardCustomizeRounded,
  DescriptionRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  GridViewRounded,
  HistoryRounded,
  IntegrationInstructionsRounded,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowLeftRounded,
  KeyboardDoubleArrowUp,
  LogoutRounded,
  SpaceDashboardRounded,
  StarBorderRounded,
  StorageRounded,
} from "@mui/icons-material";
import classes from "./Sidebar.module.scss";
import { v4 } from "uuid";
import { useConnection } from "../api/connections";
import { useDashboards } from "../api/dashboard";
import CreateDialog from "../dashboard/CreateDialog";
import clsx from "clsx";
import { useChatHistory } from "../api/chat";
import videoSnap from "../../icons/saynewgif.gif";
import integration from "../../icons/integrations.png";
import Banner from "../utils/Banner";
import { useLocalStorage } from "usehooks-ts";
import { useUser } from "../api/user";
import Stripe from "../utils/Stripe";

// let drawerWidth = 240;

export default function Sidebar() {
  const [drawerWidth, setDrawerWidth] = React.useState(240);
  const { user, isLoading, logout } = useAuth0();
  const email = user?.email || "";
  const [activeTab, setActiveTab] = React.useState("");
  const { data: serverUser } = useUser(user?.email || email);
  const location = useLocation();
  //   const [open, setOpen] = React.useState(false);
  const open = true;
  const { data: dashboards, isLoading: fetchingDashboards } =
    useDashboards(email);
  const { data: chatHistory, isLoading: loadingHistory } =
    useChatHistory(email);
  const [dashboardsList, setDashboardsList] = React.useState<any>([]);
  const [createDialog, setCreateDialog] = React.useState(false);
  const [openBanner, setBanner] = React.useState(false);
  const [openIntegrationBanner, setIntegrationBanner] = React.useState(false);
  const [dashboardExpanded, setDashboardExpanded] = React.useState(false);
  const [upgradeBanner, setUpgradeBanner] = React.useState(false);
  const [historyExpanded, setHistoryExpanded] = React.useState(false);
  const [activeConnection, setActiveConnection] = useLocalStorage(
    "saydata-active-connection",
    ""
  );
  const navigate = useNavigate();
  const [selectedConn, setSelectedConn] = React.useState("");
  const { data: connections } = useConnection(user?.email || "");
  const showDrawer =
    location.pathname.includes("/dashboard/editor") ||
    // location.pathname.includes("/canva") ||
    location.pathname.includes("/embed")
      ? false
      : true;
  const showTopBar =
    location.pathname.includes("/dashboard/editor") ||
    location.pathname.includes("/canva") ||
    location.pathname.includes("/embed")
      ? false
      : true;
  const getConnectionName = (id: any) => {
    return connections?.data?.find((connection: any) => connection.id === id)
      ?.displayName;
  };

  const getConnectionIdFromChat = (id: any) => {
    return chatHistory?.data?.find((chat: any) => chat.id === id)?.connectionId;
  };

  React.useEffect(() => {
    if (location.pathname.includes("/dashboard")) {
      // setDashboardExpanded(true);
      setActiveTab("dashboard");
      setSelectedConn(activeConnection || "");
    } else if (location.pathname.includes("/connections")) {
      setActiveTab("connections");
      setSelectedConn(activeConnection || "");
    } else if (location.pathname.includes("/history")) {
      setHistoryExpanded(true);
      setActiveTab(location.pathname);
      setSelectedConn(
        getConnectionIdFromChat(location.pathname?.split("/")?.pop() || "")
      );
    } else if (location.pathname.includes("/integrations")) {
      setActiveTab("integrations");
      setSelectedConn(activeConnection || "");
    } else if (location.pathname.includes("/chat")) {
      setActiveTab("chat");
      setSelectedConn(activeConnection || "");
    } else {
      setActiveTab("dashboard");
      setSelectedConn(activeConnection || "");
    }
  }, [location.pathname, chatHistory]);

  React.useEffect(() => {
    if (!activeConnection && connections?.data?.length > 0) {
      setActiveConnection(connections.data[0]?.id);
    }
  }, [connections]);

  React.useEffect(() => {
    if (activeConnection) {
      setSelectedConn(activeConnection);
    }
  }, [activeConnection]);

  React.useEffect(() => {
    if (location.pathname.includes("/canva")) {
      setDrawerWidth(74);
    } else {
      setDrawerWidth(240);
    }
  }, [location.pathname]);

  React?.useEffect(() => {
    if (dashboards?.data?.length > 0) {
      const exploreDashboards = dashboards?.data?.filter(
        (dashboard: any) => dashboard?.type === "explore"
      );
      setDashboardsList(exploreDashboards);
    }
  }, [dashboards]);

  const handleDrawerOpen = () => {
    if (!location.pathname.includes("/canva")) {
      return;
    }
    setDrawerWidth(240);
  };

  const handleDrawerClose = () => {
    if (!location.pathname.includes("/canva")) {
      return;
    }
    setDrawerWidth(74);
  };

  const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    "& .MuiDrawer-paper": {
      ...openedMixin(theme, drawerWidth),
      ...(open ? openedMixin(theme, drawerWidth) : closedMixin(theme)),
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: open
          ? theme.transitions.duration.enteringScreen
          : theme.transitions.duration.leavingScreen,
      }),
    },
  }));

  interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  return (
    <Box sx={{ display: "flex" }} className={classes.root}>
      {showDrawer && (
        <MuiAppBar
          position="fixed"
          sx={{
            width: !showDrawer ? 0 : `${drawerWidth}px`,
            transition: "width 0.3s cubic-bezier(0.4, 0, 0.6, 1) 0ms",
            ml: `${drawerWidth}px`,
            boxShadow: "none",
            left: `-${drawerWidth}px`,
            backgroundColor: "#1e0034",
          }}
        >
          <Toolbar
            className={classes.toolbar}
            sx={{
              visibility:
                location.pathname.includes("/canva") && showDrawer
                  ? "visible"
                  : "hidden",
            }}
          >
            {location.pathname.includes("/canva") && drawerWidth <= 74 ? (
              <img src={logo} alt="SayData" height={42} />
            ) : (
              <img src={namedLogo} alt="SayData" height={42} />
            )}
          </Toolbar>
        </MuiAppBar>
      )}
      {showTopBar && (
        <AppBar
          position="fixed"
          sx={{
            width: `100%`,
            ml: `${drawerWidth}px`,
            boxShadow: "none",
          }}
        >
          <Toolbar className={classes.toolbar}>
            <img src={namedLogo} alt="SayData" height={42} />
            {!!!activeTab.includes("dashboard") &&
              !!showDrawer &&
              (selectedConn ? (
                <Tooltip
                  title="Active connection"
                  placement="top"
                  enterDelay={500}
                >
                  <div className={classes.activeConnection}>
                    {getConnectionName(selectedConn)}
                    <Circle sx={{ fontSize: 10, color: "#74A854" }} />
                  </div>
                </Tooltip>
              ) : (
                <div className={classes.activeConnection}>
                  {"Select Datasource"}
                  <Circle sx={{ fontSize: 10 }} />
                </div>
              ))}
            {!!!showDrawer && (
              <Tabs className={classes.tab}>
                <Link to={`admin?email=${email}`}>Dashboards</Link>
                <Link to={`admin/connections?email=${email}`}>Connections</Link>
                <Link to={`admin/customers?email=${email}`}>Customers</Link>
              </Tabs>
            )}
            <div className={classes.profile}>
              <Avatar
                {...stringAvatar(user?.email || "Undefined")}
                style={{ marginRight: 8 }}
              />
              <div className={classes.info}>
                {user?.given_name && (
                  <div className={classes.name}>
                    {user?.given_name + " " + user?.family_name}
                  </div>
                )}
                <div className={classes.email}>{user?.email}</div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
      )}
      {showDrawer && (
        <MuiDrawer
          variant="persistent"
          open={open}
          onMouseMove={handleDrawerOpen}
          onMouseLeave={handleDrawerClose}
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          sx={{
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              transition: "width 0.3s cubic-bezier(0.4, 0, 0.6, 1) 0ms",
              whiteSpace: "nowrap",
            },
          }}
        >
          {/* <DrawerHeader className={classes.drawerHeader}>
            <img src={namedLogo} alt="SayData" height={36} />
          </DrawerHeader> */}
          <Divider />
          {/* <Toolbar /> */}
          <List>
            {serverUser?.data?.featureFlags?.canva ? (
              <ListItemButton
                key="canva"
                className={
                  activeTab === "canva"
                    ? clsx(classes.active, classes.item)
                    : classes.item
                }
              >
                <Link
                  to={`/canva/${v4()}?email=${email}`}
                  // onClick={() => setSelectedConn(activeConnection || "")}
                  className={
                    activeTab === `canva`
                      ? clsx(classes.activeLink, classes.link)
                      : classes.link
                  }
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <AddRounded />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.text}
                    primary="New Chat"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </Link>
              </ListItemButton>
            ) : (
              <ListItemButton
                key="chat"
                className={
                  activeTab === "chat"
                    ? clsx(classes.active, classes.item)
                    : classes.item
                }
              >
                <Link
                  to={`/chat/${v4()}?email=${email}`}
                  // onClick={() => setSelectedConn(activeConnection || "")}
                  className={
                    activeTab === `chat`
                      ? clsx(classes.activeLink, classes.link)
                      : classes.link
                  }
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <AddRounded />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.text}
                    primary="New Chat"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </Link>
              </ListItemButton>
            )}
            {/* <ListItemButton
              className={classes.item}
              key="dashboard"
              onClick={() => {
                // setActiveTab("dashboards");
                // setDashboardExpanded(!dashboardExpanded);
              }}
            >
              <ListItemIcon style={{ minWidth: "48px" }}>
                <GridViewRounded />
              </ListItemIcon>
              <ListItemText primary="Dashboard" /> */}
            {/* {dashboardExpanded ? (
                <KeyboardDoubleArrowUp />
              ) : (
                <KeyboardDoubleArrowDown />
              )} */}
            {/* </ListItemButton> */}
            {/* <Collapse
              in={dashboardExpanded}
              timeout="auto"
              unmountOnExit
              className={classes.dashboardCollapse}
            >
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() =>
                    serverUser?.data?.plan === "pro"
                      ? setCreateDialog(true)
                      : setBanner(true)
                  }
                  className={classes.item}
                >
                  <ListItemIcon>
                    <AddRounded />
                  </ListItemIcon>
                  <ListItemText primary="Create New" />
                </ListItemButton>
                {dashboardsList?.map((dashboard: any) => (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    key={dashboard?.id}
                    className={
                      activeTab === `/dashboard/${dashboard?.id}`
                        ? clsx(classes.active, classes.item)
                        : classes.item
                    }
                  >
                    <Link
                      to={`/dashboard/${dashboard?.id}?email=${email}`}
                      className={classes.link}
                    >
                      <ListItemIcon>
                        <StarBorderRounded />
                      </ListItemIcon>
                      <ListItemText primary={dashboard?.title} />
                    </Link>
                  </ListItemButton>
                ))}
              </List>
            </Collapse> */}
            {/* <ListItemButton
            key="glossary"
            onClick={() => {
              setGlossaryDialog(true);
            }}
            className={classes.item}
            style={{ cursor: "pointer" }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <DescriptionRounded />
            </ListItemIcon>
            <ListItemText primary="Glossary" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton> */}
            <ListItemButton
              key="dashboard"
              className={
                activeTab === "dashboard"
                  ? clsx(classes.active, classes.item)
                  : classes.item
              }
            >
              <Link
                to={`/dashboard?email=${email}`}
                className={
                  activeTab === `dashboard`
                    ? clsx(classes.activeLink, classes.link)
                    : classes.link
                }
                // onClick={() => setActiveTab("connections")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <GridViewRounded />
                </ListItemIcon>
                <ListItemText
                  primary="Dashboard"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </Link>
            </ListItemButton>
            <ListItemButton
              key="connections"
              className={
                activeTab === "connections"
                  ? clsx(classes.active, classes.item)
                  : classes.item
              }
            >
              <Link
                to={`/connections?email=${email}`}
                className={
                  activeTab === `connections`
                    ? clsx(classes.activeLink, classes.link)
                    : classes.link
                }
                // onClick={() => setActiveTab("connections")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <StorageRounded />
                </ListItemIcon>
                <ListItemText
                  primary="Connections"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </Link>
            </ListItemButton>
            <ListItemButton
              key="integrations"
              onClick={() => setIntegrationBanner(true)}
              className={classes.item}
            >
              {/* <Link to={`/integrations?email=${email}`} className={classes.link}> */}
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <DashboardCustomizeRounded />
              </ListItemIcon>
              <ListItemText
                primary="Integrations"
                sx={{ opacity: open ? 1 : 0 }}
              />
              {/* </Link> */}
            </ListItemButton>
            <ListItemButton
              key="history"
              className={classes.item}
              onClick={() => {
                setHistoryExpanded(!historyExpanded);
              }}
            >
              <ListItemIcon style={{ minWidth: "48px" }}>
                <HistoryRounded />
              </ListItemIcon>
              <ListItemText primary="History" />
              {historyExpanded ? (
                <KeyboardDoubleArrowUp />
              ) : (
                <KeyboardDoubleArrowDown />
              )}
            </ListItemButton>
            <Collapse
              in={historyExpanded}
              timeout="auto"
              unmountOnExit
              className={classes.history}
            >
              <List component="div" disablePadding>
                {chatHistory?.data?.map((history: any) => (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    key={history?.id}
                    className={
                      activeTab === `/history/${history?.id}`
                        ? clsx(classes.active, classes.item)
                        : classes.item
                    }
                  >
                    <Link
                      to={`/history/${history?.id}?email=${email}`}
                      className={
                        activeTab === `/history/${history?.id}`
                          ? clsx(classes.activeLink, classes.link)
                          : classes.link
                      }
                      // onClick={() => setSelectedConn(history?.connectionId)}
                    >
                      <ListItemText
                        primary={
                          history?.title?.length > 20
                            ? history?.title?.substring(0, 24) + " ..."
                            : history?.title
                        }
                        className={classes.longText}
                      />
                    </Link>
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </List>
          <ListItem
            key="upgrade"
            disablePadding
            sx={{ display: "block", marginTop: "auto" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              className={classes.item}
              style={{ position: "absolute", bottom: 90 }}
              // href="https://saydata.tech#pricing"
              // target="_blank"
              // rel="noreferrer"
              onClick={() =>
                serverUser?.data?.plan === "pro"
                  ? window.open(
                      "https://calendar.app.google/Ha83FJLswBiXTKCe6",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  : setUpgradeBanner(true)
              }
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <AutoAwesomeRounded />
              </ListItemIcon>
              <ListItemText
                primary={
                  serverUser?.data?.plan === "pro"
                    ? "Contact Us"
                    : "Upgrade Plan"
                }
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem key="logout" disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              className={classes.item}
              style={{ position: "absolute", bottom: 40 }}
              onClick={() => {
                setActiveConnection("");
                logout();
                navigate("https://saydata.tech");
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LogoutRounded />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </MuiDrawer>
      )}
      <CreateDialog
        createDialog={createDialog}
        setCreateDialog={setCreateDialog}
        email={email}
        type="explore"
      />
      <Banner
        openBanner={openBanner}
        setBanner={setBanner}
        title="Embedded Dashboards"
        description="Get early access to pin and embed your analytics that is intuitive, search-based and truly self-serve. Embed our white-labelled search-bar along with pinned insights anywhere in your product."
        media={videoSnap}
        ctaLink="showPricing"
        setPricing={setUpgradeBanner}
      />
      <Banner
        openBanner={openIntegrationBanner}
        setBanner={setIntegrationBanner}
        title="Workflow Integrations"
        description="Get early access to integrations & Share and Schedule your insights and dashboards directly to your workflow (e.g Slack, Email, Teams). Deliver instant data insights without breaking the flow."
        media={integration}
        ctaLink="https://calendar.app.google/Ha83FJLswBiXTKCe6"
      />
      <Stripe
        openStripe={upgradeBanner}
        setStripe={setUpgradeBanner}
        email={email}
      />
    </Box>
  );
}
