import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQuery } from "react-query";
import { BE_SERVER } from "../../utils/constant";

export const useUser = (apiKey: string) => {
  return useQuery(["user", apiKey], async () => {
    const res = await fetch(`${BE_SERVER}/api/user`, {
      headers: {
        ApiKey: `${apiKey}`,
      },
    });

    if (!res.ok) {
      throw new Error("Error while fetching user info");
    }

    return res.json();
  });
};

export const useUserCustomers = (apiKey: string) => {
  return useQuery(["userCustomers", apiKey], async () => {
    const res = await fetch(`${BE_SERVER}/api/user/customers`, {
      headers: {
        ApiKey: `${apiKey}`,
      },
    });

    if (!res.ok) {
      throw new Error("Error while fetching user info");
    }

    return res.json();
  });
};
