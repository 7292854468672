import { useEffect, useState } from "react";
import { DbConnection } from "../../models/DbConnection";
import classes from "./SchemaView.module.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextField,
} from "@mui/material";
import {
  ArrowDownwardRounded,
  ArrowDropDownRounded,
  ArrowRightRounded,
  KeyboardArrowDownRounded,
  KeyboardArrowRightRounded,
  SearchRounded,
} from "@mui/icons-material";
import { DarkTooltip } from "../../utils/util";

type Props = {
  connection: DbConnection;
};

export default function SchemaView({ connection }: Props) {
  const [schema, setSchema] = useState<any[]>([]);
  const [tables, setTables] = useState<string[]>([]);
  const [expanded, setExpanded] = useState(false);
  const [selectedTable, setSelectedTable] = useState<string>("");
  const [searchText, setSearchText] = useState("");
  const [filteredTables, setFilteredTables] = useState(tables);

  useEffect(() => {
    if (connection?.schemaJson) {
      setSchema(connection?.schemaJson);
      const tablesArr: string[] = [];
      connection?.schemaJson?.forEach((table: any) => {
        tablesArr.push(Object.keys(table)[0]);
      });
      setTables(tablesArr);
    }
  }, [connection]);

  const hideShow = (value: boolean, table: string) => {
    setSelectedTable(table);
    if (selectedTable === table && expanded) setExpanded(false);
    else setExpanded(true);
  };

  useEffect(() => {
    if (searchText) {
      setFilteredTables(
        tables.filter((table) =>
          table.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setFilteredTables(tables);
    }
  }, [tables, searchText]);

  return (
    <div className={classes.root}>
      <DarkTooltip title="Search" placement="top">
        <TextField
          id="query-search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              input: {
                padding: "4px 0px",
              },
              fieldset: {
                borderWidth: 0,
              },
              "&:hover fieldset": {
                borderColor: "#666",
                borderWidth: 0,
              },
              "&.Mui-focused fieldset": {
                borderColor: "#666",
                borderWidth: 0,
              },
            },
          }}
          placeholder={"Showing " + tables?.length}
          size="small"
          InputProps={{
            endAdornment: <SearchRounded style={{ fontSize: 14 }} />,
            sx: {
              fontSize: 12,
              fontWeight: 500,
              padding: "0px 12px"
            },
          }}
        />
      </DarkTooltip>
      {filteredTables.map((table: any) => (
        <Button
          key={table}
          disableRipple
          className={classes.tableName}
          onClick={(_) =>
            expanded ? hideShow(false, table) : hideShow(true, table)
          }
          startIcon={
            expanded && selectedTable === table ? (
              <ArrowDropDownRounded />
            ) : (
              <ArrowRightRounded />
            )
          }
        >
          <div className={classes.content}>
            <div className={classes.title}>{table}</div>
            {selectedTable === table &&
              expanded &&
              schema
                .find((row) => Object.keys(row)[0] === table)
                ?.[table]?.columns?.map((column: any) => (
                  <div
                    key={column?.field}
                    className={classes.colDetails}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className={classes.name}>{column?.field}</div>
                    <div className={classes.type}>{column?.type}</div>
                  </div>
                ))}
          </div>
        </Button>
      ))}
    </div>
  );
}
