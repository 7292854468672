import classes from "./DashboardEmbed.module.scss";
import useQueryParameter, { dataFormater, decrypt } from "../utils/util";
import Dashboard from "../dashboard/Dashboard";
import {
  BoltRounded,
  Circle,
  CloseRounded,
  MoreVertRounded,
  SendRounded,
} from "@mui/icons-material";
import {
  TextField,
  IconButton,
  Dialog,
  Backdrop,
  CircularProgress,
  Tooltip,
  InputAdornment,
  Button,
  DialogContent,
  DialogTitle,
  Popover,
} from "@mui/material";
import { Layout, Responsive } from "react-grid-layout";
import Graphs from "../charts/Graphs";
import RowsView from "../utils/RowsView";
import { useState, useEffect, useRef, useMemo } from "react";
import { DashboardBlock, Item } from "../models/Dashboard";
import ChatInner from "../chat/ChatInner";
import { v4 } from "uuid";
import {
  useAddChat,
  useAddChatGraph,
  useAddChatSummary,
  useAddFollowUpQuestions,
  useChatDetails,
  usePatchChat,
} from "../api/key/chat";
import { useConnection, useConnectionById } from "../api/key/connections";
import {
  useDashboardDetails,
  useDashboards,
  usePatchDashboard,
  usePatchDashboardApiKey,
  usePatchItem,
} from "../api/key/dashbaord";
import { ENCRYPTION_KEY } from "../utils/constant";
import { useUser } from "../api/key/user";
import { useParams } from "react-router-dom";
import { ChatItem } from "../models/Chat";

export default function DashboardEmbed() {
  const { dashboardId = "", activeConnection = "" } = useParams();
  // const email = useQueryParameter("email");
  const hash = useQueryParameter("hash");
  const apiKey = useMemo(() : string => {
    return decrypt(hash, ENCRYPTION_KEY);
  }, [hash]);
  const [openChatDialog, setChatDialog] = useState(false);
  const { data: dashboardDetails, isLoading: loadingDashboard } =
    useDashboardDetails(dashboardId, apiKey);
  const moreButtonRef = useRef<HTMLButtonElement>();
  const [selectedTile, setSelectedTile] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { data: serverUser } = useUser(apiKey);
  const { mutate: patchItem, isLoading: patchingItem } = usePatchItem(
    dashboardId,
    apiKey
  );
  const [dashboard, setDashboard] = useState<DashboardBlock>({ items: [] });
  const containerRef = useRef<HTMLDivElement>(null);
  const containerWidth = containerRef?.current?.offsetWidth || 1200;
  const [chatId, setChatId] = useState<string>(v4());
  const { mutate: addChat, isLoading: addingChat } = useAddChat(chatId, apiKey);
  const { data: connections } = useConnection(apiKey);
  const [showTables, setShowTables] = useState(false);
  const {
    data: chatDetails,
    isLoading: loadingDetails,
    refetch,
  } = useChatDetails(chatId, apiKey);
  const { mutate: patchChat, isLoading: patchingChat } = usePatchChat(
    chatId,
    apiKey
  );
  const { data: dashboards, isLoading: fetchingDashboards } =
    useDashboards(apiKey);
  const { mutate: updateDashboard, isLoading: updatingDashboard } =
    usePatchDashboardApiKey(apiKey);
  const { data: connection, refetch: refetchConnection } = useConnectionById(
    apiKey,
    activeConnection
  );
  const { mutateAsync: addChatGraph, isLoading: addingGraph } = useAddChatGraph(
    chatId,
    apiKey
  );
  const { mutateAsync: addChatSummary, isLoading: addingSummary } =
    useAddChatSummary(chatId, apiKey);
  const { mutateAsync: addFollowUp, isLoading: addingFollowUp } =
    useAddFollowUpQuestions(chatId, apiKey);
  const { mutate: patchDashboard, isLoading: patchingDashboard } =
    usePatchDashboard(dashboardId, apiKey);

  const [anchorElGraph, setAnchorElGraph] = useState<HTMLButtonElement | null>(
    null
  );
  const [currentGraphText, setCurrentGraphText] = useState("");

  const handleGraphClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    moreButtonRef.current && setAnchorElGraph(moreButtonRef.current);
  };

  const handleGraphClose = () => {
    setAnchorElGraph(null);
  };
  const openGraphPopover = Boolean(anchorElGraph);

  useEffect(() => {
    if (dashboardDetails?.data) {
      setDashboard(dashboardDetails.data);
    }
  }, [dashboardDetails]);

  const getAggregate = (item: Item) => {
    if (item.type === "graph") {
      const yCol = item.chatItem?.graph?.yCols?.[0]?.yCol;
      let total = 0.0;
      item?.chatItem?.rows?.forEach((row: any) => {
        if (!isNaN(row[yCol as keyof any])) {
          total += parseFloat(row[yCol as keyof any]);
        }
      });
      return dataFormater(total);
    }
    return "";
  };

  if (loadingDashboard) {
    return <div>Loading...</div>;
  }

  const updateCharts = () => {
    const request = {
      id: dashboardId,
      items: dashboard.items,
    };
    patchDashboard(request as any);
  };

  const handleLayoutChange = (layouts: Layout[], allLayouts: any) => {
    if (dashboard?.items) {
      const newDashboardItems = [...dashboard.items];
      layouts.forEach((layout) => {
        const index = newDashboardItems.findIndex(
          (item) =>
            item.chatItem.blockId === layout.i?.split("_")?.[0] &&
            item.type === layout.i?.split("_")?.[1]
        );
        const it = newDashboardItems[index];
        if (it) {
          it.x = layout.x;
          it.y = layout.y;
          it.width = layout.w;
          it.height = layout.h;
          newDashboardItems[index] = it;
        }
      });
      setDashboard({ ...dashboard, items: newDashboardItems });
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>, key: string) => {
    setAnchorEl(e.currentTarget);
    setSelectedTile(key);
    moreButtonRef.current = e.currentTarget;
  };

  const getSelectedTileProp = (selectedTile: string, k: string) => {
    const blockId = selectedTile?.split("_")?.[0];
    const type = selectedTile?.split("_")?.[1];
    return dashboard?.items?.find(
      (item) => item.chatItem.blockId === blockId && item.type === type
    )?.chatItem?.[k as keyof ChatItem];
  };

  const deleteChart = () => {
    const blockId = selectedTile?.split("_")?.[0];
    const type = selectedTile?.split("_")?.[1];

    const newDashboardItems = [...(dashboard.items || [])];
    const index = newDashboardItems.findIndex(
      (item) => item.chatItem.blockId === blockId && item.type === type
    );
    if (index >= 0) {
      newDashboardItems.splice(index, 1);
      setDashboard({ ...dashboard, items: newDashboardItems });
    }
    handleClose();
  };

  const updateItem = (key: string) => {
    const blockId = selectedTile?.split("_")?.[0];
    const type = selectedTile?.split("_")?.[1];
    const newDashboardItems = [...(dashboard.items || [])];
    const index = newDashboardItems.findIndex(
      (item) => item.chatItem.blockId === blockId && item.type === type
    );
    if (index >= 0) {
      const it = newDashboardItems[index];
      if (key === "graph") {
        it.chatItem.question = currentGraphText;
      }
      const request = {
        key: key,
        item: it,
      };
      patchItem(request as any, {
        onSuccess: (response: any) => {
          setCurrentGraphText("");
          newDashboardItems[index] = response?.data;
          setDashboard({ ...dashboard, items: newDashboardItems });
          if (key === "graph") {
            handleGraphClose();
          }
        },
      });
    }
  };

  const handleGraphEnterEvent = (e: any) => {
    if (e.keyCode === 13) {
      updateItem("graph");
    }
  };

  return (
    <div className={classes.root} ref={containerRef}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingDashboard || patchingDashboard}
      >
        <CircularProgress sx={{ color: "#4B0082" }} />
      </Backdrop>
      <div className={classes.top}>
        <TextField
          onClick={() => {
            setChatId(v4());
            setChatDialog(true);
          }}
          sx={{
            width: "calc(100% - 200px)",
            padding: "36px 12px 4px 36px",
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#4B0082",
                borderWidth: 1,
              },
            },
          }}
          placeholder="Add new metric, ask in plain english to get started..."
          InputProps={{
            autoFocus: true,
            style: {
              height: 38,
              borderRadius: "12px",
              border: "1px solid #4B0082",
              background: "#FFF",
              boxShadow: "0px 2px 2px 0px #4B0082",
            },
            endAdornment: (
              <InputAdornment position="end">
                {addingChat ? (
                  <CircularProgress size={20} sx={{ color: "#4B0082" }} />
                ) : (
                  <IconButton
                    className={classes.iconButton}
                    style={{ rotate: "-40deg", marginTop: "-5px" }}
                  >
                    <SendRounded />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        ></TextField>
        <Button
          className={classes.saveButton}
          size="small"
          onClick={updateCharts}
        >
          Save Dashboard
        </Button>
      </div>
      <Responsive
        rowHeight={60}
        onLayoutChange={handleLayoutChange}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        width={containerWidth}
        margin={[40, 40]}
        containerPadding={[40, 40]}
        className={classes.gridContainer}
        resizeHandles={["se"]}
      >
        {dashboard?.items?.map((item, index) => (
          <div
            key={item.chatItem.blockId + "_" + item?.type}
            data-grid={{
              x: item?.x,
              y: item?.y,
              w: item.width,
              h: item.height,
            }}
            className={classes.newTile}
          >
            <div className={classes.top}>
              <TextField
                value={item.chatItem?.title}
                size="small"
                sx={{
                  width: "60%",
                  "& .MuiOutlinedInput-root": {
                    input: {
                      padding: "4px 0px 4px 2px",
                    },
                    fieldset: {
                      borderWidth: 0,
                    },
                    "&:hover fieldset": {
                      borderColor: "#666",
                      borderWidth: 0.5,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#666",
                      borderWidth: 0.5,
                    },
                  },
                }}
                InputProps={{
                  sx: {
                    fontSize: 14,
                    fontWeight: 500,
                    color: "#666",
                  },
                }}
                placeholder="title"
              />
              <IconButton
                className={classes.iconButtonSmall}
                onClick={(e) =>
                  handleClick(e, item.chatItem.blockId + "_" + item.type)
                }
              >
                <MoreVertRounded />
              </IconButton>
            </div>
            <div className={classes.subTitle}>
              {item?.chatItem?.value
                ? dataFormater(item.chatItem?.rows?.[0][item.chatItem?.value])
                : getAggregate(item)}
            </div>
            {item?.type === "graph" ? (
              <Graphs
                rows={item.chatItem?.rows}
                options={item.chatItem?.graph}
              />
            ) : item?.type === "table" ? (
              <RowsView
                rows={item.chatItem?.rows}
                blockId={item.chatItem.blockId}
                showEmbed={false}
                showExport={false}
                gridHeight={item?.height ? item.height * 4.5 + "rem" : "40vh"}
              />
            ) : (
              <></>
            )}
          </div>
        ))}
      </Responsive>
      <Dialog
        open={openChatDialog}
        onClose={() => setChatDialog(false)}
        className={classes.chatDialog}
        classes={{ paper: classes.paper }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingDetails}
        >
          <CircularProgress sx={{ color: "#4B0082" }} />
        </Backdrop>
        <div className={classes.toolbar}>
          <IconButton
            style={{ position: "absolute", top: 12, right: 12 }}
            onClick={() => setChatDialog(false)}
          >
            <CloseRounded style={{ fontSize: "16px" }} />
          </IconButton>
        </div>
        <ChatInner
          email={serverUser?.data?.email}
          chatId={chatId}
          serverUser={serverUser}
          connections={connections}
          chatDetails={chatDetails}
          loadingDetails={loadingDetails}
          refetch={refetch}
          addChat={addChat}
          addingChat={addingChat}
          patchChat={patchChat}
          addChatGraph={addChatGraph}
          addChatSummary={addChatSummary}
          addFollowUp={addFollowUp}
          patchingChat={patchingChat}
          dashboards={dashboards}
          updateDashboard={updateDashboard}
          updatingDashboard={updatingDashboard}
          connection={connection}
          activeConnection={activeConnection}
          refetchConnection={refetchConnection}
          embed
          dashboardId={dashboardId}
        />
      </Dialog>
      <Popover
        id="more-options-popper"
        open={openPopover}
        anchorEl={anchorEl}
        className={classes.morePopover}
        classes={{ paper: classes.paper }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={classes.list}>
          {selectedTile?.includes("graph") && (
            <Button
              variant="text"
              size="small"
              onClick={(e) => {
                handleGraphClick(e);
                handleClose();
              }}
            >
              Edit Block
            </Button>
          )}
          <Button
            variant="text"
            size="small"
            onClick={() => {
              setShowTables(true);
              handleClose();
            }}
          >
            Underlying Data
          </Button>
          <Button variant="text" size="small" onClick={deleteChart}>
            Delete
          </Button>
        </div>
      </Popover>
      <Popover
        id="graph-edit-dashboard"
        open={openGraphPopover}
        anchorEl={anchorElGraph}
        className={classes.graphPopper}
        classes={{ paper: classes.paper }}
        onClose={handleGraphClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <TextField
          value={currentGraphText}
          onChange={(e) => setCurrentGraphText(e.target.value)}
          onKeyDown={handleGraphEnterEvent}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                border: "transparent",
              },
              fieldset: {
                border: "transparent",
              },
            },
          }}
          placeholder="convert it to line, area, bar..."
          InputProps={{
            style: {
              height: 36,
              width: 400,
            },
            endAdornment: (
              <InputAdornment position="end">
                {patchingItem ? (
                  <CircularProgress size={16} sx={{ color: "#4B0082" }} />
                ) : (
                  <IconButton
                    onClick={() => updateItem("graph")}
                    className={classes.iconButton}
                  >
                    <SendRounded />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        ></TextField>
      </Popover>
      <Dialog
        open={showTables}
        onClose={() => setShowTables(false)}
        className={classes.dialog}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle>
          <div className={classes.title}>Data</div>
          <IconButton
            style={{ position: "absolute", top: 12, right: 12 }}
            onClick={() => setShowTables(false)}
          >
            <CloseRounded style={{ fontSize: "16px" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <RowsView
            rows={getSelectedTileProp(selectedTile, "rows")}
            blockId={selectedTile}
            showEmbed={false}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
