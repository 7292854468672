import {
  CloseRounded,
  GridOnRounded,
  Inbox,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  Mail,
  TableRowsRounded,
  TableViewRounded,
  ViewCompactRounded,
} from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
  TextField,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Metadata, TableMetadata } from "../models/DbConnection";
import classes from "./Connections.module.scss";
import { useEffect, useState } from "react";
import {
  useConnectionById,
  useConnectionMetadata,
  useUpdateConnectionMetadata,
} from "../api/connections";
import useQueryParameter from "../utils/util";
import RowsView from "../utils/RowsView";

type MetaDataProps = {
  metadataDialog: boolean;
  id: string;
  setMetadataDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function MetadataView({
  metadataDialog,
  id,
  setMetadataDialog,
}: MetaDataProps) {
  const email = useQueryParameter("email");
  const { data: metadata, isLoading } = useConnectionMetadata(email, id);
  const { data: connection, isLoading: loadingConnection } = useConnectionById(
    email,
    id
  );
  const { mutate: updateConnectionMetadata, isLoading: updatingMetadata } =
    useUpdateConnectionMetadata(email, id);
  const [tablesMetadata, setTablesMetadata] = useState<any>({});
  const [activeTable, setActiveTable] = useState<TableMetadata>({
    name: "",
    description: "",
    columns: {},
    sampleRows: [],
  });
  const [showDataset, setShowDataset] = useState<boolean>(true);
  const [showMetadata, setShowMetadata] = useState<boolean>(false);

  useEffect(() => {
    if (metadata?.data) {
      setTablesMetadata(metadata.data);
    }
  }, [metadata]);

  useEffect(() => {
    if (activeTable.name === "" && tablesMetadata) {
      const key = Object.keys(tablesMetadata)[0];
      const tmp = tablesMetadata[key];
      tmp && setActiveTable(tmp);
    }
  }, [tablesMetadata, id]);

  const onChange = (key: string, value: any, colKey?: string) => {
    if (!colKey) {
      const newTablesMetadata = { ...tablesMetadata };
      newTablesMetadata[key].description = value;
      setTablesMetadata(newTablesMetadata);
      setActiveTable(newTablesMetadata[key]);
    } else {
      const newTablesMetadata = { ...tablesMetadata };
      newTablesMetadata[key].columns[colKey].description = value;
      setTablesMetadata(newTablesMetadata);
      setActiveTable(newTablesMetadata[key]);
    }
  };

  const onTabChange = (selectedTable: string) => {
    const newData = tablesMetadata?.[selectedTable];
    newData && setActiveTable(newData);
  };

  const updateMetadata = () => {
    updateConnectionMetadata(tablesMetadata as any, {
      onSuccess: () => {
        setMetadataDialog(false);
      },
    });
  };

  const onClose = () => {
    // setActiveTable({ name: "", description: "", columns: {} });
    setMetadataDialog(false);
  };

  return (
    <Dialog
      className={classes.metadataDialog}
      classes={{ paper: classes.paper }}
      open={metadataDialog}
      onClose={onClose}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading || updatingMetadata}
      >
        <CircularProgress sx={{ color: "#4B0082" }} />
      </Backdrop>
      <DialogTitle>
        <div className={classes.title}>
          {connection?.data?.displayType === "csv" ||
          connection?.data?.displayType === "gsheet"
            ? connection?.data?.displayName
            : activeTable?.name}
        </div>
        {/* <div className={classes.subTitle}>Generated metadata</div> */}
        <IconButton
          style={{ position: "absolute", top: 4, right: 0 }}
          onClick={() => onClose()}
        >
          <CloseRounded style={{ fontSize: "16px" }} />
        </IconButton>
      </DialogTitle>
      <Drawer
        PaperProps={{
          style: {
            position: "absolute",
            width: "180px",
          },
        }}
        variant="permanent"
        anchor="left"
        className={classes.drawer}
      >
        <Toolbar />
        <Divider />
        <List>
          {Object.values(tablesMetadata)?.map(
            (tableData: any, index: number) => (
              <ListItem
                key={index}
                disablePadding
                className={
                  activeTable?.name === tableData?.name
                    ? classes.listItemActive
                    : classes.listItem
                }
              >
                <ListItemButton onClick={() => onTabChange(tableData?.name)}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <ViewCompactRounded style={{ fontSize: "16px" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      connection?.data?.displayType === "csv" ||
                      connection?.data?.displayType === "gsheet"
                        ? connection?.data?.displayName
                        : tableData?.name
                    }
                    sx={{ overflow: "hidden", maxWidth: 170 }}
                  />
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
      </Drawer>
      <DialogContent className={classes.content}>
        {connection?.data?.isAIEnabled && (
          <div className={classes.block}>
            <div className={classes.label}>description</div>
            <TextField
              size="small"
              sx={{ width: "100%" }}
              value={activeTable?.description}
              onChange={(e) => onChange(activeTable?.name, e.target.value)}
            ></TextField>
          </div>
        )}
        <Divider className={classes.divider}>
          <Button
            className={classes.button}
            endIcon={
              showDataset ? (
                <KeyboardArrowDownRounded />
              ) : (
                <KeyboardArrowUpRounded />
              )
            }
            onClick={() => setShowDataset(!showDataset)}
          >
            Dataset
          </Button>
        </Divider>
        {showDataset &&
          activeTable?.sampleRows &&
          activeTable.sampleRows?.length > 0 && (
            <RowsView
              rows={activeTable?.sampleRows}
              blockId={activeTable.name}
              loading={isLoading}
              showFooter={false}
              showExport={false}
            />
          )}
        {connection?.data?.isAIEnabled && (
          <Divider className={classes.divider}>
            <Button
              className={classes.button}
              endIcon={
                showMetadata ? (
                  <KeyboardArrowDownRounded />
                ) : (
                  <KeyboardArrowUpRounded />
                )
              }
              onClick={() => setShowMetadata(!showMetadata)}
            >
              Generated Metadata
            </Button>
          </Divider>
        )}
        {showMetadata &&
          connection?.data?.isAIEnabled &&
          Object.values(activeTable?.columns)?.map(
            (col: any, index: number) => (
              <div className={classes.block} key={index}>
                <div className={classes.label}>{col.field}</div>
                <TextField
                  size="small"
                  sx={{ width: "100%" }}
                  value={col.description}
                  onChange={(e) =>
                    onChange(activeTable?.name, e.target.value, col.field)
                  }
                ></TextField>
              </div>
            )
          )}
      </DialogContent>
      {connection?.data?.isAIEnabled && (
        <div className={classes.footer}>
          <Button
            variant="contained"
            className={classes.button}
            size="small"
            onClick={updateMetadata}
          >
            Save
          </Button>
        </div>
      )}
    </Dialog>
  );
}
