import postgres from "../../icons/postgres.svg";
import mysql from "../../icons/mysql.svg";
import csv from "../../icons/csv.svg";
import sheets from "../../icons/sheets.svg";
import athena from "../../icons/athena.svg";
import clickhouse from "../../icons/clickhouse.svg";
import databricks from "../../icons/databricks.svg";
import mongo from "../../icons/mongo.svg";
import snowflake from "../../icons/snowflake.svg";
import redshift from "../../icons/redshift.svg";
import bigquery from "../../icons/bigquery.svg";
import js from "../../icons/js.svg";

type DatabaseTypes = {
  [key: string]: {
    name: string;
    icon: string;
    port: number;
    type: string;
    active: boolean;
    adminActive: boolean;
  };
};

export const BE_SERVER = "https://backend.saydata.tech";
export const STRIPE_PRICING_TABLE_ID="prctbl_1O7EYQSDpgIdxILn6NBs9jnY";
export const STRIPE_PUBLISHABLE_KEY="pk_live_51NZXAeSDpgIdxILnFUMT09HgBnRIOtMXwef82z1OEG6SLRdqNDzBJBxBydAA1E2oHDNMbYBadOl9ZvmtVIFkBUU100w9o0vyBX";
// export const BE_SERVER = "http://localhost:8000";
// export const STRIPE_PRICING_TABLE_ID = "prctbl_1NlrB7SDpgIdxILnsZgvdvHv";
// export const STRIPE_PUBLISHABLE_KEY =
//   "pk_test_51NZXAeSDpgIdxILn7fklNE0ANWRv4VaEDidua1CwuJv7ilnVrsAjz7FsYNFMF9Z2YagiaSmM9yafCwCf3mbRNHKA00X1zEq5aG";

export const SQL_KEYWORDS = [
  "select",
  "from",
  "where",
  "group by",
  "order by",
  "having",
  "join",
  "inner join",
  "left join",
  "right join",
  "full join",
  "union",
  "in",
  "between",
  "like",
  "distinct",
  "as",
];

export const REFRESH_RATES = [
  {
    label: "24 hours",
    value: 24,
  },
  {
    label: "12 hours",
    value: 12,
  },
  {
    label: "6 hours",
    value: 6,
  },
  {
    label: "1 hours",
    value: 1,
  },
  {
    label: "Realtime",
    value: 0,
  },
];

export const MONGO_ACTIONS = [
  "find",
  "count",
  "aggregate",
  "command",
  "distinct",
];

export const DATABASES: DatabaseTypes = {
  postgres: {
    name: "PostgreSQL",
    icon: postgres,
    type: "postgres",
    port: 5432,
    active: true,
    adminActive: true,
  },
  mysql: {
    name: "MySQL",
    icon: mysql,
    type: "mysql",
    port: 3306,
    active: true,
    adminActive: true,
  },
  csv: {
    name: "CSV",
    icon: csv,
    type: "csv",
    port: 3306,
    active: true,
    adminActive: true,
  },
  gsheet: {
    name: "Sheet",
    icon: sheets,
    type: "gsheet",
    port: 3306,
    active: true,
    adminActive: true,
  },
  mongodb: {
    name: "MongoDB",
    icon: mongo,
    type: "mongodb",
    port: 3306,
    active: false,
    adminActive: true,
  },
  js: {
    name: "Javascript",
    icon: js,
    type: "js",
    port: 3306,
    active: false,
    adminActive: true,
  },
  bigquery: {
    name: "BigQuery",
    icon: bigquery,
    type: "bigquery",
    port: 3306,
    active: false,
    adminActive: false,
  },
  athena: {
    name: "Athena",
    icon: athena,
    type: "athena",
    port: 3306,
    active: false,
    adminActive: false,
  },
  snowflake: {
    name: "Snowflake",
    icon: snowflake,
    type: "snowflake",
    port: 3306,
    active: false,
    adminActive: false,
  },
  redshift: {
    name: "Redshift",
    icon: redshift,
    type: "redshift",
    port: 3306,
    active: false,
    adminActive: false,
  },
  clickhouse: {
    name: "ClickHouse",
    icon: clickhouse,
    type: "clickhouse",
    port: 3306,
    active: false,
    adminActive: false,
  },
  databrick: {
    name: "Databricks",
    icon: databricks,
    type: "databrick",
    port: 3306,
    active: false,
    adminActive: false,
  },
};

export const DATABASES_PRO: DatabaseTypes = {
  postgres: {
    name: "PostgreSQL",
    icon: postgres,
    type: "postgres",
    port: 5432,
    active: true,
    adminActive: true,
  },
  mysql: {
    name: "MySQL",
    icon: mysql,
    type: "mysql",
    port: 3306,
    active: true,
    adminActive: true,
  },
  csv: {
    name: "CSV",
    icon: csv,
    type: "csv",
    port: 3306,
    active: true,
    adminActive: true,
  },
  gsheet: {
    name: "Sheet",
    icon: sheets,
    type: "gsheet",
    port: 3306,
    active: true,
    adminActive: true,
  },
  snowflake: {
    name: "Snowflake",
    icon: snowflake,
    type: "snowflake",
    port: 3306,
    active: true,
    adminActive: false,
  },
  mongodb: {
    name: "MongoDB",
    icon: mongo,
    type: "mongodb",
    port: 3306,
    active: false,
    adminActive: true,
  },
  js: {
    name: "Javascript",
    icon: js,
    type: "js",
    port: 3306,
    active: false,
    adminActive: true,
  },
  bigquery: {
    name: "BigQuery",
    icon: bigquery,
    type: "bigquery",
    port: 3306,
    active: false,
    adminActive: false,
  },
  athena: {
    name: "Athena",
    icon: athena,
    type: "athena",
    port: 3306,
    active: false,
    adminActive: false,
  },
  redshift: {
    name: "Redshift",
    icon: redshift,
    type: "redshift",
    port: 3306,
    active: false,
    adminActive: false,
  },
  clickhouse: {
    name: "ClickHouse",
    icon: clickhouse,
    type: "clickhouse",
    port: 3306,
    active: false,
    adminActive: false,
  },
  databrick: {
    name: "Databricks",
    icon: databricks,
    type: "databrick",
    port: 3306,
    active: false,
    adminActive: false,
  },
};

export const LOADING_TEXTS = [
  "Gathering data...",
  "Generating query...",
  "Validating results...",
  "Crunching numbers...",
  "Analyzing results...",
  "Tailoring response...",
];

export const TRAINING_LOADING_TEXTS = [
  "Training your model...",
  "Validating datasource...",
  "Cleaning data...",
  "Finetuning the model...",
  "Adding final checks...",
];

export const ENCRYPTION_KEY =
  "c93806d0b35eba427fd8da0dc428fb1c1c52c8af655b2c554b1e0100b73952dc";

export const OPERATORS = [
  {
    label: "Equals",
    value: "=",
    type: "string",
  },
  {
    label: "Not equals",
    value: "!=",
    type: "string",
  },
  {
    label: "Contains",
    value: "LIKE",
    type: "string",
  },
  {
    label: "Is any of",
    value: "IN",
    type: "string",
  },
  {
    label: "Starts with",
    value: "LIKE",
    type: "string",
  },
  {
    label: "Ends with",
    value: "LIKE",
    type: "string",
  },
  {
    label: "Equals",
    value: "=",
    type: "numeric",
  },
  {
    label: "Not equals",
    value: "!=",
    type: "numeric",
  },
  {
    label: "Greater than",
    value: ">",
    type: "numeric",
  },
  {
    label: "Greater than equals",
    value: ">=",
    type: "numeric",
  },
  {
    label: "Less than",
    value: "<",
    type: "numeric",
  },
  {
    label: "Less than equals",
    value: "<=",
    type: "numeric",
  },
];

export const COL_FORMATS = [
  {
    label: "String",
    value: "string",
  },
  {
    label: "Number",
    value: "number",
  },
  {
    label: "Currency",
    value: "currency",
  },
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Tag",
    value: "tag",
  },
];

export const DATE_FORMATS = [
  'MM/dd/yyyy',
  'yyyy-MM-dd',
  'dd/MM/yyyy',
  'MMMM d, yyyy',    // January 1, 2023
  'MMM d, yyyy',      // Jan 1, 2023
  'MM-dd-yyyy',       // 01-01-2023
  'yyyy/MM/dd',       // 2023/01/01
  'dd.MM.yyyy',       // 01.01.2023
  'yy-MM-dd',         // 23-01-01
  'EEEE, MMMM d, yyyy', // Sunday, January 1, 2023
  'dd MMMM yyyy',     // 01 January 2023
  'dd MMM yyyy HH:mm:ss', // 01 Jan 2023 12:00:00
];