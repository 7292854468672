import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQuery } from "react-query";
import { BE_SERVER } from "../utils/constant";
import { queryClient } from "../..";

export const useCanvaDetails = (canvaId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["canva", canvaId], async () => {
    const token = await getAccessTokenSilently();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const res = await fetch(
      `${BE_SERVER}/api/canva/${canvaId}/details?email=${email}`,
      {
        headers: headers,
      }
    );

    if (!res.ok) {
      throw new Error("Error while fetching canva details");
    }

    return res.json();
  });
};

export const useAllCanva = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["canvas", email], async () => {
    const token = await getAccessTokenSilently();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const res = await fetch(`${BE_SERVER}/api/canva?email=${email}`, {
      headers: headers,
    });
    if (!res.ok) {
      throw new Error("Error while fetching canva list");
    }

    return res.json();
  });
};

export const useAddCanva = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const res = await fetch(`${BE_SERVER}/api/canva?email=${email}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(request),
      });

      if (!res.ok) {
        throw new Error("Error while adding canva");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["canvas", email]);
        queryClient.invalidateQueries(["canvaHistory", email]);
      },
    }
  );
};

export const useAddCanvaChat = (canvaId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const res = await fetch(`${BE_SERVER}/api/canva/${canvaId}/chat?email=${email}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(request),
      });

      if (!res.ok) {
        throw new Error("Error while adding canva chat");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["canvaHistory", email]);
      },
    }
  );
};

export const usePatchCanvaChat = (canvaId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const res = await fetch(`${BE_SERVER}/api/canva/${canvaId}/chat?email=${email}`, {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(request),
      });

      if (!res.ok) {
        throw new Error("Error while adding canva chat");
      }

      return res.text();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["canva", canvaId]);
      },
    }
  );
};

export const usePatchCanva = (canvaId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const res = await fetch(
        `${BE_SERVER}/api/canva/${canvaId}?email=${email}`,
        {
          method: "PATCH",
          headers: headers,
          body: JSON.stringify(request),
        }
      );

      if (!res.ok) {
        throw new Error("Error while adding canva");
      }

      return res.text();
    }
  );
};

export const useAddChatGraph = (canvaId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const res = await fetch(
        `${BE_SERVER}/api/canva/${canvaId}/chat/graph?email=${email}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(request),
        }
      );

      if (!res.ok) {
        throw new Error("Error while adding chat graph");
      }

      return res.json();
    }
  );
};

export const useAddChatSummary = (canvaId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const res = await fetch(
        `${BE_SERVER}/api/canva/${canvaId}/chat/summary?email=${email}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(request),
        }
      );

      if (!res.ok) {
        throw new Error("Error while adding chat summary");
      }

      return res.json();
    }
  );
};

export const useAddFollowUpQuestions = (canvaId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const res = await fetch(
        `${BE_SERVER}/api/canva/${canvaId}/chat/followup?email=${email}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(request),
        }
      );

      if (!res.ok) {
        throw new Error("Error while creating followup questions");
      }

      return res.json();
    }
  );
};

export const useDeleteCanva = (canvaId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation(
    async () => {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const res = await fetch(
        `${BE_SERVER}/api/canva/${canvaId}?email=${email}`,
        {
          method: "DELETE",
          headers: headers,
        }
      );
      if (!res.ok) {
        throw new Error("Error while deleting canva");
      }

      return res.text();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["canvas", email]);
      },
    }
  );
};

export const useCanvaChatHistory = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["cavaHistory", email], async () => {
    const token = await getAccessTokenSilently();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const res = await fetch(`${BE_SERVER}/api/canva/history?email=${email}`, {
      headers: headers,
    });

    if (!res.ok) {
      throw new Error("Error while fetching canva chat history");
    }

    return res.json();
  });
};
