import {
  Autocomplete,
  Button,
  Checkbox,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { WorkspaceItem } from "../../models/Dashboard";
import classes from "./ItemEditor.module.scss";
import { useEffect, useState } from "react";
import {
  AlignHorizontalCenterRounded,
  AlignHorizontalLeftRounded,
  AlignHorizontalRightRounded,
  ArrowBackRounded,
  KeyboardArrowDownRounded,
  RemoveRedEyeRounded,
  VisibilityOffRounded,
  ClearRounded
} from "@mui/icons-material";
import clsx from "clsx";
import { COL_FORMATS } from "../../utils/constant";
import { DATABASES } from "../../utils/constant";
import { Query } from "../../models/Query";

type Props = {
  queries: Query[];
  selectedItem: WorkspaceItem;
  updateItem: (id: string, key: string, value: any) => void;
  getDataFromQuery: (queryId: string | undefined) => any;
};

export default function SelectEditor({
  queries,
  selectedItem,
  updateItem,
  getDataFromQuery,
}: Props) {
  const rows = getDataFromQuery(selectedItem.dataQueryId);
  const cols = rows?.length > 0 ? Object.keys(rows?.[0]) : [];
  const [selectedColIndex, setSelectedColIndex] = useState(0);
  const [colDetails, setColDetail] = useState(false);

  useEffect(() => {
    if (selectedItem.dataQueryId) {
      if (!selectedItem?.metadata?.columns) {
        const colMetadata: any[] = [];
        cols.forEach((col) => {
          colMetadata.push({
            key: col,
            label: col,
            hidden: false,
            backgroundColor: "#fff",
            align: "center",
            format: "string",
          });
        });
        updateItem(selectedItem.id, "metadata.columns", colMetadata);
      } else {
        const colMetadata: any[] = [];
        cols.forEach((col) => {
          const colIndex = selectedItem?.metadata?.columns?.findIndex(
            (c: any) => c.key === col
          );
          if (colIndex !== -1) {
            colMetadata.push(selectedItem?.metadata?.columns?.[colIndex]);
          } else {
            colMetadata.push({
              key: col,
              label: col,
              hidden: false,
              backgroundColor: "#fff",
              align: "center",
              format: "string",
            });
          }
        });
        updateItem(selectedItem.id, "metadata.columns", colMetadata);
      }
    }
  }, [selectedItem.dataQueryId]);

  const showColDetail = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    event.stopPropagation();
    setSelectedColIndex(index);
    setColDetail(true);
  };

  const addTriggerQuery = (query: Query) => {
    const existingQueries = selectedItem?.metadata?.triggerQueries || [];
    const index = existingQueries.findIndex((q: Query) => q.id === query.id);
    if (index !== -1) {
      return;
    }
    existingQueries.push(query);
    updateItem(selectedItem.id, "metadata.triggerQueries", existingQueries);
  };

  const removeTriggerQuery = (query: Query) => {
    const existingQueries = selectedItem?.metadata?.triggerQueries || [];
    const index = existingQueries.findIndex((q: Query) => q.id === query.id);
    if (index !== -1) {
      existingQueries.splice(index, 1);
      updateItem(selectedItem.id, "metadata.triggerQueries", existingQueries);
    }
  };

  return (
    <div className={classes.panel}>
      <div
      className={classes.title}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div className={classes.text}>Option Label</div>
          <div className={classes.colContainer}>
            {selectedItem?.metadata?.columns?.map((col: any, index: number) => (
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}
                >
                  <Checkbox
                    checked={selectedItem?.metadata?.selectedColumnLabel === index}
                    size="small"
                    sx={{
                      marginLeft: "auto",
                    }}
                    onChange={(e) =>
                      updateItem(
                        selectedItem.id,
                        "metadata.selectedColumnLabel",
                        index
                      )
                    }
                />
                <p key={col?.key} className={classes.colLabel}>{col?.label}</p>
                </div>
              ))}
          </div>
      </div>
      <div
        className={classes.title}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div className={classes.text}>Columns</div>
        <div className={classes.colContainer}>
          {colDetails ? (
            <div className={classes.colDetail}>
              <Button
                startIcon={<ArrowBackRounded />}
                className={classes.colBtn}
                onClick={() => setColDetail(false)}
              >
                {selectedItem?.metadata?.columns?.[selectedColIndex]?.label}
              </Button>
              <div className={classes.content}>
                <div className={classes.text}>Source</div>
                <Autocomplete
                  options={cols}
                  className={classes.child}
                  sx={{ width: "80%", marginLeft: "auto" }}
                  value={
                    cols.find(
                      (op) =>
                        op ===
                        selectedItem?.metadata?.columns?.[selectedColIndex]?.key
                    ) || null
                  }
                  onChange={(e, value) => {
                    if (value) {
                      updateItem(
                        selectedItem.id,
                        "metadata.columns." + selectedColIndex + ".key",
                        value
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <KeyboardArrowDownRounded sx={{ fontSize: 18 }} />
                        ),
                        style: {
                          height: 28,
                          fontSize: 12,
                          fontWeight: 600,
                          padding: "2px 8px 4px 8px",
                        },
                      }}
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        fontWeight: selected ? 600 : 400,
                        fontSize: 12,
                        padding: "4px 16px",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      {option}
                    </li>
                  )}
                />
              </div>
              <div className={classes.content}>
                <div className={classes.text}>Label</div>
                <TextField
                  size="small"
                  sx={{ width: "80%", marginLeft: "auto" }}
                  value={
                    selectedItem?.metadata?.columns?.[selectedColIndex]?.label
                  }
                  onChange={(e) =>
                    updateItem(
                      selectedItem.id,
                      "metadata.columns." + selectedColIndex + ".label",
                      e.target.value
                    )
                  }
                  InputProps={{
                    style: {
                      height: 24,
                      fontSize: 12,
                      color: "#000",
                    },
                  }}
                />
              </div>
              <div className={classes.content}>
                <div className={classes.text}>Prefix</div>
                <TextField
                  size="small"
                  sx={{ width: "80%", marginLeft: "auto" }}
                  value={
                    selectedItem?.metadata?.columns?.[selectedColIndex]
                      ?.prefix || ""
                  }
                  placeholder="$,€,¥,₹,..."
                  onChange={(e) =>
                    updateItem(
                      selectedItem.id,
                      "metadata.columns." + selectedColIndex + ".prefix",
                      e.target.value
                    )
                  }
                  InputProps={{
                    style: {
                      height: 24,
                      fontSize: 12,
                      color: "#000",
                    },
                  }}
                />
              </div>
              <div className={classes.content}>
                <div className={classes.text}>Suffix</div>
                <TextField
                  size="small"
                  sx={{ width: "80%", marginLeft: "auto" }}
                  value={
                    selectedItem?.metadata?.columns?.[selectedColIndex]
                      ?.suffix || ""
                  }
                  placeholder="%,v/v,w/w,..."
                  onChange={(e) =>
                    updateItem(
                      selectedItem.id,
                      "metadata.columns." + selectedColIndex + ".suffix",
                      e.target.value
                    )
                  }
                  InputProps={{
                    style: {
                      height: 24,
                      fontSize: 12,
                      color: "#000",
                    },
                  }}
                />
              </div>
              <div className={classes.content}>
                <div className={classes.text}>Format</div>
                <Autocomplete
                  options={COL_FORMATS}
                  className={classes.child}
                  sx={{ width: "80%", marginLeft: "auto" }}
                  value={
                    COL_FORMATS.find(
                      (op) =>
                        op.value ===
                        selectedItem?.metadata?.columns?.[selectedColIndex]
                          ?.format
                    ) || null
                  }
                  onChange={(e, value) => {
                    if (value) {
                      updateItem(
                        selectedItem.id,
                        "metadata.columns." + selectedColIndex + ".format",
                        value.value
                      );
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <KeyboardArrowDownRounded sx={{ fontSize: 18 }} />
                        ),
                        style: {
                          height: 28,
                          fontSize: 12,
                          fontWeight: 600,
                          padding: "2px 8px 4px 8px",
                        },
                      }}
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        fontWeight: selected ? 600 : 400,
                        fontSize: 12,
                        padding: "4px 16px",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      {option.label}
                    </li>
                  )}
                />
              </div>
              <div className={classes.content}>
                <div className={classes.text}>Align</div>
                <ToggleButtonGroup
                  value={
                    selectedItem?.metadata?.columns?.[selectedColIndex]?.align
                  }
                  sx={{
                    width: "80%",
                    marginLeft: "auto",
                    height: 24,
                  }}
                  exclusive
                  onChange={(e, value) =>
                    updateItem(
                      selectedItem.id,
                      "metadata.columns." + selectedColIndex + ".align",
                      value
                    )
                  }
                >
                  <ToggleButton value="left">
                    <AlignHorizontalLeftRounded style={{ fontSize: 14 }} />
                  </ToggleButton>
                  <ToggleButton value="center">
                    <AlignHorizontalCenterRounded style={{ fontSize: 14 }} />
                  </ToggleButton>
                  <ToggleButton value="right">
                    <AlignHorizontalRightRounded style={{ fontSize: 14 }} />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div className={classes.content}>
                <div className={classes.text}>Show thousands separator</div>
                <Checkbox
                  checked={
                    selectedItem?.metadata?.columns?.[selectedColIndex]
                      ?.thousandSeparator
                  }
                  size="small"
                  sx={{
                    marginLeft: "auto",
                  }}
                  onChange={(e) =>
                    updateItem(
                      selectedItem.id,
                      "metadata.columns." +
                        selectedColIndex +
                        ".thousandSeparator",
                      e.target.checked
                    )
                  }
                />
              </div>
            </div>
          ) : (
            selectedItem?.metadata?.columns?.map((col: any, index: number) => (
              <div
                style={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={selectedItem?.metadata?.selectedColumn === index}
                  size="small"
                  sx={{
                    marginLeft: "auto",
                  }}
                  onChange={(e) =>
                    updateItem(
                      selectedItem.id,
                      "metadata.selectedColumn",
                      index
                    )
                  }
                />
                <Button
                  size="small"
                  key={col?.key}
                  className={
                    col?.hidden
                      ? clsx(classes.colBtn, classes.hidden)
                      : classes.colBtn
                  }
                  onClick={(e) => {
                    showColDetail(e, index);
                  }}
                  endIcon={
                    <IconButton
                      className={classes.iconBtn}
                      onClick={(e) => {
                        e.stopPropagation();
                        col.hidden = !col.hidden;
                        updateItem(
                          selectedItem.id,
                          "metadata.columns",
                          selectedItem?.metadata?.columns
                        );
                      }}
                    >
                      {col?.hidden ? (
                        <VisibilityOffRounded />
                      ) : (
                        <RemoveRedEyeRounded />
                      )}
                    </IconButton>
                  }
                >
                  {col?.label}
                </Button>
              </div>
            ))
          )}
        </div>
      </div>
      <div
        className={classes.title}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div className={classes.title} style={{
          width: "100%"
        }}>
          <div className={classes.text}>Trigger queries on change</div>
          <Autocomplete
            sx={{
              width: "60%",
              marginLeft: "auto",
            }}
            options={queries}
            className={classes.autocomplete}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option: Query) => option.displayName}
            onChange={(e, value) => value && addTriggerQuery(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <KeyboardArrowDownRounded sx={{ fontSize: 18 }} />
                  ),
                  style: {
                    height: 24,
                    fontSize: 12,
                    color: "#000",
                    padding: "0px 8px 0px 4px",
                  },
                }}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li
                {...props}
                style={{
                  fontWeight: selected ? 600 : 400,
                  fontSize: 12,
                  padding: "4px 16px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <img
                  src={DATABASES[option.type].icon}
                  alt={option.type}
                  width={16}
                  height={16}
                />
                {option.displayName}
              </li>
            )}
          />
        </div>

        <div className={classes.colContainer}>
          {selectedItem?.metadata?.triggerQueries?.map((query: Query, index: number) => (
            <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
              <Button
                size="small"
                key={query?.id}
                className={classes.colBtn}
                endIcon={
                  <IconButton
                    className={classes.iconBtn}
                    onClick={(e) => {
                      removeTriggerQuery(query);
                    }}
                  >
                    <ClearRounded/>
                  </IconButton>
                }
              >
                {query?.displayName}
              </Button>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.title}>
        <div className={classes.text}>Placeholder</div>
        <TextField
          size="small"
          type="text"
          sx={{ width: "80%", marginLeft: "auto" }}
          value={selectedItem?.metadata?.placeholder || ""}
          onChange={(e) =>
            updateItem(selectedItem.id, "metadata.placeholder", e.target.value)
          }
          InputProps={{
            style: {
              height: 24,
              fontSize: 12,
              color: "#000",
            },
          }}
        />
      </div>
      <div className={classes.title}>
        <div className={classes.text}>Font size</div>
        <TextField
          size="small"
          type="number"
          sx={{ width: "80%", marginLeft: "auto" }}
          value={selectedItem?.metadata?.fontSize || 12}
          onChange={(e) =>
            updateItem(selectedItem.id, "metadata.fontSize", e.target.value)
          }
          InputProps={{
            style: {
              height: 24,
              fontSize: 12,
              color: "#000",
            },
          }}
        />
      </div>
    </div>
  );
}
