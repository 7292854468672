import { Handle, useStore, Position } from "reactflow";
import classes from "./ChatCanva.module.scss";
import RowsView from "../utils/RowsView";
import logo from "../../icons/new_logo.png";
import CanvaRowView from "./CanvaRowView";
import { memo, useEffect, useRef, useState } from "react";
import { Editor } from "@monaco-editor/react";
import { format } from "sql-formatter";
import Lottie from "lottie-react";
import loadingJson from "../../icons/loading.json";
import {
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
} from "@mui/material";
import {
  AutoAwesomeRounded,
  AddCircleOutlineRounded,
  PlayArrowRounded,
  ThumbUpAltOutlined,
  ThumbUpAltRounded,
  ThumbDownAltRounded,
  ThumbDownAltOutlined,
  SendRounded,
} from "@mui/icons-material";
import Graphs from "../charts/Graphs";
import TileView from "../utils/TileView";
import { StyledSwitch, removeMarkdownCodeFromString } from "../utils/util";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import clsx from "clsx";

const Placeholder = () => (
  <div className={classes.placeholder}>
    <div />
    <div />
    <div />
  </div>
);

const zoomSelector = (s: any) => {
  return s.transform[2] >= 0.9;
};

type Props = {
  id: string;
  data: any;
};
function DataNode({ id, data }: Props) {
  const focusedDivRef = useRef<HTMLDivElement | null>(null);
  const [currentFeedbackText, setCurrentFeedbackText] = useState<string>("");
  const [anchorElFeedback, setFeedbackAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const showContent = useStore(zoomSelector);
  const feedbackPopover = Boolean(anchorElFeedback);
  const handleFeedbackClick = (
    event: React.MouseEvent<HTMLButtonElement>
    // clickedChat: any
  ) => {
    // setSelectedChat(clickedChat);
    setFeedbackAnchorEl(event.currentTarget);
  };
  const handleFeedbackClose = () => {
    setFeedbackAnchorEl(null);
  };

  const onSqlChange = (sql: string) => {
    try {
      const formattedSql = format(sql, {
        language: "sql",
      });
      return formattedSql;
    } catch (e) {
      return sql;
    }
  };
  const onPythonChange = (python: string) => {
    try {
      const formattedPython = format(python, {
        language: "python",
      });
      return formattedPython;
    } catch (e) {
      return python;
    }
  };

  useEffect(() => {
    focusedDivRef?.current?.click();
  }, []);

  return (
    <div className={clsx(classes.dataNode, "nowheel")} ref={focusedDivRef}>
      <Handle type="target" id="a" position={Position.Top} />
      <div className={classes.container}>
        <div className={classes.message}>{data.value.question}</div>
        {/* <ReactMarkdown
          className={classes.markdown}
          children={removeMarkdownCodeFromString(
            data?.value?.message?.content?.[0]?.text?.value
          )}
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
        /> */}
        {(data?.value?.sql !== "" || data?.value?.python !== "") && (
          <div className={classes.sqlEditor}>
            <div className={classes.sqlBar}>
              <FormControlLabel
                control={
                  <StyledSwitch
                    shade="#4B0082"
                    size="small"
                    checked={data?.showSql}
                    onChange={(e) => data.updateSqlShow(id, e.target.checked)}
                  />
                }
                label={data?.value?.sql !== "" ? "Show query" : "Show code"}
                labelPlacement="start"
                style={{ padding: "8px 0px 8px 0px" }}
              />
              <div className={classes.feedback}>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => data.onFeedbackSave(id, "Approved")}
                >
                  {data?.value?.feedback === "Approved" ? (
                    <ThumbUpAltRounded
                      style={{ fontSize: 14, color: "green" }}
                    />
                  ) : (
                    <ThumbUpAltOutlined style={{ fontSize: 14 }} />
                  )}
                </IconButton>
                <IconButton
                  className={classes.iconButton}
                  onClick={(e) => handleFeedbackClick(e)}
                >
                  {data?.value?.feedback &&
                  data?.value?.feedback !== "Approved" ? (
                    <ThumbDownAltRounded
                      style={{
                        fontSize: 14,
                        color: "rgb(218,55,55)",
                      }}
                    />
                  ) : (
                    <ThumbDownAltOutlined style={{ fontSize: 14 }} />
                  )}
                </IconButton>
              </div>
            </div>
            {data?.showSql && data?.value?.type === "sql" && (
              <Editor
                // value={format(chat?.markdownString, {
                //   language: "sql",
                // })}
                value={onSqlChange(data?.value?.sql)}
                // onChange={(value, _) =>
                //   value && onBlockUpdate(chat?.blockId, "markdownString", value)
                // }
                className={classes.sqlEditor}
                language="sql"
                theme="vs-dark"
                height="15vh"
                options={{
                  fontSize: 8,
                  scrollbar: {
                    horizontal: "hidden",
                    verticalScrollbarSize: 8,
                  },
                  minimap: { enabled: false },
                }}
              />
            )}
            {data?.showSql && data?.value?.type === "python" && (
              <Editor
                // value={format(chat?.markdownString, {
                //   language: "sql",
                // })}
                value={onPythonChange(data?.value?.python)}
                // onChange={(value, _) =>
                //   value && onBlockUpdate(chat?.blockId, "markdownString", value)
                // }
                className={classes.sqlEditor}
                language="python"
                theme="vs-dark"
                height="15vh"
                options={{
                  fontSize: 8,
                  scrollbar: {
                    horizontal: "hidden",
                    verticalScrollbarSize: 8,
                  },
                  minimap: { enabled: false },
                }}
              />
            )}
          </div>
        )}
        {data?.value?.rows?.length > 0 && (
          <CanvaRowView
            key={id}
            rows={data?.value?.rows || []}
            blockId={data?.value?.messageId}
            fontSize={8}
            data={data}
            gridHeight="20vh"
          />
        )}
        {data?.loadingGraph && (
          <div className={classes.loading}>
            Looking for relevant graph...
            <div
              style={{
                width: 25,
                height: 25,
              }}
            >
              <Lottie animationData={loadingJson} loop={true} />
            </div>
          </div>
        )}
        {data?.value?.graph?.chartType && data?.value?.rows?.length >= 2 && (
          <div className={classes.graph}>
            <div
              style={{
                display: "flex",
                gap: "8px",
                marginLeft: "auto",
                marginTop: "8px",
              }}
            >
              <Button
                style={{ marginLeft: "auto" }}
                className={classes.textButton}
                size="small"
                onClick={(e) => data?.handleGraphEditClick(e, data?.value)}
                startIcon={<AutoAwesomeRounded />}
              >
                Edit
              </Button>
              <Button
                style={{ marginLeft: "auto" }}
                className={classes.textButton}
                onClick={(e) => data?.handleClick(e, data?.value, "graph")}
                size="small"
                startIcon={<AddCircleOutlineRounded />}
              >
                Add to Dashboard
              </Button>
              {/* {!!!embed && (
                <Button
                  style={{ marginLeft: "auto" }}
                  className={classes.textButton}
                  onClick={() =>
                    serverUser?.data?.plan === "pro"
                      ? openEmbedDialog(chat, "graph")
                      : setUpgradeBanner(true)
                  }
                  size="small"
                  startIcon={
                    <img
                      src={embedIcon}
                      alt="Embed"
                      height={14}
                      style={{ paddingLeft: "5px" }}
                    />
                  }
                >
                  Embed
                </Button>
              )} */}
            </div>
            <div className={classes.graphContainer}>
              <Graphs rows={data?.value?.rows} options={data?.value?.graph} />
            </div>
          </div>
        )}
        {data?.value?.rows?.length === 1 && data?.value?.value && (
          <div className={classes.tile}>
            <div
              style={{
                display: "flex",
                gap: "8px",
                marginLeft: "auto",
                marginTop: "8px",
                marginBottom: "8px",
              }}
            >
              {
                <Button
                  style={{ marginLeft: "auto" }}
                  className={classes.textButton}
                  onClick={(e) => data?.handleClick(e, data?.value, "tile")}
                  size="small"
                  startIcon={<AddCircleOutlineRounded />}
                >
                  Add to Dashboard
                </Button>
              }
              {/* {!!!embed && (
                <Button
                  style={{ marginLeft: "auto" }}
                  className={classes.textButton}
                  onClick={() =>
                    serverUser?.data?.plan === "pro"
                      ? openEmbedDialog(chat, "tile")
                      : setUpgradeBanner(true)
                  }
                  size="small"
                  startIcon={
                    <img
                      src={embedIcon}
                      alt="Embed"
                      height={14}
                      style={{ paddingLeft: "5px" }}
                    />
                  }
                >
                  Embed
                </Button>
              )} */}
            </div>
            <TileView
              title={data?.value?.title}
              value={data?.value?.rows?.[0][data?.value?.value]}
              fontSize={12}
              titleFontSize={8}
            />
          </div>
        )}
        {data?.loadingSummary && (
          <div className={classes.loading}>
            Generating summary and insights...
            <div
              style={{
                width: 25,
                height: 25,
              }}
            >
              <Lottie animationData={loadingJson} loop={true} />
            </div>
          </div>
        )}
        {data?.value?.summary && (
          <ReactMarkdown
            className={classes.markdown}
            children={data?.value?.summary}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          />
        )}
        {data?.loadingFollowUp && (
          <div className={classes.loading}>
            Generating follow-up question...
            <div
              style={{
                width: 25,
                height: 25,
              }}
            >
              <Lottie animationData={loadingJson} loop={true} />
            </div>
          </div>
        )}
        {data?.value?.followUpQuestions && (
          <div className={classes.suggestions}>
            {data?.value?.followUpQuestions?.map((q: string, i: number) => (
              <div
                className={classes.block}
                key={i}
                onClick={(e) => {
                  data.updateNodeText(id, q);
                  data.onSubmit(id, q);
                }}
              >
                <AutoAwesomeRounded />
                <div className={classes.title}>{q}</div>
              </div>
            ))}
          </div>
        )}
        {data?.showTextField && (
          <TextField
            value={data?.currentText}
            onChange={(e) => data.updateNodeText(id, e.target.value)}
            onKeyDown={(e) => data.handleEnterEvent(e, id)}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderWidth: 1,
                  borderColor: "#4B0082",
                },
                fieldset: {
                  borderWidth: 1,
                  borderColor: "rgba(239, 217, 255, 0.6)",
                },
              },
            }}
            placeholder="Ask a question"
            // ref={inputRef}
            InputProps={{
              //   disabled: addingChat,
              autoFocus: true,
              style: {
                borderRadius: 8,
                fontSize: 8,
                fontWeight: 500,
                background: "#fff",
                cursor: "auto",
                paddingRight: "6px",
                height: 24,
              },
              onFocus: () => data.focusNode(id),
              startAdornment: (
                <InputAdornment position="start">
                  <img src={logo} alt="logo" width={12} height={12} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e) => data.onSubmit(id)}
                    className={classes.iconButton}
                  >
                    <SendRounded />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
        )}
      </div>
      <Popover
        id="feedback-share"
        open={feedbackPopover}
        anchorEl={anchorElFeedback}
        className={`${classes.feedbackPopper} ${
          !showContent ? classes.popoverZoomedOut : ""
        }`}
        classes={{ paper: classes.paper }}
        onClose={handleFeedbackClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {showContent ? (
          <>
            <TextField
              value={currentFeedbackText}
              onChange={(e) => setCurrentFeedbackText(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    border: "transparent",
                  },
                  fieldset: {
                    border: "transparent",
                  },
                },
              }}
              multiline
              rows={4}
              maxRows={4}
              placeholder="Improve accuracy by explaining what went wrong..."
              InputProps={{
                style: {
                  width: 500,
                },
              }}
            ></TextField>
            <div className={classes.footer}>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  data.onFeedbackSave(id, currentFeedbackText);
                  handleFeedbackClose();
                }}
              >
                Submit
              </Button>
            </div>
          </>
        ) : (
          <Placeholder />
        )}
      </Popover>
      <Handle type="source" id="b" position={Position.Bottom} />
    </div>
  );
}

export default memo(DataNode);
