import { Popover } from "@mui/material";
import { SketchPicker } from "react-color";
import classes from "./ColorPicker.module.scss";

type Props = {
  selectedColor: string;
  openPopover: boolean;
  anchorEl: HTMLDivElement | null;
  handleClose: () => void;
  updateColor: (value: any) => void;
};

export default function ColorPicker({
  selectedColor,
  openPopover,
  anchorEl,
  handleClose,
  updateColor,
}: Props) {
  return (
    <Popover
      id="color-picker"
      open={openPopover}
      anchorEl={anchorEl}
      className={classes.popover}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <SketchPicker
        color={selectedColor}
        onChangeComplete={(color) =>
          updateColor(color.hex)
        }
      />
    </Popover>
  );
}
