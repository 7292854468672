import { CloseRounded } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
  Tooltip,
} from "@mui/material";
import { DATABASES, DATABASES_PRO } from "../utils/constant";
import classes from "./Connections.module.scss";

type DbTypesProps = {
  dbChoicesDialog: boolean;
  setDbChoicesDialog: React.Dispatch<React.SetStateAction<boolean>>;
  onDbSelect: (type: string) => void;
  setUpgradeBanner?: (value: boolean) => void;
  tab: string;
  user?: any;
};

export default function DbTypes({
  dbChoicesDialog,
  setDbChoicesDialog,
  onDbSelect,
  setUpgradeBanner = () => {},
  tab,
  user,
}: DbTypesProps) {
  const databasesList = user?.data?.plan === "pro" ? DATABASES_PRO : DATABASES;
  const activeKey = tab === "explore" ? "active" : "adminActive"; 
  return (
    <Dialog
      className={classes.dialog}
      open={dbChoicesDialog}
      classes={{ paper: classes.paper }}
      onClose={() => setDbChoicesDialog(false)}
    >
      <DialogTitle>
        <div className={classes.title}>Add connection</div>
        <div className={classes.subTitle}>
          Securely connect to a data source
        </div>
        <IconButton
          style={{ position: "absolute", top: 4, right: 4 }}
          onClick={() => setDbChoicesDialog(false)}
        >
          <CloseRounded style={{ fontSize: "16px" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {Object.keys(databasesList).map((db, index) =>
          databasesList[db][activeKey] ? (
            <Button
              className={classes.dbTile}
              key={index}
              onClick={() => onDbSelect(db)}
              startIcon={
                <img src={databasesList[db].icon} alt="icon" height={16}></img>
              }
            >
              <div>{databasesList[db].name}</div>
            </Button>
          ) : (
            <Tooltip title="Upgrade plan to enable integration" placement="top">
              <Button
                className={classes.disabledTile}
                key={index}
                onClick={() => setUpgradeBanner(true)}
                startIcon={
                  <img src={databasesList[db].icon} alt="icon" height={16}></img>
                }
              >
                <div>{databasesList[db].name}</div>
              </Button>
            </Tooltip>
          )
        )}
      </DialogContent>
    </Dialog>
  );
}
