import { memo, useEffect, useState } from "react";
import classes from "./ChatCanva.module.scss";
import { LinearProgress } from "@mui/material";
import { Handle, Position } from "reactflow";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';

type Props = {
  id: string;
  data: any;
};

const loadingObjects = [
  {
    text: "Searching for results...",
    icon: SearchRoundedIcon
  },
  {
    text: "Analyzing data...",
    icon: QueryStatsRoundedIcon,
  },
  {
    text: "Validating results...",
    icon: AutorenewRoundedIcon
  },
  {
    text: "Integrating results...",
    icon: SettingsSuggestRoundedIcon
  },
]

function LoadingNode({ id, data }: Props) {
  const [loadingObj, setLoadingObj] = useState<any>(loadingObjects[0]);
  const changeLoadingText = () => {
    const index = Math.floor(Math.random() * loadingObjects.length);
    setLoadingObj(loadingObjects[index]);
  };

  useEffect(() => {
    const loadInterval = setInterval(() => {
      changeLoadingText();
    }, 4000);

    return () => clearInterval(loadInterval);
  }, []);

  return (
    <div className={classes.loadingNode}>
      <Handle type="target" id={`t-${id}`} position={Position.Top} />
      <div className={classes.container}>
        <div className={classes.message}>{data?.currentText}</div>
        <div className={classes.animBox}>
          <div className={classes.icon}>
            <loadingObj.icon />
          </div>
          <div className={classes.loadingMessage}>{loadingObj.text}</div>
        </div>
        <div className={classes.loading}>
          <LinearProgress
            classes={{
              colorPrimary: classes.linearPrimary,
              barColorPrimary: classes.linearBarPrimary,
            }}
            sx={{ borderRadius: 4 }}
          />
        </div>
      </div>
    </div>
  );
}

export default memo(LoadingNode);
