import { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format, parseISO } from "date-fns";
import { useQueryVariable } from "../utils/useQueryVariable";
import styles from "./CustomDate.module.scss";

type DateProps = {
  item: any;
  onItemGridClick: (id: string) => void;
};

const CustomDate = ({ item, onItemGridClick }: DateProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const { updateVs } = useQueryVariable();
  const today = parseISO(new Date().toDateString());
  const formatDate = (inputDate:any, userFormat:string) => {
    const date = new Date(inputDate);
    const formattedDate = format(date, userFormat);
    return formattedDate;
  }
  return (
    <div className={styles.root}>
      <p className={styles.label} onClick={()=>onItemGridClick(item.id)}>{item.metadata.label}</p>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          sx={{ width: "auto", marginTop: "4px" }}
          maxDate={today}
          value={selectedDate}
          defaultValue={
            item.metadata.defaultValue
              ? parseISO(item.metadata.defaultValue)
              : null
          }
          slotProps={{
            textField: {
              size: "small",
            },
            inputAdornment: {
              position: "start",
            },
          }}
          onChange={(newValue) => {
            setSelectedDate(newValue);
            updateVs(item?.title, formatDate(newValue?.toString(), item?.metadata?.dateFormat ?? "yyyy-MM-dd"));
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default CustomDate;
