import classes from "./Connections.module.scss";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  Popover,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  DeleteRounded,
  GridViewRounded,
  LabelImportantRounded,
  MoreHorizRounded,
  NoteAddRounded,
  RemoveRedEyeRounded,
  SyncRounded,
} from "@mui/icons-material";
import { DATABASES, TRAINING_LOADING_TEXTS } from "../utils/constant";
import DbTypes from "./DbTypes";
import DbConnectionDetails from "./DbConnectionDetails";
import { DbConnection, Metadata } from "../models/DbConnection";
import {
  useAddConnection,
  useConnection,
  useDeleteConnection,
  useRegenerateMetadata,
} from "../api/connections";
import useQueryParameter, { StyledSwitch } from "../utils/util";
import MetadataView from "./MetadataView";
import Upload from "./Upload";
import { queryClient } from "../..";
import { useLocation } from "react-router-dom";
import GSheet from "./GSheet";
import GlossaryDialog from "../glossary/GlossaryDialog";
import { useLocalStorage } from "usehooks-ts";
import { useUser } from "../api/user";
import Stripe from "../utils/Stripe";

export default function Connections() {
  const email = useQueryParameter("email");
  const { data: existingConnections, isLoading } = useConnection(email);
  const { data: user } = useUser(email);
  const { mutate: addConnection, isLoading: addingConnection } =
    useAddConnection(email);
  const { mutate: deleteConneciton, isLoading: deletingConnection } =
    useDeleteConnection(email);

  const { mutate: regenerateMetada, isLoading: regeneratingMetadata } =
    useRegenerateMetadata(email);

  const [connections, setConnections] = useState<DbConnection[]>([]);
  const [dbChoicesDialog, setDbChoicesDialog] = useState(false);
  const [dbDetailDialog, setDbDetailDialog] = useState(false);
  const [metadataDialog, setMetadataDialog] = useState(false);
  const [selectedDb, setSelectedDb] = useState("postgres");
  const [selectedConnection, setSelectedConnection] = useState("");
  const [uploadDialog, setUploadDialog] = useState(false);
  const [gsheetDialog, setGsheetDialog] = useState(false);
  const [glossaryDialog, setGlossaryDialog] = useState(false);
  const [upgradeBanner, setUpgradeBanner] = useState(false);
  const [loadingText, setLoadingText] = useState<string>(
    TRAINING_LOADING_TEXTS[0]
  );
  const [newInterval, setNewInterval] = useState<any>(null);
  const [activeConnection, setActiveConnection] = useLocalStorage(
    "saydata-active-connection",
    ""
  );

  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      if (location?.state?.openSample) {
        const id =
          existingConnections?.data?.find(
            (c: any) => c?.displayName === "Sample Data"
          )?.id || "";
        setSelectedConnection(id);
        setMetadataDialog(true);
      } else if (location?.state?.openDb) {
        setDbChoicesDialog(true);
      } else if (location?.state?.openUpload) {
        setUploadDialog(true);
      } else if (location?.state?.openGSheet) {
        setGsheetDialog(true);
      }
    }
  }, [location]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    clickedConn: any
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedConnection(clickedConn);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);

  const openMetadataDialog = () => {
    setMetadataDialog(true);
    handleClose();
  };

  const onDeleteConnection = () => {
    deleteConneciton(selectedConnection as any);
    handleClose();
  };

  const onRegenerateMetadata = () => {
    regenerateMetada(selectedConnection as any);
    handleClose();
  };

  const onDbSelect = (type: string) => {
    setSelectedDb(type);
    setDbChoicesDialog(false);
    if (type === "csv") {
      setUploadDialog(true);
    } else if (type === "gsheet") {
      setGsheetDialog(true);
    } else {
      setDbDetailDialog(true);
    }
  };

  const getSelectedConnectionType = () => {
    const conn = connections?.find((c) => c.id === selectedConnection);
    return conn?.type;
  };

  const onSave = (newConn: DbConnection) => {
    let tables: string[] = [];
    newConn.tab = "explore";
    addConnection(newConn as any, {
      onSuccess: (response) => {
        tables = response?.data;
      },
    });
    setDbDetailDialog(false);
    return tables;
  };

  useEffect(() => {
    if (existingConnections?.data?.length > 0) {
      const exploreConnections = existingConnections.data;
      setConnections(exploreConnections);
      if (!activeConnection) {
        setActiveConnection(exploreConnections?.[0].id);
      }
    }
  }, [existingConnections]);

  const toggleConnection = (id: string, checked: boolean) => {
    if (checked) {
      setActiveConnection(id);
    }
  };

  return (
    <div className={classes.root}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading || deletingConnection || addingConnection}
      >
        <CircularProgress sx={{ color: "#4B0082" }} />
      </Backdrop>
      <div className={classes.header}>
        <div>Connections</div>
        <Tooltip
          title={
            user?.data?.plan !== "pro"
              ? "Upgrade to plan to add more connections"
              : ""
          }
          placement="top"
          arrow
        >
          <span style={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              className={
                user?.data?.plan !== "pro" &&
                existingConnections?.data?.length >= 2
                  ? classes.disabledButton
                  : classes.addButton
              }
              onClick={() => setDbChoicesDialog(true)}
              disabled={
                user?.data?.plan !== "pro" &&
                existingConnections?.data?.length >= 2
              }
            >
              Add connection
            </Button>
          </span>
        </Tooltip>
      </div>

      {connections?.map((connection, index) => (
        <div className={classes.wrapper}>
          {!!!connection.metadataGenerated && connection.isAIEnabled && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress
                classes={{
                  colorPrimary: classes.linearPrimary,
                  barColorPrimary: classes.linearBarPrimary,
                }}
                sx={{ borderRadius: 4 }}
              />
            </Box>
          )}
          <div key={index} className={classes.tile}>
            <img
              src={DATABASES[connection?.displayType]?.icon}
              alt="icon"
              width={28}
              height={28}
            ></img>
            <div
              className={
                connection.metadataGenerated || !connection.isAIEnabled
                  ? classes.connectionName
                  : classes.trainingLabel
              }
            >
              {connection.metadataGenerated || !connection.isAIEnabled
                ? connection.displayName
                : "Training your model, generally takes 2-5 mins"}
            </div>
            <div className={classes.iconGroup}>
              {connection?.isAIEnabled && (
                <StyledSwitch
                  shade="green"
                  checked={activeConnection === connection.id}
                  disabled={!!!connection.metadataGenerated}
                  onChange={(e) =>
                    toggleConnection(connection.id, e.target.checked)
                  }
                />
              )}
              <IconButton
                onClick={(e) => handleClick(e, connection.id)}
                disabled={
                  !!!connection.metadataGenerated && connection.isAIEnabled
                }
              >
                <MoreHorizRounded />
              </IconButton>
            </div>
          </div>
        </div>
      ))}

      <DbTypes
        dbChoicesDialog={dbChoicesDialog}
        setDbChoicesDialog={setDbChoicesDialog}
        onDbSelect={onDbSelect}
        tab="explore"
        setUpgradeBanner={setUpgradeBanner}
        user={user}
      />
      <Upload openUpload={uploadDialog} setUpload={setUploadDialog} />
      <GSheet
        openGSheetDialog={gsheetDialog}
        setGSheetDialog={setGsheetDialog}
      />
      <DbConnectionDetails
        dbDetailDialog={dbDetailDialog}
        selectedDb={selectedDb}
        onSave={onSave}
        setDbDetailDialog={setDbDetailDialog}
        tab="explore"
      />
      <MetadataView
        metadataDialog={metadataDialog}
        id={selectedConnection}
        setMetadataDialog={setMetadataDialog}
      />
      <GlossaryDialog
        glossaryDialog={glossaryDialog}
        setGlossaryDialog={setGlossaryDialog}
        activeConnectionId={selectedConnection}
        email={email}
      />
      <Stripe
        openStripe={upgradeBanner}
        setStripe={setUpgradeBanner}
        email={email}
      />

      <Popover
        id="more-options-connections"
        open={openPopover}
        anchorEl={anchorEl}
        className={classes.popover}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List>
          {getSelectedConnectionType() !== "csv" && (
            <ListItem key="metadata" className={classes.options} disablePadding>
              <Button
                sx={{ color: "#4B0082" }}
                startIcon={<GridViewRounded />}
                onClick={openMetadataDialog}
              >
                Metadata
              </Button>
            </ListItem>
          )}
          <ListItem key="glossary" className={classes.options} disablePadding>
            <Button
              sx={{ color: "#4B0082" }}
              startIcon={<NoteAddRounded />}
              onClick={() => {
                setGlossaryDialog(true);
                handleClose();
              }}
            >
              Glossary
            </Button>
          </ListItem>
          <ListItem key="regenerate" className={classes.options} disablePadding>
            <Button
              sx={{ color: "#4B0082" }}
              startIcon={<SyncRounded />}
              onClick={onRegenerateMetadata}
            >
              Sync Metadata
            </Button>
          </ListItem>
          <Tooltip
            title={
              activeConnection === selectedConnection
                ? "Can't delete active connection"
                : ""
            }
            arrow
            placement="top"
            enterDelay={300}
          >
            <ListItem key="delete" className={classes.options} disablePadding>
              <Button
                color="error"
                startIcon={<DeleteRounded />}
                onClick={onDeleteConnection}
                disabled={
                  deletingConnection || activeConnection === selectedConnection
                }
              >
                Delete
              </Button>
            </ListItem>
          </Tooltip>
        </List>
      </Popover>
    </div>
  );
}
