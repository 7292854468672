import { CloseRounded } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import classes from "./Dashboard.module.scss";
import { useEffect, useState } from "react";
import { useAddDashboard, usePatchDashboard } from "../api/dashboard";
import { v4 } from "uuid";
import { useLocalStorage } from "usehooks-ts";
import { useNavigate } from "react-router-dom";
import { DashboardBlock } from "../models/Dashboard";

type CreateDialogProps = {
  selectedDashboard?: DashboardBlock;
  createDialog: boolean;
  setCreateDialog: React.Dispatch<React.SetStateAction<boolean>>;
  email: string;
  type: string;
};
export default function CreateDialog({
  selectedDashboard,
  createDialog,
  email,
  setCreateDialog,
  type,
}: CreateDialogProps) {
  const { mutate: addDashboard, isLoading: creatingDashboard } =
    useAddDashboard(email);

  const { mutate: patchDashboard, isLoading: patchingDashboard } =
    usePatchDashboard(selectedDashboard?.id || "", email);

  const [title, setTitle] = useState(selectedDashboard?.title || "");
  const [description, setDescription] = useState(
    selectedDashboard?.description || ""
  );
  const [activeConnection, setActiveConnection] = useLocalStorage(
    "saydata-active-connection",
    ""
  );

  useEffect(() => {
    if (selectedDashboard?.id) {
      setTitle(selectedDashboard?.title || "");
      setDescription(selectedDashboard?.description || "");
    } else {
      setTitle("");
      setDescription("");
    }
  }, [selectedDashboard])

  const onSave = () => {
    if (selectedDashboard?.id) {
      patchDashboard(
        {
          id: selectedDashboard?.id,
          title: title,
          description: description,
        },
        {
          onSettled: () => {
            setTitle("");
            setDescription("");
            setCreateDialog(false);
          },
        }
      );
    } else {
      const id = v4();
      addDashboard(
        {
          id: id,
          title: title,
          description: description,
          type: type,
        },
        {
          onSettled: () => {
            setTitle("");
            setDescription("");
            setCreateDialog(false);
          },
        }
      );
    }
  };

  return (
    <Dialog
      className={classes.dialogDetail}
      open={createDialog}
      classes={{ paper: classes.paper }}
      onClose={() => setCreateDialog(false)}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={creatingDashboard || patchingDashboard}
      >
        <CircularProgress sx={{ color: "#4B0082" }} />
      </Backdrop>
      <DialogTitle>
        <div className={classes.title}>New Dashboard</div>
        <div className={classes.subTitle}>
          Create centralized view pinning charts from various sources
        </div>
        <IconButton
          style={{ position: "absolute", top: 4, right: 4 }}
          onClick={() => setCreateDialog(false)}
        >
          <CloseRounded style={{ fontSize: "16px" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.text}>
          <div className={classes.label}>Title</div>
          <TextField
            size="small"
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#4B0082",
                  borderWidth: 1,
                },
              },
            }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          ></TextField>
        </div>
        <div className={classes.text}>
          <div className={classes.label}>Description</div>
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#4B0082",
                  borderWidth: 1,
                },
              },
            }}
            value={description}
            multiline
            rows={4}
            onChange={(e) => setDescription(e?.target?.value)}
          ></TextField>
        </div>
        <div className={classes.footer}>
          <Button
            variant="contained"
            className={classes.button}
            size="small"
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
