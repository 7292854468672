import { useLocation, useNavigate, useParams } from "react-router-dom";
import classes from "./AdminEditor.module.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import { Query } from "../../models/Query";
import { v4 } from "uuid";
import QueryEditor from "./QueryEditor";
import useQueryParameter, {
  DarkTooltip,
  convertJsToValue,
  decrypt,
  encrypt,
  hashJsonObject,
} from "../../utils/util";
import { DbConnection } from "../../models/DbConnection";
import Workspace from "./Workspace";
import { DashboardBlock, Filter, WorkspaceItem } from "../../models/Dashboard";
import ItemEditor from "./ItemEditor";
import Lottie from "lottie-react";
import loadingJson from "../../../icons/loading.json";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  Drawer,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import {
  AddRounded,
  ArrowBackRounded,
  Circle,
  CloseRounded,
  KeyboardDoubleArrowLeftRounded,
  SendRounded,
} from "@mui/icons-material";
import ChatInner from "../../chat/ChatInner";
import { useConnection, useConnectionById } from "../../api/key/connections";
import { ENCRYPTION_KEY } from "../../utils/constant";
import { useUser, useUserCustomers } from "../../api/key/user";
import {
  useAddChat,
  useAddChatGraph,
  useAddChatSummary,
  useAddFollowUpQuestions,
  useChatDetails,
  useChatHistory,
  usePatchChat,
} from "../../api/key/chat";
import {
  useDashboardChatHistory,
  useDashboardDetails,
  useDashboards,
  useGenerateEmbedId,
  usePatchDashboard,
  usePatchDashboardApiKey,
} from "../../api/key/dashbaord";
import { useDashboardQueries, usePatchQuery, useRunDataQuery } from "../../api/key/query";
import clsx from "clsx";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { format, parseISO } from "date-fns";
import { useQueryVariable } from "../../utils/useQueryVariable";

type Props = {
  readonly?: boolean;
};

export default function EmbeddedAdmin({ readonly = false }: Props) {
  const { id = "", activeConnection = "" } = useParams();
  //   const email = useQueryParameter("email");
  const hash = useQueryParameter("hash");
  // const parameters: any = JSON.parse(useQueryParameter("parameters") || "[]");
  const apiKey = useMemo((): string => {
    return decrypt(hash, ENCRYPTION_KEY);
  }, [hash]);
  const location = useLocation();
  const navigate = useNavigate();
  const [brandPallete, setBrandPallete] = useState<any>({
    light: "#faf8ff",
    dark: "#4b0082",
  });
  const filterId: Number = +(useQueryParameter("filter") || 0);
  const [chatId, setChatId] = useState<string>(v4());
  readonly = true;
  const { data: existingConnections, isLoading } = useConnection(apiKey);
  const { data: serverUser } = useUser(apiKey);
  const {
    data: chatDetails,
    isLoading: loadingDetails,
    refetch: refetchChat,
  } = useChatDetails(chatId, apiKey);
  const { mutate: addChat, isLoading: addingChat } = useAddChat(chatId, apiKey);
  const { mutate: patchChat, isLoading: patchingChat } = usePatchChat(
    chatId,
    apiKey
  );
  const { data: dashboards, isLoading: fetchingDashboards } =
    useDashboards(apiKey);
  const { mutate: generateEmbedId, isLoading: generatingEmbedId } =
    useGenerateEmbedId(id, apiKey);
  const { mutate: updateDashboard, isLoading: updatingDashboard } =
    usePatchDashboardApiKey(apiKey);
  const { data: dashboardDetails, isLoading: loadingDashboard } =
    useDashboardDetails(id, apiKey);
  const { mutate: patchDashboard, isLoading: patchingDashboard } =
    usePatchDashboard(id, apiKey);
  const { data: connection, refetch: refetchConnection } = useConnectionById(
    apiKey,
    activeConnection
  );
  const { mutate: patchQuery, isLoading: savingQuery } = usePatchQuery(
    id,
    apiKey
  );
  const { mutate: mutateQuery, isLoading: runningQuery } = useRunDataQuery(apiKey);
  const { data: dashboardChatHistory, isLoading: loadingDashboardChatHistory } =
    useDashboardChatHistory(id, apiKey);
  const { mutateAsync: addChatGraph, isLoading: loadingGraphs } =
    useAddChatGraph(chatId, apiKey);
  const { mutateAsync: addChatSummary, isLoading: loadingSummary } =
    useAddChatSummary(chatId, apiKey);
  const { mutateAsync: addFollowUp, isLoading: loadingFollowUp } =
    useAddFollowUpQuestions(chatId, apiKey);
  const [openChatDialog, setChatDialog] = useState(false);
  const [connections, setConnections] = useState<DbConnection[]>([]);
  const [dashboard, setDashboard] = useState<DashboardBlock>({
    editorItems: [],
  });
  const [filters, setFilters] = useState<Filter[]>([]);
  const {
    data: existingQueries,
    refetch,
    isFetching: isLoadingQueries,
  } = useDashboardQueries(id, apiKey, filters);
  //   const { mutate: addQuery, isLoading: addingQuery } = useAddQuery(id, email);
  //   const { mutate: removeQuery, isLoading: deletingQuery } = useDeleteQuery(
  //     id,
  //     email
  //   );
  const [queries, setQueries] = useState<Query[]>([]);
  const [lhsOpened, setLhsOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState<WorkspaceItem>({
    id: "",
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    type: "",
  });
  const { data: customers, isLoading: loadingCustomers } =
    useUserCustomers(apiKey);
  const [currentCustomer, setCurrentCustomer] = useState<any>({});

  const [queryEditor, setQueryEditor] = useState(false);
  const [itemEditorDrawer, setItemEditorDrawer] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [dashboardHash, setDashboardHash] = useState("");
  const [selectedQuery, setSelectedQuery] = useState<Query>({
    id: "",
    displayName: "",
    dashboardId: "",
    type: "",
    code: "",
    connectionId: "",
    refreshRate: 24,
    parameters: {},
    results: [],
  });

  useEffect(() => {
    if (existingQueries?.data?.length > 0) {
      const allQueries = existingQueries.data || [];
      allQueries?.forEach((query: Query, index: number) => {
        if (query.type === "js") {
          allQueries[index].results = convertJsToValue(query.code, allQueries);
        }
      });
      setQueries(allQueries);
    }
  }, [existingQueries]);

  useEffect(() => {
    if (dashboardDetails?.data) {
      setDashboard(dashboardDetails.data);
      setDashboardHash(hashJsonObject(dashboardDetails.data));
    }
  }, [dashboardDetails]);

  useEffect(() => {
    if (customers?.data?.length > 0) {
      const cusSelected = customers.data.find(
        (customer: any) => customer.id === filterId
      );
      setCurrentCustomer(cusSelected);
    }
  }, [filterId, customers]);

  useEffect(() => {
    if (serverUser) {
      setBrandPallete(
        serverUser?.data?.brandPalette || {
          light: "#faf8ff",
          dark: "#4b0082",
        }
      );
    }
  }, [serverUser]);

  const updateFiltersParams = (value: any) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("filter", value?.id);
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const newChat = () => {
    setChatId(v4());
    setChatDialog(true);
  };

  const historicalChat = (chatEle: any) => {
    setChatId(chatEle.id);
    setChatDialog(true);
  };

  useEffect(() => {
    // if (parameters && parameters?.length > 0) {
    //   const newFilters = currentCustomer?.filters || [];
    //   if (newFilters.length > 0) {
    //     parameters?.forEach((param: any) => {
    //       const innerFilter = newFilters?.[param?.index];
    //       innerFilter.values = param?.values;
    //       setFilters(newFilters);
    //     });
    //   }
    // } else {
    // if (currentCustomer?.filters?.length > 0) {
    //   setFilters(currentCustomer.filters);
    // }
    // }
    if (
      dashboard &&
      dashboard?.parameters &&
      dashboard?.parameters?.length > 0
    ) {
      const newFilters = currentCustomer?.filters || [];
      if (newFilters.length > 0) {
        dashboard?.parameters?.forEach((param: any) => {
          const innerFilter = newFilters?.[param?.index];
          innerFilter.values = param?.values;
          setFilters(newFilters);
        });
      }
    } else {
      if (currentCustomer?.filters?.length > 0) {
        setFilters(currentCustomer.filters);
      }
    }
  }, [currentCustomer, dashboard]);

  useEffect(() => {
    if (filters && filters.length > 0) {
      refetch();
    }
  }, [filters]);

  const onSave = () => {
    const request = {
      id: id,
      editorItems: dashboard.editorItems,
    };
    patchDashboard(request as any);
  };

  const setWorkspaceItems = (items: WorkspaceItem[]) => {
    setDashboard({
      ...dashboard,
      editorItems: items,
    });
  };

  useEffect(() => {
    if (dashboard && dashboard?.id && dashboard.id !== "") {
      if (dashboardHash === "") {
        setDashboardHash(hashJsonObject(dashboard));
        setPristine(true);
      } else {
        const newHash = hashJsonObject(dashboard);
        if (dashboardHash !== newHash) {
          setPristine(false);
          setDashboardHash(newHash);
        } else {
          setPristine(true);
        }
      }
    } else {
      setPristine(true);
    }
  }, [dashboard]);

  //   const deleteQuery = (id: string) => {
  //     removeQuery({
  //       id: id,
  //       filters: filters,
  //     });
  //   };

  const getConnectionName = (id: any) => {
    return existingConnections?.data?.find(
      (connection: any) => connection.id === id
    )?.displayName;
  };

  //   const addNewQuery = (queryType?: string) => {
  //     const length = queries.length;
  //     const newQuery = {
  //       id: v4(),
  //       displayName: `query${length + 1}`,
  //       type: queryType ? queryType : connections[0].type,
  //       email: email,
  //       code: "",
  //       dashboardId: id,
  //       connectionId: connections[0].id,
  //       refreshRate: 24,
  //       parameters: {},
  //       results: [],
  //     };
  //     addQuery(newQuery as any, {
  //       onSuccess: () => {
  //         setQueries([...queries, newQuery]);
  //       },
  //     });
  //   };

  const saveQueryRun = (queryId: string, newState: any) => {
    const queryClone = queries?.find((query) => query.id === queryId);
    if (!queryClone) {
      return;
    }

    if (queryClone.type === "js") {
      const value = convertJsToValue(queryClone.code, queries, newState);
      queryClone.results = value;
      setQueries([...queries]);
    } else {
      let queryRequest;
      if (queryClone.type === "mongodb") {
        queryRequest = {
          queryOptions: queryClone.queryOptions,
          type: "mongodb",
          connectionId: queryClone.connectionId,
          parameters: newState,
        };
      } else {
        queryRequest = {
          code: queryClone.code,
          connectionId: queryClone.connectionId,
          parameters: newState,
        };
      }
      const request = {
        query: queryRequest,
        filters: filters,
      };
      mutateQuery(request as any, {
        onSuccess: (response) => {
          queryClone.results = response?.data;
          setQueries([...queries]);
        }
      });
    }
  };

  const getDataFromQuery = (queryId: string | undefined) => {
    return queries.find((query) => query.id === queryId)?.results || [];
  };

  useEffect(() => {
    if (existingConnections?.data?.length > 0) {
      const adminConnections = existingConnections.data;
      setConnections(adminConnections);
    }
  }, [existingConnections]);

  const updateItem = (id: string, key: string, value: any) => {
    const newWorkspaceItems = [...(dashboard.editorItems || [])];
    const index = newWorkspaceItems.findIndex((item) => item.id === id);

    if (index >= 0) {
      const keys = key.split(".");
      let currentItem = newWorkspaceItems[index];

      for (let i = 0; i < keys.length - 1; i++) {
        const currentKey = keys[i];
        if (!(currentKey in currentItem)) {
          (currentItem as any)[currentKey as keyof any] = {};
        }
        currentItem = (currentItem as any)[currentKey as keyof any];
      }

      const finalKey = keys[keys.length - 1];
      (currentItem as any)[finalKey as keyof any] = value;

      setWorkspaceItems(newWorkspaceItems);
    }
  };

  if (loadingCustomers || generatingEmbedId) {
    return (
      <div
        style={{
          display: "flex",
          margin: "auto",
          width: 200,
          height: "100vh",
        }}
      >
        <Lottie animationData={loadingJson} loop={true} />
      </div>
    );
  }

  const printToPdf = () => {
    var ele = document.getElementById("dashboard-editor");
    if (!ele) {
      return;
    }
    html2canvas(ele, {
      scale: 3,
    }).then((canva) => {
      const imgData = canva.toDataURL("image/png");
      const pdf = new jsPDF({
        unit: "px",
        format: [canva.width, canva.height],
        orientation: canva.width > canva.height ? "landscape" : "portrait",
      });
      pdf.addImage(imgData, "PNG", 0, 0, canva.width, canva.height);
      pdf.save("Dashboard_" + new Date().toISOString() + ".pdf");
    });
  };

  return (
    <div
      className={classes.root}
      style={{ backgroundColor: brandPallete.light }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingQueries}
      >
        <CircularProgress sx={{ color: brandPallete?.dark }} />
      </Backdrop>
      {/* {!!!readonly && (
        <TopBar
          onSave={onSave}
          dashboard={dashboard}
          customers={customers?.data}
          currentCustomer={currentCustomer}
          updateFiltersParams={updateFiltersParams}
        />
      )}
      {!!!readonly && (
        <MiniDrawer
          queries={queries}
          workspaceItems={dashboard.editorItems || []}
          brandPallete={brandPallete}
          setBrandPallete={setBrandPallete}
          setWorkspaceItems={setWorkspaceItems}
          setLhsOpened={setLhsOpened}
          setQuery={setSelectedQuery}
          setQueryEditor={setQueryEditor}
          addNewQuery={addNewQuery}
          deleteQuery={deleteQuery}
          setSelectedItem={setSelectedItem}
        />
      )} */}
      <div className={classes.embedContainer}>
        <TextField
          onClick={() => {
            setChatId(v4());
            setChatDialog(true);
          }}
          sx={{
            width: "calc(100% - 292px)",
            marginLeft: "24px",
            marginTop: readonly ? "24px" : "64px",
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: brandPallete.dark,
                borderWidth: 1,
              },
            },
          }}
          placeholder="Add new metric, ask in plain english to get started..."
          InputProps={{
            autoFocus: true,
            style: {
              height: 44,
              borderRadius: "12px",
              border: "1px solid " + brandPallete.dark,
              background: "#FFF",
              boxShadow: "0px 2px 2px 0px " + brandPallete.dark,
            },
            endAdornment: (
              <InputAdornment position="end">
                {false ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: brandPallete.dark }}
                  />
                ) : (
                  <IconButton
                    className={classes.iconButton}
                    style={{
                      rotate: "-40deg",
                      marginTop: "-5px",
                      color: brandPallete.dark,
                    }}
                  >
                    <SendRounded />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        ></TextField>
        <Button
          className={
            !!!pristine ? classes.save : clsx(classes.save, classes.disabled)
          }
          onClick={onSave}
          style={{ backgroundColor: brandPallete?.dark }}
          disabled={pristine}
        >
          Save changes
        </Button>
        <Button
          className={classes.save}
          style={{ backgroundColor: brandPallete?.dark }}
          onClick={() => printToPdf()}
        >
          Generate PDF
        </Button>
      </div>
      <Workspace
        queries={queries}
        lhsOpened={lhsOpened}
        brandPalette={brandPallete}
        workspaceItems={dashboard.editorItems || []}
        setWorkspaceItems={setWorkspaceItems}
        setSelectedItem={setSelectedItem}
        setItemEditorDrawer={setItemEditorDrawer}
        getDataFromQuery={getDataFromQuery}
        saveQueryRun={saveQueryRun}
        viewOnly={readonly}
      />
      {/* {!!!readonly && (
        <QueryEditor
          open={queryEditor}
          dashboardId={id}
          email={email}
          setOpen={setQueryEditor}
          query={selectedQuery}
          allQueries={queries}
          connections={connections}
          lhsOpened={lhsOpened}
          filters={filters}
        />
      )} */}
      <ItemEditor
        selectedItem={selectedItem}
        updateItem={updateItem}
        itemEditorDrawer={itemEditorDrawer}
        queries={queries}
        setItemEditorDrawer={setItemEditorDrawer}
        getDataFromQuery={getDataFromQuery}
        viewOnly={readonly}
      />
      <Dialog
        open={openChatDialog}
        onClose={() => setChatDialog(false)}
        className={classes.chatDialog}
        classes={{ paper: classes.paper }}
        fullScreen
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingDetails}
        >
          <CircularProgress sx={{ color: brandPallete?.dark }} />
        </Backdrop>
        <div className={classes.toolbar}>
          {/* <DarkTooltip
            title="Active connection"
            placement="top"
            enterDelay={500}
          >
            <div className={classes.activeConnection}>
              {getConnectionName(activeConnection)}
              <Circle sx={{ fontSize: 10, color: "#74A854" }} />
            </div>
          </DarkTooltip> */}
          <IconButton
            style={{
              position: "absolute",
              top: 32,
              right: 12,
              border: "2px solid " + brandPallete?.dark,
              color: brandPallete?.dark,
              borderRadius: "8px",
            }}
            onClick={() => setChatDialog(false)}
          >
            <CloseRounded style={{ fontSize: "16px" }} />
          </IconButton>
        </div>
        <hr />
        <Drawer
          variant="permanent"
          open
          anchor="left"
          className={classes.historyDrawer}
          classes={{ paper: classes.paper }}
        >
          <div className={classes.pane}>
            <Button
              startIcon={<AddRounded />}
              className={classes.button}
              style={{
                color: brandPallete?.dark,
                borderColor: brandPallete?.dark,
              }}
              variant="contained"
              onClick={() => newChat()}
            >
              New Chat
            </Button>
          </div>
          <div className={classes.hist}>
            <div className={classes.text}>History</div>
            {dashboardChatHistory?.data?.map((chatEle: any) => (
              <div
                key={chatEle?.id}
                className={classes.tile}
                onClick={() => historicalChat(chatEle)}
              >
                <div className={classes.title}>
                  {chatEle?.title?.slice(1, -1)}
                </div>
                <div className={classes.date}>
                  {format(parseISO(chatEle?.createdAt), "d MMM yyyy")}
                </div>
                {/* todo :: implement delete later
            <IconButton className={classes.icon}>
              <DeleteRounded color="error" />
            </IconButton> */}
              </div>
            ))}
          </div>
        </Drawer>
        <ChatInner
          email={serverUser?.data?.email}
          chatId={chatId}
          brandPalette={brandPallete}
          embed
          serverUser={serverUser}
          connections={{ data: connections }}
          chatDetails={chatDetails}
          loadingDetails={loadingDetails}
          refetch={refetchChat}
          addChat={addChat}
          addingChat={addingChat}
          patchChat={patchChat}
          addChatGraph={addChatGraph}
          loadingGraphs={loadingGraphs}
          addChatSummary={addChatSummary}
          loadingSummary={loadingSummary}
          addFollowUp={addFollowUp}
          loadingFollowUp={loadingFollowUp}
          filters={filters}
          dashboardId={id}
          patchingChat={patchingChat}
          dashboards={dashboards}
          updateDashboard={updateDashboard}
          updatingDashboard={updatingDashboard}
          connection={connection}
          activeConnection={activeConnection}
          refetchConnection={refetchConnection}
        />
      </Dialog>
    </div>
  );
}
