import {
  AppBar,
  Autocomplete,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Toolbar,
} from "@mui/material";
import classes from "./AdminEditor.module.scss";
import logo from "../../../icons/logo_s.svg";
import { DashboardBlock, Filter } from "../../models/Dashboard";
import { KeyboardArrowDownRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useMemo, useState } from "react";
import EmbedDialog from "../../utils/Embed";
import { encrypt } from "../../utils/util";
import { ENCRYPTION_KEY } from "../../utils/constant";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

type Props = {
  id: string;
  activeConnection: string;
  dashboard: DashboardBlock;
  customers: any[];
  currentCustomer: any;
  serverUser: any;
  parameters: any[];
  filters: Filter[];
  updateFiltersParams: (value: any) => void;
  onSave: () => void;
  generateEmbedId: any;
  generatingEmbedId: boolean;
};

export default function TopBar({
  id,
  activeConnection,
  dashboard,
  onSave,
  customers,
  currentCustomer,
  serverUser,
  parameters,
  filters,
  updateFiltersParams,
  generateEmbedId,
  generatingEmbedId,
}: Props) {
  const [openEmbed, setEmbed] = useState(false);
  // const [embedLink, setEmbedLink] = useState<string>("");
  // const [iframeText, setIframeText] = useState<string>("");
  const embedLink = useMemo(() => {
    const apiKey = serverUser?.data?.apiKey;
    const encryptedApiKey = apiKey ? encrypt(apiKey, ENCRYPTION_KEY) : "";
    let finalParams = [];
    if (parameters && parameters?.length > 0) {
      finalParams = parameters;
    } else {
      filters.forEach((filter: Filter, index: number) => {
        finalParams.push({
          index: index,
          values: filter.values || [],
        });
      });
    }

    return `https://app.saydata.tech/embed/generate/${id}/${activeConnection}?filter=${
      currentCustomer?.id
    }&parameters=${encodeURIComponent(
      JSON.stringify(finalParams)
    )}&hash=${encodeURIComponent(encryptedApiKey)}`;
  }, [
    id,
    activeConnection,
    serverUser?.data?.apiKey,
    currentCustomer,
    parameters,
    filters,
  ]);

  const printToPdf = () => {
    var ele = document.getElementById("dashboard-editor");
    if (!ele) {
      return;
    }
    html2canvas(ele, {
      scale: 3,
    }).then((canva) => {
      const imgData = canva.toDataURL("image/png");
      const pdf = new jsPDF({
        unit: "px",
        format: [canva.width, canva.height],
        orientation: canva.width > canva.height ? "landscape" : "portrait",
      });
      pdf.addImage(imgData, "PNG", 0, 0, canva.width, canva.height);
      pdf.save("Dashboard_" + new Date().toISOString() + ".pdf");
    });
  };

  const iframeText = `<iframe
     width="1440"
     height="1000"
     style="border: none;"
     src="${embedLink}">
    </iframe>`;

  // const getEmbeddedDashboardId = () => {
  //   const request = {
  //     filterId: currentCustomer?.id,
  //     parameters: currentCustomer?.filters
  //   };

  //   generateEmbedId(request as any, {
  //     onSuccess: (response: any) => {
  //       const newDashboardId = response?.data;
  //       const apiKey = serverUser?.data?.apiKey;
  //       const encryptedApiKey = apiKey ? encrypt(apiKey, ENCRYPTION_KEY) : "";
  //       let finalParams = [];
  //       if (parameters && parameters?.length > 0) {
  //         finalParams = parameters;
  //       } else {
  //         filters.forEach((filter: Filter, index: number) => {
  //           finalParams.push({
  //             index: index,
  //             values: filter.values || [],
  //           });
  //         });
  //       }
  //       const newEmbedLink = `http://localhost:3000/embed/generate/${newDashboardId}/${activeConnection}?filter=${
  //         currentCustomer?.id
  //       }&parameters=${encodeURIComponent(
  //         JSON.stringify(finalParams)
  //       )}&hash=${encodeURIComponent(encryptedApiKey)}`;
  //       setEmbedLink(newEmbedLink);
  //       setIframeText(
  //         `<iframe
  //    width="1440"
  //    height="1000"
  //    style="border: none;"
  //    src="${newEmbedLink}">
  //   </iframe>`
  //       );
  //       setEmbed(true);
  //     },
  //   });
  // };
  return (
    <div className={classes.topbar}>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: "none",
        }}
      >
        <Toolbar className={classes.toolbar}>
          <Link to="/dashboard" className={classes.homeButton}>
            <img src={logo} alt="SayData" height={26} />
          </Link>
          <div className={classes.title}>{dashboard.title}</div>
          <div className={classes.right}>
            <div className={classes.customers}>
              <div className={classes.title}>Filter</div>
              <Autocomplete
                options={customers || []}
                value={currentCustomer || null}
                isOptionEqualToValue={(option: any, value: any) =>
                  option?.uniqueId === value?.uniqueId
                }
                getOptionLabel={(option: any) => option?.name || ""}
                onChange={(e, value) => {
                  if (value) {
                    updateFiltersParams(value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{ width: "200px" }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <KeyboardArrowDownRounded sx={{ fontSize: 18 }} />
                      ),
                      style: {
                        height: 26,
                        fontSize: 12,
                        fontWeight: 600,
                        padding: "2px 8px 4px 4px",
                      },
                    }}
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li
                    {...props}
                    style={{
                      fontWeight: selected ? 600 : 400,
                      fontSize: 12,
                      padding: "4px 16px",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    {option?.name}
                  </li>
                )}
              />
            </div>
            {/* <Button
              className={classes.preview}
              href={`/dashboard/editor/${dashboard?.id}?email=${dashboard.email}&filter=${currentCustomer?.id}&preview=true`}
              target="_blank"
              rel="noreferrer"
            >
              Preview
            </Button> */}
            <Button className={classes.save} onClick={onSave}>
              Save
            </Button>
            <Button
              className={classes.save}
              onClick={() => setEmbed(true)}
              // onClick={() => getEmbeddedDashboardId()}
              variant="contained"
              size="small"
              endIcon={
                generatingEmbedId ? (
                  <CircularProgress sx={{ color: "#fff" }} size={12} />
                ) : (
                  <></>
                )
              }
              disabled={generatingEmbedId}
            >
              Embed
            </Button>
            <Button className={classes.save} onClick={() => printToPdf()}>
              Generate PDF
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <EmbedDialog
        embedLink={embedLink}
        iframeText={iframeText}
        embedDialog={openEmbed}
        setEmbedDialog={setEmbed}
      />
    </div>
  );
}
