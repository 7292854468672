import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import classes from "./GSheets.module.scss";
import { CloseRounded } from "@mui/icons-material";
import Dropzone from "react-dropzone";
import { useUploadFile, useUploadGSheet } from "../api/file";
import useQueryParameter from "../utils/util";
import { queryClient } from "../..";
import { useConnectionById } from "../api/connections";
import { useLocalStorage } from "usehooks-ts";

type GSheetProps = {
  openGSheetDialog: boolean;
  setGSheetDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function GSheet({
  openGSheetDialog,
  setGSheetDialog,
}: GSheetProps) {
  const email = useQueryParameter("email");
  const [connectionId, setConnectionId] = useState<string>("");
  const { data: connectionDetails } = useConnectionById(email, connectionId);
  const { mutate: uploadFile, isLoading } = useUploadGSheet(email);
  const [newInterval, setNewInterval] = useState<any>(null);
  const [sheetUrl, setSheetUrl] = useState<string>("");
  const [activeConnection, setActiveConnection] = useLocalStorage(
    "saydata-active-connection",
    ""
  );

  const getSheetId = (url: string) => {
    const regex = /\/d\/([a-zA-Z0-9-_]+)/;
    const match = url.match(regex);
    if (match && match.length >= 2) {
      return match[1];
    } else {
      return "";
    }
  };
  const onAdd = () => {
    const id = getSheetId(sheetUrl);
    const request = {
      gsheetId: id,
    };
    uploadFile(request as any, {
      onSuccess: (response: any) => {
        setConnectionId(response?.data);
        setGSheetDialog(false);
        const interval = setInterval(() => {
          queryClient.invalidateQueries(["connection", response?.data]);
        }, 5000);
        setNewInterval(interval);
      },
    });
  };

  useEffect(() => {
    if (connectionDetails?.data?.metadataGenerated) {
      clearInterval(newInterval);
      queryClient.invalidateQueries(["connections", email]);
      setActiveConnection(connectionDetails?.data?.id);
    }
  }, [connectionDetails]);
  return (
    <Dialog
      className={classes.dialog}
      classes={{ paper: classes.paper }}
      open={openGSheetDialog}
      onClose={() => setGSheetDialog(false)}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress sx={{ color: "#4B0082" }} />
      </Backdrop>
      <DialogTitle>
        <div className={classes.title}>Sheet Link</div>
        <IconButton
          style={{ position: "absolute", top: 4, right: 4 }}
          onClick={() => setGSheetDialog(false)}
        >
          <CloseRounded style={{ fontSize: "16px" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <TextField
          value={sheetUrl}
          onChange={(e) => setSheetUrl(e.target.value)}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#4B0082",
                borderWidth: 1,
              },
            },
          }}
          placeholder="Enter sheet sharable url"
          InputProps={{
            style: {
              borderRadius: 4,
              height: 32,
            },
          }}
        ></TextField>
      </DialogContent>

      <div className={classes.footer}>
        <Button
          variant="contained"
          className={classes.button}
          size="small"
          onClick={onAdd}
        >
          Save
        </Button>
      </div>
    </Dialog>
  );
}
