import { CloseRounded } from "@mui/icons-material";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
} from "@mui/material";
import classes from "./Connections.module.scss";
import { useEffect, useState } from "react";
import { useConnectionById, useModifyConnection } from "../api/connections";
import useQueryParameter from "../utils/util";
import { queryClient } from "../..";
import { useLocalStorage } from "usehooks-ts";
import { useUser } from "../api/user";

type TableProps = {
  tablesDialog: boolean;
  id: string;
  tables: string[];
  setTablesDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Tables({
  tablesDialog,
  tables,
  id,
  setTablesDialog,
}: TableProps) {
  const [selectedTables, setSelectedTables] = useState<string[]>([]);
  const email = useQueryParameter("email");
  const { data: connectionDetails } = useConnectionById(email, id);
  const { data: user } = useUser(email);
  const { mutate: modifyConnection, isLoading } = useModifyConnection(
    email,
    id
  );
  const [newInterval, setNewInterval] = useState<any>(null);
  const [activeConnection, setActiveConnection] = useLocalStorage(
    "saydata-active-connection",
    ""
  );

  const handleChange = (checked: boolean, table: string) => {
    const newTables = checked
      ? [...selectedTables, table]
      : selectedTables.filter((selectedTable) => selectedTable !== table);

    setSelectedTables(newTables);
  };

  useEffect(() => {
    if (connectionDetails?.data?.metadataGenerated) {
      clearInterval(newInterval);
      queryClient.invalidateQueries(["connections", email]);
      setActiveConnection(connectionDetails?.data?.id);
    }
  }, [connectionDetails]);

  const onSave = () => {
    const request = {
      selectedTables: selectedTables.join(","),
    };
    modifyConnection(request as any, {
      onSuccess: () => {
        setTablesDialog(false);
        const interval = setInterval(() => {
          queryClient.invalidateQueries(["connection", id]);
        }, 5000);
        setNewInterval(interval);
      },
    });
  };

  return (
    <Dialog
      className={classes.tableDialog}
      classes={{ paper: classes.paper }}
      open={tablesDialog}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress sx={{ color: "#4B0082" }} />
      </Backdrop>
      <DialogTitle>
        <div className={classes.title}>Select tables</div>
        <div
          className={classes.subTitle}
          style={{
            color:
              user?.data?.plan !== "pro" && selectedTables?.length > 5
                ? "red"
                : "rgb(133, 133, 133)",
          }}
        >
          {user?.data?.plan !== "pro"
            ? "Choose upto 5 tables"
            : "Choose tables"}
        </div>
        <IconButton
          style={{ position: "absolute", top: 4, right: 4 }}
          onClick={() => setTablesDialog(false)}
        >
          <CloseRounded style={{ fontSize: "16px" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <FormGroup className={classes.tile}>
          {tables?.map((table, index) => (
            <FormControlLabel
              className={classes.label}
              control={
                <Checkbox
                  size="small"
                  onChange={(e) => handleChange(e.target.checked, table)}
                />
              }
              label={table}
              key={index}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <div className={classes.footer}>
        <Button
          variant="contained"
          className={classes.button}
          size="small"
          disabled={user?.data?.plan !== "pro" && selectedTables?.length > 5}
          onClick={onSave}
        >
          Save
        </Button>
      </div>
    </Dialog>
  );
}
