import {
  useAddUserCustomers,
  useDeleteUserCustomers,
  usePatchUserCustomers,
  useUserCustomers,
} from "../../api/user";
import useQueryParameter from "../../utils/util";
import classes from "./Customers.module.scss";
import Lottie from "lottie-react";
import loadingJson from "../../../icons/loading.json";
import { Filter } from "../../models/Dashboard";
import StringFilter from "../utils/StringFilter";
import NumericFilter from "../utils/NumericFilter";
import { useConnection } from "../../api/connections";
import { useEffect, useState } from "react";
import { DbConnection } from "../../models/DbConnection";
import {
  Accordion,
  AccordionSummary,
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  TextField,
} from "@mui/material";
import { AddRounded, KeyboardArrowDownRounded } from "@mui/icons-material";

export default function Customers() {
  const email = useQueryParameter("email");
  const { data: customersData, isLoading: loadingCustomers } =
    useUserCustomers(email);
  const { data: existingConnections, isLoading: loadingConnections } =
    useConnection(email);
  const { mutate: patchCustomer, isLoading: patchingCustomer } =
    usePatchUserCustomers(email);
  const { mutate: addNewFilter, isLoading: addingFilter } =
    useAddUserCustomers(email);
  const { mutate: deleteCustomer, isLoading: deletingCustomer } =
    useDeleteUserCustomers(email); 
  const [connections, setConnections] = useState<DbConnection[]>([]);
  const [customers, setCustomers] = useState<any>([]);

  useEffect(() => {
    if (existingConnections?.data?.length > 0) {
      const adminConnections = existingConnections.data;
      setConnections(adminConnections);
    }
  }, [existingConnections]);

  useEffect(() => {
    if (customersData?.data?.length > 0) {
      setCustomers(customersData.data);
    }
  }, [customersData]);

  const addCustomer = () => {
    addNewFilter({
      name: "New Filter",
      filters: [],
    });
  };

  const updateCustomer = (index: number, key: string, value: any) => {
    const customer = customers[index];
    customer[key] = value;
    setCustomers([...customers]);
  };

  const setFilter = (index: number, parentIndex: number, filter: Filter) => {
    const customer = customers[parentIndex];
    customer.filters[index] = filter;
    setCustomers([...customers]);
  };

  const addFilter = (parentIndex: number) => {
    const customer = customers[parentIndex];
    customer.filters.push({
      type: "string",
      dataSourceId: connections[0].id,
      key: "",
      operator: "=",
      value: "",
    });
    setCustomers([...customers]);
  };

  const removeFilter = (index: number, parentIndex: number) => {
    const customer = customers[parentIndex];
    customer.filters.splice(index, 1);
    setCustomers([...customers]);
  };

  const onSave = (parentIndex: number) => {
    const customer = customers[parentIndex];
    patchCustomer(customer);
  };

  const onDelete = (parentIndex: number) => {
    const customer = customers[parentIndex];
    deleteCustomer(customer);
  };

  if (loadingCustomers) {
    return (
      <div
        style={{
          display: "flex",
          margin: "auto",
          width: 200,
          height: "100vh",
        }}
      >
        <Lottie animationData={loadingJson} loop={true} />
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          patchingCustomer ||
          addingFilter ||
          loadingConnections ||
          deletingCustomer
        }
      >
        <CircularProgress sx={{ color: "#4B0082" }} />
      </Backdrop>
      <div className={classes.title}>
        Filters
        <div className={classes.right}>
          <Button
            className={classes.createButton}
            variant="contained"
            onClick={() => addCustomer()}
          >
            Add new
          </Button>
        </div>
      </div>
      <div className={classes.customers}>
        {customers?.map((customer: any, parentIndex: number) => (
          <Accordion
            className={classes.accordion}
            key={"customer_"+ parentIndex + "_" + customer?.id}
          >
            <AccordionSummary
              expandIcon={<KeyboardArrowDownRounded />}
              className={classes.summary}
            >
              {customer?.name}
            </AccordionSummary>
            <div className={classes.filterContainer}>
              <div className={classes.name}>
                <div className={classes.label}>Name</div>
                <TextField
                  value={customer?.name}
                  size="small"
                  sx={{
                    width: "80%",
                    marginLeft: "auto",
                    "& .MuiOutlinedInput-root": {
                      input: {
                        padding: "4px 0px 4px 4px",
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      fontSize: 12,
                      fontWeight: 600,
                      height: 28,
                      padding: "0px 8px",
                    },
                  }}
                  onChange={(e) => {
                    updateCustomer(parentIndex, "name", e.target.value);
                  }}
                />
              </div>
              {customer?.filters?.map((filter: Filter, index: number) => (
                <StringFilter
                  filter={filter}
                  index={index}
                  parentIndex={parentIndex}
                  connections={connections}
                  setFilter={setFilter}
                  removeFilter={removeFilter}
                />
              ))}
            </div>
            <Button
              startIcon={<AddRounded />}
              size="small"
              className={classes.add}
              onClick={(_) => addFilter(parentIndex)}
            >
              Add filter
            </Button>
            <Divider className={classes.divider} />
            <div className={classes.footer}>
              <Button
                size="small"
                className={classes.save}
                variant="contained"
                onClick={(_) => onSave(parentIndex)}
              >
                Save changes
              </Button>
              <Button
                size="small"
                color="error"
                className={classes.delete}
                variant="outlined"
                onClick={(_) => onDelete(parentIndex)}
              >
                Delete
              </Button>
            </div>
          </Accordion>
        ))}
      </div>
    </div>
  );
}
