import RGL, { Layout, WidthProvider } from "react-grid-layout";
import { Query } from "../../models/Query";
import classes from "./Workspace.module.scss";
import { useRef, useState } from "react";
import RowsView from "../../utils/RowsView";
import { WorkspaceItem } from "../../models/Dashboard";
import { IconButton } from "@mui/material";
import {
  DeleteRounded,
  EditRounded,
  MoreVertRounded,
} from "@mui/icons-material";
import Graphs from "../../charts/Graphs";
import { DarkTooltip, formatWithThousandSeparator } from "../../utils/util";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import Select from "../../select/Select";
import CustomDate from "../../date/CustomDate";

type Props = {
  queries: Query[];
  workspaceItems: WorkspaceItem[];
  brandPalette: any;
  setWorkspaceItems: (items: WorkspaceItem[]) => void;
  lhsOpened: boolean;
  setSelectedItem: React.Dispatch<React.SetStateAction<WorkspaceItem>>;
  setItemEditorDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  viewOnly?: boolean;
  getDataFromQuery: (queryId: string | undefined) => any;
  saveQueryRun: (queryId: string, newParams: any) => void;
};

const ReactGridLayout = WidthProvider(RGL);

export default function Workspace({
  queries,
  workspaceItems,
  brandPalette,
  setWorkspaceItems,
  lhsOpened,
  setSelectedItem,
  setItemEditorDrawer,
  viewOnly = false,
  getDataFromQuery,
  saveQueryRun
}: Props) {
  const onItemGridClick = (id: string) => {
    const clickedItem = workspaceItems.find((item) => item.id === id);
    clickedItem?.id && setSelectedItem(clickedItem);
    setItemEditorDrawer(true);
  };

  const handleLayoutChange = (layouts: Layout[]) => {
    if (workspaceItems) {
      const newDashboardItems = [...workspaceItems];
      layouts.forEach((layout) => {
        const index = newDashboardItems.findIndex(
          (item) => item.id === layout.i
        );
        const it = newDashboardItems[index];
        if (it) {
          it.x = layout.x;
          it.y = layout.y;
          it.width = layout.w;
          it.height = layout.h;
          newDashboardItems[index] = it;
        }
      });
      setWorkspaceItems(newDashboardItems);
    }
  };

  const generateLayout = () => {
    const layout: Layout[] = [];
    workspaceItems.forEach((item) => {
      layout.push({
        i: item.id,
        x: item.x,
        y: item.y,
        w: item.width,
        h: item.height,
      });
    });
    return layout;
  };

  const deleteChart = (itemId: string) => {
    const newDashboardItems = [...workspaceItems];
    const index = newDashboardItems.findIndex((item) => item.id === itemId);
    if (index >= 0) {
      newDashboardItems.splice(index, 1);
      setWorkspaceItems(newDashboardItems);
    }
  };

  const convertJsToValue = (metadata: any, queryId: string) => {
    const jsString = metadata?.value;
    let val = "";
    if (jsString?.startsWith("{{") && jsString?.endsWith("}}")) {
      const query = queries.find((query) => query.id === queryId);
      if (query?.results) {
        try {
          const code = jsString.replace("{{", "").replace("}}", "");
          const modifiedJsString = `const ${
            query.displayName
          } = ${JSON.stringify({
            results: query.results,
          })};\n 
          ${code};`;
          const value = eval(modifiedJsString);
          val = "" + value;
        } catch (error: any) {
          val = "";
        }
      }
    } else {
      val = "";
    }
    if (metadata?.thousandSeparator) {
      val = formatWithThousandSeparator(val);
    }
    if (metadata?.prefix) {
      val = metadata?.prefix + val;
    }
    if (metadata?.suffix) {
      val = val + metadata?.suffix;
    }

    return val;
  };

  return (
    <div
      className={classes.workspace}
      id="dashboard-editor"
      style={{
        marginLeft: viewOnly ? 0 : lhsOpened ? 220 : 40,
        marginTop: 12,
      }}
    >
      <div className={classes.container} style={{ transform: "scale(1)" }}>
        <ReactGridLayout
          rowHeight={18}
          cols={32}
          layout={generateLayout()}
          onLayoutChange={handleLayoutChange}
          margin={[40, 40]}
          containerPadding={[20, 20]}
          className={classes.gridContainer}
          resizeHandles={["se"]}
        >
          {workspaceItems?.map((item) => (
            <div key={item.id} className={classes.newTile}>
              <div className={classes.topBar}>
                <div
                  className={classes.title}
                  style={{ fontSize: +item?.metadata?.title?.fontSize || 14 }}
                >
                  {item?.title || "Add title"}
                </div>
                {
                  <div className={classes.right}>
                    <DarkTooltip title="Edit" placement="top">
                      <IconButton
                        className={classes.iconButtonSmall}
                        onClick={() => onItemGridClick(item.id)}
                      >
                        <EditRounded />
                      </IconButton>
                    </DarkTooltip>
                    <DarkTooltip title="Delete" placement="top">
                      <IconButton
                        className={classes.iconButtonSmall}
                        onClick={() => deleteChart(item.id)}
                      >
                        <DeleteRounded color="error" />
                      </IconButton>
                    </DarkTooltip>
                  </div>
                }
              </div>
              {item?.type === "table" ? (
                <div className={classes.table}>
                  <RowsView
                    rows={getDataFromQuery(item?.dataQueryId)}
                    title={item?.title}
                    cols={item?.metadata?.columns}
                    brandPalette={brandPalette}
                    blockId={item.id}
                    showEmbed={false}
                    showExport={true}
                    embed={true}
                    fontSize={+item?.metadata?.fontSize || 14}
                    headerColor={item?.metadata?.headerColor || "#F6F2F9"}
                    gridHeight={
                      item?.height ? item?.height * 2.6 + "rem" : "20vh"
                    }
                  />
                </div>
              ) : item?.type === "tile" ? (
                item?.dataQueryId && (
                  <div className={classes.tile}>
                    <div
                      className={classes.value}
                      style={{
                        fontSize: +item?.metadata?.tile?.fontSize || 18,
                      }}
                    >
                      {convertJsToValue(item?.metadata, item?.dataQueryId)}
                    </div>
                  </div>
                )
              ) : item?.type === "chart" || item?.type === "graph" ? (
                <Graphs
                  key={JSON.stringify(item?.metadata?.options)}
                  rows={getDataFromQuery(item?.dataQueryId)}
                  options={item.metadata?.options}
                />
              ) : item.type === "select" ? (
                <Select
                  item={item}
                  metadata={item?.metadata}
                  rows={getDataFromQuery(item?.dataQueryId)}
                  saveQueryRun={saveQueryRun}
                />
              ) : item.type === "date" ? (
                <CustomDate
                  item={item}
                  onItemGridClick={onItemGridClick}
                />
              ) : (
                <></>
              )}
            </div>
          ))}
        </ReactGridLayout>
      </div>
    </div>
  );
}
