import { useEffect, useState } from "react";
import CreateDialog from "../../dashboard/CreateDialog";
import classes from "./Panel.module.scss";
import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { v4 } from "uuid";
import {
  useAddDashboard,
  useDashboards,
  useDeleteDashboard,
} from "../../api/dashboard";
import { DashboardBlock } from "../../models/Dashboard";
import { DeleteRounded, EditRounded } from "@mui/icons-material";
import { format, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import loadingJson from "../../../icons/loading.json";
import { useLocalStorage } from "usehooks-ts";
import { useUser, useUserCustomers } from "../../api/user";
import { encrypt } from "../../utils/util";
import { ENCRYPTION_KEY } from "../../utils/constant";

export default function Panel() {
  const { user, isLoading, logout } = useAuth0();
  const email = user?.email || "";
  const { data: serverUser, isLoading: fetchingUser } = useUser(email);
  const [createDialog, setCreateDialog] = useState(false);
  const { data: allDashboards, isLoading: fetchingDashboards } =
    useDashboards(email);
  const { mutate: removeDashboard, isLoading: deletingDashboard } =
    useDeleteDashboard(email);
  const [dashboards, setDashboards] = useState<DashboardBlock[]>([]);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [selectedDashboard, setSelectedDashboard] = useState<DashboardBlock>();

  useEffect(() => {
    if (allDashboards?.data) {
      const adminDashboards =
        allDashboards.data?.filter((d: DashboardBlock) => d.type === "admin") ||
        [];
      setDashboards(adminDashboards);
    }
  }, [allDashboards]);

  const [activeConnection, setActiveConnection] = useLocalStorage(
    "saydata-active-connection",
    ""
  );

  const apiKey = serverUser?.data?.apiKey;
  const encryptedApiKey = apiKey ? encrypt(apiKey, ENCRYPTION_KEY) : "";

  const { data: customers, isLoading: loadingCustomers } =
    useUserCustomers(email);

  const [filterIdNameMap, setFilterIdNameMap] = useState<any>({});

  const deleteDashboard = (e: any, id: string | undefined) => {
    e.stopPropagation();
    removeDashboard(id as any);
  };

  useEffect(() => {
    if (searchText) {
      const filteredDashboards = dashboards.filter(
        (d: DashboardBlock) =>
          d.title && d.title.toLowerCase().includes(searchText.toLowerCase())
      );
      setDashboards(filteredDashboards || []);
    } else {
      setDashboards(
        allDashboards?.data?.filter(
          (d: DashboardBlock) => d.type === "admin"
        ) || []
      );
    }
  }, [searchText]);

  const openCreateDialog = (e: any, dashboard: DashboardBlock) => {
    e.stopPropagation();
    setSelectedDashboard(dashboard);
    setCreateDialog(true);
  };

  useEffect(() => {
    if (customers?.data) {
      const filterIdNameMap: any = {};
      customers.data.forEach((customer: any) => {
        filterIdNameMap[customer.id] = customer.name;
      });
      setFilterIdNameMap(filterIdNameMap);
    }
  }, [customers]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          margin: "auto",
          width: 200,
          height: "100vh",
        }}
      >
        <Lottie animationData={loadingJson} loop={true} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={fetchingDashboards || deletingDashboard}
      >
        <CircularProgress sx={{ color: "#4B0082" }} />
      </Backdrop>
      <div className={classes.panelTitle}>
        Dashboards
        <div className={classes.right}>
          <TextField
            id="dashboard-search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                input: {
                  padding: "4px 12px",
                },
                fieldset: {
                  borderWidth: 1,
                },
                "&:hover fieldset": {
                  borderColor: "#4b0082",
                  borderWidth: 1,
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#4b0082",
                  borderWidth: 1,
                },
              },
            }}
            placeholder="Search..."
            size="small"
            InputProps={{
              sx: {
                fontSize: 14,
                fontWeight: 500,
              },
            }}
          />
          <Button
            className={classes.createButton}
            variant="contained"
            onClick={() => {
              setSelectedDashboard(undefined);
              setCreateDialog(true);
            }}
          >
            New Dashboard
          </Button>
          <Button
            className={classes.createButton}
            variant="contained"
            onClick={() => navigate(`/dashboard/filters?email=${email}`)}
          >
            Manage Filters
          </Button>
        </div>
      </div>

      <div className={classes.table}>
        <div>
          <div className={classes.headerTile}>
            <div className={classes.title}>Name</div>
            <div className={classes.time}>Last Updated</div>
            <div className={classes.bogus}>Created</div>
            <div className={classes.bogus}>Created</div>
          </div>
        </div>
        {dashboards
          ?.filter((d) => d.embedHash === "")
          ?.map((dashboard) => (
            <div
              className={classes.tile}
              onClick={() =>
                navigate(`/dashboard/editor/${dashboard.id}?email=${email}`)
              }
              key={dashboard.id}
            >
              <div className={classes.title}>{dashboard.title}</div>
              {dashboard.updatedAt && (
                <div className={classes.time}>
                  {format(parseISO(dashboard.updatedAt), "d MMM yyyy")}
                </div>
              )}
              <div className={classes.right}>
                <IconButton
                  className={classes.icon}
                  onClick={(e) => openCreateDialog(e, dashboard)}
                >
                  <EditRounded color="primary" />
                </IconButton>
                <IconButton
                  className={
                    dashboard.title !== "Sample Dashboard"
                      ? classes.icon
                      : classes.iconDisabled
                  }
                  onClick={(e) => deleteDashboard(e, dashboard.id)}
                >
                  <DeleteRounded color="error" />
                </IconButton>
              </div>
            </div>
          ))}
        <Divider>Embedded Dashboards</Divider>
        {dashboards
          ?.filter((d) => d.embedHash !== "")
          ?.map((dashboard) => (
            <div
              className={classes.tile}
              onClick={() =>
                navigate({
                  pathname: `/embed/dashboard/${dashboard.id}/${activeConnection}`,
                  search: `?filter=${
                    dashboard?.filterId
                  }&hash=${encodeURIComponent(encryptedApiKey)}`,
                })
              }
              key={dashboard.id}
            >
              <div className={classes.title}>
                {dashboard.title +
                  " (Filter : " +
                  filterIdNameMap[dashboard?.filterId || 0] +
                  ") " +
                  " (Parameters : " +
                  JSON.stringify(dashboard.parameters) +
                  ")"}
              </div>
              {dashboard.updatedAt && (
                <div className={classes.time}>
                  {format(parseISO(dashboard.updatedAt), "d MMM yyyy")}
                </div>
              )}
              <div className={classes.right}>
                <IconButton
                  className={classes.icon}
                  onClick={(e) => openCreateDialog(e, dashboard)}
                >
                  <EditRounded color="primary" />
                </IconButton>
                <IconButton
                  className={classes.icon}
                  onClick={(e) => deleteDashboard(e, dashboard.id)}
                >
                  <DeleteRounded color="error" />
                </IconButton>
              </div>
            </div>
          ))}
      </div>

      <CreateDialog
        selectedDashboard={selectedDashboard}
        createDialog={createDialog}
        setCreateDialog={setCreateDialog}
        email={email}
        type="admin"
      />
    </div>
  );
}
