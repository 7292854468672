import { Dialog } from "@mui/material";
import classes from "./Stripe.module.scss";
import * as React from "react";
import { v4 } from "uuid";
import { STRIPE_PRICING_TABLE_ID, STRIPE_PUBLISHABLE_KEY } from "./constant";

// If using TypeScript, add the following snippet to your file as well.
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

type StripeProps = {
  openStripe: boolean;
  email: string;
  setStripe: (open: boolean) => void;
};

export default function Stripe({ openStripe, setStripe, email }: StripeProps) {
  // Paste the stripe-pricing-table snippet in your React component
  return (
    <Dialog
      open={openStripe}
      onClose={() => setStripe(false)}
      className={classes.dialog}
      classes={{ paper: classes.paper }}
    >
      <stripe-pricing-table
        pricing-table-id={STRIPE_PRICING_TABLE_ID}
        client-reference-id={email}
        customer-email={email}
        publishable-key={STRIPE_PUBLISHABLE_KEY}
      ></stripe-pricing-table>
    </Dialog>
  );
}
