import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQuery } from "react-query";
import { BE_SERVER } from "../utils/constant";
import { queryClient } from "../..";
import { useSnackbar } from "notistack";

export const useUser = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["user", email], async () => {
    const token = await getAccessTokenSilently();
    const res = await fetch(`${BE_SERVER}/api/user?email=${email}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error("Error while fetching user info");
    }

    return res.json();
  });
};

export const useCreateUser = () => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation(async (user: any) => {
    const token = await getAccessTokenSilently();
    const res = await fetch(`${BE_SERVER}/api/user?email=${user?.email}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });

    if (!res.ok) {
      throw new Error("Error while adding user");
    }

    return res.json();
  });
};

export const useUpdateUser = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation(
    async (user: any) => {
      const token = await getAccessTokenSilently();
      const res = await fetch(`${BE_SERVER}/api/user?email=${email}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      if (!res.ok) {
        throw new Error("Error while updating user");
      }

      return res.text();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user", email]);
      },
    }
  );
};

export const useUserCustomers = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["userCustomers", email], async () => {
    const token = await getAccessTokenSilently();
    const res = await fetch(`${BE_SERVER}/api/user/customers?email=${email}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error("Error while fetching user info");
    }

    return res.json();
  });
};

export const useAddUserCustomers = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (customer: any) => {
      const token = await getAccessTokenSilently();
      const res = await fetch(
        `${BE_SERVER}/api/user/customers?email=${email}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(customer),
        }
      );

      if (!res.ok) {
        throw new Error("Error while creating customer");
      }

      return res.text();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["userCustomers", email]);
      },
      onError: () => {
        enqueueSnackbar("Error while updating", { variant: "error" });
      },
    }
  );
};

export const usePatchUserCustomers = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (customer: any) => {
      const token = await getAccessTokenSilently();
      const res = await fetch(
        `${BE_SERVER}/api/user/customers?email=${email}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(customer),
        }
      );

      if (!res.ok) {
        throw new Error("Error while updating customer info");
      }

      return res.text();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["userCustomers", email]);
        enqueueSnackbar("Updated", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Error while updating", { variant: "error" });
      },
    }
  );
};

export const useDeleteUserCustomers = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (customer: any) => {
      const token = await getAccessTokenSilently();
      const res = await fetch(
        `${BE_SERVER}/api/user/customers/${customer?.id}?email=${email}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!res.ok) {
        throw new Error("Error while updating customer info");
      }

      return res.text();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["userCustomers", email]);
        enqueueSnackbar("Deleted", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Error while deleting", { variant: "error" });
      },
    }
  );
};
