import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import classes from "./Embed.module.scss";
import { useSnackbar } from "notistack";

type EmbedProps = {
  embedLink: string;
  iframeText: string;
  embedDialog: boolean;
  setEmbedDialog: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function EmbedDialog({
  embedLink,
  iframeText,
  embedDialog,
  setEmbedDialog,
}: EmbedProps) {
  const { enqueueSnackbar } = useSnackbar();

  const onCopyClick = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy);
    enqueueSnackbar("Copied to clipboard", { variant: "success" });
    setEmbedDialog(false);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={embedDialog}
        onClose={() => setEmbedDialog(false)}
        className={classes.dialog}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle>
          <div className={classes.title}>Select Embed Type</div>
        </DialogTitle>
        <DialogContent>
          <div className={classes.content}>
            <div className={classes.tile}>
              <div className={classes.heading}>Embeddable link</div>
              <div className={classes.info}>
                Paste this link directly into supporting applications, such as
                Notion or share directly with your team.
              </div>
              <Button
                variant="contained"
                onClick={() => onCopyClick(embedLink)}
                className={classes.button}
              >
                Copy link
              </Button>
            </div>
            <div className={classes.tile}>
              <div className={classes.heading}>Embed code</div>
              <div className={classes.info}>
                Paste this HTML iframe into a webpage or supporting application,
                such as Confluence.
              </div>
              <Button
                variant="contained"
                onClick={() => onCopyClick(iframeText)}
                className={classes.button}
              >
                Copy code
              </Button>
            </div>
            <div className={classes.tile}>
              <div className={classes.heading}>NPM package</div>
              <div className={classes.info}>
                Use our robust npm package with advanced features and embed in
                your application using javascript, react, nextjs.
              </div>
              <Button
                variant="contained"
                href="https://www.npmjs.com/package/saydata"
                target="_blank"
                rel="noreferrer"
                className={classes.button}
              >
                Get package
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
