import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Pagination,
} from "@mui/material";
import classes from "./Instructions.module.scss";
import { CloseRounded } from "@mui/icons-material";
import { useEffect, useState } from "react";
import db from "../../icons/mysql.svg";
import db2 from "../../icons/postgres.svg";
import csv from "../../icons/csv.svg";
import sheet from "../../icons/sheets.svg";
import { useNavigate } from "react-router-dom";
import useQueryParameter from "./util";

type InstructionsProp = {
  openInstructions: boolean;
  page: number;
  setInstructions: React.Dispatch<React.SetStateAction<boolean>>;
  setExplore: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function Instructions({
  openInstructions,
  page,
  setInstructions,
  setExplore,
}: InstructionsProp) {
  const email = useQueryParameter("email");
  const [activePage, setActivePage] = useState(page);
  const navigate = useNavigate();

  const handleClick = (key: string) => {
    if (key === "openSample") {
      setExplore(true);
    } else {
      navigate(`/connections?email=${email}`, {
        state: { [key]: true },
        replace: false,
      });
    }
    setInstructions(false);
  };

  const onClose = () => {
    setActivePage(1);
    setInstructions(false);
  };
  return (
    <Dialog
      className={classes.root}
      classes={{ paper: classes.paper }}
      open={openInstructions}
      onClose={onClose}
    >
      <DialogTitle>
        <IconButton
          style={{ position: "absolute", top: 4, right: 4 }}
          onClick={onClose}
        >
          <CloseRounded style={{ fontSize: "16px" }} />
        </IconButton>
      </DialogTitle>
      {activePage === 2 ? (
        <DialogContent className={classes.content}>
          <div className={classes.text}>
            <div>
              Ask a Plain <b>English</b> Question such as
            </div>
            <div>
              <b>
                "Who were the top 10 sales people by order volume in May 2022?"
              </b>
            </div>
            <div>
              The question should be as <b>specific</b> as possible:
            </div>
          </div>
          <ol className={classes.list}>
            <li>
              <div>"Best" is ambiguous.</div>
              <div>Who were the best sales people?</div>
              <div>
                Who were the <b>top 10</b> sales people?
              </div>
            </li>
            <li>
              <div>Top 10 by what metric?</div>
              <div>Who were the top 10 sales people?</div>
              <div>
                Who were the top 10 sales people <b>by order volume</b> ?
              </div>
            </li>
            <li>
              <div>When? Of all time? In a specific month?</div>
              <div>Who were the top 10 sales people by order volume?</div>
              <div>
                Who were the top 10 sales people by order volume in{" "}
                <b>May 2022</b> ?
              </div>
            </li>
          </ol>
        </DialogContent>
      ) : (
        <DialogContent className={classes.content}>
          <div className={classes.text}>
            In order to ask questions, you must have a data source selected.
          </div>
          <div className={classes.text}>
            Go to <b>Connections</b> tab to connect you database
          </div>
          <div className={classes.text}>
            You can also get started with a Sample E-Commerce Database
          </div>
          <div className={classes.text}>
            You can also upload any csv/excel file or connect any google sheet.
          </div>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => handleClick("openSample")}
            startIcon={<img src={db} height={18} alt="Sample" />}
          >
            Explore Dataset
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => handleClick("openDb")}
            startIcon={<img src={db2} height={18} alt="DB" />}
          >
            Connect your Database
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => handleClick("openUpload")}
            startIcon={<img height={18} src={csv} alt="CSV" />}
          >
            Upload CSV/Excel file
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => handleClick("openGSheet")}
            startIcon={<img height={18} src={sheet} alt="GSheet" />}
          >
            Connect Google Sheet
          </Button>
        </DialogContent>
      )}
      <div className={classes.footer}>
        <Pagination
          count={2}
          sx={{ color: "#4B0082" }}
          onChange={(e, p) => setActivePage(p)}
        />
      </div>
    </Dialog>
  );
}
