import {
  AddRounded,
  DeleteRounded,
  EditRounded,
  KeyboardDoubleArrowLeftRounded,
  SearchRounded,
} from "@mui/icons-material";
import { Button, Drawer, IconButton, Popover, TextField } from "@mui/material";
import classes from "./QueriesDrawer.module.scss";
import { useParams } from "react-router-dom";
import { Query } from "../../models/Query";
import { DATABASES } from "../../utils/constant";
import { useEffect, useState } from "react";
import QueryEditor from "./QueryEditor";
import { DarkTooltip } from "../../utils/util";
import jsIcon from "../../../icons/js.svg";
import { useDeleteQuery } from "../../api/query";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  queries: Query[];
  setQuery: React.Dispatch<React.SetStateAction<Query>>;
  setQueryEditor: React.Dispatch<React.SetStateAction<boolean>>;
  addNewQuery: (queryType?: string) => void;
  deleteQuery: (queryId: string) => void;
};

export default function QueriesDrawer({
  open,
  setOpen,
  queries,
  setQuery,
  setQueryEditor,
  addNewQuery,
  deleteQuery,
}: Props) {
  const [searchText, setSearchText] = useState("");
  const [filteredQueries, setFilteredQueries] = useState(queries);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);

  useEffect(() => {
    if (searchText) {
      setFilteredQueries(
        queries.filter((query) =>
          query.displayName.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setFilteredQueries(queries);
    }
  }, [queries, searchText]);
  return (
    <Drawer
      open={open}
      variant="persistent"
      className={classes.queryDrawer}
      classes={{ paper: classes.paper }}
    >
      <div className={classes.parent}>
        <div className={classes.top}>
          <div className={classes.text}>Code</div>
          <IconButton
            onClick={() => setOpen(false)}
            className={classes.iconButton}
          >
            <KeyboardDoubleArrowLeftRounded />
          </IconButton>
        </div>
        <div className={classes.options}>
          <DarkTooltip title="Search" placement="top">
            <TextField
              id="query-search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  input: {
                    padding: "4px 0px",
                  },
                  fieldset: {
                    borderWidth: 0,
                  },
                  "&:hover fieldset": {
                    borderColor: "#666",
                    borderWidth: 0,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#666",
                    borderWidth: 0,
                  },
                },
              }}
              placeholder={"Showing " + queries?.length}
              size="small"
              InputProps={{
                endAdornment: <SearchRounded style={{ fontSize: 12 }} />,
                sx: {
                  fontSize: 12,
                  fontWeight: 500,
                },
              }}
            />
          </DarkTooltip>
          <DarkTooltip title="Add" placement="top">
            <IconButton onClick={(e) => handleClick(e)}>
              <AddRounded />
            </IconButton>
          </DarkTooltip>
        </div>
        <div className={classes.queries}>
          {filteredQueries.map((query) => (
            <div className={classes.query} key={query.id}>
              <Button
                className={classes.queryButton}
                startIcon={
                  <img
                    src={DATABASES[query.type]?.icon}
                    alt={query.type}
                    width={16}
                    height={16}
                  />
                }
                sx={{
                  ".MuiButton-endIcon": {
                    marginLeft: "auto",
                  },
                }}
                endIcon={
                  <DarkTooltip title="Delete" placement="top">
                    <IconButton
                      color="error"
                      className={classes.deleteQuery}
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteQuery(query.id);
                      }}
                    >
                      <DeleteRounded />
                    </IconButton>
                  </DarkTooltip>
                }
                onClick={() => {
                  setQuery(query);
                  setQueryEditor(true);
                }}
              >
                {query.displayName}
              </Button>
            </div>
          ))}
        </div>
      </div>
      <Popover
        id="query-select"
        open={openPopover}
        anchorEl={anchorEl}
        className={classes.popover}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className={classes.container}>
          <Button
            className={classes.queryButton}
            onClick={() => {
              addNewQuery();
              handleClose();
            }}
          >
            Resource query
          </Button>
          <Button
            className={classes.queryButton}
            onClick={() => {
              addNewQuery("js");
              handleClose();
            }}
          >
            Javascript query
          </Button>
        </div>
      </Popover>
    </Drawer>
  );
}
