import {
  CloseRounded,
  RemoveCircle,
  RemoveCircleOutlineRounded,
} from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import classes from "./GlossaryDialog.module.scss";
import { useEffect, useState } from "react";
import { useConnectionById, useModifyConnection } from "../api/connections";
import { DbConnection } from "../models/DbConnection";
import clsx from "clsx";

type GlossaryDialogProps = {
  glossaryDialog: boolean;
  setGlossaryDialog: React.Dispatch<React.SetStateAction<boolean>>;
  activeConnectionId: string;
  email: string;
};
export default function GlossaryDialog({
  glossaryDialog,
  activeConnectionId,
  email,
  setGlossaryDialog,
}: GlossaryDialogProps) {
  const { data: connectionData, isLoading } = useConnectionById(
    email,
    activeConnectionId
  );
  const { mutate: modifyConnection, isLoading: updating } = useModifyConnection(
    email,
    activeConnectionId
  );
  const [glossary, setGlossary] = useState([{ key: "", value: "", error: "" }]);

  useEffect(() => {
    if (connectionData?.data?.glossary) {
      setGlossary(connectionData.data.glossary);
    } else {
      setGlossary([{ key: "", value: "", error: "" }]);
    }
  }, [connectionData, activeConnectionId]);

  const handleValidation = (value: string) => {
    if (value.match(/^[a-z0-9_]+$/)) {
      return "";
    } else {
      return "Only characters, numbers and underscores are allowed";
    }
  };

  const updateGlossary = (key: string, index: number, value: string) => {
    const newGlossary = [...glossary];
    newGlossary[index][key as keyof (typeof newGlossary)[0]] = value;
    if (key === "key") {
      const error = handleValidation(value);
      newGlossary[index]["error"] = error;
    }
    setGlossary(newGlossary);
  };

  const deleteGlossaryItem = (index: number) => {
    const newGlossary = [...glossary];
    newGlossary.splice(index, 1);
    setGlossary(newGlossary);
  };

  const addGlossaryItem = () => {
    const newGlossary = [...glossary];
    newGlossary.push({ key: "", value: "", error: "" });
    setGlossary(newGlossary);
  };

  const onSave = () => {
    const request = {
      glossary: glossary,
    };
    modifyConnection(request as any, {
      onSuccess: () => {
        setGlossaryDialog(false);
      },
    });
  };

  return (
    <Dialog
      className={classes.dialogDetail}
      open={glossaryDialog}
      classes={{ paper: classes.paper }}
      onClose={() => setGlossaryDialog(false)}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={updating}
      >
        <CircularProgress sx={{ color: "#4B0082" }} />
      </Backdrop>
      <DialogTitle>
        <div className={classes.title}>Glossary</div>
        <div className={classes.subTitle}>
          Finetune results and queries with additional business context and
          definations
        </div>
        <IconButton
          style={{ position: "absolute", top: 4, right: 4 }}
          onClick={() => setGlossaryDialog(false)}
        >
          <CloseRounded style={{ fontSize: "16px" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {glossary?.map((gloss: any, index: number) => (
          <div className={classes.text}>
            <Tooltip title={gloss?.error} placement="top">
              <TextField
                placeholder="power_users"
                value={gloss?.key}
                error={!!gloss?.error}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: !!gloss?.error ? "red" : "#4B0082",
                      borderWidth: 1,
                    },
                  },
                }}
                onChange={(e) => updateGlossary("key", index, e.target.value)}
              />
            </Tooltip>
            <TextField
              placeholder="users who have more than 5 orders"
              value={gloss?.value}
              size="small"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#4B0082",
                    borderWidth: 1,
                  },
                },
              }}
              onChange={(e) => updateGlossary("value", index, e.target.value)}
            />
            <IconButton
              onClick={() => deleteGlossaryItem(index)}
              sx={{ padding: 0 }}
            >
              <RemoveCircleOutlineRounded sx={{ fontSize: 18, color: "red" }} />
            </IconButton>
          </div>
        ))}
        <div className={classes.footer}>
          <Button
            variant="outlined"
            size="small"
            onClick={addGlossaryItem}
            className={classes.addButton}
          >
            Add Item
          </Button>
          <Button
            variant="contained"
            className={
              glossary?.findIndex((gloss) => !!gloss?.error) === -1 &&
              glossary?.length > 0
                ? classes.button
                : clsx(classes.button, classes.disabled)
            }
            size="small"
            disabled={
              glossary?.findIndex((gloss) => !!gloss?.error) === -1 &&
              glossary?.length > 0
                ? false
                : true
            }
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
