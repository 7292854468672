import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  Label,
  Tooltip,
  YAxis,
  Line,
  CartesianGrid,
} from "recharts";
import classes from "./Line.module.scss";
import { dataFormater } from "../utils/util";
import React, { memo } from "react";

type LineProps = {
  chartOptions: any;
  rows: any;
};

function CustomLine({ chartOptions, rows }: LineProps) {
  return (
    <ResponsiveContainer width={"100%"} height={"80%"} minHeight={"inherit"}>
      <LineChart
        data={rows}
        className={classes.root}
        margin={{ top: 30, right: 30, left: -20, bottom: -10 }}
      >
        <CartesianGrid vertical={false} stroke="#E5E5E5" />
        <XAxis
          dataKey={chartOptions?.xCol}
          stroke="#E5E5E5"
          tick={{ fill: "#666" }}
        >
        </XAxis>
        <Tooltip />
        {chartOptions?.yCols?.map((yColObj: any, index: number) => (
          <React.Fragment key={index}>
            <YAxis
              orientation={index % 2 === 0 ? "left" : "right"}
              yAxisId={index}
              axisLine={false}
              stroke="#E5E5E5"
              tick={{ fill: "#666" }}
              tickFormatter={(value) => dataFormater(value)}
              domain={yColObj?.max ? [0, yColObj?.max] : ["auto", "auto"]}
            ></YAxis>
            <Line
              type="linear"
              key={index}
              dataKey={yColObj?.yCol}
              stroke={yColObj?.color}
              strokeWidth={2}
              yAxisId={index}
              dot={false}
            />
          </React.Fragment>
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}

export default memo(CustomLine);
