import { Checkbox, TextField } from "@mui/material";
import { WorkspaceItem } from "../../models/Dashboard";
import classes from "./ItemEditor.module.scss";
import { SketchPicker } from "react-color";

type Props = {
  selectedItem: WorkspaceItem;
  updateItem: (id: string, key: string, value: any) => void;
  readonly?: boolean;
};

export default function Tile({
  selectedItem,
  updateItem,
  readonly = false,
}: Props) {
  return (
    <div className={classes.panel}>
      {!!!readonly && (
        <div className={classes.title}>
          <div className={classes.text}>Value</div>
          <TextField
            size="small"
            sx={{ width: "80%", marginLeft: "auto" }}
            value={selectedItem?.metadata?.value || ""}
            onChange={(e) =>
              updateItem(selectedItem.id, "metadata.value", e.target.value)
            }
            InputProps={{
              style: {
                height: 24,
                fontSize: 12,
                color: "#000",
              },
            }}
          />
        </div>
      )}
      <div className={classes.title}>
        <div className={classes.text}>Value size</div>
        <TextField
          size="small"
          type="number"
          sx={{ width: "80%", marginLeft: "auto" }}
          value={selectedItem?.metadata?.tile?.fontSize || ""}
          onChange={(e) =>
            updateItem(
              selectedItem.id,
              "metadata.tile.fontSize",
              e.target.value
            )
          }
          InputProps={{
            style: {
              height: 24,
              fontSize: 12,
              color: "#000",
            },
          }}
        />
      </div>
      <div className={classes.title}>
        <div className={classes.text}>Formatting</div>
      </div>
      <div className={classes.title}>
        <div className={classes.text}>Prefix</div>
        <TextField
          size="small"
          sx={{ width: "80%", marginLeft: "auto" }}
          value={selectedItem?.metadata?.prefix || ""}
          placeholder="$,€,¥,₹,..."
          onChange={(e) =>
            updateItem(selectedItem.id, "metadata.prefix", e.target.value)
          }
          InputProps={{
            style: {
              height: 24,
              fontSize: 12,
              color: "#000",
            },
          }}
        />
      </div>
      <div className={classes.title}>
        <div className={classes.text}>Suffix</div>
        <TextField
          size="small"
          sx={{ width: "80%", marginLeft: "auto" }}
          value={selectedItem?.metadata?.suffix || ""}
          placeholder="%,v/v,w/w,..."
          onChange={(e) =>
            updateItem(selectedItem.id, "metadata.suffix", e.target.value)
          }
          InputProps={{
            style: {
              height: 24,
              fontSize: 12,
              color: "#000",
            },
          }}
        />
      </div>
      <div className={classes.title}>
        <div className={classes.text}>Show thousands separator</div>
        <Checkbox
          checked={selectedItem?.metadata?.thousandSeparator}
          size="small"
          sx={{
            marginLeft: "auto",
          }}
          onChange={(e) =>
            updateItem(
              selectedItem.id,
              "metadata.thousandSeparator",
              e.target.checked
            )
          }
        />
        {/* <TextField
          size="small"
          sx={{ width: "80%", marginLeft: "auto" }}
          value={selectedItem?.metadata?.value || ""}
          onChange={(e) =>
            updateItem(selectedItem.id, "metadata.value", e.target.value)
          }
          InputProps={{
            style: {
              height: 24,
              fontSize: 12,
              color: "#000",
            },
          }}
        /> */}
      </div>
    </div>
  );
}
