import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
} from "recharts";
import classes from "./Pie.module.scss";
import { generateShades } from "../utils/util";
import { memo } from "react";

type ChartProps = {
  chartOptions: any;
  rows: any;
};

function CustomPie({ chartOptions, rows }: ChartProps) {
  const shades = generateShades(
    chartOptions?.yCols?.[0]?.color,
    chartOptions?.yCols?.[0]?.lightColor,
    rows?.length
  );
  return (
    <ResponsiveContainer width={"100%"} height={"80%"} minHeight={"inherit"}>
      <PieChart className={classes.root}>
        <Legend
          wrapperStyle={{
            position: "static",
            top: "0px",
          }}
          verticalAlign="middle"
          content={
            <CustomLegend
              rows={rows}
              shades={shades}
              yCol={chartOptions?.yCols?.[0]?.yCol}
              xCol={chartOptions?.xCol}
            />
          }
        />
        {chartOptions?.yCols?.map((yColObj: any, index: number) => (
          <Pie
            data={rows}
            key={index}
            dataKey={yColObj?.yCol}
            nameKey={chartOptions?.xCol}
            outerRadius={"100%"}
            innerRadius={"75%"}
            paddingAngle={1}
            fill={yColObj?.color}
            // cx={"-150"}
            // cy={"50%"}
          >
            {rows?.map((entry: any, index: number) => (
              <Cell key={`cell-${index}`} fill={shades[index]} />
            ))}
          </Pie>
        ))}
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
}

const CustomLegend = ({ rows, shades, yCol, xCol }: any) => {
  const totalValue = rows.reduce(
    (total: any, row: any) => total + row?.[yCol],
    0
  );
  return (
    <div className={classes.customLegend}>
      {rows?.map((row: any, index: number) => (
        <div key={index} className={classes.legendRow}>
          <div
            className={classes.fill}
            style={{ backgroundColor: shades[index] }}
          ></div>
          <div style={{ minWidth: 40 }}>
            {((row?.[yCol] / totalValue) * 100)?.toFixed(2)}%
          </div>
          <div>{row?.[xCol]}</div>
        </div>
      ))}
    </div>
  );
};

export default memo(CustomPie);
