import classes from "./ChatCanva.module.scss";
import Lottie from "lottie-react";
import loadingJson from "../../icons/loading.json";
import React, { useCallback, useEffect, useState } from "react";
import ReactFlow, {
  Background,
  Controls,
  MarkerType,
  MiniMap,
  ReactFlowProvider,
  addEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStoreApi,
} from "reactflow";

import "reactflow/dist/style.css";
import WelcomeNode from "./WelcomeNode";
import ChatNode from "./ChatNode";
import {
  useAddCanva,
  useAddCanvaChat,
  useAddChatGraph,
  useAddChatSummary,
  useAddFollowUpQuestions,
  useCanvaChatHistory,
  useCanvaDetails,
  usePatchCanva,
  usePatchCanvaChat,
} from "../api/canva";
import useQueryParameter from "../utils/util";
import { Canva, CanvaChat, ClarifyingQuestion } from "../models/Canva";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";
import { useAddChat } from "../api/chat";
import { useLocalStorage } from "usehooks-ts";
import InnerCanva from "./InnerCanva";
import DataNode from "./DataNode";
import LoadingNode from "./LoadingNode";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Popover,
} from "@mui/material";
import { AddBoxOutlined, CloseRounded } from "@mui/icons-material";
import { DbConnection } from "../models/DbConnection";
import { useConnection } from "../api/connections";
import { useDashboards, usePatchDashboardEmail } from "../api/dashboard";
import { queryClient } from "../..";
import { useUser } from "../api/user";
import Stripe from "../utils/Stripe";
import CanvaHistDrawer from "./CanvaHistDrawer";
import ChartEditor from "../admin/editor/ChartEditor";
import { WorkspaceItem } from "../models/Dashboard";
import Graphs from "../charts/Graphs";

const nodeTypes = {
  welcome: WelcomeNode,
  chat: ChatNode,
  data: DataNode,
  loading: LoadingNode,
};
export default function ChatCanva() {
  const { canvaId = "" } = useParams();
  const email = useQueryParameter("email");
  const { data: canva, isLoading } = useCanvaDetails(canvaId, email);
  const [activeConnection, setActiveConnection] = useLocalStorage(
    "saydata-active-connection",
    ""
  );
  const [currentConnection, setCurrentConnection] = useState<string>();
  const [focusNodeId, setFocusNodeId] = useState("");
  const { mutate: patchCanva, isLoading: patchingCanva } = usePatchCanva(
    canvaId,
    email
  );
  const { data: connections } = useConnection(email);
  const [selectedCanva, setSelectedCanva] = useState<CanvaChat>();
  const { data: dashboards } = useDashboards(email);
  const { data: serverUser, isLoading: loadingUser } = useUser(email);
  const { mutate: createCanva, isLoading: creatingCanvaChat } =
    useAddCanva(email);
  const { mutate: addCanvaChat, isLoading: addingCanvaChat } = useAddCanvaChat(
    canvaId,
    email
  );
  const { mutate: patchCanvaChat, isLoading: patchingCanvaChat } =
    usePatchCanvaChat(canvaId, email);
  const { data: canvaHistory, isLoading: loadingCanvaHistory } =
    useCanvaChatHistory(email);
  const { mutateAsync: addChatGraph, isLoading: addingGraph } = useAddChatGraph(
    canvaId,
    email
  );
  const { mutateAsync: addChatSummary, isLoading: addingSummary } =
    useAddChatSummary(canvaId, email);
  const { mutateAsync: addFollowUp, isLoading: addingFollowUp } =
    useAddFollowUpQuestions(canvaId, email);
  const { mutate: updateDashboard, isLoading: updatingDashboard } =
    usePatchDashboardEmail(email);
  const [canvaDetails, setCanvaDetails] = useState<Canva>();
  const [selectedDashboard, setSelectedDashboard] = useState("");
  const [selectedBlockType, setSelectedBlockType] = useState("");
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [sampleQuestions, setSampleQuestions] = useState([]);
  const [openCanvaHistDrawer, setOpenCanvaHistDrawer] = useState(false);
  const reactFlowInstance = useReactFlow();
  const onConnect = useCallback(
    (params: any) => setEdges((eds) => addEdge(params, eds)),
    []
  );
  const proOptions = { hideAttribution: true };
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchorEl);
  const [showChartEditor, setShowChartEditor] = useState(false);
  const [selectedItem, setSelectedItem] = useState<WorkspaceItem>({
    id: "",
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    type: "",
  });

  useEffect(() => {
    if (canva?.data?.connectionId) {
      setCurrentConnection(canva?.data?.connectionId);
    } else {
      setCurrentConnection(activeConnection);
    }
  }, [canva, activeConnection]);

  const [upgradeBanner, setUpgradeBanner] = useState(false);

  const updateNodeText = (id: string, text: string) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          node.data = {
            ...node.data,
            currentText: text,
          };
        }
        return node;
      })
    );
  };

  const updateNodes = (updateFunc: any) => {
    setNodes((nds) => {
      return nds.map((node) => updateFunc(node));
    });
  };

  const focusNode = (id: string) => {
    if (focusNodeId !== id) {
      setFocusNodeId(id);
    }
  };

  const updateSqlShow = (id: string, showSql: boolean) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          node.data = {
            ...node.data,
            showSql: showSql,
          };
        }
        return node;
      })
    );
  };

  const onChoiceSelect = (
    id: string,
    primaryIndex: number,
    secondaryIndex: number
  ) => {
    setNodes((nds) => {
      return nds.map((node) => {
        if (node.id === id) {
          const message = node?.data?.value;
          message.clarifyingQuestions[primaryIndex].selectedIndex =
            secondaryIndex;
          node.data = {
            ...node.data,
            value: message,
          };
        }
        return node;
      });
    });
  };

  const onFeedbackSave = (id: string, feedback: string) => {
    const selectedCanvaChat = canvaDetails?.chats?.find(
      (c) => c.messageId === id
    );
    if (selectedCanvaChat) {
      patchCanvaChat({
        ...selectedCanvaChat,
        feedback: feedback,
      });
    }
  };

  const updateTextBox = (id: string, showTextField: boolean) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          node.data = {
            ...node.data,
            showTextField: showTextField,
          };
        }
        return node;
      })
    );
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    clickedCanva: any,
    type: string
  ) => {
    if (serverUser?.data?.plan === "pro") {
      setAnchorEl(event.currentTarget);
      setSelectedCanva(clickedCanva);
      setSelectedBlockType(type);
    } else {
      setUpgradeBanner(true);
    }
  };

  const handleGraphEditClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    clickedCanva: CanvaChat
  ) => {
    const newItem: WorkspaceItem = {
      id: clickedCanva.messageId || "",
      dataQueryId: clickedCanva.messageId,
      metadata: { options: clickedCanva.graph },
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      type: "graph",
    };
    setSelectedItem(newItem);
    setSelectedCanva(clickedCanva);
    setShowChartEditor(true);
  };

  const updateItem = (id: string, key: string, value: any) => {
    key = key.replace("metadata.options.", "graph.");
    const keys = key.split(".");
    let newItem = { ...selectedCanva };
    let currentItem = newItem;

    for (let i = 0; i < keys.length - 1; i++) {
      const currentKey = keys[i];
      if (!(currentKey in currentItem)) {
        (currentItem as any)[currentKey as keyof any] = {};
      }
      currentItem = (currentItem as any)[currentKey as keyof any];
    }

    const finalKey = keys[keys.length - 1];
    (currentItem as any)[finalKey as keyof any] = value;

    setSelectedCanva(newItem);
  };

  const getDataFromId = (id: string | undefined) => {
    return canvaDetails?.chats?.find(
      (canva: CanvaChat) => canva.messageId === id
    )?.rows;
  };

  const onSaveChartChanges = () => {
    patchCanvaChat(selectedCanva as any, {
      onSuccess: () => {
        setShowChartEditor(false);
      },
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addChartToDashboard = (dashboardSelected: string) => {
    setSelectedDashboard(dashboardSelected);
    const connId = currentConnection;
    const request = {
      id: dashboardSelected,
      obj: {
        chatItem: {
          blockId: selectedCanva?.messageId,
          rows: selectedCanva?.rows,
          graph: selectedCanva?.graph,
          sql:
            selectedCanva?.type === "python"
              ? selectedCanva?.python
              : selectedCanva?.sql,
          title: selectedCanva?.title,
          value: selectedCanva?.value,
        },
        type: selectedBlockType,
        connectionId: connId,
        connectionType: connections?.data?.find(
          (conn: DbConnection) => conn.id === connId
        )?.type,
        x: 0,
        y: 0,
        width: selectedBlockType === "tile" ? 3 : 6,
        height: selectedBlockType === "tile" ? 3 : 6,
      },
    };
    updateDashboard(request as any, {
      onSuccess: () => {
        queryClient.invalidateQueries(["dashboard", dashboardSelected]);
        queryClient.invalidateQueries({
          queryKey: ["dashboardQueries", dashboardSelected],
        });
        handleClose();
      },
    });
  };

  useEffect(() => {
    setNodes(initialNodes);
    // setEdges(initialEdges);
  }, [canvaId]);

  useEffect(() => {
    if (connections?.data && currentConnection) {
      const conn = connections.data.find(
        (conn: DbConnection) => conn.id === currentConnection
      );
      if (conn) {
        setSampleQuestions(conn.sampleQuestions);
      }
    }
  }, [connections, currentConnection]);

  useEffect(() => {
    if (canva?.data && canva?.data?.chats?.length > 0 && serverUser?.data) {
      setCanvaDetails(canva.data);
      var newNodes: any[] = [];
      canva.data.chats.forEach((chat: CanvaChat, index: number) => {
        newNodes.push({
          id: chat.messageId,
          position: chat.position,
          data: {
            value: chat,
            currentText: "",
            updateNodeText: updateNodeText,
            handleEnterEvent: handleEnterEvent,
            updateSqlShow: updateSqlShow,
            onChoiceSelect: onChoiceSelect,
            onSubmit: onSubmit,
            onFeedbackSave: onFeedbackSave,
            focusNode: focusNode,
            handleClick: handleClick,
            handleGraphEditClick: handleGraphEditClick,
            showSql: false,
            showTextField: index === canva.data.chats.length - 1 ? true : false,
          },
          type: chat?.type === "text" ? "chat" : "data",
        });
      });
      setNodes(newNodes);
      const newEdges: any[] = [];
      canva?.data?.edges?.forEach((edge: any) => {
        newEdges.push({
          id: edge?.id,
          source: edge?.source,
          target: edge?.target,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            color: "#4B0082",
          },
          style: {
            stroke: "#4B0082",
          },
        });
      });
      setEdges(newEdges);
      setFocusNodeId(newNodes[newNodes.length - 1]?.id);
    } else {
      if (isLoading) {
        return;
      }
      const request = {
        id: canvaId,
        email: email,
        chatId: v4(),
        connectionId: currentConnection,
      };
      createCanva(request);
      setCanvaDetails(request);
      setNodes((nds) => nds.concat(initialNodes));
      // setEdges(initialEdges);
    }
  }, [canva, serverUser]);

  const handleEnterEvent = (e: any, nodeId: string) => {
    if (e.keyCode === 13) {
      onSubmit(nodeId);
    }
  };

  const postProcessing = (canvaChat: CanvaChat) => {
    const followupRequest = {
      question: canvaChat.question,
      connectionId: currentConnection,
    };
    addChatGraph(canvaChat).then((response: any) => {
      canvaChat.graph = response?.data?.graph;
      canvaChat.title = response?.data?.title;
      canvaChat.value = response?.data?.value;
      updateNodes((node: any) => {
        if (node.id === canvaChat.messageId) {
          node.data = {
            ...node.data,
            value: {
              ...node.data.value,
              graph: response?.data?.graph,
              title: response?.data?.title,
              value: response?.data?.value,
            },
            loadingGraph: false,
          };
        }
        return node;
      });
    });
    addChatSummary(canvaChat).then((response: any) => {
      canvaChat.summary = response?.data?.summary;
      updateNodes((node: any) => {
        if (node.id === canvaChat.messageId) {
          node.data = {
            ...node.data,
            value: {
              ...node.data.value,
              summary: response?.data?.summary,
            },
            loadingSummary: false,
          };
        }
        return node;
      });
    });
    addFollowUp(followupRequest).then((response: any) => {
      canvaChat.followUpQuestions = response?.data;
      updateNodes((node: any) => {
        if (node.id === canvaChat.messageId) {
          node.data = {
            ...node.data,
            value: {
              ...node.data.value,
              followUpQuestions: response?.data,
            },
            loadingFollowUp: false,
          };
        }
        return node;
      });
      patchCanvaChat(canvaChat);
      const newCanvaDetails = { ...canvaDetails };
      newCanvaDetails.chats = [...(newCanvaDetails.chats || []), canvaChat];
      setCanvaDetails(newCanvaDetails);
    });
  };

  const onSubmit = (nodeId: string, text?: string) => {
    const flowNodes = reactFlowInstance.getNodes();
    const selectedNode = flowNodes.find((node) => node.id === nodeId);
    // const node = flowNodes[flowNodes.length - 1];
    var pos = {
      x: 0,
      y: 0,
    };
    if (
      selectedNode &&
      selectedNode.position &&
      selectedNode.height &&
      nodeId !== "welcomeNode"
    ) {
      pos.y = selectedNode.position.y + selectedNode.height + 50;
      updateTextBox(nodeId, false);
    }
    const request = {
      chatId: canvaDetails?.chatId,
      question: text ? text : selectedNode?.data?.currentText,
      canvaChat: {
        position: pos,
      },
    };
    if (selectedNode?.data?.value?.clarifyingQuestions) {
      selectedNode.data.value.clarifyingQuestions.forEach(
        (clarifyingQuestion: ClarifyingQuestion) => {
          const selectedChoice = clarifyingQuestion.choices.find(
            (choice: string, index: number) =>
              clarifyingQuestion.selectedIndex === index
          );
          if (selectedChoice) {
            request.question += ` ${selectedChoice}`;
          }
        }
      );
    }
    const loadingNode = {
      id: "loadingNode",
      position: pos,
      data: {
        value: "",
        currentText: text ? text : selectedNode?.data?.currentText,
      },
      type: "loading",
    };
    nodeId === "welcomeNode" &&
      setNodes((nds) => nds.filter((node) => node.id !== "welcomeNode"));
    setNodes((nds) => nds.concat(loadingNode));
    setFocusNodeId("loadingNode");
    const newEdge = {
      id: `e${nodeId}-loadingNode`,
      source: nodeId,
      target: "loadingNode",
      markerEnd: {
        type: MarkerType.ArrowClosed,
        color: "#4B0082",
      },
      style: {
        stroke: "#4B0082",
      },
    };
    setEdges((eds) => eds.concat(newEdge));
    addCanvaChat(request, {
      onSuccess: (response: any) => {
        var newNodes: any[] = [];
        const canvaChat = response?.data;
        newNodes.push({
          id: canvaChat.messageId,
          position: canvaChat.position,
          data: {
            value: canvaChat,
            currentText: "",
            updateNodeText: updateNodeText,
            handleEnterEvent: handleEnterEvent,
            onSubmit: onSubmit,
            updateSqlShow: updateSqlShow,
            onChoiceSelect: onChoiceSelect,
            onFeedbackSave: onFeedbackSave,
            focusNode: focusNode,
            handleClick: handleClick,
            handleGraphEditClick: handleGraphEditClick,
            showSql: false,
            showTextField: true,
            loadingGraph: true,
            loadingSummary: true,
            loadingFollowUp: canvaChat.type !== "text" ? true : false,
          },
          type: canvaChat?.type === "text" ? "chat" : "data",
        });
        setNodes((nds) => nds.concat(newNodes));
        if (nodeId !== "welcomeNode" && nodeId !== "loadingNode") {
          const newEdge = {
            id: `e${nodeId}-${canvaChat.messageId}`,
            source: nodeId,
            target: canvaChat.messageId,
            markerEnd: {
              type: MarkerType.ArrowClosed,
              color: "#4B0082",
            },
            style: {
              stroke: "#4B0082",
            },
          };
          const currentEgdes = reactFlowInstance.getEdges();
          const newEdges = currentEgdes.concat(newEdge);
          setEdges((eds) => eds.concat(newEdge));
          patchCanva({
            edges: newEdges,
          } as any);
        }
        setFocusNodeId(canvaChat.messageId);
        if (canvaChat.type !== "text") {
          postProcessing(canvaChat);
        }
      },
      onError: (error: any) => {
        var errorNodes: any[] = [];
        const errorNodeId = "errorNode-" + v4();
        errorNodes.push({
          id: errorNodeId,
          position: pos,
          data: {
            value: {
              question: text ? text : selectedNode?.data?.currentText,
              summary: `Seems like we were not able to find any relevant data to answer your question or we are experincing high load.

Can you be please elaborate a bit more on what you want to know or analyze or can point us to a relevant part of dataset to use for above question?
                
Or maybe retry again once...`,
            },
            currentText: "",
            updateNodeText: updateNodeText,
            handleEnterEvent: handleEnterEvent,
            updateSqlShow: updateSqlShow,
            onChoiceSelect: onChoiceSelect,
            onFeedbackSave: onFeedbackSave,
            onSubmit: onSubmit,
            focusNode: focusNode,
            handleClick: handleClick,
            handleGraphEditClick: handleGraphEditClick,
            showSql: false,
            showTextField: true,
          },
          type: "chat",
        });
        setNodes((nds) => nds.concat(errorNodes));
      },
      onSettled: () => {
        setNodes((nds) =>
          nds.filter(
            (node) => node.id !== "loadingNode" && node.id !== "welcomeNode"
          )
        );
        setEdges((eds) =>
          eds.filter((edge) => edge.id !== `e${nodeId}-loadingNode`)
        );
      },
    });
  };

  const onClick = () => {
    const flowNodes = reactFlowInstance.getNodes();
    const node = flowNodes[flowNodes.length - 1];
    var pos = {
      x: 0,
      y: 0,
    };
    if (node && node.position && node.height) {
      pos.y = node.position.y + node.height + 50;
    }
    const loadingNode = {
      id: "loadingNode",
      position: pos,
      data: {
        value: "",
        currentText: "testing",
      },
      type: "loading",
    };
    setFocusNodeId("loadingNode");
    setNodes((nds) => nds.concat(loadingNode));
  };

  useEffect(() => {
    if (focusNodeId !== "") {
      const flowNodes = reactFlowInstance.getNodes();
      const node = flowNodes.find((node) => node.id === focusNodeId);
      if (node && node?.height) {
        reactFlowInstance.fitView({
          nodes: [node],
          duration: 1000,
        });
        // setFocusNodeId("");
      }
    }
  }, [nodes, focusNodeId]);

  const toggleCanvaHistDrawer = () => {
    setOpenCanvaHistDrawer(!openCanvaHistDrawer);
  };

  const initialNodes = [
    {
      id: "welcomeNode",
      position: { x: 0, y: 0 },
      data: {
        value: { sampleQuestions: sampleQuestions },
        currentText: "",
        updateNodeText: updateNodeText,
        handleEnterEvent: handleEnterEvent,
        onSubmit: onSubmit,
      },
      type: "welcome",
    },
  ];

  if (isLoading || loadingUser) {
    return (
      <div
        style={{
          display: "flex",
          margin: "auto",
          width: 200,
          height: "100vh",
        }}
      >
        <Lottie animationData={loadingJson} loop={true} />
      </div>
    );
  }

  return (
    <div className={classes.canvaParent}>
      <CanvaHistDrawer
        canvaHistory={canvaHistory?.data}
        openCanvaHistDrawer={openCanvaHistDrawer}
        toggleCanvaHistDrawer={toggleCanvaHistDrawer}
      />
      <InnerCanva
        initialNodes={nodes}
        initialEdges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        onClick={onClick}
        proOptions={proOptions}
        openCanvaHistDrawer={openCanvaHistDrawer}
        toggleCanvaHistDrawer={toggleCanvaHistDrawer}
      />
      <Stripe
        openStripe={upgradeBanner}
        setStripe={setUpgradeBanner}
        email={email}
      />
      <Dialog
        open={showChartEditor}
        className={classes.editChartDialog}
        classes={{ paper: classes.paper }}
        onClose={() => setShowChartEditor(false)}
        fullScreen
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={patchingCanvaChat}
        >
          <CircularProgress sx={{ color: "#4B0082" }} />
        </Backdrop>
        <DialogTitle className={classes.titleContainer}>
          <div className={classes.title}>Edit Chart</div>
          <div className={classes.right}>
            <Button
              className={classes.saveButton}
              variant="contained"
              onClick={() => onSaveChartChanges()}
            >
              Save
            </Button>
            <Button
              className={classes.closeButton}
              variant="contained"
              onClick={() => setShowChartEditor(false)}
            >
              Close
            </Button>
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.graph}>
            <Graphs
              key={JSON.stringify(selectedCanva?.graph)}
              rows={selectedCanva?.rows}
              options={selectedCanva?.graph}
            />
          </div>
          <div className={classes.editor}>
            <ChartEditor
              selectedItem={selectedItem}
              updateItem={updateItem}
              getDataFromQuery={getDataFromId}
            />
          </div>
        </DialogContent>
      </Dialog>
      {anchorEl && (
        <Popover
          id="more-options-connections"
          open={openPopover}
          anchorEl={anchorEl}
          className={classes.addDashboardPopover}
          classes={{ paper: classes.paper }}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className={classes.list}>
            {dashboards?.data?.map((dashboard: any) => (
              <Button
                key={dashboard?.id}
                size="small"
                variant="text"
                onClick={() => addChartToDashboard(dashboard?.id)}
                endIcon={
                  updatingDashboard && selectedDashboard === dashboard?.id ? (
                    <CircularProgress sx={{ color: "#4B0082" }} size={12} />
                  ) : (
                    <></>
                  )
                }
              >
                {dashboard?.title}
              </Button>
            ))}
          </div>
        </Popover>
      )}
    </div>
  );
}
