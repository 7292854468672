import {
  ResponsiveContainer,
  XAxis,
  Tooltip,
  YAxis,
  CartesianGrid,
  AreaChart,
  Area,
} from "recharts";
import classes from "./Line.module.scss";
import { dataFormater } from "../utils/util";
import React, { memo } from "react";

type AreaProps = {
  chartOptions: any;
  rows: any;
};

function CustomArea({ chartOptions, rows }: AreaProps) {
  return (
    <ResponsiveContainer width={"100%"} height={"80%"} minHeight={"inherit"}>
      <AreaChart
        data={rows}
        className={classes.root}
        margin={{ top: 30, right: 30, left: -20, bottom: -10 }}
      >
        <CartesianGrid vertical={false} stroke="#E5E5E5" />
        <XAxis
          dataKey={chartOptions?.xCol}
          stroke="#E5E5E5"
          tick={{ fill: "#666" }}
        ></XAxis>
        <Tooltip cursor={{ fill: chartOptions?.yCols?.[0]?.backgroundColor }} />
        {chartOptions?.yCols?.map((yColObj: any, index: number) => (
          <React.Fragment key={index}>
            <YAxis
              orientation={index % 2 === 0 ? "left" : "right"}
              yAxisId={index}
              axisLine={false}
              stroke="#E5E5E5"
              tick={{ fill: "#666" }}
              domain={yColObj?.max ? [0, yColObj?.max] : ["auto", "auto"]}
              tickFormatter={(value) => dataFormater(value)}
            ></YAxis>
            <defs>
              <linearGradient id={yColObj?.color} x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={yColObj?.color}
                  stopOpacity={0.8}
                />
                <stop offset="95%" stopColor={yColObj?.color} stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              key={index}
              stackId={"1"}
              dataKey={yColObj?.yCol}
              stroke={yColObj?.color}
              fillOpacity={1}
              fill={`url(#${yColObj?.color})`}
              yAxisId={index}
            />
          </React.Fragment>
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default memo(CustomArea);
