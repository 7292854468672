import { useMutation, useQuery } from "react-query";
import { queryClient } from "../..";
import { BE_SERVER } from "../utils/constant";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "notistack";

export const useDashboards = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["dashboards", email], async () => {
    const token = await getAccessTokenSilently();
    const res = await fetch(`${BE_SERVER}/api/dashboards?email=${email}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error("Error while fetching dashboards");
    }

    return res.json();
  });
};

export const useDashboardDetails = (id: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["dashboard", id], async () => {
    const token = await getAccessTokenSilently();
    const res = await fetch(
      `${BE_SERVER}/api/dashboards/${id}?email=${email}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!res.ok) {
      throw new Error("Error while fetching dashboard details");
    }

    return res.json();
  });
};

export const useDashboardDetailsById = (id: string) => {
  return useQuery(["dashboard", id], async () => {
    const res = await fetch(`${BE_SERVER}/api/embed/dashboards/${id}`);

    if (!res.ok) {
      throw new Error("Error while fetching dashboard details");
    }

    return res.json();
  });
};

export const useAddDashboard = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const res = await fetch(
        `${BE_SERVER}/api/dashboards/${request.id}?email=${email}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(request),
        }
      );

      if (!res.ok) {
        throw new Error("Error while adding dashboard details");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dashboards", email]);
        enqueueSnackbar("Dashboard created", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Error while creating dashboard", { variant: "error" });
      },
    }
  );
};

export const usePatchDashboard = (dashboardId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const res = await fetch(
        `${BE_SERVER}/api/dashboards/${dashboardId}?email=${email}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(request),
        }
      );

      if (!res.ok) {
        throw new Error("Error while updating dashboard");
      }

      return res.text();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dashboard", dashboardId]);
        queryClient.invalidateQueries(["dashboards", email]);
        enqueueSnackbar("Dashboard updated", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Error while saving changes", { variant: "error" });
      },
    }
  );
};

export const usePatchDashboardEmail = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const res = await fetch(
        `${BE_SERVER}/api/dashboards/${request.id}/item?email=${email}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(request.obj),
        }
      );

      if (!res.ok) {
        throw new Error("Error while updating dashboard");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dashboards", email]);
        enqueueSnackbar("Block added to dashboard", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Error while adding block to dashboard", {
          variant: "error",
        });
      },
    }
  );
};

export const usePatchItem = (dashboardId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const res = await fetch(
        `${BE_SERVER}/api/dashboards/${dashboardId}/item?email=${email}`,
        {
          method: "PATCH",
          headers: headers,
          body: JSON.stringify(request),
        }
      );

      if (!res.ok) {
        throw new Error("Error while updating item");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        enqueueSnackbar("Updated successfully", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Error while updating", { variant: "error" });
      },
    }
  );
};

export const useDeleteDashboard = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (id) => {
      const token = await getAccessTokenSilently();
      const res = await fetch(
        `${BE_SERVER}/api/dashboards/${id}?email=${email}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!res.ok) {
        throw new Error("Error while deleting query");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dashboards", email]);
        enqueueSnackbar("Deleted successfully", {
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar("Error while deleting dashboard", {
          variant: "error",
        });
      },
    }
  );
};

export const useGenerateEmbedId = (dashboardId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation(async (request: any) => {
    const token = await getAccessTokenSilently();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const res = await fetch(
      `${BE_SERVER}/api/dashboards/${dashboardId}/embed?email=${email}`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(request),
      }
    );

    if (!res.ok) {
      const message = await res.json();
      throw new Error(message?.error);
    }

    return res.json();
  });
};
