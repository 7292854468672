import { useEffect, useState } from "react";
import { Graph, YCol } from "../models/Chat";
import CustomBar from "./Bar";
import CustomPie from "./Pie";
import CustomArea from "./Area";
import CustomLine from "./Line";

type ChartProps = {
  options: any;
  rows: any;
};

const Graphs = ({ options, rows }: ChartProps) => {
  const [chartOptions, setChartOptions] = useState<Graph>(options);
  const [formattedRows, setFormattedRows] = useState([]);

  useEffect(() => {
    const newChartOptions = { ...options };
    newChartOptions?.yCols?.forEach((yCol: YCol) => {
      const quantities = rows?.map((row: any) => row?.[yCol?.yCol]);
      yCol.min = Math.min(...quantities);
      yCol.max = Math.max(...quantities);
    });
    setChartOptions(newChartOptions);
    const newRows = rows?.map((obj: any) => {
      const newObj = { ...obj };
      for (const key in newObj) {
        if (!isNaN(newObj[key])) {
          newObj[key] = parseFloat(newObj[key]);
        }
      }
      return newObj;
    });
    setFormattedRows(newRows);
  }, [rows, options]);

  switch (chartOptions?.chartType) {
    case "line":
      return <CustomLine rows={formattedRows} chartOptions={chartOptions} />;
    case "area":
      return <CustomArea rows={formattedRows} chartOptions={chartOptions} />;
    case "bar":
      return <CustomBar rows={formattedRows} chartOptions={chartOptions} />;
    case "pie":
      return <CustomPie rows={formattedRows} chartOptions={chartOptions} />;
    default:
      return <></>;
  }
};

export default Graphs;
