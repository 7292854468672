import { CloseRounded } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
  TextField,
  Typography,
  Divider,
  Backdrop,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import classes from "./Connections.module.scss";
import { DATABASES } from "../utils/constant";
import { DbConnection } from "../models/DbConnection";
import { useEffect, useState } from "react";
import useQueryParameter from "../utils/util";
import Tables from "./Tables";
import { useAddConnection } from "../api/connections";
import { v4 } from "uuid";

type DbTypesProps = {
  dbDetailDialog: boolean;
  selectedDb: string;
  setDbDetailDialog: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: (newConn: DbConnection) => string[];
  tab: string;
};

export default function DbConnectionDetails({
  dbDetailDialog,
  selectedDb,
  setDbDetailDialog,
  onSave,
  tab,
}: DbTypesProps) {
  const email = useQueryParameter("email");
  const { mutate: addConnection, isLoading: addingConnection } =
    useAddConnection(email);

  const dbDetails = DATABASES[selectedDb];
  const [newConn, setNewConn] = useState<DbConnection>({
    displayType: selectedDb,
    type: DATABASES[selectedDb].type,
    portNumber: DATABASES[selectedDb].port,
    email: email,
    tab: tab,
    id: v4(),
    displayName: "",
    host: "",
    username: "",
    password: "",
    databaseName: "",
    schemaName: "",
  });
  const [tablesDialog, setTablesDialog] = useState(false);
  const [tables, setTables] = useState<string[]>([]);
  const [displayNameError, setDisplayNameError] = useState(false);
  const [hostError, setHostError] = useState(false);
  const [portNumberError, setPortNumberError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [databaseNameError, setDatabaseNameError] = useState(false);
  const [schemaNameError, setSchemaNameError] = useState(false);

  const validateInput = () => {
    if (!newConn.displayName) {
      setDisplayNameError(true);
      return false;
    }
    if (dbDetails?.type !== "snowflake" && newConn.host === "") {
      setHostError(true);
      return false;
    }
    if (dbDetails?.type !== "mongodb" && !newConn.portNumber) {
      setPortNumberError(true);
      return false;
    }
    if (dbDetails?.type === "snowflake" && newConn.host === "") {
      setHostError(true);
      return false;
    }
    if (newConn.username === "") {
      setUsernameError(true);
      return false;
    }
    if (newConn.password === "") {
      setPasswordError(true);
      return false;
    }
    if (newConn.databaseName === "") {
      setDatabaseNameError(true);
      return false;
    }
    if (dbDetails?.type === "snowflake" && newConn.schemaName === "") {
      setSchemaNameError(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    setNewConn({
      ...newConn,
      displayType: selectedDb,
      type: DATABASES[selectedDb].type,
      portNumber: DATABASES[selectedDb].port,
    });
  }, [selectedDb]);

  const onInputChange = (value: any, field: string) => {
    setNewConn({ ...newConn, [field]: value, type: selectedDb });
    if (field === "displayName") setDisplayNameError(false);
    if (field === "host") setHostError(false);
    if (field === "portNumber") setPortNumberError(false);
    if (field === "username") setUsernameError(false);
    if (field === "password") setPasswordError(false);
    if (field === "databaseName") setDatabaseNameError(false);
    if (field === "schemaName") setSchemaNameError(false);
  };

  const onNext = async () => {
    if (!validateInput()) {
      return;
    }
    addConnection(newConn as any, {
      onSuccess: (response) => {
        if (tab === "explore") {
          setTables(response?.data);
          // setNewConn({ ...newConn, id: response?.data?.id });
          setTablesDialog(true);
        }
        setDbDetailDialog(false);
      },
    });
  };

  return (
    <>
      <Dialog
        className={classes.dialogDetail}
        open={dbDetailDialog}
        classes={{ paper: classes.paper }}
        onClose={() => setDbDetailDialog(false)}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={addingConnection}
        >
          <CircularProgress sx={{ color: "#4B0082" }} />
        </Backdrop>
        <DialogTitle>
          <div className={classes.title}>Add connection</div>
          <div className={classes.subTitle}>
            Securely connect to a data source
          </div>
          <IconButton
            style={{ position: "absolute", top: 4, right: 4 }}
            onClick={() => setDbDetailDialog(false)}
          >
            <CloseRounded style={{ fontSize: "16px" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <div className={classes.block}>
            <div className={classes.label}>Display Name</div>
            <TextField
              size="small"
              sx={{ width: "100%" }}
              placeholder={dbDetails.name}
              value={newConn.displayName}
              onChange={(e) => onInputChange(e.target.value, "displayName")}
              error={displayNameError}
              helperText={displayNameError ? "Display Name is required" : ""}
            ></TextField>
          </div>
          {dbDetails?.type !== "snowflake" && (
            <div className={classes.adjacent}>
              <div className={classes.block} style={{ flex: "50%" }}>
                <div className={classes.label}>Host</div>
                <TextField
                  size="small"
                  sx={{ width: "100%" }}
                  value={newConn.host}
                  onChange={(e) => onInputChange(e.target.value, "host")}
                  error={hostError}
                  helperText={hostError ? "Host is required" : ""}
                ></TextField>
              </div>
              {dbDetails?.type !== "mongodb" && (
                <div className={classes.block}>
                  <div className={classes.label}>Port</div>
                  <TextField
                    type="number"
                    size="small"
                    sx={{ width: "100%" }}
                    placeholder={"" + dbDetails.port}
                    value={newConn.portNumber}
                    onChange={(e) =>
                      onInputChange(parseInt(e.target.value), "portNumber")
                    }
                    error={portNumberError}
                    helperText={
                      portNumberError ? "Port Number is required" : ""
                    }
                  ></TextField>
                </div>
              )}
            </div>
          )}
          {dbDetails?.type === "snowflake" && (
            <div className={classes.block} style={{ flex: "50%" }}>
              <div className={classes.label}>Account Name</div>
              <TextField
                size="small"
                sx={{ width: "100%" }}
                value={newConn.host}
                onChange={(e) => onInputChange(e.target.value, "host")}
                error={hostError}
                helperText={hostError ? "Account Name is required" : ""}
              ></TextField>
            </div>
          )}
          <div className={classes.adjacent}>
            <div className={classes.block}>
              <div className={classes.label}>User</div>
              <TextField
                size="small"
                sx={{ width: "100%" }}
                placeholder="user name"
                value={newConn.username}
                onChange={(e) => onInputChange(e.target.value, "username")}
                error={usernameError}
                helperText={usernameError ? "User is required" : ""}
              ></TextField>
            </div>
            <div className={classes.block}>
              <div className={classes.label}>Password</div>
              <TextField
                type="password"
                size="small"
                sx={{ width: "100%" }}
                placeholder="password"
                value={newConn.password}
                onChange={(e) => onInputChange(e.target.value, "password")}
                error={passwordError}
                helperText={passwordError ? "Password is required" : ""}
              ></TextField>
            </div>
          </div>
          <div className={classes.adjacent}>
            <div className={classes.block}>
              <div className={classes.label}>Database</div>
              <TextField
                size="small"
                sx={{ width: "100%" }}
                placeholder="database name"
                value={newConn.databaseName}
                onChange={(e) => onInputChange(e.target.value, "databaseName")}
                error={databaseNameError}
                helperText={databaseNameError ? "Database is required" : ""}
              ></TextField>
            </div>
            {dbDetails?.type === "snowflake" && (
              <div className={classes.block}>
                <div className={classes.label}>Schema</div>
                <TextField
                  size="small"
                  sx={{ width: "100%" }}
                  placeholder="schama name"
                  value={newConn.schemaName}
                  onChange={(e) => onInputChange(e.target.value, "schemaName")}
                  error={schemaNameError}
                  helperText={schemaNameError ? "Schema is required" : ""}
                ></TextField>
              </div>
            )}
          </div>
          <div className={classes.block}>
            {(dbDetails?.type === "mysql" ||
              dbDetails?.type === "postgres") && (
              <div className={classes.ssl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={newConn.sslEnabled}
                      size="small"
                      onChange={(e) => onInputChange(e.target.checked, "sslEnabled")}
                    />
                  }
                  label="Enable SSL"
                />
              </div>
            )}
          </div>
          <div className={classes.ip}>
            <div>
              If you maintain an IP whitelist you need to whitelist our static
              IP addresses:
            </div>
            <ul>
              <li>35.89.204.87</li>
              {/* <li>34.90.244.142</li> */}
            </ul>
          </div>
          <div className={classes.footer}>
            {/* <Button variant="outlined" className={classes.button} size="small">
                        Test
                    </Button> */}
            <Button
              variant="contained"
              className={classes.button}
              size="small"
              onClick={onNext}
            >
              {tab === "explore" ? "Next" : "Save"}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Tables
        tablesDialog={tablesDialog}
        tables={tables}
        id={newConn.id || ""}
        setTablesDialog={setTablesDialog}
      />
    </>
  );
}
