import { useParams } from "react-router-dom";
import classes from "./Embeds.module.scss";
import useQueryParameter, { dataFormater } from "../utils/util";
import { useChatDetailsById } from "../api/chat";
import { useEffect, useState } from "react";
import RowsView from "../utils/RowsView";
import { ChatItem } from "../models/Chat";
import TileView from "../utils/TileView";
import Graphs from "../charts/Graphs";

export default function Embeds() {
  const { blockId = "", chatId = "" } = useParams();
  const type = useQueryParameter("type");
  const { data: chatDetails, isLoading } = useChatDetailsById(chatId);
  const [blockDetails, setBlockDetails] = useState<ChatItem>();

  useEffect(() => {
    const block = chatDetails?.data?.chatItems?.find(
      (item: any) => item.blockId === blockId && item?.role === "system"
    );
    setBlockDetails(block);
  }, [chatDetails, blockId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={classes.root}>
      {type === "table" ? (
        <RowsView
          rows={blockDetails?.rows || []}
          blockId={blockId}
          showEmbed={false}
          showExport={false}
        />
      ) : type === "graph" ? (
        <div style={{height: "calc(100vh - 48px)"}}>
          <Graphs rows={blockDetails?.rows} options={blockDetails?.graph} />
        </div>
      ) : type === "tile" ? (
        <TileView
          title={blockDetails?.title}
          value={dataFormater(blockDetails?.rows?.[0][blockDetails?.value])}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
