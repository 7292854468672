import { memo } from "react";
import classes from "./ChatCanva.module.scss";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Handle, Position } from "reactflow";
import { AutoAwesomeOutlined, SendRounded } from "@mui/icons-material";
import logoGif from "../../icons/logo.gif";
import logo from "../../icons/new_logo.png";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { ClarifyingQuestion } from "../models/Canva";
import clsx from "clsx";

type Props = {
  id: string;
  data: any;
};

function ChatNode({ id, data }: Props) {
  return (
    <div className={classes.chatNode}>
      <Handle type="target" id={`t-${id}`} position={Position.Top} />
      <div className={classes.container}>
        <div className={classes.message}>{data.value.question}</div>
        {/* <ReactMarkdown
          className={classes.markdown}
          // children={data?.value?.message?.content?.[0]?.text?.value}
          children={data?.value?.clarifyingQuestions}
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
        /> */}
        <div className={classes.clarifyingQuestions}>
          {data?.value?.clarifyingQuestions?.map(
            (q: ClarifyingQuestion, i: number) => (
              <div className={classes.clarify} key={i}>
                <div className={classes.question}>{q.question}</div>
                <div className={classes.choices}>
                  {q?.choices?.map((a: string, j: number) => (
                    <div
                      key={j}
                      className={
                        q.selectedIndex === j
                          ? clsx(classes.choice, classes.selected)
                          : classes.choice
                      }
                      onClick={() => data.onChoiceSelect(id, i, j)}
                    >
                      {a}
                    </div>
                  ))}
                </div>
              </div>
            )
          )}
        </div>
        {data?.value?.summary && (
          <ReactMarkdown
            className={classes.markdown}
            children={data?.value?.summary}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          />
        )}
        {data?.showTextField && (
          <TextField
            value={data?.currentText}
            onChange={(e) => data.updateNodeText(id, e.target.value)}
            onKeyDown={(e) => data.handleEnterEvent(e, id)}
            sx={{
              width: "100%",
              //   padding: "0px 24px 24px 24px",
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderWidth: 1,
                  borderColor: "#4B0082",
                },
                fieldset: {
                  borderWidth: 1,
                  borderColor: "rgba(239, 217, 255, 0.6)",
                },
              },
            }}
            placeholder="Ask a question"
            // ref={inputRef}
            InputProps={{
              //   disabled: addingChat,
              autoFocus: true,
              style: {
                borderRadius: 8,
                fontSize: 8,
                fontWeight: 500,
                background: "#fff",
                cursor: "auto",
                paddingRight: "6px",
                height: 24,
              },
              onFocus: () => data.focusNode(id),
              startAdornment: (
                <InputAdornment position="start">
                  <img src={logo} alt="logo" width={12} height={12} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e) => data.onSubmit(id)}
                    className={classes.iconButton}
                  >
                    <SendRounded />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
        )}
      </div>
      <Handle type="source" id={`s-${id}`} position={Position.Bottom} />
    </div>
  );
}

export default memo(ChatNode);
