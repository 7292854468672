import { KeyboardArrowDownRounded } from "@mui/icons-material";
import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useQueryVariable } from "../utils/useQueryVariable";

type SelectProps = {
  item: any;
  metadata: any;
  rows: any;
  saveQueryRun?: (queryId: string, newParams: any) => void;
};

const Select = ({ item, metadata, rows, saveQueryRun }: SelectProps) => {
  const [placeholder, setPlaceholder] = useState<string>("");
  const [uniqueRows, setUniqueRows] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState<any | null>(null);
  const { vs, updateVs } = useQueryVariable();

  useEffect(() => {
    setSelectedOption(null);
    if (metadata.selectedColumn || metadata.selectedColumn === 0) {
      let colName = metadata?.columns[metadata?.selectedColumn]?.key;
      let colLabel = metadata?.columns[metadata?.selectedColumnLabel]?.key;

      let uniqueRows = Array.from(rows.reduce((acc:any, row:any) => {
        const value = row[colName];
        const label = row[colLabel ?? colName];
        acc.set(value, { value, label });
        return acc;
      }, new Map()).values());

      setPlaceholder(colName);
      console.log("Unique Rows", uniqueRows);
      setUniqueRows(uniqueRows);
    }
  }, [metadata, metadata.selectedColumn, metadata.selectedColumnLabel, rows]);

  const onChange = (newValue: any) => {
    setSelectedOption(newValue);
    updateVs(item?.title, newValue.value);
    const newState = { ...(vs ?? {}), [item?.title]: { value: newValue } };
    if (saveQueryRun) {
      item?.metadata?.triggerQueries?.forEach((q: any) => {
        saveQueryRun(q?.id, newState);
      })
    }
  };

  return (
    <Autocomplete
      value={selectedOption}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      options={uniqueRows || null}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option: any) => option?.label || ""}
      sx={{ width: "100%", marginLeft: "auto", marginTop: "4px" }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={metadata.placeholder}
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: <KeyboardArrowDownRounded sx={{ fontSize: 18 }} />,
            style: {
              height: 28,
              fontSize: 12,
              fontWeight: 600,
              padding: "2px 8px 4px 8px",
            },
          }}
        />
      )}
      renderOption={(props, option: any, { selected }) => {
        return (
          <li
            {...props}
            style={{
              fontWeight: selected ? 600 : 400,
              fontSize: 12,
              padding: "4px 16px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {option.label}
          </li>
        );
      }}
    />
  );
};

export default Select;
