import {
  Autocomplete,
  Chip,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import { Filter } from "../../models/Dashboard";
import { DbConnection } from "../../models/DbConnection";
import classes from "./Filter.module.scss";
import { DATABASES, OPERATORS } from "../../utils/constant";
import {
  CloseOutlined,
  CloseRounded,
  KeyboardArrowDownRounded,
  RemoveCircleOutlineRounded,
  RemoveCircleRounded,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { DarkTooltip } from "../../utils/util";

type Props = {
  index: number;
  parentIndex: number;
  filter: Filter;
  setFilter: (index: number, parentIndex: number, filter: Filter) => void;
  removeFilter: (index: number, parentIndex: number) => void;
  connections: DbConnection[];
};

export default function StringFilter({
  index,
  filter,
  parentIndex,
  connections,
  setFilter,
  removeFilter,
}: Props) {
  const selectedConnection =
    connections?.find((connection) => connection.id === filter.dataSourceId) ||
    connections[0];

  const [tables, setTables] = useState<string[]>([]);

  useEffect(() => {
    if (selectedConnection?.schemaJson) {
      const tablesArr: string[] = [];
      selectedConnection?.schemaJson?.forEach((table: any) => {
        tablesArr.push(Object.keys(table)[0]);
      });
      setTables(tablesArr);
    }
  }, [selectedConnection]);
  return (
    <div className={classes.root} key={index}>
      <Divider className={classes.divider}>
        Filter {index + 1}
        <DarkTooltip title="Remove" placement="top">
          <IconButton onClick={(_) => removeFilter(index, parentIndex)}>
            <RemoveCircleOutlineRounded style={{ fontSize: 12 }} />
          </IconButton>
        </DarkTooltip>
      </Divider>
      <div className={classes.select}>
        <div className={classes.title}>Datasource</div>
        <Autocomplete
          options={connections}
          sx={{ width: "80%", marginLeft: "auto" }}
          value={selectedConnection || null}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          getOptionLabel={(option: DbConnection) => option.displayName}
          onChange={(e, value) => {
            if (value) {
              setFilter(index, parentIndex, {
                ...filter,
                dataSourceId: value.id,
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <img
                    src={DATABASES[selectedConnection?.type]?.icon}
                    alt={selectedConnection?.type}
                    width={16}
                    height={16}
                  />
                ),
                endAdornment: (
                  <KeyboardArrowDownRounded sx={{ fontSize: 18 }} />
                ),
                style: {
                  height: 28,
                  fontSize: 12,
                  fontWeight: 600,
                  padding: "2px 8px 4px 16px",
                },
              }}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              style={{
                fontWeight: selected ? 600 : 400,
                fontSize: 12,
                padding: "4px 16px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img
                src={DATABASES[option.type].icon}
                alt={option.type}
                width={16}
                height={16}
              />
              {option.displayName}
            </li>
          )}
        />
      </div>
      <div className={classes.select}>
        <div className={classes.title}>Tables</div>
        <Autocomplete
          multiple
          options={tables}
          limitTags={3}
          sx={{ width: "80%", marginLeft: "auto" }}
          value={filter.tables || []}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option: string) => option}
          onChange={(e, value) => {
            if (value) {
              setFilter(index, parentIndex, {
                ...filter,
                tables: value,
              });
            }
          }}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                label={option}
                className={classes.chips}
                size="small"
              />
            ));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              placeholder="Select tables"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <KeyboardArrowDownRounded sx={{ fontSize: 18 }} />
                ),
                style: {
                  fontSize: 12,
                  fontWeight: 600,
                  padding: "2px 8px 4px 8px",
                },
              }}
            />
          )}
        />
      </div>
      <div className={classes.condition}>
        <TextField
          size="small"
          value={filter.column}
          className={classes.child}
          label="Column"
          onChange={(e) =>
            setFilter(index, parentIndex, {
              ...filter,
              column: e.target.value,
            })
          }
          InputLabelProps={{
            style: {
              fontSize: 12,
              marginTop: "-3px",
            },
          }}
          InputProps={{
            style: {
              fontSize: 12,
              height: 28,
              fontWeight: 600,
            },
          }}
        />
        <Autocomplete
          options={OPERATORS}
          className={classes.child}
          value={
            OPERATORS.find(
              (op) => op.value === filter.operator && op.type === filter.type
            ) || null
          }
          isOptionEqualToValue={(option, value) =>
            option?.value === value?.value && option?.type === value?.type
          }
          getOptionLabel={(option: any) => option.label}
          groupBy={(option: any) => option.type}
          onChange={(e, value) => {
            if (value) {
              setFilter(index, parentIndex, {
                ...filter,
                type: value.type,
                operator: value.value,
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Operator"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <KeyboardArrowDownRounded sx={{ fontSize: 18 }} />
                ),
                style: {
                  height: 28,
                  fontSize: 12,
                  fontWeight: 600,
                  padding: "2px 8px 4px 8px",
                },
              }}
            />
          )}
        />
        {filter.operator === "IN" ? (
          <Autocomplete
            clearIcon={false}
            options={[]}
            value={filter.values || []}
            freeSolo
            multiple
            sx={{ width: "33%", marginLeft: "auto" }}
            limitTags={4}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option}
                  {...getTagProps({ index })}
                  className={classes.chips}
                  size="small"
                />
              ))
            }
            onChange={(e, value) => {
              if (value) {
                setFilter(index, parentIndex, {
                  ...filter,
                  values: value,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Values"
                InputProps={{
                  ...params.InputProps,
                  style: {
                    fontSize: 12,
                    fontWeight: 600,
                    padding: "2px 8px 4px 8px",
                  },
                }}
              />
            )}
          />
        ) : (
          <TextField
            size="small"
            value={filter.values?.[0]}
            label="Value"
            className={classes.child}
            onChange={(e) =>
              setFilter(index, parentIndex, {
                ...filter,
                values: [e.target.value],
              })
            }
            InputLabelProps={{
              style: {
                fontSize: 12,
                marginTop: "-3px",
              },
            }}
            InputProps={{
              style: {
                fontSize: 12,
                height: 28,
                fontWeight: 600,
              },
            }}
          />
        )}
      </div>
    </div>
  );
}
