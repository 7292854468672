import classes from "./TileView.module.scss";

type TileViewProps = {
  title?: string;
  value: string;
  fontSize?: number;
  titleFontSize?: number;
};

export default function TileView({
  title,
  value,
  fontSize = 18,
  titleFontSize = 14,
}: TileViewProps) {
  return (
    <div className={classes.tile}>
      {title && (
        <div className={classes.key} style={{ fontSize: titleFontSize }}>
          {title}
        </div>
      )}
      <div className={classes.value} style={{ fontSize: fontSize }}>
        {value}
      </div>
    </div>
  );
}
