import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import classes from "./Bar.module.scss";
import { dataFormater } from "../utils/util";
import React, { memo } from "react";

type BarProps = {
  chartOptions: any;
  rows: any;
};

function CustomBar({ chartOptions, rows }: BarProps) {
  return (
    <ResponsiveContainer width={"100%"} height={"80%"} minHeight={"inherit"}>
      <BarChart
        data={rows}
        className={classes.root}
        margin={{ top: 30, right: 30, left: -20, bottom: -10 }}
      >
        <CartesianGrid vertical={false} stroke="#E5E5E5" />
        <XAxis
          dataKey={chartOptions?.xCol}
          stroke="#E5E5E5"
          tick={{ fill: "#666" }}
        ></XAxis>
        <Tooltip cursor={{ fill: "transparent" }} />
        {chartOptions?.yCols?.map((yColObj: any, index: number) => (
          <React.Fragment key={index}>
            <YAxis
              orientation={index % 2 === 0 ? "left" : "right"}
              yAxisId={index}
              axisLine={false}
              stroke="#E5E5E5"
              tick={{ fill: "#666" }}
              domain={yColObj?.max ? [0, yColObj?.max] : ["auto", "auto"]}
              tickFormatter={(value) => dataFormater(value)}
            ></YAxis>
            <Bar
              key={index}
              dataKey={yColObj?.yCol}
              fill={yColObj?.color}
              maxBarSize={30}
              radius={[2, 2, 0, 0]}
              yAxisId={index}
            />
          </React.Fragment>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default memo(CustomBar);
