import { useNavigate, useParams } from "react-router-dom";
import useQueryParameter, { decrypt } from "../../utils/util";
import { useGenerateEmbedId } from "../../api/key/dashbaord";
import Lottie from "lottie-react";
import loadingJson from "../../../icons/loading.json";
import { useEffect, useMemo } from "react";
import { ENCRYPTION_KEY } from "../../utils/constant";

export default function EmbededWrapper() {
  const { id = "", activeConnection = "" } = useParams();
  const hash = useQueryParameter("hash");
  const apiKey = useMemo((): string => {
    return decrypt(hash, ENCRYPTION_KEY);
  }, [hash]);
  const filterId: Number = +(useQueryParameter("filter") || 0);
  const parameters: any = JSON.parse(useQueryParameter("parameters") || "[]");
  const navigate = useNavigate();

  const { mutate: generateEmbedId, isLoading: generatingEmbedId } =
    useGenerateEmbedId(id, apiKey);

  useEffect(() => {
    if (generatingEmbedId) {
      return;
    }
    generateEmbedId(
      {
        filterId,
        parameters,
      } as any,
      {
        onSuccess: (response: any) => {
          const newDashboardId = response?.data;
          navigate({
            pathname: `/embed/dashboard/${newDashboardId}/${activeConnection}`,
            search: `?filter=${filterId}&hash=${encodeURIComponent(hash)}`,
          });
        },
      }
    );
  }, []);

  if (generatingEmbedId) {
    return (
      <div
        style={{
          display: "flex",
          margin: "auto",
          width: 200,
          height: "100vh",
        }}
      >
        <Lottie animationData={loadingJson} loop={true} />
      </div>
    );
  }

  return <></>;
}
