import { Skeleton } from "@mui/material";
import classes from "./ChatInterface.module.scss";
import { useEffect, useRef, useState } from "react";
import { Chat } from "../models/Chat";
import { useAuth0 } from "@auth0/auth0-react";
import useQueryParameter from "../utils/util";
import { useConnection, useConnectionById } from "../api/connections";
import {
  useAddChat,
  useAddChatGraph,
  useAddChatSummary,
  useAddFollowUpQuestions,
  useChatDetails,
  usePatchChat,
} from "../api/chat";
import { v4 } from "uuid";
import { useParams } from "react-router-dom";
import { useDashboards, usePatchDashboardEmail } from "../api/dashboard";
import { useLocalStorage } from "usehooks-ts";
import { useUser } from "../api/user";
import ChatInner from "./ChatInner";

export default function ChatInterface() {
  const { user } = useAuth0();
  const { chatId = v4() } = useParams();
  const email = useQueryParameter("email");
  const { data: serverUser } = useUser(user?.email || email);
  const { data: connections } = useConnection(user?.email || email);
  const {
    data: chatDetails,
    isLoading: loadingDetails,
    refetch,
  } = useChatDetails(chatId, user?.email || email);
  const { mutate: addChat, isLoading: addingChat } = useAddChat(chatId, email);
  const { mutate: patchChat, isLoading: patchingChat } = usePatchChat(
    chatId,
    email
  );
  const { mutateAsync: addChatGraph, isLoading: loadingGraphs } = useAddChatGraph(
    chatId,
    email
  );
  const { mutateAsync: addChatSummary, isLoading: loadingSummary } =
    useAddChatSummary(chatId, email);
  const { mutateAsync: addFollowUp, isLoading: loadingFollowUp } =
    useAddFollowUpQuestions(chatId, email);
  const { data: dashboards } = useDashboards(email);
  const { mutate: updateDashboard, isLoading: updatingDashboard } =
    usePatchDashboardEmail(email);
  const [activeConnection, setActiveConnection] = useLocalStorage(
    "saydata-active-connection",
    ""
  );
  const { data: connection, refetch: refetchConnection } = useConnectionById(
    user?.email || email,
    activeConnection
  );

  useEffect(() => {
    if (activeConnection) {
      refetchConnection();
      if (chatHistory?.chatItems?.length === 0) {
        const newChatItems = [...chatHistory?.chatItems];
        newChatItems.push({
          role: "welcome",
          blockId: v4(),
          clarifyingQuestions: [],
        });
        chatHistory.chatItems = newChatItems;
        setChatHistory(chatHistory);
      }
    }
  }, [activeConnection]);

  const chatRef = useRef<HTMLDivElement>(null);

  const [chatHistory, setChatHistory] = useState<Chat>({
    id: chatId,
    email: email,
    connectionId: activeConnection,
    chatItems: [],
  });

  useEffect(() => {
    setChatHistory({
      id: chatId,
      email: email,
      connectionId: activeConnection,
      chatItems: [],
    });
    refetch();
  }, [chatId]);

  useEffect(() => {
    if (!activeConnection && connections?.data?.length > 0) {
      setActiveConnection(connections.data[0]?.id);
      refetchConnection();
    }
  }, [connections]);

  useEffect(() => {
    if (chatDetails?.data) {
      if (chatDetails.data?.chatItems?.length > 0) {
        setChatHistory(chatDetails.data);
      } else {
        const newChatItems = [...chatDetails.data?.chatItems];
        newChatItems.push({
          role: "welcome",
          blockId: v4(),
        });
        chatDetails.data.chatItems = newChatItems;
        setChatHistory(chatDetails.data);
      }
    }
  }, [chatDetails]);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (chatRef.current) {
        const scrollOptions: ScrollToOptions = {
          top: chatRef.current.scrollHeight,
          behavior: "smooth",
        };
        chatRef.current.scrollTo(scrollOptions);
      }
    }, 100);
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory?.chatItems?.length]);

  return (
    <div className={classes.root}>
      {loadingDetails ? (
        <div className={classes.skelton}>
          <div className={classes.inner}>
            <Skeleton
              className={classes.skeltonBar}
              variant="rectangular"
              animation="wave"
              width={40}
              height={40}
            />
            <Skeleton
              variant="rectangular"
              width={"calc(100% - 40px)"}
              animation="wave"
              height={25}
              className={classes.skeltonBar}
            />
          </div>
          <div className={classes.inner}>
            <Skeleton
              className={classes.skeltonBar}
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={"15vh"}
            />
          </div>
          <div className={classes.inner}>
            <Skeleton
              className={classes.skeltonBar}
              variant="rectangular"
              animation="wave"
              width={60}
              height={20}
            />
            <Skeleton
              variant="circular"
              animation="wave"
              width={16}
              height={16}
            />
            <div style={{ display: "flex", gap: "12px", marginLeft: "auto" }}>
              <Skeleton
                variant="circular"
                animation="wave"
                width={12}
                height={12}
              />
            </div>
          </div>
          <div
            className={classes.inner}
            style={{ flexDirection: "column", alignItems: "unset" }}
          >
            <Skeleton
              className={classes.skeltonBar}
              variant="rectangular"
              animation="wave"
              width={"calc(35vw + 32px)"}
              height={25}
            />
            {[1, 2, 3].map((index) => (
              <div style={{ display: "flex", gap: "16px" }} key={index}>
                <Skeleton
                  className={classes.skeltonBar}
                  variant="rectangular"
                  animation="wave"
                  width={"10vw"}
                  height={25}
                />
                <Skeleton
                  className={classes.skeltonBar}
                  variant="rectangular"
                  animation="wave"
                  width={"15vw"}
                  height={25}
                />
                <Skeleton
                  className={classes.skeltonBar}
                  variant="rectangular"
                  animation="wave"
                  width={"10vw"}
                  height={25}
                />
              </div>
            ))}
          </div>
          <div className={classes.inner}>
            <Skeleton
              variant="rectangular"
              width={"100%"}
              animation="wave"
              height={"15vh"}
              className={classes.skeltonBar}
            />
          </div>
          <div className={classes.inner}>
            <Skeleton
              variant="rectangular"
              width={"calc(100% - 40px)"}
              animation="wave"
              height={"3vh"}
              className={classes.skeltonBar}
            />
            <Skeleton
              variant="circular"
              animation="wave"
              width={24}
              height={24}
            />
          </div>
        </div>
      ) : (
        <ChatInner
          email={user?.email || email}
          chatId={chatId}
          serverUser={serverUser}
          connections={connections}
          chatDetails={chatDetails}
          loadingDetails={loadingDetails}
          refetch={refetch}
          addChat={addChat}
          addingChat={addingChat}
          patchChat={patchChat}
          addChatGraph={addChatGraph}
          loadingGraphs={loadingGraphs}
          addChatSummary={addChatSummary}
          loadingSummary={loadingSummary}
          addFollowUp={addFollowUp}
          loadingFollowUp={loadingFollowUp}
          patchingChat={patchingChat}
          dashboards={dashboards}
          updateDashboard={updateDashboard}
          updatingDashboard={updatingDashboard}
          connection={connection}
          activeConnection={activeConnection}
          refetchConnection={refetchConnection}
        />
      )}
    </div>
  );
}
