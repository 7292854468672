import {
  ArrowDownwardRounded,
  IndeterminateCheckBoxOutlined,
  KeyboardArrowDownRounded,
  KeyboardDoubleArrowRightRounded,
  ErrorOutlineRounded,
} from "@mui/icons-material";
import { Query } from "../../models/Query";
import classes from "./QueryEditor.module.scss";
import {
  Autocomplete,
  Button,
  Divider,
  Drawer,
  IconButton,
  LinearProgress,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { DbConnection } from "../../models/DbConnection";
import {
  DATABASES,
  MONGO_ACTIONS,
  REFRESH_RATES,
  SQL_KEYWORDS,
} from "../../utils/constant";
import { Editor } from "@monaco-editor/react";
import SchemaView from "./SchemaView";
import { useRunQuery } from "../../api/connections";
import RowsView from "../../utils/RowsView";
import { usePatchQuery, useRunDataQuery } from "../../api/query";
import { convertJsToValue, replaceGlobalVariables } from "../../utils/util";
import { AutoSizer, List } from "react-virtualized";
import hljs from "highlight.js/lib/common";
import "highlight.js/styles/atom-one-light.css";
import { Filter } from "../../models/Dashboard";
import { enqueueSnackbar } from "notistack";
import { useQueryVariable } from "../../utils/useQueryVariable";

type Props = {
  dashboardId: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  query: Query;
  allQueries: Query[];
  connections: DbConnection[];
  email: string;
  lhsOpened: boolean;
  filters: Filter[];
};

const defaultDrawerHeight = 500;
const minDrawerHeight = 300;
const maxDrawerHeight = 800;
export default function QueryEditor({
  email,
  dashboardId,
  open,
  setOpen,
  query,
  connections,
  lhsOpened,
  allQueries,
  filters,
}: Props) {
  const { vs, updateVs } = useQueryVariable();
  const [drawerHeight, setDrawerHeight] = useState(defaultDrawerHeight);
  const { mutate: patchQuery, isLoading: savingQuery } = usePatchQuery(
    dashboardId,
    email
  );
  const [startY, setStartY] = useState(null);
  const [queryClone, setQueryClone] = useState(query);
  const [isDragging, setIsDragging] = useState(false);
  const [monacoInstance, setMonacoInstance] = useState<any>(null);
  const { mutate: mutateQuery, isLoading: runningQuery } =
    useRunDataQuery(email);
  const [sqlPreviewError, setSqlPreviewError] = useState<string>("");
  const [rightActiveTab, setRightActiveTab] = useState(
    query?.type === "js" ? "output" : "schema"
  );
  const [selectedConnection, setSelectedConnection] = useState<DbConnection>({
    id: "",
    displayName: "",
    type: "mysql",
    displayType: "mysql",
  });
  const [tables, setTables] = useState<string[]>([]);

  useEffect(() => {
    if (selectedConnection?.schemaJson) {
      const tablesArr: string[] = [];
      selectedConnection?.schemaJson?.forEach((table: any) => {
        tablesArr.push(Object.keys(table)[0]);
      });
      setTables(tablesArr);
    }
  }, [selectedConnection]);

  useEffect(() => {
    setQueryClone({ ...query });
    if (queryClone.type === "js") {
      setRightActiveTab("output");
    }
    setSqlPreviewError("");
  }, [query]);

  useEffect(() => {
    const root = document.getElementById("queryEditor");
    if (root) {
      if (lhsOpened) {
        root.style.setProperty("--left-margin", "220px");
      } else {
        root.style.setProperty("--left-margin", "38px");
      }
    }
  }, [lhsOpened]);

  const handleMouseDown = useCallback((e: any) => {
    setIsDragging(true);
    setStartY(e.clientY);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    setStartY(null);
  }, []);

  const handleMouseMove = useCallback(
    (e: any) => {
      if (isDragging && startY !== null) {
        const newHeight = drawerHeight + (startY - e.clientY);
        if (newHeight > minDrawerHeight && newHeight < maxDrawerHeight) {
          setDrawerHeight(newHeight);
        }
        setStartY(e.clientY);
      }
    },
    [isDragging, startY]
  );

  useEffect(() => {
    if (!!!selectedConnection?.id) {
      setSelectedConnection(connections?.[0]);
    }
  }, [connections]);

  useEffect(() => {
    if (query.connectionId) {
      const qColl = connections.find(
        (connection) => connection.id === query.connectionId
      );
      if (qColl) {
        setSelectedConnection(qColl);
      }
    }
  }, [query]);

  const handleEditorMount = (monaco: any, language: string) => {
    setMonacoInstance(monaco);
    if (language === "sql") {
      monaco.languages.registerCompletionItemProvider("sql", {
        triggerCharacters: [" ", "."],
        provideCompletionItems: (model: any, position: any) => {
          const word = model.getWordUntilPosition(position);
          const defaultRange = new monaco.Range(
            position.lineNumber,
            word.startColumn,
            position.lineNumber,
            word.endColumn
          );

          const suggestions = SQL_KEYWORDS.map((suggestion) => {
            return {
              label: suggestion,
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: suggestion,
              range: defaultRange,
            };
          });

          if (!!!selectedConnection?.schemaJson) {
            return { suggestions };
          }
          selectedConnection?.schemaJson?.forEach((row) => {
            const table = Object.keys(row)[0];
            suggestions.push({
              label: table,
              kind: monaco.languages.CompletionItemKind.Text,
              insertText: table,
              range: defaultRange,
            });
            const columns = row[table]?.columns;
            columns?.forEach((column: any) => {
              suggestions.push({
                label: column?.field,
                kind: monaco.languages.CompletionItemKind.Text,
                insertText: column?.field,
                range: defaultRange,
              });
            });
          });
          return { suggestions };
        },
      });
    }
  };

  useEffect(() => {
    if (monacoInstance) {
      handleEditorMount(monacoInstance, queryClone.type);
    }
  }, [selectedConnection, monacoInstance]);

  const handleQueryChange = (value: string) => {
    setQueryClone({
      ...queryClone,
      code: value,
    });
  };

  const runQuery = () => {
    if (queryClone.type === "js") {
      const value = convertJsToValue(queryClone.code, allQueries, vs);
      setQueryClone({
        ...queryClone,
        results: value,
      });
      setRightActiveTab("output");
    } else {
      let queryRequest;
      if (queryClone.type === "mongodb") {
        queryRequest = {
          queryOptions: queryClone.queryOptions,
          type: "mongodb",
          connectionId: selectedConnection.id,
          parameters: vs,
        };
      } else {
        queryRequest = {
          code: queryClone.code,
          connectionId: queryClone.connectionId,
          parameters: vs,
        };
      }
      setRightActiveTab("output");
      const request = {
        query: queryRequest,
        filters: filters,
      };
      mutateQuery(request as any, {
        onSuccess: (response) => {
          setSqlPreviewError("");
          setQueryClone({
            ...queryClone,
            results: response?.data,
          });
        },
        onError: (error: any) => {
          let errorMessage =
            error?.message?.split(":")[1]?.trim() || error?.message?.trim();
          setSqlPreviewError(errorMessage);
          setQueryClone({
            ...queryClone,
            results: [],
          });
        },
      });
    }
  };

  const saveQuery = () => {
    if (sqlPreviewError) {
      enqueueSnackbar("Cannot save query with errors", {
        variant: "error",
      });
      return;
    }
    const updatedQuery = { ...queryClone };
    if (queryClone.type === "js") {
      updatedQuery.results = [];
    }
    updatedQuery.parameters = vs;
    const request = {
      query: updatedQuery,
      filters: filters,
    };
    patchQuery(request as any);
  };

  // useEffect(() => {
  //   hljs.highlightAll();
  // }, [rightActiveTab]);

  const renderOutput = ({
    key,
    index,
    isScrolling,
    isVisisble,
    style,
  }: any) => {
    return (
      <pre>
        <code>{JSON.stringify(queryClone?.results?.[index], null, 2)}</code>
      </pre>
    );
  };

  const renderMongoOptions = () => {
    switch (queryClone?.queryOptions?.operation) {
      case "find":
        return (
          <>
            <div className={classes.selectSource}>
              <div className={classes.title}>Query</div>
              <TextField
                size="small"
                sx={{ width: "524px" }}
                value={queryClone?.queryOptions?.options?.query || ""}
                multiline
                onChange={(e) => {
                  setQueryClone({
                    ...queryClone,
                    queryOptions: {
                      ...queryClone?.queryOptions,
                      options: {
                        ...queryClone?.queryOptions?.options,
                        query: e.target.value,
                      },
                    },
                  });
                }}
                rows={3}
                placeholder={`{ "item": "apple", "numInventory": { $gte: 1 } }`}
                InputProps={{
                  style: {
                    fontSize: 12,
                  },
                }}
              />
            </div>
            <div className={classes.selectSource}>
              <div className={classes.title}>Projection</div>
              <TextField
                size="small"
                sx={{ width: "524px" }}
                value={queryClone?.queryOptions?.options?.projection || ""}
                onChange={(e) => {
                  setQueryClone({
                    ...queryClone,
                    queryOptions: {
                      ...queryClone?.queryOptions,
                      options: {
                        ...queryClone?.queryOptions?.options,
                        projection: e.target.value,
                      },
                    },
                  });
                }}
                placeholder={`{ "name": 1, "_id": 0 }`}
                InputProps={{
                  style: {
                    fontSize: 12,
                  },
                }}
              />
            </div>
            <div className={classes.selectSource}>
              <div className={classes.title}>Sort by</div>
              <TextField
                size="small"
                sx={{ width: "524px" }}
                value={queryClone?.queryOptions?.options?.sort || ""}
                onChange={(e) => {
                  setQueryClone({
                    ...queryClone,
                    queryOptions: {
                      ...queryClone?.queryOptions,
                      options: {
                        ...queryClone?.queryOptions?.options,
                        sort: e.target.value,
                      },
                    },
                  });
                }}
                placeholder={`{ "name": 1, "createdAt": -1 }`}
                InputProps={{
                  style: {
                    fontSize: 12,
                  },
                }}
              />
            </div>
            <div className={classes.selectSource}>
              <div className={classes.title}>Limit</div>
              <TextField
                size="small"
                sx={{ width: "524px" }}
                value={queryClone?.queryOptions?.options?.limit || 10}
                onChange={(e) => {
                  setQueryClone({
                    ...queryClone,
                    queryOptions: {
                      ...queryClone?.queryOptions,
                      options: {
                        ...queryClone?.queryOptions?.options,
                        limit: e.target.value,
                      },
                    },
                  });
                }}
                InputProps={{
                  style: {
                    fontSize: 12,
                  },
                }}
              />
            </div>
          </>
        );
      case "aggregate":
        return (
          <>
            <div className={classes.selectSource}>
              <div className={classes.title}>Aggregation</div>
              <TextField
                size="small"
                sx={{ width: "524px" }}
                value={queryClone?.queryOptions?.options?.aggregate || ""}
                multiline
                onChange={(e) => {
                  setQueryClone({
                    ...queryClone,
                    queryOptions: {
                      ...queryClone?.queryOptions,
                      options: {
                        ...queryClone?.queryOptions?.options,
                        aggregate: e.target.value,
                      },
                    },
                  });
                }}
                maxRows={6}
                placeholder={`[ 
 { "$match": { "color": "red" } }, 
 { "$group": { "_id": "$item", "totalQuantity": { "$sum": "$quantity" } } 
]`}
                InputProps={{
                  style: {
                    fontSize: 12,
                  },
                }}
              />
            </div>
          </>
        );
      case "count":
        return (
          <>
            <div className={classes.selectSource}>
              <div className={classes.title}>Query</div>
              <TextField
                size="small"
                sx={{ width: "524px" }}
                value={queryClone?.queryOptions?.options?.query || ""}
                multiline
                onChange={(e) => {
                  setQueryClone({
                    ...queryClone,
                    queryOptions: {
                      ...queryClone?.queryOptions,
                      options: {
                        ...queryClone?.queryOptions?.options,
                        query: e.target.value,
                      },
                    },
                  });
                }}
                rows={3}
                placeholder={`{ "item": "apple", "numInventory": { $gte: 1 } }`}
                InputProps={{
                  style: {
                    fontSize: 12,
                  },
                }}
              />
            </div>
          </>
        );
      case "distinct":
        return (
          <>
            <div className={classes.selectSource}>
              <div className={classes.title}>Query</div>
              <TextField
                size="small"
                sx={{ width: "524px" }}
                value={queryClone?.queryOptions?.options?.query || ""}
                multiline
                onChange={(e) => {
                  setQueryClone({
                    ...queryClone,
                    queryOptions: {
                      ...queryClone?.queryOptions,
                      options: {
                        ...queryClone?.queryOptions?.options,
                        query: e.target.value,
                      },
                    },
                  });
                }}
                rows={3}
                placeholder={`{ "item": "apple", "numInventory": { $gte: 1 } }`}
                InputProps={{
                  style: {
                    fontSize: 12,
                  },
                }}
              />
            </div>
            <div className={classes.selectSource}>
              <div className={classes.title}>Field</div>
              <TextField
                size="small"
                sx={{ width: "524px" }}
                value={queryClone?.queryOptions?.options?.field || ""}
                onChange={(e) => {
                  setQueryClone({
                    ...queryClone,
                    queryOptions: {
                      ...queryClone?.queryOptions,
                      options: {
                        ...queryClone?.queryOptions?.options,
                        field: e.target.value,
                      },
                    },
                  });
                }}
                placeholder={`items`}
                InputProps={{
                  style: {
                    fontSize: 12,
                  },
                }}
              />
            </div>
          </>
        );
      case "command":
        return (
          <>
            <div className={classes.selectSource}>
              <div className={classes.title}>Query</div>
              <TextField
                size="small"
                sx={{ width: "524px" }}
                value={queryClone?.queryOptions?.options?.query || ""}
                multiline
                onChange={(e) => {
                  setQueryClone({
                    ...queryClone,
                    queryOptions: {
                      ...queryClone?.queryOptions,
                      options: {
                        ...queryClone?.queryOptions?.options,
                        query: e.target.value,
                      },
                    },
                  });
                }}
                rows={3}
                placeholder={`{ "item": "apple", "numInventory": { $gte: 1 } }`}
                InputProps={{
                  style: {
                    fontSize: 12,
                  },
                }}
              />
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <Drawer
      open={open}
      variant="persistent"
      className={classes.queryEditor}
      id="queryEditor"
      classes={{ paper: classes.paper }}
      anchor="bottom"
      PaperProps={{ style: { height: drawerHeight } }}
    >
      <div className={classes.parent}>
        <div
          className={classes.dragger}
          onMouseDown={(e) => handleMouseDown(e)}
          onMouseUp={(e) => handleMouseUp()}
        ></div>
        {isDragging && (
          <div
            style={{
              position: "fixed",
              top: "0",
              right: "0",
              bottom: "0",
              left: "0",
            }}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
          ></div>
        )}
        <div className={classes.top}>
          <TextField
            size="small"
            value={queryClone.displayName}
            onChange={(e) => {
              setQueryClone({
                ...queryClone,
                displayName: e.target.value,
              });
            }}
            InputProps={{
              style: {
                height: 22,
                fontSize: 12,
                fontWeight: 600,
                margin: 8,
              },
            }}
          />
          <IconButton
            className={classes.minusIcon}
            onClick={() => setOpen(false)}
          >
            <IndeterminateCheckBoxOutlined />
          </IconButton>
        </div>
        <div className={classes.container}>
          <div className={classes.left}>
            <div className={classes.bar}>
              <div className={classes.title}>General</div>
              <Button className={classes.preview} onClick={runQuery}>
                Preview
              </Button>
              <Button className={classes.run} onClick={saveQuery}>
                Save
              </Button>
            </div>
            <div className={classes.content}>
              {queryClone.type !== "js" && (
                <>
                  <div className={classes.selectSource}>
                    <div className={classes.title}>Datasource</div>
                    <Autocomplete
                      options={connections}
                      value={selectedConnection || null}
                      isOptionEqualToValue={(option, value) =>
                        option?.id === value?.id
                      }
                      getOptionLabel={(option: DbConnection) =>
                        option.displayName
                      }
                      onChange={(e, value) => {
                        if (value) {
                          setSelectedConnection(value);
                          setQueryClone({
                            ...queryClone,
                            type: value.type,
                            connectionId: value.id,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{ width: "524px" }}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <img
                                src={DATABASES[selectedConnection?.type]?.icon}
                                alt={selectedConnection?.type}
                                width={16}
                                height={16}
                              />
                            ),
                            endAdornment: (
                              <KeyboardArrowDownRounded sx={{ fontSize: 18 }} />
                            ),
                            style: {
                              height: 28,
                              fontSize: 12,
                              fontWeight: 600,
                              padding: "2px 8px 4px 16px",
                            },
                          }}
                        />
                      )}
                      renderOption={(props, option, { selected }) => (
                        <li
                          {...props}
                          style={{
                            fontWeight: selected ? 600 : 400,
                            fontSize: 12,
                            padding: "4px 16px",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <img
                            src={DATABASES[option.type].icon}
                            alt={option.type}
                            width={16}
                            height={16}
                          />
                          {option.displayName}
                        </li>
                      )}
                    />
                  </div>
                  <div className={classes.selectSource}>
                    <div className={classes.title}>Refresh Frequency</div>
                    <Autocomplete
                      options={REFRESH_RATES}
                      value={
                        REFRESH_RATES.find(
                          (option) => option.value === queryClone.refreshRate
                        ) || null
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.value == value.value
                      }
                      getOptionLabel={(option: any) => option.label}
                      onChange={(e, value) => {
                        if (value) {
                          setQueryClone({
                            ...queryClone,
                            refreshRate: value.value,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{ width: "524px" }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <KeyboardArrowDownRounded sx={{ fontSize: 18 }} />
                            ),
                            style: {
                              height: 28,
                              fontSize: 12,
                              fontWeight: 600,
                              padding: "2px 8px 4px 16px",
                            },
                          }}
                        />
                      )}
                      renderOption={(props, option, { selected }) => (
                        <li
                          {...props}
                          style={{
                            fontWeight: selected ? 600 : 400,
                            fontSize: 12,
                            padding: "4px 16px",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          {option.label}
                        </li>
                      )}
                    />
                  </div>
                </>
              )}
              <Divider />
              {queryClone.type !== "mongodb" && (
                <Editor
                  value={queryClone.code}
                  className={classes.sqlEditor}
                  onChange={(value, _) => value && handleQueryChange(value)}
                  language={
                    queryClone.type === "js"
                      ? "javascript"
                      : queryClone.type === "mongodb"
                      ? "json"
                      : queryClone.type === "python"
                      ? "python"
                      : "sql"
                  }
                  theme="vs-dark"
                  height={drawerHeight - 200 + "px"}
                  options={{
                    fontSize: 12,
                    cursorBlinking: "smooth",
                    scrollbar: {
                      horizontal: "hidden",
                      verticalScrollbarSize: 8,
                    },
                    minimap: { enabled: false },
                  }}
                  onMount={(editor, monaco) =>
                    handleEditorMount(monaco, queryClone.type || "sql")
                  }
                />
              )}
              {queryClone.type === "mongodb" && (
                <div className={classes.mongoOptions}>
                  <div className={classes.selectSource}>
                    <div className={classes.title}>Collection</div>
                    <Autocomplete
                      options={tables}
                      value={queryClone?.queryOptions?.collection || null}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(option: string) => option}
                      onChange={(e, value) => {
                        if (value) {
                          setQueryClone({
                            ...queryClone,
                            queryOptions: {
                              ...queryClone?.queryOptions,
                              collection: value,
                            },
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{ width: "524px" }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <KeyboardArrowDownRounded sx={{ fontSize: 18 }} />
                            ),
                            style: {
                              height: 26,
                              fontSize: 12,
                              fontWeight: 600,
                              padding: "2px 8px 4px 4px",
                            },
                          }}
                        />
                      )}
                      renderOption={(props, option, { selected }) => (
                        <li
                          {...props}
                          style={{
                            fontWeight: selected ? 600 : 400,
                            fontSize: 12,
                            padding: "4px 16px",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          {option}
                        </li>
                      )}
                    />
                  </div>
                  <div className={classes.selectSource}>
                    <div className={classes.title}>Action type</div>
                    <Autocomplete
                      options={MONGO_ACTIONS}
                      value={queryClone?.queryOptions?.operation || null}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(option: string) => option}
                      onChange={(e, value) => {
                        if (value) {
                          setQueryClone({
                            ...queryClone,
                            queryOptions: {
                              ...queryClone?.queryOptions,
                              operation: value,
                            },
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{ width: "524px" }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <KeyboardArrowDownRounded sx={{ fontSize: 18 }} />
                            ),
                            style: {
                              height: 26,
                              fontSize: 12,
                              fontWeight: 600,
                              padding: "2px 8px 4px 4px",
                            },
                          }}
                        />
                      )}
                      renderOption={(props, option, { selected }) => (
                        <li
                          {...props}
                          style={{
                            fontWeight: selected ? 600 : 400,
                            fontSize: 12,
                            padding: "4px 16px",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          {option}
                        </li>
                      )}
                    />
                  </div>
                  {renderMongoOptions()}
                </div>
              )}
            </div>
          </div>
          <div className={classes.right}>
            <div className={classes.bar}>
              {queryClone.type !== "js" && (
                <Button
                  className={
                    rightActiveTab === "schema"
                      ? clsx(classes.textButton, classes.active)
                      : classes.textButton
                  }
                  onClick={(_) => setRightActiveTab("schema")}
                >
                  Schema
                </Button>
              )}
              <Button
                className={
                  rightActiveTab === "output"
                    ? clsx(classes.textButton, classes.active)
                    : classes.textButton
                }
                onClick={(_) => setRightActiveTab("output")}
              >
                Output
              </Button>
              {/* <IconButton className={classes.rightExpand}>
                <KeyboardDoubleArrowRightRounded />
              </IconButton> */}
            </div>
            {sqlPreviewError && (
              <div className={classes.error}>
                <ErrorOutlineRounded sx={{ fontSize: 16 }} />
                <p>{sqlPreviewError}</p>
              </div>
            )}
            {rightActiveTab === "schema" && (
              <SchemaView connection={selectedConnection} />
            )}
            {rightActiveTab === "output" &&
              (queryClone?.type === "js" || queryClone?.type === "mongodb" ? (
                runningQuery ? (
                  <LinearProgress
                    classes={{
                      colorPrimary: classes.linearPrimary,
                      barColorPrimary: classes.linearBarPrimary,
                    }}
                    sx={{ borderRadius: 4 }}
                  />
                ) : (
                  queryClone?.results ? (
                    <List
                      rowRenderer={renderOutput}
                      width={640}
                      height={1000}
                      rowHeight={30}
                      rowCount={queryClone?.results?.length || 5}
                      containerStyle={{
                        overflow: "auto",
                      }}
                    />
                  ) : (
                    <></>
                  )
                )
              ) : (
                <RowsView
                  rows={queryClone?.results || []}
                  blockId={queryClone?.id}
                  showExport={false}
                  loading={runningQuery}
                  fontSize={12}
                  gridHeight={drawerHeight - 80 + "px"}
                  showBorder={false}
                />
              ))}
          </div>
        </div>
      </div>
    </Drawer>
  );
}
