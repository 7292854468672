import { useMutation, useQuery } from "react-query";
import { Filter } from "../../models/Dashboard";
import { BE_SERVER } from "../../utils/constant";
import { queryClient } from "../../..";
import { useSnackbar } from "notistack";

export const useDashboardQueries = (
    dashboardId: string,
    apiKey: string,
    filters: Filter[]
  ) => {
    return useQuery(["dashboardQueries", dashboardId, filters], async () => {
      const headers = {
        ApiKey: `${apiKey}`,
        Filters: JSON.stringify(filters),
      };
      const res = await fetch(
        `${BE_SERVER}/api/data/query/dashboard/${dashboardId}`,
        {
          headers: headers,
        }
      );
  
      if (!res.ok) {
        throw new Error("Error while fetching queries for dashboard");
      }
  
      return res.json();
    });
  };

  export const usePatchQuery = (dashboardId: string, apiKey: string) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation(
      async (request: any) => {
        const headers = {
          ApiKey: `${apiKey}`,
        };
        const res = await fetch(
          `${BE_SERVER}/api/data/query/${request?.id}`,
          {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(request),
          }
        );
  
        if (!res.ok) {
          throw new Error("Error while updating query");
        }
  
        return res.text();
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["dashboardQueries", dashboardId],
          });
        },
        onError: () => {
          enqueueSnackbar("Error while updating", { variant: "error" });
        },
      }
    );
  };

  export const useRunDataQuery = (apiKey: string) => {
    return useMutation(async (request: any) => {
      const headers = {
        ApiKey: `${apiKey}`,
        "Content-Type": "application/json",
      };
      const res = await fetch(
        `${BE_SERVER}/api/data/query/${request.id}/run`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(request),
        }
      );
  
      if (res.ok) {
        return res.json();
      } else {
        const errorText = await res.text();
        const errorObj = JSON.parse(errorText || "{}");
        const errorMessage = errorObj.error || "Internal Server Error";
        throw new Error(errorMessage);
      }
    });
  };