import { useMutation, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { BE_SERVER } from "../../utils/constant";
import { queryClient } from "../../..";

export const useConnection = (apiKey: string) => {
  return useQuery(["connections", apiKey], async () => {
    const res = await fetch(`${BE_SERVER}/api/connections`, {
      headers: {
        ApiKey: `${apiKey}`,
      },
    });

    if (!res.ok) {
      throw new Error("Error while fetching connections");
    }

    return res.json();
  });
};

export const useConnectionById = (apiKey: string, id: string) => {
  return useQuery(["connection", id], async () => {
    const res = await fetch(
      `${BE_SERVER}/api/connections/${id}`,
      {
        headers: {
          ApiKey: `${apiKey}`,
        },
      }
    );

    if (!res.ok) {
      throw new Error("Error while fetching connection");
    }

    return res.json();
  });
};

export const useAddConnection = (apiKey: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (newConnection: any) => {
      const res = await fetch(
        `${BE_SERVER}/api/connections/${newConnection.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ApiKey: `${apiKey}`,
          },
          body: JSON.stringify(newConnection),
        }
      );

      if (!res.ok) {
        throw new Error("Error while adding connection");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["connections", apiKey]);
        enqueueSnackbar("Connection added successfully", {
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar("Error while adding connection", { variant: "error" });
      },
    }
  );
};

export const useModifyConnection = (
  apiKey: string,
  id: string
) => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request) => {
      const res = await fetch(
        `${BE_SERVER}/api/connections/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            ApiKey: `${apiKey}`,
          },
          body: JSON.stringify(request),
        }
      );

      if (!res.ok) {
        throw new Error("Error while adding connection");
      }

      return res.text();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["connection", id]);
        queryClient.invalidateQueries(["connections", apiKey]);
        enqueueSnackbar("Updated successfully", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Error while updating", { variant: "error" });
      },
    }
  );
};

export const useDeleteConnection = (apiKey: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (id) => {
      const res = await fetch(
        `${BE_SERVER}/api/connections/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            ApiKey: `${apiKey}`,
          },
        }
      );

      if (!res.ok) {
        throw new Error("Error while adding connection");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["connections", apiKey]);
        queryClient.invalidateQueries(["chatHistory", apiKey]);
        enqueueSnackbar("Connection deleted successfully", {
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar("Error while deleting connection", {
          variant: "error",
        });
      },
    }
  );
};

export const useConnectionMetadata = (
  apiKey: string,
  id: string
) => {
  return useQuery(["connection-metadata", id], async () => {
    const res = await fetch(
      `${BE_SERVER}/api/connections/metadata/${id}`,
      {
        headers: {
          ApiKey: `${apiKey}`,
        },
      }
    );

    if (!res.ok) {
      throw new Error("Error while fetching connection metadata");
    }

    return res.json();
  });
};

export const useUpdateConnectionMetadata = (
  apiKey: string,
  id: string
) => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (newConnectionMetadata) => {
      const res = await fetch(
        `${BE_SERVER}/api/connections/metadata/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            ApiKey: `${apiKey}`,
          },
          body: JSON.stringify(newConnectionMetadata),
        }
      );

      if (!res.ok) {
        throw new Error("Error while adding connection");
      }

      return res.text();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["connection-metadata", id]);
        enqueueSnackbar("Metadata updated successfully", {
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar("Error while updating metadata", { variant: "error" });
      },
    }
  );
};

export const useRegenerateMetadata = (apiKey: string) => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (id) => {
      const res = await fetch(
        `${BE_SERVER}/api/connections/metadata/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ApiKey: `${apiKey}`,
          },
        }
      );

      if (!res.ok) {
        throw new Error("Error while syncing metadata");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        enqueueSnackbar("Metadata regenerate initiated", {
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar("Error while initiating request", { variant: "error" });
      },
    }
  );
};
