import {
  DataGrid,
  GridColDef,
  GridCsvExportOptions,
  useGridApiContext,
} from "@mui/x-data-grid";
import classes from "../utils/RowsView.module.scss";
import {
  Button,
  CircularProgress,
  LinearProgress,
  Popover,
} from "@mui/material";
import embedIcon from "../../icons/embed.svg";
import embedFrame from "../../icons/embedframe.svg";
import noData from "../../icons/nodata.svg";
import { AddCircleOutlineRounded, DownloadRounded } from "@mui/icons-material";
import Banner from "../utils/Banner";
import { useState } from "react";
import { ChatItem } from "../models/Chat";

type CanvaRowViewProps = {
  rows: any;
  cols?: any;
  blockId: string;
  data?: any;
  showFooter?: boolean;
  showExport?: boolean;
  showEmbed?: boolean;
  gridHeight?: string;
  fontSize?: number;
  showBorder?: boolean;
  headerColor?: string;
};

export default function CanvaRowView({
  rows,
  cols = [],
  blockId,
  showFooter = true,
  showExport = true,
  showBorder = true,
  gridHeight = "40vh",
  headerColor = "#F6F2F9",
  fontSize = 14,
  data,
}: CanvaRowViewProps) {
  const [openEmbedBanner, setEmbedBanner] = useState(false);

  function CustomToolbar() {
    const apiRef = useGridApiContext();
    const [anchorElTable, setAnchorElTable] =
      useState<HTMLButtonElement | null>(null);
    const openTablePopover = Boolean(anchorElTable);
    //   const handleClick = (e: React.MouseEvent<HTMLButtonElement>, chat: any) => {
    //     if (serverUser?.data?.plan === "pro") {
    //       setAnchorElTable(e.currentTarget);
    //       setSelectedBlockType("table");
    //       setSelectedChat(chat);
    //     } else {
    //       setUpgradeBanner(true);
    //     }
    //   };

    const handleClose = () => {
      setAnchorElTable(null);
    };

    const handleExport = (options: GridCsvExportOptions) =>
      apiRef.current.exportDataAsCsv(options);
    return (
      <div className={classes.customToolbar}>
        <Button
          className={classes.smallTextButton}
          size="small"
          onClick={() =>
            handleExport({
              delimiter: ";",
              utf8WithBom: true,
              fileName: "export",
            })
          }
          startIcon={
            <DownloadRounded sx={{ fontSize: 18, paddingLeft: "5px" }} />
          }
        >
          Export
        </Button>
        {
          <Button
            style={{ marginLeft: "auto" }}
            className={classes.smallTextButton}
            // onClick={(e) =>
            //   !!!embed
            //     ? handleClick(e, chat)
            //     : addChartToDashboardEmbed(selectedDashboard, chat, "table")
            // }
            onClick={(e) => data?.handleClick(e, data?.value, "table")}
            size="small"
            startIcon={<AddCircleOutlineRounded />}
          >
            Add to Dashboard
          </Button>
        }
        {/* {chat && (
            <Button
              style={{ marginLeft: "auto" }}
              className={classes.textButton}
              onClick={(e) =>
                !!!embed
                  ? handleClick(e, chat)
                  : addChartToDashboardEmbed(selectedDashboard, chat, "table")
              }
              size="small"
              startIcon={<AddCircleOutlineRounded />}
            >
              Add to Dashboard
            </Button>
          )} */}
        {/* {showEmbed && chat && (
            <Button
              className={classes.textButton}
              onClick={() =>
                serverUser?.data?.plan === "pro"
                  ? openEmbedDialog(chat, "table")
                  : setUpgradeBanner(true)
              }
              size="small"
              startIcon={
                <img
                  src={embedIcon}
                  alt="Embed"
                  height={14}
                  style={{ paddingLeft: "5px" }}
                />
              }
            >
              Embed
            </Button>
          )} */}
        {/* <Popover
            id="rows-view-add-popper"
            open={openTablePopover}
            anchorEl={anchorElTable}
            className={classes.addDashboardPopover}
            classes={{ paper: classes.paper }}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div className={classes.list}>
              {dashboards?.data?.map((dashboard: any) => (
                <Button
                  key={dashboard?.id}
                  size="small"
                  variant="text"
                  onClick={() => addChartToDashboard(dashboard?.id)}
                  endIcon={
                    updatingDashboard && selectedDashboard === dashboard?.id ? (
                      <CircularProgress sx={{ color: "#4B0082" }} size={12} />
                    ) : (
                      <></>
                    )
                  }
                >
                  {dashboard?.title}
                </Button>
              ))}
            </div>
          </Popover> */}
      </div>
    );
  }

  function CustomLinearProgress() {
    return (
      <LinearProgress
        classes={{
          colorPrimary: classes.linearPrimary,
          barColorPrimary: classes.linearBarPrimary,
        }}
        sx={{ borderRadius: 4 }}
      />
    );
  }

  const columns: GridColDef[] =
    cols?.length > 0
      ? cols
          ?.filter((col: any) => !col?.hidden)
          .map((col: any) => {
            return {
              field: col?.key,
              headerName: col?.label,
              flex: 1,
              headerClassName: "table-header",
              headerAlign: col?.align || "center",
              cellClassName: "cell-class",
              minWidth: 150,
              disableColumnMenu: true,
              renderCell: (params: any) => (
                <div
                  style={{
                    backgroundColor: col?.backgroundColor || "transparent",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: col?.align || "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {params.value}
                </div>
              ),
            };
          })
      : rows?.length > 0
      ? Array.from(Object.keys(rows?.[0])).map((col_name) => {
          return {
            field: col_name,
            headerName: col_name,
            flex: 1,
            headerClassName: "table-header",
            align: "center",
            headerAlign: "center",
            cellClassName: "cell-class",
            minWidth: 150,
            disableColumnMenu: true,
          };
        })
      : [];

  const modifiedRows = rows?.map((row: any, index: number) => {
    return {
      ...row,
      id: blockId + "-" + index,
    };
  });
  return (
    <div
      className={classes.root}
      style={{ border: showBorder ? "1px solid rgba(224, 224, 224, 1)" : "" }}
    >
      <DataGrid
        style={
          rows?.length === 0
            ? { maxHeight: "20vh", minHeight: "10vh" }
            : { maxHeight: gridHeight }
        }
        rows={modifiedRows}
        columns={columns}
        sx={{
          fontSize: fontSize,
          border: "none",
          padding: "2px",
          "& .table-header": {
            background: headerColor,
          },
          "& .MuiDataGrid-cell": {
            padding: "0px",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: 600,
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "none",
          },
          "& .cell-class": {
            color: "rgba(0, 0, 0, 0.56)",
          },
          "& .MuiDataGrid-footerContainer": {
            minHeight: "unset",
            height: "20px",
          },
          "& .MuiTablePagination-displayedRows": {
            fontSize: fontSize,
          },
          "& .MuiTablePagination-action,button,svg": {
            fontSize: 12,
          },
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 50 } },
        }}
        columnHeaderHeight={20}
        hideFooter={!showFooter || rows?.length === 0}
        rowHeight={20}
        rowSelection={false}
        pageSizeOptions={[50]}
        slots={
          showExport && rows?.length > 0
            ? {
                toolbar: CustomToolbar,
                loadingOverlay: CustomLinearProgress,
              }
            : {
                loadingOverlay: CustomLinearProgress,
                noRowsOverlay: () => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img src={noData} alt="No Data" />
                  </div>
                ),
              }
        }
      />
      <Banner
        openBanner={openEmbedBanner}
        setBanner={setEmbedBanner}
        title="Embed Into Your App"
        description="Get embeddable link to direclty use them into supporting applications, such as Notion or get iframe for a webpage or any other supporting application."
        media={embedFrame}
        ctaLink="https://calendar.app.google/Ha83FJLswBiXTKCe6"
      />
    </div>
  );
}
